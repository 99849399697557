import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getGeneric } from "../../../store/generic/reducer";
import { fetchResource } from "../../../store/generic/actions";
import PageContentContainer from "../../Container/PageContentContainer";
import LegalbirdLoader from "../../ContentLoader/LegalbirdLoader";
import CustomerPageContent from "../../CustomerPageContent/CustomerPageContent";
import { Customer } from "../../../types/Customer";
import ApiClient from "../../../services/ApiClient";
import { getCaseLinkByBackofficeCase } from "../../../services/Product/ProductService";

export default function CustomerPage() {
    const dispatch = useDispatch();
    const { customerId } = useParams();
    const [backofficeCases, setBackofficeCases] = useState([]);
    const navigate = useNavigate();

    const customer: Customer = useSelector((state) => getGeneric(state, "customers" + customerId));

    useEffect(() => {
        if (!customer) {
            dispatch(fetchResource(customerId, "customers"));
        }
    }, []);

    useEffect(() => {
        if (!customer) {
            return;
        }
        ApiClient.get("backoffice_cases?customerEmail=" + encodeURIComponent(customer.email)).then((result) =>
            setBackofficeCases(result["hydra:member"])
        );
    }, [customer]);

    useEffect(() => {
        if (backofficeCases.length > 0) {
            navigate(getCaseLinkByBackofficeCase(backofficeCases[0]));
        }
    }, [backofficeCases]);

    if (!customer || !backofficeCases) {
        return <LegalbirdLoader centered />;
    }

    return (
        <PageContentContainer>
            <CustomerPageContent customer={customer} />
        </PageContentContainer>
    );
}
