import customerTranslations from "./customerTranslations";
import abstractCaseTranslations from "./abstractCaseTranslations";
import { courtTranslations } from "./courtTranslations";
import personTranslations from "./personTranslations";
import insuranceTranslations from "./insuranceTranslations";

const settlementTranslations = {
  label: "Kündigungsschutzklage",
  customer: customerTranslations,
  ...abstractCaseTranslations,
  responsibleLawyer: {
    label: "Anwalt",
  },
  leadStatus: {
    10: "Antrag erstellt",
    20: "Antrag ausgefüllt",
    30: "Vollmacht erteilt",
  },
  personalTextMessage: {
    label: "Fragen und Anmerkungen zum Fall",
  },
  reference: {
    label: "Unser Zeichen",
  },
  contractTerminated: {
    label: "Wurden Sie gekündigt?",
    values: {
      true: "Ja",
      false: "Nein",
    },
  },
  maritalStatus: {
    label: "Familienstand",
    values: {
      unmarried: "ledig",
      married: "verheiratet",
      divorced: "geschieden",
    },
  },
  partnerIsWorking: {
    label: "Ehegatte berufstätig",
    values: {
      true: "Ja",
      false: "Nein",
    },
  },
  numberChildren: {
    label: "Unterhaltspflichtige Kinder",
  },
  powerOfAttorney: {
    label: "Vollmacht erteilt am",
  },
  paymentType: {
    label: "Zahlart",
    values: {
      insurance: "Rechtsschutzversicherung",
      nwnf: "No-Win-No-Fee",
      private: "Selbstzahler",
    },
  },
  employedSince: {
    label: "Seit Wann im Unternehmen",
  },
  grossSalary: {
    label: "Bruttogehalt in €",
  },
  contractTerminationReceivedDate: {
    label: "Kündigung erhalten am",
  },
  terminationNoticeType: {
    label: "Kündigung fristlos oder fristgerecht?",
    values: {
      withoutNotice: "Fristlose Kündigung",
      notice: "Fristgerechte Kündigung",
      unsure: "Bin mir nicht sicher",
    },
  },
  preferredOutcome: {
    label: "Welches Ergebnis wünschen Sie sich?",
    values: {
      settlement: "Abfindung",
      employment: "Rückkehr auf den Arbeitsplatz",
    },
  },
  jobDescription: {
    label: "Ausgeübte Tätigkeit/Arbeitsbezeichung",
  },
  terminationObstacles: {
    label: "Besonderer Kündigungsschutz?",
    values: {
      pregnant: "Ich bin schwanger bzw. in Mutterschutz",
      parent: "Ich bin in Elternzeit",
      disabled: "Ich bin schwerbehindert",
      council: "Ich bin Betriebsratmitglied",
      privacyOfficer: "Ich bin Datenschutzbeauftragter",
      apprentice: "Ich bin Auszubildernder",
    },
  },
  employer: {
    numberOfEmployees: {
      label: "Anzahl Mitarbeiter",
      values: {
        10: "0 - 10",
        50: "11 - 50",
        500: "51 - 500",
        1000: "Mehr als 500",
      },
    },
    worksCouncil: {
      label: "Betriebsrat vorhanden?",
      values: {
        true: "Ja",
        false: "Nein",
      },
    },
    name: {
      label: "Arbeitgeber / Firmenname",
    },
    representedBy: {
      label: "Vertretungsberechtigter Arbeitgeber",
    },
    legalForm: {
      label: "Rechtsform Arbeitgeber",
      values: {
        GmbH: "GmbH",
        UG: "UG",
        GmbHCoKG: "GmbH & Co. KG",
        AG: "AG",
        OHG: "OHG",
        GbR: "GbR",
        Limited: "Limited",
        LimitedCoKG: "Limited & Co. KG",
        JuristischePersonDesOeffentlichenRechts: "Juristische Person des öffentlichen Rechts",
        natuerlichePerson: "natürliche Person",
        eingetragenerKaufmann: "eingetragener Kaufmann",
      },
    },
    address: {
      streetAddress: {
        label: "Arbeitgeber Straße",
      },
      postalCode: {
        label: "Arbeitgeber PLZ",
      },
      addressLocality: {
        label: "Arbeitgeber Ort",
      },
    },
  },
  costs: {
    valueInDispute: {
      label: "Streitwert",
    },
    insuranceCoverageDate: {
      label: "Kostendeckung bestätigt",
    },
    deductibleInvoiceDate: {
      label: "Selbstbehalt Rechnungsdatum",
    },
    deductibleInvoiceDueDate: {
      label: "Selbstbehalt Zahlungsziel",
    },
    deductibleInvoicePaidDate: {
      label: "Selbstbehalt bezahlt am",
    },
    nWnFAmount: {
      label: "Gebühr NWNF",
    },
  },
  responsibleCourt: {
    name: {
      label: "Zuständiges Gericht",
    },
  },
  insurance: insuranceTranslations,
  extended: {
    referenceCourt: {
      label: "Aktenzeichen Gericht",
    },
    dismissalProtectionSuitSubmittedDate: {
      label: "Klage eingereicht am",
    },
    insuranceClaimNumber: {
      label: "Schadennummer",
    },
    contractDate: {
      label: "Arbeitsvertrag vom",
    },
    responsibleCourt: courtTranslations,
    terminationType: {
      label: "Kündigungsart",
      values: {
        betriebsbedingteKuendigung: "betriebsbedingte Kündigung",
        personenbedingteKuendigung: "personenbedingte Kündigung",
        verhaltensbedingteKuendigung: "verhaltensbedingte Kündigung",
        krankheitsbedingteKuendigung: "krankheitsbedingte Kündigung",
        Aenderungskuendigung: "Änderungskündigung",
      },
    },
    protectionSuitText: {
      label: "Kündigungsschutz Klagetext",
    },
    bargainingCoverage: {
      label: "Tarifbindung",
      values: {
        true: "Ja",
        false: "Nein",
      },
    },
    lastWorkDayCalculated: {
      label: "Berechnete Kündigungsfrist",
    },
    contractTerminationDate: {
      label: "Datum Kündigungsschreiben",
    },
    lastWorkDayCancellation: {
      label: "Kündigungsfrist aus Schreiben",
    },
    finalJudgement: {
      label: "Urteil gefällt und Begründung vorliegend am",
    },
    settlementRevocationDate: {
      label: "Widerrufsfrist abgelaufen am",
    },
    dismissalProtectionSuitDeadline: {
      label: "Frist für Klage läuft ab am",
    },
    settlementReachedDate: {
      label: "Einigung protokolliert am",
    },
    settlementCourtDate: {
      appointmentDate: {
        label: "Gütetermin Datum",
      },
      appointmentTime: {
        label: "Gütetermin Uhrzeit",
      },
    },
    trialCourtDate: {
      appointmentDate: {
        label: "Kammertermin Datum",
      },
      appointmentTime: {
        label: "Kammertermin Uhrzeit",
      },
    },
  },
  client: personTranslations,
  petitioner: {
    gender: {
      label: "Anrede",
      values: {
        male: "Herr",
        female: "Frau",
      },
    },
    givenName: {
      label: "Vorname",
    },
    familyName: {
      label: "Nachname",
    },
    nationality: {
      label: "Staatsangehörigkeit",
    },
    birthDate: {
      label: "Geburtsdatum",
    },
    residenceAddress: {
      streetAddress: {
        label: "Straße und Hausnummer",
      },
      postalCode: {
        label: "PLZ",
      },
      addressLocality: {
        label: "Ort",
      },
    },
    addresses: [
      {
        streetAddress: {
          label: "Straße und Hausnummer",
        },
        postalCode: {
          label: "PLZ",
        },
        addressLocality: {
          label: "Ort",
        },
      },
    ],
  },
  requestDocumentText: {
    label: "Dokumente anfordern E-Mail-Text",
  },
  contract: {
    label: "Arbeitsvertrag",
  },
  contractTermination: {
    label: "Kündigung",
  },
};

export default settlementTranslations;
