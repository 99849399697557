import * as stagesSettlement from "../../Stages/StagesSettlement";
import {
  dateFieldDefault,
  insurancePickerValidator,
  moneyFieldDefault,
  numberFieldDefault,
  postalCodeDefault,
  textFieldDefault,
} from "../../validationRules";
import { formValue } from "../../formServiceFunctions";
import InputAdornment from "@mui/material/InputAdornment";
import React from "react";
import { trueFalseDefaultOptions } from "./formDefinitionFunctions";
import { getCourtAppointmentFields } from "./contract/fullFormDefinition";
import _ from "lodash";
import legalForms from "../../legalForms";
import { courtFields } from "./courtFields";
import { hasActiveCourtAppointment } from "../ProductService";

const lateFinancialInfo = [
  {
    type: "ValidatorDateField",
    path: "settlement.extended.settlementRevocationDate",
    validators: dateFieldDefault,
  },
  {
    type: "ValidatorTextField",
    path: "settlement.costs.valueInDispute",
    validators: moneyFieldDefault,
    additionalProps: {
      InputProps: {
        endAdornment: <InputAdornment position="start">€</InputAdornment>,
      },
    },
  },
  {
    type: "ValidatorTextField",
    path: "settlement.costs.nWnFAmount",
    validators: moneyFieldDefault,
    isHidden: ({ product }) => product.paymentType !== "nwnf",
    additionalProps: {
      InputProps: {
        endAdornment: <InputAdornment position="start">€</InputAdornment>,
      },
    },
  },
];

const settlementCourtFields = [
  {
    type: "ValidatorDateField",
    path: "settlement.extended.settlementReachedDate",
    validators: dateFieldDefault,
  },
];

const deductibleFields = [
  {
    type: "ValidatorDateField",
    path: "settlement.costs.deductibleInvoiceDate",
    isHidden: ({ product }) =>
      product.paymentType !== "insurance" || !product.insurance || product.insurance.deductible >= 150,
    validators: dateFieldDefault,
  },
  {
    type: "ValidatorDateField",
    path: "settlement.costs.deductibleInvoiceDueDate",
    isHidden: ({ product }) =>
      product.paymentType !== "insurance" || !product.insurance || product.insurance.deductible >= 150,
    validators: dateFieldDefault,
  },
  {
    type: "ValidatorDateField",
    path: "settlement.costs.deductibleInvoicePaidDate",
    isHidden: ({ product }) =>
      product.paymentType !== "insurance" || !product.insurance || product.insurance.deductible >= 150,
    validators: dateFieldDefault,
  },
];

const preChecked = [
  {
    type: "ValidatorDateField",
    path: "settlement.extended.dismissalProtectionSuitDeadline",
    validators: dateFieldDefault,
  },
  {
    type: "ValidatorTextField",
    path: "settlement.powerOfAttorney",
    isDisabled: () => true,
  },
  {
    type: "ValidatorSelect",
    path: "settlement.paymentType",
    options: () => [{ value: "__null__" }, { value: "insurance" }, { value: "nwnf" }, { value: "private" }],
  },
  {
    type: "ValidatorTextField",
    path: "settlement.costs.nWnFAmount",
    isHidden: ({ values }) => formValue(values, "settlement.paymentType") !== "nwnf",
    validators: moneyFieldDefault,
    additionalProps: {
      InputProps: {
        endAdornment: <InputAdornment position="start">€</InputAdornment>,
      },
    },
  },
  {
    type: "ValidatorSelect",
    path: "settlement.contractTerminated",
    options: () => trueFalseDefaultOptions("settlement.contractTerminated"),
  },
  {
    type: "ValidatorSelect",
    path: "settlement.employer.numberOfEmployees",
    options: () => [{ value: "__null__" }, { value: 10 }, { value: 50 }, { value: 500 }, { value: 5000 }],
  },
  {
    type: "ValidatorDateField",
    path: "settlement.employedSince",
    validators: [...dateFieldDefault],
  },
  {
    type: "ValidatorTextField",
    path: "settlement.grossSalary",
    validators: [...moneyFieldDefault],
    additionalProps: {
      InputProps: {
        endAdornment: <InputAdornment position="start">€</InputAdornment>,
      },
    },
  },
  {
    type: "ValidatorTextField",
    path: "settlement.jobDescription",
    validators: [...textFieldDefault],
  },
  {
    type: "ValidatorSelect",
    path: "settlement.employer.worksCouncil",
    options: () => trueFalseDefaultOptions("settlement.employer.worksCouncil"),
  },
  {
    type: "ValidatorSelect",
    path: "settlement.terminationObstacles",
    options: ({ values, product }) => {
      let obstacles = [];
      product.petitioner.gender === "female" && obstacles.push({ value: "pregnant" });
      obstacles.push({ value: "parent" });
      obstacles.push({ value: "disabled" });
      formValue(values, "settlement.employer.worksCouncil") === true && obstacles.push({ value: "council" });
      obstacles.push({ value: "privacyOfficer" });
      obstacles.push({ value: "apprentice" });
      return obstacles;
    },
    additionalProps: {
      multiple: true,
    },
  },
  {
    type: "ValidatorDateField",
    path: "settlement.contractTerminationReceivedDate",
    validators: [...dateFieldDefault],
  },
  {
    type: "ValidatorSelect",
    path: "settlement.terminationNoticeType",
    options: () => [{ value: "__null__" }, { value: "withoutNotice" }, { value: "notice" }, { value: "unsure" }],
  },
  {
    type: "ValidatorSelect",
    path: "settlement.preferredOutcome",
    options: () => [{ value: "__null__" }, { value: "settlement" }, { value: "employment" }],
  },
];

export const stageFormDefinition = {
  [stagesSettlement.STAGE_INCOMPLETE]: preChecked,
  [stagesSettlement.STAGE_READY]: preChecked,
  [stagesSettlement.STAGE_CHECKED]: [
    {
      type: "ValidatorDateField",
      path: "settlement.extended.dismissalProtectionSuitDeadline",
      isDisabled: () => true,
    },
    {
      type: "ValidatorDateField",
      path: "settlement.costs.insuranceCoverageDate",
      isHidden: ({ product }) => product.paymentType !== "insurance",
      validators: dateFieldDefault,
    },
  ],
  [stagesSettlement.STAGE_SUBMITTED]: [
    {
      type: "ValidatorDateField",
      path: "settlement.extended.dismissalProtectionSuitDeadline",
      isDisabled: () => true,
    },
    {
      type: "ValidatorDateField",
      path: "settlement.extended.dismissalProtectionSuitSubmittedDate",
      isDisabled: () => true,
    },
    {
      type: "ValidatorTextField",
      path: "settlement.extended.referenceCourt",
      validators: textFieldDefault,
    },
    {
      type: "ValidatorDateField",
      path: "settlement.costs.insuranceCoverageDate",
      isHidden: ({ product }) => product.paymentType !== "insurance",
      validators: dateFieldDefault,
    },
    ...deductibleFields,
    ...settlementCourtFields,
  ],
  [stagesSettlement.STAGE_PAID]: [
    {
      type: "ValidatorTextField",
      path: "settlement.extended.referenceCourt",
      validators: textFieldDefault,
    },
    {
      type: "ValidatorDateField",
      path: "settlement.costs.insuranceCoverageDate",
      isHidden: ({ product }) => product.paymentType !== "insurance",
      validators: dateFieldDefault,
    },
    ...deductibleFields,
    ...settlementCourtFields,
  ],
  [stagesSettlement.STAGE_DATE_SET_SETTLEMENT]: [
    {
      type: "ValidatorTextField",
      path: "settlement.extended.referenceCourt",
      validators: textFieldDefault,
    },
    ...deductibleFields,
    ...settlementCourtFields,
    ...lateFinancialInfo,
  ],
  [stagesSettlement.STAGE_DATE_SET_TRIAL]: [
    {
      type: "ValidatorTextField",
      path: "settlement.extended.referenceCourt",
      validators: textFieldDefault,
    },
    ...deductibleFields,
    {
      type: "ValidatorDateField",
      path: "settlement.extended.settlementReachedDate",
      validators: dateFieldDefault,
    },
    {
      type: "ValidatorDateField",
      path: "settlement.extended.finalJudgement",
      validators: dateFieldDefault,
      isHidden: ({ values }) => !!formValue(values, "settlement.extended.settlementReachedDate"),
    },
    ...lateFinancialInfo,
  ],
  [stagesSettlement.STAGE_DECISION_MADE]: [
    {
      type: "ValidatorTextField",
      path: "settlement.extended.referenceCourt",
      validators: textFieldDefault,
    },
    ...deductibleFields,
    {
      type: "ValidatorDateField",
      path: "settlement.extended.settlementReachedDate",
      validators: dateFieldDefault,
    },
    {
      type: "ValidatorDateField",
      path: "settlement.extended.finalJudgement",
      validators: dateFieldDefault,
      isHidden: ({ values }) => !!formValue(values, "settlement.extended.settlementReachedDate"),
    },
    ...lateFinancialInfo,
  ],
  [stagesSettlement.STAGE_COMPLETED]: [
    {
      type: "ValidatorDateField",
      path: "settlement.extended.settlementReachedDate",
      validators: dateFieldDefault,
      isDisabled: () => true,
      isHidden: ({ product }) => !product.extended.settlementReachedDate,
    },
    {
      type: "ValidatorDateField",
      path: "settlement.extended.finalJudgement",
      validators: dateFieldDefault,
      isDisabled: () => true,
      isHidden: ({ product }) => !product.extended.finalJudgement,
    },
    {
      type: "ValidatorTextField",
      path: "settlement.extended.referenceCourt",
      validators: textFieldDefault,
    },
  ],
};

export const fullFormDefinition = (product) => ({
  product: {
    sections: [
      {
        label: "Zur Person",
        elements: [
          {
            type: "ValidatorSelect",
            path: "settlement.petitioner.gender",
            options: () => [{ value: "male" }, { value: "female" }],
          },
          {
            type: "ValidatorTextField",
            path: "settlement.petitioner.givenName",
            validators: [...textFieldDefault],
          },
          {
            type: "ValidatorTextField",
            path: "settlement.petitioner.familyName",
            validators: [...textFieldDefault],
          },
          {
            type: "ValidatorDateField",
            path: "settlement.petitioner.birthDate",
            validators: [...dateFieldDefault],
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
          },
          {
            type: "ValidatorTextField",
            path: "settlement.petitioner.residenceAddress.streetAddress",
            validators: [...textFieldDefault],
          },
          {
            type: "ValidatorTextField",
            path: "settlement.petitioner.residenceAddress.postalCode",
            validators: [...textFieldDefault, postalCodeDefault],
          },
          {
            type: "ValidatorTextField",
            path: "settlement.petitioner.residenceAddress.addressLocality",
            validators: [...textFieldDefault],
          },
          {
            type: "ValidatorSelect",
            path: "settlement.maritalStatus",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            options: () => [{ value: "unmarried" }, { value: "married" }, { value: "divorced" }],
          },
          {
            type: "ValidatorSelect",
            path: "settlement.partnerIsWorking",
            options: () => trueFalseDefaultOptions("settlement.partnerIsWorking"),
            isDisabled: ({ values, product }) =>
              formValue(values, "settlement.maritalStatus") !== "married" ||
              product.stage > stagesSettlement.STAGE_READY,
          },
          {
            type: "ValidatorTextField",
            path: "settlement.numberChildren",
            validators: [...numberFieldDefault],
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
          },
        ],
      },
      {
        label: "Zur Kündigung",
        elements: [
          {
            type: "ValidatorSelect",
            path: "settlement.contractTerminated",
            options: () => trueFalseDefaultOptions("settlement.contractTerminated"),
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
          },
          {
            type: "ValidatorSelect",
            path: "settlement.employer.numberOfEmployees",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            options: () => [{ value: 10 }, { value: 50 }, { value: 500 }, { value: 1000 }],
          },
          {
            type: "ValidatorDateField",
            path: "settlement.employedSince",
            validators: [...dateFieldDefault],
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
          },
          {
            type: "ValidatorTextField",
            path: "settlement.grossSalary",
            validators: [...moneyFieldDefault],
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            additionalProps: {
              InputProps: {
                endAdornment: <InputAdornment position="start">€</InputAdornment>,
              },
            },
          },
          {
            type: "ValidatorTextField",
            path: "settlement.jobDescription",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            validators: [...textFieldDefault],
          },
          {
            type: "ValidatorSelect",
            path: "settlement.employer.worksCouncil",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            options: () => trueFalseDefaultOptions("settlement.employer.worksCouncil"),
          },
          {
            type: "ValidatorSelect",
            path: "settlement.terminationObstacles",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            options: ({ values, product }) => {
              let obstacles = [];
              product.petitioner.gender === "female" && obstacles.push({ value: "pregnant" });
              obstacles.push({ value: "parent" });
              obstacles.push({ value: "disabled" });
              formValue(values, "settlement.employer.worksCouncil") === true && obstacles.push({ value: "council" });
              obstacles.push({ value: "privacyOfficer" });
              obstacles.push({ value: "apprentice" });
              return obstacles;
            },
            additionalProps: {
              multiple: true,
            },
          },
          {
            type: "ValidatorDateField",
            path: "settlement.contractTerminationReceivedDate",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            validators: [...dateFieldDefault],
          },
          {
            type: "ValidatorSelect",
            path: "settlement.terminationNoticeType",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            options: () => [
              { value: "__null__" },
              { value: "withoutNotice" },
              { value: "notice" },
              { value: "unsure" },
            ],
          },
          {
            type: "ValidatorSelect",
            path: "settlement.preferredOutcome",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            options: () => [{ value: "__null__" }, { value: "settlement" }, { value: "employment" }],
          },
        ],
      },
      {
        label: "",
        elements: [
          {
            type: "ValidatorTextField",
            path: "settlement.personalTextMessage",
            isDisabled: () => true,
            additionalProps: {
              multiline: true,
              rows: 12,

              fullWidth: true,
            },
          },
        ],
      },
      {
        label: "Ergänzende Angaben für Klage",
        elements: [
          {
            type: "ValidatorDateField",
            path: "settlement.extended.dismissalProtectionSuitDeadline",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            validators: dateFieldDefault,
          },
          {
            type: "ValidatorDateField",
            path: "settlement.extended.contractTerminationDate",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            validators: dateFieldDefault,
          },
          {
            type: "ValidatorDateField",
            path: "settlement.extended.lastWorkDayCancellation",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            validators: dateFieldDefault,
          },
          {
            type: "ValidatorDateField",
            path: "settlement.extended.contractDate",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            validators: dateFieldDefault,
          },
          {
            type: "ValidatorDateField",
            path: "settlement.extended.lastWorkDayCalculated",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            validators: dateFieldDefault,
          },
          {
            type: "ValidatorSelect",
            path: "settlement.extended.bargainingCoverage",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            options: () => trueFalseDefaultOptions("settlement.extended.bargainingCoverage"),
          },
          {
            type: "ValidatorTextField",
            path: "settlement.employer.name",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            validators: textFieldDefault,
          },
          {
            type: "ValidatorTextField",
            path: "settlement.employer.representedBy",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            validators: textFieldDefault,
          },
          {
            type: "ValidatorSelect",
            path: "settlement.employer.legalForm",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            options: () => [
              { value: "__null__", label: "Keine Gesellschaftsform" },
              ..._.map(legalForms, (legalForm) => {
                return {
                  value: legalForm.label,
                  label: legalForm.label,
                };
              }),
            ],
          },
          {
            type: "ValidatorTextField",
            path: "settlement.employer.address.streetAddress",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            validators: textFieldDefault,
          },
          {
            type: "ValidatorTextField",
            path: "settlement.employer.address.postalCode",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            validators: [...textFieldDefault, postalCodeDefault],
          },
          {
            type: "ValidatorTextField",
            path: "settlement.employer.address.addressLocality",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            validators: textFieldDefault,
          },
          {
            type: "ValidatorSelect",
            path: "settlement.extended.terminationType",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            options: () => [
              { value: "__null__" },
              {
                value: "betriebsbedingte Kündigung",
                label: "betriebsbedingte Kündigung",
              },
              {
                value: "personenbedingte Kündigung",
                label: "personenbedingte Kündigung",
              },
              {
                value: "verhaltensbedingte Kündigung",
                label: "verhaltensbedingte Kündigung",
              },
              { value: "krankheitsbedingte Kündigung", label: "krankheitsbedingte Kündigung" },
              {
                value: "Änderungskündigung",
                label: "Änderungskündigung",
              },
            ],
          },
          {
            type: "ValidatorTextField",
            path: "settlement.extended.protectionSuitText",
            validators: textFieldDefault,
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            additionalProps: {
              multiline: true,
              rows: 8,
              fullWidth: true,
            },
          },
        ],
      },
    ],
  },
  additional: {
    sections: [
      {
        label: "",
        elements: [
          {
            type: "ValidatorTextField",
            path: "settlement.reference",
            isDisabled: () => true,
          },
          {
            type: "ValidatorTextField",
            path: "settlement.powerOfAttorney",
            isDisabled: () => true,
          },
          {
            type: "ValidatorDateField",
            path: "settlement.extended.dismissalProtectionSuitSubmittedDate",
            isDisabled: () => true,
          },
          {
            type: "ValidatorTextField",
            path: "settlement.extended.referenceCourt",
            validators: textFieldDefault,
          },
          {
            type: "ValidatorSelect",
            path: "settlement.paymentType",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            options: () => [{ value: "__null__" }, { value: "insurance" }, { value: "nwnf" }, { value: "private" }],
          },
          {
            type: "ValidatorTextField",
            path: "settlement.costs.valueInDispute",
            validators: moneyFieldDefault,
            isDisabled: ({ product }) => product.stage === stagesSettlement.STAGE_COMPLETED,
            additionalProps: {
              InputProps: {
                endAdornment: <InputAdornment position="start">€</InputAdornment>,
              },
            },
          },
          {
            type: "ValidatorTextField",
            path: "settlement.costs.nWnFAmount",
            validators: moneyFieldDefault,
            isHidden: ({ values }) => formValue(values, "settlement.paymentType") !== "nwnf",
            isDisabled: ({ product }) =>
              [stagesSettlement.STAGE_COMPLETED, stagesSettlement.STAGE_DECISION_MADE].includes(product.stage),
            additionalProps: {
              InputProps: {
                endAdornment: <InputAdornment position="start">€</InputAdornment>,
              },
            },
          },
        ],
      },
      {
        label: "Angaben zur Versicherung",
        isHidden: ({ values }) => formValue(values, "settlement.paymentType") !== "insurance",
        elements: [
          {
            type: "InsurancePicker",
            path: "settlement.insurance.insurance",
            validators: insurancePickerValidator(product),
          },
          {
            type: "ValidatorTextField",
            path: "settlement.insurance.insurancePolicyNumber",
            validators: textFieldDefault,
          },
          {
            type: "ValidatorTextField",
            path: "settlement.insurance.deductible",
            validators: moneyFieldDefault,
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            additionalProps: {
              InputProps: {
                endAdornment: <InputAdornment position="start">€</InputAdornment>,
              },
            },
          },
          {
            type: "ValidatorTextField",
            path: "settlement.insurance.caseReferenceNumber",
            validators: textFieldDefault,
            isHidden: ({ values }) => formValue(values, "settlement.paymentType") !== "insurance",
          },
        ],
      },
      {
        label: "Rechnungen & Zahlungen",
        isHidden: ({ product }) => product.paymentType === "nwnf",
        elements: [
          {
            type: "ValidatorDateField",
            path: "settlement.costs.insuranceCoverageDate",
            validators: dateFieldDefault,
            isHidden: ({ product }) => product.paymentType !== "insurance",
            isDisabled: ({ product }) => product.stage === stagesSettlement.STAGE_COMPLETED,
          },
          {
            type: "ValidatorDateField",
            path: "settlement.costs.deductibleInvoiceDate",
            validators: dateFieldDefault,
            isHidden: ({ product, values }) =>
              formValue(values, "settlement.paymentType") !== "insurance" ||
              !product.insurance ||
              product.insurance.deductible >= 150,
            isDisabled: ({ product }) =>
              [
                stagesSettlement.STAGE_INCOMPLETE,
                stagesSettlement.STAGE_READY,
                stagesSettlement.STAGE_CHECKED,
                stagesSettlement.STAGE_COMPLETED,
              ].includes(product.stage),
          },
          {
            type: "ValidatorDateField",
            path: "settlement.costs.deductibleInvoiceDueDate",
            validators: dateFieldDefault,
            isHidden: ({ product, values }) =>
              formValue(values, "settlement.paymentType") !== "insurance" ||
              !product.insurance ||
              product.insurance.deductible >= 150,
            isDisabled: ({ product }) =>
              [
                stagesSettlement.STAGE_INCOMPLETE,
                stagesSettlement.STAGE_READY,
                stagesSettlement.STAGE_CHECKED,
                stagesSettlement.STAGE_COMPLETED,
              ].includes(product.stage),
          },
          {
            type: "ValidatorDateField",
            path: "settlement.costs.deductibleInvoicePaidDate",
            validators: dateFieldDefault,
            isHidden: ({ product, values }) =>
              formValue(values, "settlement.paymentType") !== "insurance" ||
              !product.insurance ||
              product.insurance.deductible >= 150,
            isDisabled: ({ product }) =>
              [
                stagesSettlement.STAGE_INCOMPLETE,
                stagesSettlement.STAGE_READY,
                stagesSettlement.STAGE_CHECKED,
                stagesSettlement.STAGE_COMPLETED,
              ].includes(product.stage),
          },
        ],
      },
      {
        label: "Gerichtsdaten",
        elements: [
          {
            type: "ValidatorDateField",
            path: "settlement.extended.settlementReachedDate",
            validators: dateFieldDefault,
            isDisabled: ({ product }) =>
              ![
                stagesSettlement.STAGE_SUBMITTED,
                stagesSettlement.STAGE_PAID,
                stagesSettlement.STAGE_DATE_SET_SETTLEMENT,
                stagesSettlement.STAGE_DATE_SET_TRIAL,
              ].includes(product.stage),
          },
          {
            type: "ValidatorDateField",
            path: "settlement.extended.finalJudgement",
            validators: dateFieldDefault,
            isHidden: ({ product }) => !!product.extended.settlementReachedDate,
            isDisabled: ({ product }) =>
              ![stagesSettlement.STAGE_DECISION_MADE, stagesSettlement.STAGE_DATE_SET_TRIAL].includes(product.stage),
          },
          {
            type: "ValidatorDateField",
            path: "settlement.extended.settlementRevocationDate",
            validators: dateFieldDefault,
            isDisabled: ({ product }) =>
              ![
                stagesSettlement.STAGE_DATE_SET_SETTLEMENT,
                stagesSettlement.STAGE_DATE_SET_TRIAL,
                stagesSettlement.STAGE_DECISION_MADE,
              ].includes(product.stage),
          },
        ],
      },
      {
        label: product.courtAppointments.length === 1 ? "Gerichtstermin" : "Gerichtstermine",
        isHidden: ({ product }) => product.courtAppointments.length === 0,
        elements: getCourtAppointmentFields(product),
      },
    ],
  },
  court: {
    sections: [
      {
        elements: [
          {
            type: "CourtPicker",
            path: "settlement.extended.responsibleCourt",
            isDisabled: ({ product }) =>
              product.stage < stagesSettlement.STAGE_SUBMITTED || hasActiveCourtAppointment(product.courtAppointments),
            additionalProps: {
              fullWidth: true,
              courtTypes: ["Amtsgericht", "Arbeitsgericht", "Landgericht"],
            },
          },
          ...courtFields("settlement", "extended.responsibleCourt"),
        ],
      },
    ],
  },
});
