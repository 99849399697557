import customerTranslations from "./customerTranslations";
import basicCaseTranslations from "./basicCaseTranslations";
import contractClaimTranslations from "./contractClaimTranslations";

const eventContractTranslations = {
  customer: customerTranslations,
  ...basicCaseTranslations,
  ...contractClaimTranslations,
  label: "Veranstaltung",
  reasonForDispute: {
    label: "Problem mit Veranstaltung",
    values: {
      cancelled: "Veranstaltung wurde abgesagt",
      cancellationRefused: "Widerruf wird nicht akzeptiert",
      other: "Anderes Problem",
      custom: "Individueller Sachverhalt",
    },
  },
  contractNumber: {
    label: "Buchungsnummer",
  },
  eventType: {
    label: "Art der Veranstaltung",
    values: {
      private: "Privat",
      public: "Öffentlich"
    },
  },
  eventTitle: {
    label: "Name der Veranstaltung",
  },
  eventCity: {
    label: "Stadt der Veranstaltung",
  },
  eventDate: {
    label: "Datum der Veranstaltung",
  },
  numberOfTickets: {
    label: "Anzahl Tickets"
  }
};

export default eventContractTranslations;
