import React from "react";
import * as PropTypes from "prop-types";
import LinkListItemWithIcon from "../LinkListItemWithIcon/LinkListItemWithIcon";
import uploadImage from "../../assets/icon/hochladen_100px.png";
import bookingImage from "../../assets/icon/icon_buchen_100px.png";
import { List } from "@mui/material";
import _ from "lodash";
import { Roles } from "../../types/BackofficeUser";

const Payments = ({ currentUser }) => {
  const userHasOneOftheseRoles = (rolesToCheck) => {
    return _.intersection(currentUser.roles, rolesToCheck).length > 0;
  };

  return (
    <List>
      {userHasOneOftheseRoles(["ROLE_ADMIN", "ROLE_BANKING", "ROLE_DEVELOPER"]) && (
        <LinkListItemWithIcon
          title={"Hochladen"}
          subtitle={"mit CoBa CSV"}
          description={"Aus CoBa exportierte Zahlungseingänge können importiert werden"}
          to={"/services/tools/zahlungseingaenge-hochladen"}
          imageIcon={uploadImage}
        />
      )}
      {userHasOneOftheseRoles([Roles.admin, Roles.accountmanager, Roles.traineeLawyer, Roles.developer]) && (
        <LinkListItemWithIcon
          title={"Buchen"}
          subtitle={"in das Mandantenkonto"}
          description={"Manuell buchen, was nicht automatisch erkannt wurde"}
          to={"/services/tools/zahlungseingaenge-buchen"}
          imageIcon={bookingImage}
        />
      )}
    </List>
  );
};

Payments.propTypes = {
  currentUser: PropTypes.object.isRequired,
};

export default Payments;
