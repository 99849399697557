import React, { useState } from "react";
import * as PropTypes from "prop-types";
import ActionButton from "../../Actions/ActionButton";
import { caseRelatedActions, caseRelatedAdditionalActions } from "../../../services/navigationActionsService";
import _ from "lodash";
import CaseRelatedModals from "./CaseRelatedModals";
import CaseRelatedContainer from "./CaseRelatedContainer";
import { useSelector } from "react-redux";
import { getBackofficeUser } from "../../../store/backofficeUser/reducer";

const CaseRelatedActions = ({ product, refreshPage, refreshActivities, refreshEmails }) => {
  const currentUser = useSelector((state) => getBackofficeUser(state));
  const [open, setOpen] = useState("");
  const [useCondensedView, setUseCondensedView] = useState(false);

  const Actions = ({ actions }) => {
    return _.map(actions, (actionProps, index) => (
      <ActionButton key={index} useCondensedView={useCondensedView} {...actionProps} />
    ));
  };

  return (
    <div>
      <CaseRelatedContainer
        mainActions={<Actions actions={caseRelatedActions(product, setOpen, currentUser)} />}
        moreActions={<Actions actions={caseRelatedAdditionalActions(product, setOpen, currentUser)} />}
        useCondensedView={useCondensedView}
        setUseCondensedView={setUseCondensedView}
      />
      <CaseRelatedModals
        product={product}
        open={open}
        setOpen={setOpen}
        refreshPage={refreshPage}
        refreshActivities={refreshActivities}
        refreshEmails={refreshEmails}
      />
    </div>
  );
};

CaseRelatedActions.propTypes = {
  product: PropTypes.object.isRequired,
  refreshPage: PropTypes.func,
  refreshActivities: PropTypes.func,
  refreshEmails: PropTypes.func,
};

CaseRelatedActions.defaultProps = {
  refreshPage: () => {},
  refreshActivities: () => {},
  refreshEmails: () => {},
};

export default CaseRelatedActions;
