import InputAdornment from "@mui/material/InputAdornment";
import React from "react";
import _ from "lodash";
import { numberFieldDefault } from "../../validationRules";

export const courtFields = (productClassName, courtPath) => {

  return [
    {
      type: "ValidatorTextField",
      path: `${productClassName}.${courtPath}.name`,
      isDisabled: () => true,
    },
    {
      type: "ValidatorTextField",
      path: `${productClassName}.${courtPath}.address.streetAddress`,
      isDisabled: () => true,
    },
    {
      type: "ValidatorTextField",
      path: `${productClassName}.${courtPath}.address.addressLocality`,
      isDisabled: () => true,
    },
    {
      type: "ValidatorTextField",
      path: `${productClassName}.${courtPath}.address.postalCode`,
      isDisabled: () => true,
    },
    {
      type: "ValidatorTextField",
      path: `${productClassName}.${courtPath}.address.postOfficeBoxNumber`,
      isDisabled: () => true,
    },
    {
      type: "ValidatorTextField",
      path: `${productClassName}.${courtPath}.telephone`,
      isDisabled: () => true,
    },
    {
      type: "ValidatorTextField",
      path: `${productClassName}.${courtPath}.beaSafeId`,
      isDisabled: () => true,
    },
    {
      type: "ValidatorTextField",
      path: `${productClassName}.${courtPath}.fax`,
      isDisabled: () => true,
      additionalProps: {
        InputProps: {
          startAdornment: <InputAdornment position="start">+49</InputAdornment>,
        },
      },
    },
    {
      type: "ValidatorTextField",
      path: `${productClassName}.${courtPath}.advoAssistId`,
      isDisabled: ({product}) => !_.get(product, courtPath) || _.get(product, courtPath + ".advoAssistId"),
      validators: numberFieldDefault,
    },
  ];
};
