import React, { useMemo, useState } from "react";
import * as PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { getMediaObjectsIdentifier } from "../../hooks/useMediaObjects";
import useStyles from "./mediaObjectsStyle";
import { useSelector } from "react-redux";
import { getGeneric } from "../../store/generic/reducer";
import ContentBox from "../ContentBox/ContentBox";
import { Box, Stack, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import ApiClient from "../../services/ApiClient";
import { FolderOutlined, ListOutlined, StarOutline } from "@mui/icons-material";
import MediaObjectListView from "./MediaObjectListView";
import MediaObjectFolderView from "./MediaObjectFolderView";
import MediaObjectUserFilter from "./MediaObjectUserFilter";
import _ from "lodash";
import { getBackofficeUser } from "../../store/backofficeUser/reducer";
import { filterByFolder } from "../../services/mediaObjectService";
import MediaObjectOrderBySelect from "./MediaObjectOrderBySelect";

const MediaObjects = ({ product }) => {
  const classes = useStyles({});
  const [viewType, setViewType] = useState("list");
  const [userFilter, setUserFilter] = useState("allUsers");
  const [orderBy, setOrderBy] = useState("createdDESC");
  const mediaObjects = useSelector((state) => getGeneric(state, getMediaObjectsIdentifier(product)));
  const currentUser = useSelector((state) => getBackofficeUser(state));

  const mediaObjectsArray = mediaObjects ? mediaObjects["hydra:member"] : [];

  const filteredMediaObjects = useMemo(() => {
    if (userFilter === "allUsers") {
      return mediaObjectsArray;
    }

    return _.filter(mediaObjectsArray, (mediaObject) => {
      if (userFilter === "customer") {
        return mediaObject.createdBy === "customer";
      }
      return mediaObject.createdBy === userFilter;
    });
  }, [mediaObjects, userFilter]);

  const orderedMediaObjects = useMemo(() => {
    switch (orderBy) {
      case "originalNameASC":
        return _.orderBy(filteredMediaObjects, "originalName");
      case "originalNameDESC":
        return _.orderBy(filteredMediaObjects, "originalName", "desc");
      case "createdASC":
        return _.orderBy(filteredMediaObjects, "created");
      case "createdDESC":
      default:
        return _.orderBy(filteredMediaObjects, "created", "desc");
    }
  }, [filteredMediaObjects, orderBy]);

  const filteredListViewMediaObjects = useMemo(() => {
    return filterByFolder(orderedMediaObjects, "CD_");
  }, [orderedMediaObjects]);

  const filteredFavouritesViewMediaObjects = useMemo(() => {
    return filteredListViewMediaObjects.filter((mediaObject) => mediaObject.isFavourite);
  }, [filteredListViewMediaObjects]);

  if (!mediaObjects) {
    return null;
  }

  return (
    <>
      <div className={classes.scrollToAnchor} id={"documents"} />
      <ContentBox headline="Dokumente">
        <Stack direction={"row"} justifyContent={"flex-end"} spacing={2} sx={{ marginBottom: "1rem" }}>
          {!currentUser.isExternal && <ElectronicFileImport product={product} />}
          <MediaObjectOrderBySelect setOrderBy={setOrderBy} orderBy={orderBy} />
          <MediaObjectUserFilter
            mediaObjects={mediaObjectsArray}
            setUserFilter={setUserFilter}
            userFilter={userFilter}
          />
          <ToggleButtonGroup value={viewType} exclusive onChange={(event, newViewType) => setViewType(newViewType)}>
            <ToggleButton value={"list"}>
              <Tooltip title={"Liste"}>
                <ListOutlined />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value={"folderStructure"}>
              <Tooltip title={"Ordner"}>
                <FolderOutlined />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value={"favourites"}>
              <Tooltip title={"Favoriten"}>
                <StarOutline />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
        {viewType === "list" && (
          <MediaObjectListView mediaObjects={filteredListViewMediaObjects || []} product={product} />
        )}
        {viewType === "folderStructure" && (
          <MediaObjectFolderView mediaObjects={orderedMediaObjects || []} product={product} />
        )}
        {viewType === "favourites" && (
          <MediaObjectListView mediaObjects={filteredFavouritesViewMediaObjects || []} product={product} />
        )}
      </ContentBox>
    </>
  );
};

const ElectronicFileImport = ({ product }) => {
  const [electronicFileImport, setElectronicFileImport] = useState(false);
  if (product.productClassName !== "traffic") {
    return null;
  }

  const handleImport = () => {
    ApiClient.get("lb/services/batch_import/" + product.id);
    setElectronicFileImport(false);
  };

  return (
    <Box sx={{ textAlign: "right" }}>
      <Button fullWidth={false} onClick={() => setElectronicFileImport(true)}>
        CD-Import
      </Button>
      <LegalbirdIoModal
        handleClose={() => setElectronicFileImport(false)}
        open={electronicFileImport}
        title={"CD-Daten importieren"}
        submitButton={
          <Button variant={"contained"} onClick={handleImport}>
            Daten importieren
          </Button>
        }
      >
        <p className={"center"}>
          Nach dem Klick auf "Importieren" werden <strong>alle</strong> Dateien, welche sich im Google-Ordner
          "CD-Import" befinden, der E-Akte dieses Falls angehangen. Bitte sicherstellen, dass sich nur Dateien für
          diesen Fall im Import-Ordner befinden.
        </p>
      </LegalbirdIoModal>
    </Box>
  );
};

MediaObjects.propTypes = {
  product: PropTypes.object.isRequired,
};

export default MediaObjects;
