const infoBarStyle = {
  container: {
    textAlign: "center",
  },
  innerContainer: {
    padding: "1rem",
  },
  release: {
    backgroundColor: "#fff3cd",
  },
  error: {
    backgroundColor: "#f8d7da",
  },
};

export default infoBarStyle;
