import React, { useState } from "react";
import * as PropTypes from "prop-types";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { updateResource } from "../../../store/generic/actions";
import Snackbar from "../../Snackbar/Snackbar";
import LegalbirdAutoComplete from "../../AutoComplete/LegalbirdAutoComplete";
import { getRoleToDisplay, sortByRole } from "../../../services/backofficeUserService";
import { Box } from "@mui/material";
import { useBackofficeUser } from "../../../provider/BackofficeUserProvider";

const AssignedUser = ({ activity, updateActivities }) => {
  const { agents } = useBackofficeUser();
  const [assignedUser, setAssignedUser] = useState("not_assigned");

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const revertUserAssignment = () => {
    dispatch(
      updateResource(activity.id, "activities", {
        assignedUser: null,
      })
    );
    closeSnackbar();
  };

  if (!agents || !activity) {
    return null;
  }

  const handleAssignedUserChange = (event, value) => {
    setAssignedUser(value.id);
    dispatch(
      updateResource(activity.id, "activities", {
        assignedUser: value.id,
      })
    ).then(() => {
      updateActivities();
      enqueueSnackbar("", {
        content: () => (
          <div>
            <Snackbar buttonType={"function"} buttonFunction={() => revertUserAssignment()} />
          </div>
        ),
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    });
  };

  const options = getOptions(agents);

  const value = _.find(options, (option) => option.id === assignedUser);

  return (
    <>
      {activity.assignedUser ? (
        activity.assignedUser.person.fullname
      ) : (
        <Box sx={{ width: 180 }}>
          <LegalbirdAutoComplete
            label="Zu erledigen durch"
            value={value}
            getOptionDisabled={(option) => option.disabled}
            options={options}
            groupBy={(option) => option.groupBy}
            setValue={handleAssignedUserChange}
          />
        </Box>
      )}
    </>
  );
};

const getOptions = (agents) => {
  const users = _.map(sortByRole(agents), (agent) => {
    return {
      label: agent.person.fullname,
      id: agent["@id"],
      groupBy: getRoleToDisplay(agent),
    };
  });

  return [{ label: "Nicht zugeordnet", id: "not_assigned", disabled: true }, ...users];
};

AssignedUser.propTypes = {
  activity: PropTypes.object.isRequired,
  updateActivities: PropTypes.func,
};

AssignedUser.defaultProps = {
  updateActivities: () => {},
};

export default AssignedUser;
