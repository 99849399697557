import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchMediaObjects } from "../../../hooks/useMediaObjects";
import { useDispatch } from "react-redux";
import { fetchPaidTasks } from "../../../hooks/usePaidTasks";
import CaseOverviewPageLoader from "../../ContentLoader/CaseOverviewPageLoader";
import CaseError from "../../Case/CaseError/CaseError";
import CaseNotFound from "../../Case/CaseNotFound/CaseNotFound";
import PageContentContainer from "../../Container/PageContentContainer";
import { Grid } from "@mui/material";
import CaseAlerts from "../../Case/CaseAlerts/CaseAlerts";
import CaseOverviewBox from "../../Case/CaseOverviewBox/CaseOverviewBox";
import CaseNotifications from "../../CaseNotifications/CaseNotifications";
import CaseContact from "../../Case/CaseContact/CaseContact";
import PreleadInfoBox from "../../Case/CasePreleadInfo/PreleadInfoBox";
import CaseDetailsForm from "../../Case/CaseForm/Details/CaseDetailsForm";
import CaseCostBox from "../../Case/CaseCostBox/CaseCostBox";
import PinnedNotes from "../../PinnedNotes/PinnedNotes";
import PaidTasksCase from "../../PaidTask/PaidTasksCase/PaidTasksCase";
import Activities from "../../Activities/Activities";
import Emails from "../../Email/Emails";
import MediaObjects from "../../MediaObjectSection/MediaObjects";
import InvestigationAssessment from "../../InvestigationAssessment/InvestigationAssessment";
import CaseRelatedMediaObjects from "../../Case/CaseRelatedMediaObjects/CaseRelatedMediaObjects";
import CourtAppointments from "../../CourtAppointments/CourtAppointments";
import CaseRelatedActions from "../../Case/CaseRelatedActions/CaseRelatedActions";
import BeaMessages from "../../Bea/BeaMessages";
import NavigationExtensionCasePage from "../../NavigationBar/NavigationExtensionCasePage";
import CaseProvider, { useCase } from "../../../provider/CaseProvider";

const CasePage = () => {
  const { productId, productUrlPart } = useParams();
  return (
    <CaseProvider caseUrlPart={productUrlPart} caseId={productId}>
      <CasePageInner />
    </CaseProvider>
  );
};

const CasePageInner = () => {
  const dispatch = useDispatch();
  const [activitiesRefreshCounter, setActivitiesRefreshCounter] = useState(0);
  const [emailsRefreshCounter, setEmailsRefreshCounter] = useState(0);
  const [hasUpdates, setHasUpdates] = useState(false);
  const { product, isLoading, error, refreshCase } = useCase();

  useEffect(() => {
    fetchMediaObjects(product, dispatch);
    fetchPaidTasks(product, dispatch);
  }, [product, dispatch]);

  const refreshActivitites = () => {
    setActivitiesRefreshCounter(activitiesRefreshCounter + 1);
  };

  const refreshEmails = () => {
    setEmailsRefreshCounter(emailsRefreshCounter + 1);
  };

  const refreshPage = () => {
    refreshCase();
    refreshActivitites();
    refreshEmails();
    setHasUpdates(false);
  };

  if (isLoading) {
    return <CaseOverviewPageLoader />;
  }

  /* customer.isFake is a synonym for customer.isScheduledForDeletion */
  if (error?.status === 404 || product?.customer?.isFake) {
    return <CaseNotFound />;
  }

  if (!!error) {
    return <CaseError errorMessage={error.error} errorStatus={error.status} />;
  }

  return (
    <>
      <CaseRelatedActions
        product={product}
        refreshPage={refreshPage}
        refreshActivities={refreshActivitites}
        refreshEmails={refreshEmails}
      />
      <NavigationExtensionCasePage product={product} />
      <PageContentContainer>
        <CaseAlerts product={product} />
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <CaseOverviewBox product={product} refreshPage={refreshPage} hasUpdates={hasUpdates} />
          </Grid>
          <Grid item xs={12} lg={4}>
            <CaseContact product={product} />
            <PreleadInfoBox product={product} />
            <CaseDetailsForm product={product} key={product.id} refreshCase={refreshCase} />
            <CaseCostBox product={product} />
          </Grid>
          <Grid item xs={12} lg={8}>
            <PinnedNotes product={product} />
            <PaidTasksCase product={product} refreshFunction={() => fetchPaidTasks(product, dispatch)} />
            <Activities
              product={product}
              refreshCounter={activitiesRefreshCounter}
              refreshActivities={refreshActivitites}
            />
            <BeaMessages product={product} />
            <MediaObjects product={product} />
            <InvestigationAssessment product={product} />
            <CaseRelatedMediaObjects product={product} refreshPage={refreshPage} />
            <CourtAppointments product={product} refreshPage={refreshPage} />
            <Emails
              product={product}
              isExternalUser={false}
              refreshCounter={emailsRefreshCounter}
              refreshEmails={refreshEmails}
            />
          </Grid>
        </Grid>
      </PageContentContainer>
      <CaseNotifications product={product} refreshPage={refreshPage} setHasUpdates={setHasUpdates} />
    </>
  );
};

export default CasePage;
