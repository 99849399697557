import * as stagesCase from "../../Stages/StagesCase";

const otherProductSpecificDefinition = (product) => [
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.endOfContractDate`,
    isDisabled: ({product}) => product.stage >= stagesCase.STAGE_CHECKED,
  },
]

export default otherProductSpecificDefinition;
