import React, { useState } from "react";
import ButtonLoading from "../../Button/ButtonLoading";
import { Box, Stack, TextField, Typography } from "@mui/material";
import ContentBox from "../../ContentBox/ContentBox";
import { useDispatch, useSelector } from "react-redux";
import { getBackofficeUser } from "../../../store/backofficeUser/reducer";
import { DropzoneArea } from "mui-file-dropzone";
import { updateResource } from "../../../store/generic/actions";
import FetchBeaMessages from "./FetchBeaMessages";
import { fetch } from "../../../store/backofficeUser/actions";

export default function BeAData() {
  const currentUser = useSelector((state) => getBackofficeUser(state));
  const [files, setFiles] = useState([]);
  const [refreshDropZoneTrigger, setRefreshDropZoneTrigger] = useState(0);
  const [pin, setPin] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  if (!currentUser.isLawyer) {
    return null;
  }

  const handleSave = async () => {
    setIsLoading(true);
    const encodedFile = await toBase64(files[0]);
    const sanitizedEncodedFile = stripDataInfo(encodedFile);

    await dispatch(
      updateResource(currentUser.person.id, "backoffice_people", {
        encodedBeaCertificate: sanitizedEncodedFile,
        plainBeaPin: pin,
      })
    );
    dispatch(fetch());
    setPin("");
    setFiles([]);
    setRefreshDropZoneTrigger(refreshDropZoneTrigger + 1);
    setIsLoading(false);
  };

  return (
    <ContentBox headline={"beA"}>
      <Stack alignItems={"center"} spacing={3} sx={{ marginTop: "4rem" }}>
        <Typography variant={"h3"}>Upload beA-Zertifikat</Typography>
        {currentUser.person.beaData && (
          <Typography sx={{ color: "green" }}>Sie haben aktuell ein Zertifikat und einen PIN hinterlegt</Typography>
        )}
        {/*this key is unfortunately needed to reset the content of the dropzone*/}
        <Box key={refreshDropZoneTrigger} sx={{ width: "100%" }}>
          <DropzoneArea
            onChange={(files) => setFiles(files)}
            filesLimit={1}
            dropzoneText={"Zertifikat hochladen"}
            showFileNames
            showAlerts={false}
            maxFileSize={300000000}
            useChipsForPreview
            acceptedFiles={[".p12"]}
          />
        </Box>
        <TextField
          fullWidth={false}
          label={"PIN"}
          type={"password"}
          value={pin}
          onChange={(event) => setPin(event.target.value)}
        />
        <ButtonLoading
          variant={"contained"}
          disabled={files.length !== 1 || pin.length !== 6}
          onClick={handleSave}
          isLoading={isLoading}
          sx={{ width: 200 }}
        >
          Speichern
        </ButtonLoading>
      </Stack>
      <FetchBeaMessages />
    </ContentBox>
  );
}

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const stripDataInfo = (encodedFile) => {
  const cutOfPointIdentifier = ";base64,";
  return encodedFile.substring(encodedFile.indexOf(cutOfPointIdentifier) + cutOfPointIdentifier.length);
};
