import React from "react";
import PageContentContainer from "../../Container/PageContentContainer";
import PersonalData from "../../MyAccount/PersonalData";
import Billing from "../../MyAccount/Billing/Billing";
import Signature from "../../MyAccount/Signature/Signature";
import BeAData from "../../MyAccount/BeA/BeAData";
import PersonalHumanResourceManagement from "../../MyAccount/PersonalHumanResourceManagement";
import PageHeadline from "../../PageHeadline/PageHeadline";
import { useSelector } from "react-redux";
import { getBackofficeUser } from "../../../store/backofficeUser/reducer";
import NavigationExtensionMyAccount from "../../NavigationBar/NavigationExtensionMyAccount";
import { userHasRole } from "../../../services/backofficeUserService";

const MyAccountPage = () => {
  const currentUser = useSelector((state) => getBackofficeUser(state));

  return (
    <>
      <NavigationExtensionMyAccount />
      <PageContentContainer size={"small"}>
        <PageHeadline
          main={"Alle Details zum Konto von"}
          sub={`${currentUser.person.gender === "female" ? "Frau" : "Herrn"} ${currentUser.person.givenName} ${
            currentUser.person.familyName
          }`}
        />
        <PersonalData />
        <Billing />
        <Signature />
        <BeAData />
        {!userHasRole(currentUser, "ROLE_EXTERNAL") && <PersonalHumanResourceManagement />}
      </PageContentContainer>
    </>
  );
};
export default MyAccountPage;
