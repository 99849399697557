const settlementTransformations = {
  dateFields: [
    { path: "costs.insuranceCoverageDate", type: "date" },
    { path: "costs.deductibleInvoiceDate", type: "date" },
    { path: "costs.deductibleInvoiceDueDate", type: "date" },
    { path: "costs.deductibleInvoicePaidDate", type: "date" },
    { path: "extended.settlementReachedDate", type: "date" },
    { path: "extended.settlementRevocationDate", type: "date" },
    { path: "extended.dismissalProtectionSuitDeadline", type: "date" },
    { path: "petitioner.birthDate", type: "date" },
    { path: "employedSince", type: "date" },
    { path: "contractTerminationReceivedDate", type: "date" },
    { path: "extended.contractTerminationDate", type: "date" },
    { path: "extended.contractDate", type: "date" },
    { path: "powerOfAttorney", type: "dateTime" },
    { path: "extended.finalJudgement", type: "date" },
    { path: "extended.lastWorkDayCalculated", type: "date" },
    { path: "extended.lastWorkDayCancellation", type: "date" },
    { path: "verification", type: "dateTime" },
  ],
  numberFields: ["leadStatus", "applicationStatus", "numberChildren", "employer.numberOfEmployees", "extended.responsibleCourt.advoAssistId"],
  moneyFields: ["grossSalary", "costs.valueInDispute", "costs.nWnFAmount", "insurance.deductible"],
};
export default settlementTransformations;
