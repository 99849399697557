import {
  toIban,
  transformFromFormValues,
  transformMomentsToStrings,
  transformToFormValues
} from "../formServiceFunctions";
import _ from "lodash";
import ApiClient from "../ApiClient";
import { updateResource } from "../../store/generic/actions";
import { getProductData } from "../Product/ProductService";

export const persist = async (values, origProduct, dispatch) => {
  const transformedDateValues = transformMomentsToStrings(
    values[origProduct.productClassName],
    origProduct.productClassName
  );
  let updateData = difference(transformedDateValues, transformToFormValues(origProduct));
  if (_.has(updateData, "terminationObstacles")) {
    updateData.terminationObstacles = transformedDateValues.terminationObstacles;
  }
  if (_.has(updateData, "contractualPartnerLegalEntity.additionalRepresentatives")) {
    updateData.contractualPartnerLegalEntity.additionalRepresentatives =
      transformedDateValues.contractualPartnerLegalEntity.additionalRepresentatives;
  }
  updateData = transformFromFormValues(origProduct.productClassName, updateData);
  const productPersister = getProductData(origProduct.productClassName, "persist");
  await productPersister(updateData, origProduct, dispatch);
};

export const persistDivorce = async (updateData, origDivorce, dispatch) => {
  const subEntityRequests = [];

  if (_.isObject(updateData.customer)) {
    const customerUpdateData = getUpdateData(updateData.customer, origDivorce, "customer");
    subEntityRequests.push(() => persistCustomer(origDivorce.customer.id, customerUpdateData));
  }

  if (_.isObject(updateData.courtDate)) {
    const courtDateUpdateData = getUpdateData(updateData.courtDate, origDivorce, "courtDate");
    subEntityRequests.push(() => persistAppointment(origDivorce.courtDate.id, courtDateUpdateData));
  }

  if (_.isObject(updateData.petitioner)) {
    const petitionerUpdateData = getUpdateData(updateData.petitioner, origDivorce, "petitioner");
    subEntityRequests.push(() => persistPerson(origDivorce.petitioner.id, petitionerUpdateData));
  }

  if (_.isObject(updateData.responsibleCourt)) {
    const courtUpdateData = getUpdateData(updateData.responsibleCourt, origDivorce, "responsibleCourt");
    !_.isEmpty(courtUpdateData) && (await persistCourt(origDivorce.responsibleCourt.id, courtUpdateData));
  }

  if (_.isObject(updateData.insurance)) {
    let insuranceUpdateData = getUpdateData(updateData.insurance, origDivorce, "insurance");
    if (_.has(updateData.insurance, "insurance.id")) {
      insuranceUpdateData.insurance = `/insurances/${updateData.insurance.insurance.id}`;
    }
    subEntityRequests.push(() => persistInsurance(origDivorce.insurance.id, insuranceUpdateData));
  }

  if (_.has(updateData, "petitioner.residenceAddress") && _.isObject(updateData.petitioner.residenceAddress)) {
    const petitionerAddressUpdateData = getUpdateData(
      updateData.petitioner.residenceAddress,
      origDivorce,
      "petitioner.residenceAddress"
    );
    subEntityRequests.push(() =>
      persistAddress(origDivorce.petitioner.residenceAddress.id, petitionerAddressUpdateData)
    );
  }

  if (_.isObject(updateData.partner)) {
    const partnerUpdateData = getUpdateData(updateData.partner, origDivorce, "partner");
    subEntityRequests.push(() => persistPerson(origDivorce.partner.id, partnerUpdateData));
  }

  if (_.has(updateData, "partner.residenceAddress") && _.isObject(updateData.partner.residenceAddress)) {
    const partnerAddressUpdateData = getUpdateData(
      updateData.partner.residenceAddress,
      origDivorce,
      "partner.residenceAddress"
    );
    subEntityRequests.push(() => persistAddress(origDivorce.partner.residenceAddress.id, partnerAddressUpdateData));
  }

  if (_.has(updateData, "commonAddress") && _.isObject(updateData.commonAddress)) {
    const commonAddressUpdateData = getUpdateData(updateData.commonAddress, origDivorce, "commonAddress");
    subEntityRequests.push(() => persistAddress(origDivorce.commonAddress.id, commonAddressUpdateData));
  }

  if (_.isObject(updateData.costs)) {
    const costsUpdateData = getUpdateData(updateData.costs, origDivorce, "costs");
    subEntityRequests.push(() =>
      ApiClient.put(`divorce_costs/${origDivorce.costs.id}`, {
        body: JSON.stringify(costsUpdateData),
      })
    );
  }

  if (_.has(updateData, "children")) {
    _.forEach(updateData.children, (child, index) => {
      const childUpdateData = getUpdateData(child, origDivorce, `children[${index}]`);
      const childId = origDivorce.children[index].id;
      subEntityRequests.push(() => persistChild(childId, childUpdateData));
    });
  }

  await Promise.all(_.map(subEntityRequests, (request) => request()));

  const divorceUpdateData = getUpdateData(updateData, origDivorce);
  await dispatch(updateResource(origDivorce.id, "divorces", divorceUpdateData, "divorces" + origDivorce.id));
};

export const persistSettlement = async (updateData, origSettlement, dispatch) => {
  if (_.isObject(updateData.customer)) {
    const customerUpdateData = getUpdateData(updateData.customer, origSettlement, "customer");
    await persistCustomer(origSettlement.customer.id, customerUpdateData);
  }

  if (_.isObject(updateData.petitioner)) {
    const petitionerUpdateData = getUpdateData(updateData.petitioner, origSettlement, "petitioner");
    await persistPerson(origSettlement.petitioner.id, petitionerUpdateData);
  }

  if (_.has(updateData, "extended.responsibleCourt") && _.isObject(updateData.extended.responsibleCourt)) {
    const courtUpdateData = getUpdateData(updateData.extended.responsibleCourt, origSettlement, "extended.responsibleCourt");
    !_.isEmpty(courtUpdateData) && (await persistCourt(origSettlement.extended.responsibleCourt.id, courtUpdateData));
  }

  if (_.has(updateData, "petitioner.residenceAddress") && _.isObject(updateData.petitioner.residenceAddress)) {
    const petitionerAddressUpdateData = getUpdateData(
      updateData.petitioner.residenceAddress,
      origSettlement,
      "petitioner.residenceAddress"
    );
    await persistAddress(origSettlement.petitioner.residenceAddress.id, petitionerAddressUpdateData);
  }

  if (_.isObject(updateData.employer)) {
    const employerUpdateData = getUpdateData(updateData.employer, origSettlement, "employer");
    await ApiClient.put(`employers/${origSettlement.employer.id}`, {
      body: JSON.stringify(sanitizeEmployerData(employerUpdateData)),
    });
  }

  if (_.has(updateData, "employer.address") && _.isObject(updateData.employer.address)) {
    const employerAddressUpdateData = getUpdateData(updateData.employer.address, origSettlement, "employer.address");
    await persistAddress(origSettlement.employer.address.id, employerAddressUpdateData);
  }

  if (_.isObject(updateData.costs)) {
    const costsUpdateData = getUpdateData(updateData.costs, origSettlement, "costs");
    await ApiClient.put(`settlement_costs/${origSettlement.costs.id}`, {
      body: JSON.stringify(costsUpdateData),
    });
  }

  if (_.isObject(updateData.insurance)) {
    let insuranceUpdateData = getUpdateData(updateData.insurance, origSettlement, "insurance");
    if (_.has(updateData.insurance, "insurance.id")) {
      insuranceUpdateData.insurance = `/insurances/${updateData.insurance.insurance.id}`;
    }
    await ApiClient.put(`customer_insurances/${origSettlement.insurance.id}`, {
      body: JSON.stringify(insuranceUpdateData),
    });
  }

  if (_.isObject(updateData.extended)) {
    const extendedUpdateData = getUpdateData(updateData.extended, origSettlement, "extended");
    await ApiClient.put(`settlement_extendeds/${origSettlement.extended.id}`, {
      body: JSON.stringify(extendedUpdateData),
    });
  }

  let settlementUpdateData = getUpdateData(updateData, origSettlement);
  if (updateData.terminationObstacles) {
    settlementUpdateData["terminationObstacles"] = updateData.terminationObstacles;
  }
  await dispatch(
    updateResource(origSettlement.id, "settlements", settlementUpdateData, "settlements" + origSettlement.id)
  );
};

export const persistTraffic = async (updateData, origTraffic, dispatch) => {
  if (_.isObject(updateData.customer)) {
    const customerUpdateData = getUpdateData(updateData.customer, origTraffic, "customer");
    !_.isEmpty(customerUpdateData) && (await persistCustomer(origTraffic.customer.id, customerUpdateData));
  }

  if (_.isObject(updateData.petitioner)) {
    const petitionerUpdateData = getUpdateData(updateData.petitioner, origTraffic, "petitioner");
    !_.isEmpty(petitionerUpdateData) && (await persistPerson(origTraffic.petitioner.id, petitionerUpdateData));
  }

  if (_.has(updateData, "extended.responsibleCourt") && _.isObject(updateData.extended.responsibleCourt)) {
    const courtUpdateData = getUpdateData(updateData.extended.responsibleCourt, origTraffic, "extended.responsibleCourt");
    !_.isEmpty(courtUpdateData) && (await persistCourt(origTraffic.extended.responsibleCourt.id, courtUpdateData));
  }

  if (_.has(updateData, "petitioner.residenceAddress") && _.isObject(updateData.petitioner.residenceAddress)) {
    const petitionerAddressUpdateData = getUpdateData(
      updateData.petitioner.residenceAddress,
      origTraffic,
      "petitioner.residenceAddress"
    );
    !_.isEmpty(petitionerAddressUpdateData) &&
      (await persistAddress(origTraffic.petitioner.residenceAddress.id, petitionerAddressUpdateData));
  }

  if (
    _.has(updateData, "extended.responsibleCourtAddress") &&
    _.isObject(updateData.extended.responsibleCourtAddress)
  ) {
    const responsibleCourtAddressUpdateData = getUpdateData(
      updateData.extended.responsibleCourtAddress,
      origTraffic,
      "extended.responsibleCourtAddress"
    );
    !_.isEmpty(responsibleCourtAddressUpdateData) &&
      (await persistAddress(origTraffic.extended.responsibleCourtAddress.id, responsibleCourtAddressUpdateData));
  }

  if (_.has(updateData, "extended.investigation") && _.isObject(updateData.extended.investigation)) {
    const investigationUpdateData = getUpdateData(
      updateData.extended.investigation,
      origTraffic,
      "extended.investigation"
    );
    !_.isEmpty(investigationUpdateData) &&
      (await ApiClient.put(`traffic_investigations/${origTraffic.extended.investigation.id}`, {
        body: JSON.stringify(investigationUpdateData),
      }));
  }

  if (_.has(updateData, "insurance") && _.isObject(updateData.insurance)) {
    let insuranceUpdateData = getUpdateData(updateData.insurance, origTraffic, "insurance");
    if (_.has(updateData.insurance, "insurance.id")) {
      insuranceUpdateData.insurance = `/insurances/${updateData.insurance.insurance.id}`;
    }
    !_.isEmpty(insuranceUpdateData) &&
      (await ApiClient.put(`customer_insurances/${origTraffic.insurance.id}`, {
        body: JSON.stringify(insuranceUpdateData),
      }));
  }

  if (_.isObject(updateData.distance)) {
    const distanceUpdateData = getUpdateData(updateData.distance, origTraffic, "distance");
    !_.isEmpty(distanceUpdateData) &&
      (await ApiClient.put(`traffic_distances/${origTraffic.distance.id}`, {
        body: JSON.stringify(distanceUpdateData),
      }));
  }

  if (_.isObject(updateData.redLight)) {
    const redLightUpdateData = getUpdateData(updateData.redLight, origTraffic, "redLight");
    !_.isEmpty(redLightUpdateData) &&
      (await ApiClient.put(`traffic_red_lights/${origTraffic.redLight.id}`, {
        body: JSON.stringify(redLightUpdateData),
      }));
  }

  if (_.isObject(updateData.speeding)) {
    const speedingUpdateData = getUpdateData(updateData.speeding, origTraffic, "speeding");
    !_.isEmpty(speedingUpdateData) &&
      (await ApiClient.put(`traffic_speedings/${origTraffic.speeding.id}`, {
        body: JSON.stringify(speedingUpdateData),
      }));
  }

  if (_.isObject(updateData.mobile)) {
    const mobileUpdateData = getUpdateData(updateData.mobile, origTraffic, "mobile");
    !_.isEmpty(mobileUpdateData) &&
      (await ApiClient.put(`traffic_mobiles/${origTraffic.mobile.id}`, {
        body: JSON.stringify(mobileUpdateData),
      }));
  }

  if (_.isObject(updateData.costs)) {
    const costsUpdateData = getUpdateData(updateData.costs, origTraffic, "costs");
    !_.isEmpty(costsUpdateData) &&
      (await ApiClient.put(`traffic_costs/${origTraffic.costs.id}`, {
        body: JSON.stringify(costsUpdateData),
      }));
  }

  if (_.isObject(updateData.extended)) {
    const extendedUpdateData = getUpdateData(updateData.extended, origTraffic, "extended");
    !_.isEmpty(extendedUpdateData) &&
      (await ApiClient.put(`traffic_extendeds/${origTraffic.extended.id}`, {
        body: JSON.stringify(extendedUpdateData),
      }));
  }

  if (_.has(updateData, "extended.responsibleAuthority") && _.isObject(updateData.extended.responsibleAuthority)) {
    await ApiClient.put(`traffic_extendeds/${origTraffic.extended.id}`, {
      body: JSON.stringify({
        responsibleAuthority: "/authorities/" + updateData.extended.responsibleAuthority.id,
      }),
    });
  }

  const trafficUpdateData = getUpdateData(updateData, origTraffic);
  await dispatch(updateResource(origTraffic.id, "traffic", trafficUpdateData, "traffic" + origTraffic.id));
};

export const persistDocument = async (updateData, origDocument, dispatch) => {
  if (_.isObject(updateData.customer)) {
    const customerUpdateData = getUpdateData(updateData.customer, origDocument, "customer");
    await persistCustomer(origDocument.customer.id, customerUpdateData);
  }
  if (_.isObject(updateData.petitioner)) {
    const petitionerUpdateData = getUpdateData(updateData.petitioner, origDocument, "petitioner");
    await persistPerson(origDocument.petitioner.id, petitionerUpdateData);
  }
  if (_.has(updateData, "insurance") && _.isObject(updateData.insurance)) {
    let insuranceUpdateData = getUpdateData(updateData.insurance, origDocument, "insurance");
    if (_.has(updateData.insurance, "insurance.id")) {
      insuranceUpdateData.insurance = `/insurances/${updateData.insurance.insurance.id}`;
    }
    await ApiClient.put(`customer_insurances/${origDocument.insurance.id}`, {
      body: JSON.stringify(insuranceUpdateData),
    });
  }
  const apiUri = getProductData(origDocument.productClassName, "apiUri");
  const documentUpdateData = getUpdateData(updateData, origDocument);
  await dispatch(updateResource(origDocument.id, apiUri, documentUpdateData, apiUri + origDocument.id));
};

//only update alimony specific document fields here, the others are handled in persistDocument
export const persistAlimony = async (updateData, origDocument, dispatch) => {
  if (_.isObject(updateData?.alimonyPetitioner?.person)) {
    const alimonyPetitionerUpdateData = getUpdateData(
      updateData.alimonyPetitioner.person,
      origDocument,
      "alimonyPetitioner.person"
    );
    await persistPerson(origDocument.alimonyPetitioner.person.id, alimonyPetitionerUpdateData);
  }
  await persistDocument(updateData, origDocument, dispatch);
};

export const persistBasicCase = async (updateData, origBasicCase, dispatch) => {
  if (_.isObject(updateData.customer)) {
    const customerUpdateData = getUpdateData(updateData.customer, origBasicCase, "customer");
    await persistCustomer(origBasicCase.customer.id, customerUpdateData);
  }

  if (_.isObject(updateData.petitioner)) {
    const petitionerUpdateData = getUpdateData(updateData.petitioner, origBasicCase, "petitioner");
    await persistPerson(origBasicCase.petitioner.id, petitionerUpdateData);
  }

  if (_.isObject(updateData.client)) {
    const clientUpdateData = getUpdateData(updateData.client, origBasicCase, "client");
    await persistPerson(origBasicCase.client.id, clientUpdateData);
  }

  if (_.isObject(updateData.insurance)) {
    const insuranceUpdateData = getUpdateData(updateData.insurance, origBasicCase, "insurance");
    await ApiClient.put(`customer_insurances/${origBasicCase.insurance.id}`, {
      body: JSON.stringify(insuranceUpdateData),
    });
  }

  if (_.has(updateData, "petitioner.residenceAddress") && _.isObject(updateData.petitioner.residenceAddress)) {
    const petitionerAddressUpdateData = getUpdateData(
      updateData.petitioner.residenceAddress,
      origBasicCase,
      "petitioner.residenceAddress"
    );
    await persistAddress(origBasicCase.petitioner.residenceAddress.id, petitionerAddressUpdateData);
  }

  const apiUri = getProductData(origBasicCase.productClassName, "apiUri");
  const basicCaseUpdateData = getUpdateData(updateData, origBasicCase);
  await dispatch(updateResource(origBasicCase.id, apiUri, basicCaseUpdateData, _.camelCase(apiUri) + origBasicCase.id));
};

export const persistContractCase = async (updateData, origContractCase, dispatch) => {
  if (_.isObject(updateData.customer)) {
    const customerUpdateData = getUpdateData(updateData.customer, origContractCase, "customer");
    await persistCustomer(origContractCase.customer.id, customerUpdateData);
  }

  if (_.isObject(updateData.insurance)) {
    const insuranceUpdateData = getUpdateData(updateData.insurance, origContractCase, "insurance");
    await ApiClient.put(`customer_insurances/${origContractCase.insurance.id}`, {
      body: JSON.stringify(insuranceUpdateData),
    });
  }

  if (_.isObject(updateData.clientBankData)) {
    const clientBankUpdateData = getUpdateData(updateData.clientBankData, origContractCase, "clientBankData");
    if (clientBankUpdateData.iban) {
      clientBankUpdateData.iban = toIban(clientBankUpdateData.iban);
    }
    await ApiClient.put(`bank_datas/${origContractCase.clientBankData.id}`, {
      body: JSON.stringify(clientBankUpdateData),
    });
  }

  if (_.isObject(updateData.responsibleCourt)) {
    const courtUpdateData = getUpdateData(updateData.responsibleCourt, origContractCase, "responsibleCourt");
    !_.isEmpty(courtUpdateData) && (await persistCourt(origContractCase.responsibleCourt.id, courtUpdateData));
  }

  if (_.has(updateData, "insurance.policyHolder") && _.isObject(updateData.insurance.policyHolder)) {
    const policyHolderUpdateData = getUpdateData(
      updateData.insurance.policyHolder,
      origContractCase,
      "insurance.policyHolder"
    );
    await persistPerson(origContractCase.insurance.policyHolder.id, policyHolderUpdateData);
  }

  if (_.isObject(updateData.client)) {
    const petitionerUpdateData = getUpdateData(updateData.client, origContractCase, "petitioner");
    await persistPerson(origContractCase.client.id, petitionerUpdateData);
  }

  if (_.has(updateData, "client.residenceAddress") && _.isObject(updateData.client.residenceAddress)) {
    const clientAddressUpdateData = getUpdateData(
      updateData.client.residenceAddress,
      origContractCase,
      "client.residenceAddress"
    );
    await persistAddress(origContractCase.client.residenceAddress.id, clientAddressUpdateData);
  }

  if (_.isObject(updateData.contractualPartnerPerson)) {
    const contractualPartnerPersonUpdateData = getUpdateData(
      updateData.contractualPartnerPerson,
      origContractCase,
      "contractualPartnerPerson"
    );
    await persistPerson(origContractCase.contractualPartnerPerson.id, contractualPartnerPersonUpdateData);
  }

  if (
    _.has(updateData, "contractualPartnerPerson.residenceAddress") &&
    _.isObject(updateData.contractualPartnerPerson.residenceAddress)
  ) {
    const contractualPartnerPersonAddressUpdateData = getUpdateData(
      updateData.contractualPartnerPerson.residenceAddress,
      origContractCase,
      "contractualPartnerPerson.residenceAddress"
    );
    await persistAddress(
      origContractCase.contractualPartnerPerson.residenceAddress.id,
      contractualPartnerPersonAddressUpdateData
    );
  }

  if (_.isObject(updateData.contractualPartnerLegalEntity)) {
    let contractualPartnerLegalEntityUpdateData = getUpdateData(
      updateData.contractualPartnerLegalEntity,
      origContractCase,
      "contractualPartnerLegalEntity"
    );
    if (_.isArray(updateData.contractualPartnerLegalEntity.additionalRepresentatives)) {
      contractualPartnerLegalEntityUpdateData.additionalRepresentatives = _.without(
        updateData.contractualPartnerLegalEntity.additionalRepresentatives,
        ""
      );
    }
    await ApiClient.put(`organizations/${origContractCase.contractualPartnerLegalEntity.id}`, {
      body: JSON.stringify(sanitizeOrganizationData(contractualPartnerLegalEntityUpdateData)),
    });
  }

  if (
    _.has(updateData, "contractualPartnerLegalEntity.address") &&
    _.isObject(updateData.contractualPartnerLegalEntity.address)
  ) {
    const contractualPartnerLegalEntityAddressUpdateData = getUpdateData(
      updateData.contractualPartnerLegalEntity.address,
      origContractCase,
      "contractualPartnerLegalEntity.address"
    );
    await persistAddress(
      origContractCase.contractualPartnerLegalEntity.address.id,
      contractualPartnerLegalEntityAddressUpdateData
    );
  }

  if (_.isObject(updateData.opponentLawyer)) {
    const opponentLawyerUpdateData = getUpdateData(updateData.opponentLawyer, origContractCase, "opponentLawyer");
    await persistPerson(origContractCase.opponentLawyer.id, opponentLawyerUpdateData);
  }

  if (_.isObject(updateData.opponentLawFirm)) {
    const opponentLawFirmUpdateData = getUpdateData(updateData.opponentLawFirm, origContractCase, "opponentLawFirm");
    await ApiClient.put(`organizations/${origContractCase.opponentLawFirm.id}`, {
      body: JSON.stringify(sanitizeOrganizationData(opponentLawFirmUpdateData)),
    });
  }

  if (_.has(updateData, "opponentLawFirm.address") && _.isObject(updateData.opponentLawFirm.address)) {
    const opponentLawFirmAddressUpdateData = getUpdateData(
      updateData.opponentLawFirm.address,
      origContractCase,
      "opponentLawFirm.address"
    );
    await persistAddress(origContractCase.opponentLawFirm.address.id, opponentLawFirmAddressUpdateData);
  }

  if (_.isObject(updateData.debtCollectionAgency)) {
    const debtCollectionAgencyData = getUpdateData(
      updateData.debtCollectionAgency,
      origContractCase,
      "debtCollectionAgency"
    );
    await ApiClient.put(`organizations/${origContractCase.debtCollectionAgency.id}`, {
      body: JSON.stringify(sanitizeOrganizationData(debtCollectionAgencyData)),
    });
  }

  if (_.has(updateData, "debtCollectionAgency.address") && _.isObject(updateData.debtCollectionAgency.address)) {
    const debtCollectionAgencyAddressUpdateData = getUpdateData(
      updateData.debtCollectionAgency.address,
      origContractCase,
      "opponentLawFirm.address"
    );
    await persistAddress(origContractCase.debtCollectionAgency.address.id, debtCollectionAgencyAddressUpdateData);
  }

  if (_.isObject(updateData.courtDate)) {
    const courtDateUpdateData = getUpdateData(updateData.courtDate, origContractCase, "courtDate");
    await persistAppointment(origContractCase.courtDate.id, courtDateUpdateData);
  }

  const apiUri = getProductData(origContractCase.productClassName, "apiUri");
  const contractCaseUpdateData = getUpdateData(updateData, origContractCase);
  await dispatch(
    updateResource(origContractCase.id, apiUri, contractCaseUpdateData, _.camelCase(apiUri) + origContractCase.id)
  );
};

export const persistAddress = async (id, addressData) => {
  return await ApiClient.put(`addresses/${id}`, {
    body: JSON.stringify(sanitizeAddressData(addressData)),
  });
};

const persistInsurance = async (id, insuranceUpdateData) => {
  return await ApiClient.put(`customer_insurances/${id}`, {
    body: JSON.stringify(insuranceUpdateData),
  });
};

const persistChild = async (id, childData) => {
  return await ApiClient.put(`divorce_children/${id}`, {
    body: JSON.stringify(childData),
  });
};

export const persistCustomer = async (id, customerData) => {
  return await ApiClient.put(`customers/${id}`, {
    body: JSON.stringify(customerData),
  });
};

export const persistPerson = async (id, personData) => {
  return await ApiClient.put(`people/${id}`, {
    body: JSON.stringify(sanitizePersonData(personData)),
  });
};

export const persistCourt = async (id, courtData) => {
  return await ApiClient.put(`courts/${id}`, {
    body: JSON.stringify(courtData),
  });
};

const persistAppointment = async (id, appointmentData) => {
  return await ApiClient.put(`appointments/${id}`, {
    body: JSON.stringify(appointmentData),
  });
};

const getUpdateData = (object, product = null, basePath = "") => {
  let resultSet = _.pickBy(object, (value) => {
    return !_.isObjectLike(value);
  });

  if (!_.isEmpty(resultSet)) {
    if (product !== null) {
      resultSet.backofficeCaseId = product.backofficeCase.id;
    }
    resultSet.basePath = basePath;
  }

  resultSet = _.mapValues(resultSet, (value) => {
    return value !== "" ? value : null;
  });

  return resultSet;
};

export const difference = (object, base) => {
  function changes(object, base) {
    return _.transform(object, function (result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] = _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  }

  return changes(object, base);
};

const trimValueIfSet = (field, values) => {
  if (values[field]) {
    values[field] = _.trim(values[field]);
  }
  return values;
};

export const sanitizePersonData = (personData) => {
  let sanitizedPersonData = _.cloneDeep(personData);
  sanitizedPersonData = trimValueIfSet("givenName", sanitizedPersonData);
  sanitizedPersonData = trimValueIfSet("familyName", sanitizedPersonData);
  return sanitizedPersonData;
};

const sanitizeAddressData = (addressData) => {
  let sanitizedAddressData = _.cloneDeep(addressData);
  sanitizedAddressData = trimValueIfSet("streetAddress", sanitizedAddressData);
  sanitizedAddressData = trimValueIfSet("postalCode", sanitizedAddressData);
  sanitizedAddressData = trimValueIfSet("addressLocality", sanitizedAddressData);
  sanitizedAddressData = trimValueIfSet("postOfficeBoxAddressLocality", sanitizedAddressData);
  sanitizedAddressData = trimValueIfSet("postOfficeBoxPostalCode", sanitizedAddressData);
  return sanitizedAddressData;
};

export const sanitizeOrganizationData = (organizationData) => {
  let sanitizedOrganizationData = _.cloneDeep(organizationData);
  sanitizedOrganizationData = trimValueIfSet("name", sanitizedOrganizationData);
  sanitizedOrganizationData = trimValueIfSet("legalName", sanitizedOrganizationData);
  sanitizedOrganizationData = trimValueIfSet("email", sanitizedOrganizationData);
  sanitizedOrganizationData = trimValueIfSet("telephone", sanitizedOrganizationData);
  return sanitizedOrganizationData;
};

export const sanitizeEmployerData = (employerData) => {
  let sanitizedEmployerData = _.cloneDeep(employerData);
  sanitizedEmployerData = trimValueIfSet("name", sanitizedEmployerData);
  sanitizedEmployerData = trimValueIfSet("email", sanitizedEmployerData);
  sanitizedEmployerData = trimValueIfSet("telephone", sanitizedEmployerData);
  return sanitizedEmployerData;
};
