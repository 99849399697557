import React, { useEffect, useState } from "react";
import useStyles from "./bankPaymentEntriesStyle";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import ApiClient from "../../services/ApiClient";
import _ from "lodash";
import BankPaymentEntryRow from "./BankPaymentEntryRow";
import PageContentContainer from "../Container/PageContentContainer";
import ButtonLoading from "../Button/ButtonLoading";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getGeneric } from "../../store/generic/reducer";
import { fetchCollection } from "../../store/generic/actions";
import BarsLoader from "../ContentLoader/BarsLoader";
import PageHeadline from "../PageHeadline/PageHeadline";
import ContentBox from "../ContentBox/ContentBox";

const BankPaymentEntries = () => {
  const classes = useStyles({});

  const [bookModalOpen, setBookModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const unbookedPaymentEntries = useSelector((state) => getGeneric(state, "unbookedPaymentEntries"));

  useEffect(() => {
    dispatch(fetchCollection("bank_payment_entries?exists[bookedDate]=false", "unbookedPaymentEntries"));
  }, [dispatch]);

  const handleBookingClick = async () => {
    setIsLoading(true);
    const date = moment();
    const bankPaymentEntries = await dispatch(
      fetchCollection("bank_payment_entries?exists[bookedDate]=false", "unbookedPaymentEntries")
    );
    let promises = [];
    _.forEach(bankPaymentEntries["hydra:member"], (bankPaymentEntry) => {
      if (!bankPaymentEntry.checkedDate) {
        return;
      }
      promises.push(
        ApiClient.put("bank_payment_entries/" + bankPaymentEntry.id, {
          body: JSON.stringify({
            bookedDate: date,
          }),
        })
      );
    });
    await Promise.all(promises);
    await dispatch(fetchCollection("bank_payment_entries?exists[bookedDate]=false", "unbookedPaymentEntries"));
    setIsLoading(false);
    setBookModalOpen(false);
  };

  const hasBookableEntries = (unbookedPaymentEntries) => {
    if (!unbookedPaymentEntries) {
      return false;
    }

    return _.some(unbookedPaymentEntries["hydra:member"], (paymentEntry) => paymentEntry.checkedDate !== null);
  };

  const refreshList = async () => {
    dispatch(fetchCollection("bank_payment_entries?exists[bookedDate]=false", "unbookedPaymentEntries"));
  };

  return (
    <PageContentContainer size={"large"}>
      <PageHeadline main="Zahlungseingänge buchen" />
      <ContentBox>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cellSmall}>Datum</TableCell>
              <TableCell className={classes.cellSmall}>Account</TableCell>
              <TableCell className={classes.cellSmall}>Zahler</TableCell>
              <TableCell className={classes.cellText}>Verwendungszweck</TableCell>
              <TableCell className={classes.cellSmall}>Betrag</TableCell>
              <TableCell className={classes.cell}>Zugeordneter Fall</TableCell>
              <TableCell className={classes.cell}>Suche</TableCell>
              <TableCell className={classes.cellSmall}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!(isLoading || !unbookedPaymentEntries) &&
              _.map(unbookedPaymentEntries["hydra:member"], (bankPaymentEntry) => (
                <BankPaymentEntryRow
                  key={bankPaymentEntry.id}
                  bankPaymentEntry={bankPaymentEntry}
                  refreshList={refreshList}
                />
              ))}
          </TableBody>
        </Table>
        {(isLoading || !unbookedPaymentEntries) && (
          <div className={classes.spinner}>
            <BarsLoader />
          </div>
        )}
        <ButtonLoading
          fullWidth={false}
          className={classes.button}
          onClick={() => setBookModalOpen(true)}
          disabled={!hasBookableEntries(unbookedPaymentEntries)}
          variant={"contained"}
        >
          Alle Einträge buchen
        </ButtonLoading>
      </ContentBox>
      <LegalbirdIoModal
        handleClose={() => setBookModalOpen(false)}
        open={bookModalOpen}
        title={"Geprüfte Einträge buchen?"}
        submitButton={
          <ButtonLoading variant={"contained"} isLoading={false} onClick={handleBookingClick}>
            Jetzt buchen
          </ButtonLoading>
        }
      >
        <div className={classes.modalText}>Sind Sie sicher, dass alle geprüften Einträge gebucht werden sollen?</div>
      </LegalbirdIoModal>
    </PageContentContainer>
  );
};

export default BankPaymentEntries;
