import _ from "lodash";
import { getAdvoAssistRepresentative } from "../../../services/Product/ProductService";

const isOpponentLawyerDataComplete = (product) => {
  // Required fields for opponentLawFirm.name is NOT required
  return !!(
    product.opponentLawyer &&
    product.opponentLawFirm &&
    product.opponentLawyer.gender &&
    product.opponentLawyer.givenName &&
    product.opponentLawyer.familyName &&
    product.opponentLawFirm.address &&
    product.opponentLawFirm.address.streetAddress &&
    product.opponentLawFirm.address.addressLocality &&
    product.opponentLawFirm.address.postalCode
  );
};

const isDebtCollectionAgencyDataComplete = (product) => {
  return !!(
    product.debtCollectionAgency &&
    product.debtCollectionAgency.name &&
    product.debtCollectionAgency.address &&
    product.debtCollectionAgency.address.streetAddress &&
    product.debtCollectionAgency.address.addressLocality &&
    product.debtCollectionAgency.address.postalCode
  );
};

const isContractualPartnerPersonDataComplete = (product) => {
  // Required fields for contractualPartnerPerson
  return !!(
    product.contractualPartnerPerson &&
    product.contractualPartnerPerson.gender &&
    product.contractualPartnerPerson.givenName &&
    product.contractualPartnerPerson.familyName &&
    product.contractualPartnerPerson.addresses[0] &&
    product.contractualPartnerPerson.addresses[0].streetAddress &&
    product.contractualPartnerPerson.addresses[0].addressLocality &&
    product.contractualPartnerPerson.addresses[0].postalCode
  );
};

const isContractualPartnerLegalEntityDataComplete = (product) => {
  // Required fields for contractualPartnerLegalEntity --> No streetAddress required!
  return !!(
    product.contractualPartnerLegalEntity &&
    product.contractualPartnerLegalEntity.legalName &&
    product.contractualPartnerLegalEntity.address &&
    ((product.contractualPartnerLegalEntity.address.addressLocality &&
      product.contractualPartnerLegalEntity.address.postalCode) ||
      (product.contractualPartnerLegalEntity.address.postOfficeBoxNumber &&
        product.contractualPartnerLegalEntity.address.postOfficeBoxPostalCode &&
        product.contractualPartnerLegalEntity.address.postOfficeBoxAddressLocality))
  );
};

const isContractualPartnerDataComplete = (product) => {
  if (product.contractualPartnerType === "contractualPartnerLegalEntity") {
    return isContractualPartnerLegalEntityDataComplete(product);
  }
  if (product.contractualPartnerType === "contractualPartnerPerson") {
    return isContractualPartnerPersonDataComplete(product);
  }
};

const getContractualPartnerPersonLetterheadData = (product) => {
  return {
    gender: product.contractualPartnerPerson.gender,
    name: product.contractualPartnerPerson.givenName + " " + product.contractualPartnerPerson.familyName,
    streetAddress: product.contractualPartnerPerson.residenceAddress.streetAddress,
    postalCode: product.contractualPartnerPerson.residenceAddress.postalCode,
    addressLocality: product.contractualPartnerPerson.residenceAddress.addressLocality,
    postOfficeBoxAddressLocality: product.contractualPartnerPerson.residenceAddress.postOfficeBoxAddressLocality,
    postOfficeBoxNumber: product.contractualPartnerPerson.residenceAddress.postOfficeBoxNumber,
    postOfficeBoxPostalCode: product.contractualPartnerPerson.residenceAddress.postOfficeBoxPostalCode,
  };
};

const getContractualPartnerLegalEntityLetterheadData = (product) => {
  return getOrganizationLetterheadData("contractualPartnerLegalEntity", product);
};

const getOrganizationLetterheadData = (path, product) => {
  return {
    firm: _.get(product, path + ".legalName"),
    streetAddress: _.get(product, path + ".address.streetAddress"),
    postalCode: _.get(product, path + ".address.postalCode"),
    addressLocality: _.get(product, path + ".address.addressLocality"),
    postOfficeBoxAddressLocality: _.get(product, path + ".address.postOfficeBoxAddressLocality"),
    postOfficeBoxNumber: _.get(product, path + ".address.postOfficeBoxNumber"),
    postOfficeBoxPostalCode: _.get(product, path + ".address.postOfficeBoxPostalCode"),
  };
};

const getContractualPartnerLetterheadData = (product) => {
  if (product.contractualPartnerType === "contractualPartnerLegalEntity") {
    return getContractualPartnerLegalEntityLetterheadData(product);
  }
  if (product.contractualPartnerType === "contractualPartnerPerson") {
    return getContractualPartnerPersonLetterheadData(product);
  }
};

const getAuthorityLetterheadData = (product) => {
  return {
    name: product.extended.responsibleAuthority.name,
    department: product.extended.responsibleAuthority.department,
    subDepartment: product.extended.responsibleAuthority.subDepartment,
    streetAddress: product.extended.responsibleAuthority.address.streetAddress,
    postalCode: product.extended.responsibleAuthority.address.postalCode,
    addressLocality: product.extended.responsibleAuthority.address.addressLocality,
    postOfficeBoxAddressLocality: product.extended.responsibleAuthority.address.postOfficeBoxAddressLocality,
    postOfficeBoxNumber: product.extended.responsibleAuthority.address.postOfficeBoxNumber,
    postOfficeBoxPostalCode: product.extended.responsibleAuthority.address.postOfficeBoxPostalCode,
  };
};

const getProsecutionLetterheadData = (product) => {

  const responsibleProsecution = _.includes(["traffic", "settlement"], product.productClassName) ?
    product.extended.responsibleProsecution :
    product.responsibleProsecution;

  return {
    name: responsibleProsecution.name,
    department: responsibleProsecution.department,
    subDepartment: responsibleProsecution.subDepartment,
    streetAddress: responsibleProsecution.address.streetAddress,
    postalCode: responsibleProsecution.address.postalCode,
    addressLocality: responsibleProsecution.address.addressLocality,
    postOfficeBoxAddressLocality: responsibleProsecution.address.postOfficeBoxAddressLocality,
    postOfficeBoxNumber: responsibleProsecution.address.postOfficeBoxNumber,
    postOfficeBoxPostalCode: responsibleProsecution.address.postOfficeBoxPostalCode,
  };
};

const getPetitionerLetterheadData = (product) => {
  return getPersonLetterheadData("client", product);
};

const getPersonLetterheadData = (path, product) => {
  return {
    gender: _.get(product, path + ".gender"),
    name: _.get(product, path + ".givenName") + " " + _.get(product, path + ".familyName"),
    streetAddress: _.get(product, path + ".residenceAddress.streetAddress"),
    postalCode: _.get(product, path + ".residenceAddress.postalCode"),
    addressLocality: _.get(product, path + ".residenceAddress.addressLocality"),
    postOfficeBoxAddressLocality: _.get(product, path + ".residenceAddress.postOfficeBoxAddressLocality"),
    postOfficeBoxNumber: _.get(product, path + ".residenceAddress.postOfficeBoxNumber"),
    postOfficeBoxPostalCode: _.get(product, path + ".residenceAddress.postOfficeBoxPostalCode"),
  };
};

const getCourtLetterheadData = (product) => {
  return {
    firm: product.responsibleCourt.name,
    streetAddress: product.responsibleCourt.address.streetAddress,
    postalCode: product.responsibleCourt.address.postalCode,
    addressLocality: product.responsibleCourt.address.addressLocality,
    postOfficeBoxAddressLocality: product.responsibleCourt.address.postOfficeBoxAddressLocality,
    postOfficeBoxNumber: product.responsibleCourt.address.postOfficeBoxNumber,
    postOfficeBoxPostalCode: product.responsibleCourt.address.postOfficeBoxPostalCode,
  };
};

const getOpponentLawFirmLetterheadData = (product) => {
  return {
    firm: product.opponentLawFirm.name ?? null,
    name: (product.opponentLawyer.gender === "female" ? "RAin " : "RA ") + product.opponentLawyer.givenName + " " + product.opponentLawyer.familyName,
    streetAddress: product.opponentLawFirm.address.streetAddress,
    postalCode: product.opponentLawFirm.address.postalCode,
    addressLocality: product.opponentLawFirm.address.addressLocality,
    postOfficeBoxAddressLocality: product.opponentLawFirm.address.postOfficeBoxAddressLocality,
    postOfficeBoxNumber: product.opponentLawFirm.address.postOfficeBoxNumber,
    postOfficeBoxPostalCode: product.opponentLawFirm.address.postOfficeBoxPostalCode,
  };
};

const getDebtCollectionAgencyLetterheadData = (product) => {
  return {
    name: product.debtCollectionAgency.name,
    streetAddress: product.debtCollectionAgency.address.streetAddress,
    postalCode: product.debtCollectionAgency.address.postalCode,
    addressLocality: product.debtCollectionAgency.address.addressLocality,
    postOfficeBoxAddressLocality: product.debtCollectionAgency.address.postOfficeBoxAddressLocality,
    postOfficeBoxNumber: product.debtCollectionAgency.address.postOfficeBoxNumber,
    postOfficeBoxPostalCode: product.debtCollectionAgency.address.postOfficeBoxPostalCode,
  };
};

const getAdvoAssistRepresentativeLetterheadData = (product) => {
  const advoAssistRepresentative = getAdvoAssistRepresentative(product);

  return {
    name: advoAssistRepresentative.fullName,
    streetAddress: advoAssistRepresentative.residenceAddress.streetAddress,
    postalCode: advoAssistRepresentative.residenceAddress.postalCode,
    addressLocality: advoAssistRepresentative.residenceAddress.addressLocality,
  };
};

const buildAddress = (values, product) => {
  const baseData = {
    firm: null,
    gender: null,
    name: null,
    department: null,
    subDepartment: null,
    streetAddress: null,
    postalCode: null,
    addressLocality: null,
    postOfficeBoxNumber: null,
    postOfficeBoxPostalCode: null,
    postOfficeBoxAddressLocality: null,
  };

  const recipientType = values.recipientType.split("[")[0];
  switch (recipientType) {
    case "custom":
      return _.merge({}, baseData, values.address);
    case "petitioner":
      return _.merge({}, baseData, getPetitionerLetterheadData(product));
    case "court":
      return _.merge({}, baseData, getCourtLetterheadData(product));
    case "authority":
      return _.merge({}, baseData, getAuthorityLetterheadData(product));
    case "prosecution":
      return _.merge({}, baseData, getProsecutionLetterheadData(product));
    case "contractualPartnerLegalEntity":
      return _.merge({}, baseData, getContractualPartnerLetterheadData(product));
    case "opponentLawFirm":
      return _.merge({}, baseData, getOpponentLawFirmLetterheadData(product));
    case "debtCollectionAgency":
      return _.merge({}, baseData, getDebtCollectionAgencyLetterheadData(product));
    case "advoAssistRepresentative":
      return _.merge({}, baseData, getAdvoAssistRepresentativeLetterheadData(product));
    case "processParticipantsOrganizations":
      return _.merge({}, baseData, getOrganizationLetterheadData(values.recipientType, product));
    case "processParticipantsPeople":
      return _.merge({}, baseData, getPersonLetterheadData(values.recipientType, product));
    default:
      return baseData;
  }
};

const getExternalReference = (product, switchValue) => {
  switch (product.productClassName) {
    case "traffic":
      switch (switchValue) {
        case "court":
          return product.extended.courtReference;
        case "authority":
          return product.extended.fineNoticeReference;
        case "prosecution":
          return product.extended.prosecutionReference;
        default:
          return null;
      }
    case "divorce":
      switch (switchValue) {
        case "court":
          return product.referenceCourt;
        default:
          return null;
      }
    case "settlement":
      switch (switchValue) {
        case "court":
          return product.extended.referenceCourt;
        default:
          return null;
      }
    case "eventContract":
    case "membershipContract":
    case "otherContract":
    case "providerContract":
    case "purchaseContract":
    case "serviceContract":
    case "subscriptionContract":
    case "travelContract":
    case "vehicleContract":
      switch (switchValue) {
        case "debtCollectionAgency":
          return product.debtCollectionAgencyReference;
        case "court":
          return product.courtReference;
        case "opponentLawyer":
          return product.referenceOpponentLawyer;
        case "contractNumber":
          return product.contractNumber;
        case "prosecution":
          return product.prosecutionReference;
        default:
          return null;
      }
    default:
      return null;
  }
};

const getReferenceOptions = (product) => {
  let references = [];

  switch (product.productClassName) {
    case "divorce":
      references.push({
        type: "court",
        label: "Aktenzeichen Gericht",
        isHidden: !product.referenceCourt,
      });
      break;
    case "settlement":
      references.push({
        type: "court",
        label: "Aktenzeichen Gericht",
        isHidden: !product.extended.referenceCourt,
      });
      break;
    case "traffic":
      references.push(
        {
          type: "court",
          label: "Aktenzeichen Gericht",
          isHidden: !product.extended.courtReference,
        },
        {
          type: "authority",
          label: "Aktenzeichen Behörde",
          isHidden: !product.extended.fineNoticeReference,
        },
        {
          type: "prosecution",
          label: "Aktenzeichen Staatsanwaltschaft",
          isHidden: !product.extended.prosecutionReference,
        }
      );
      break;
    case "eventContract":
    case "membershipContract":
    case "otherContract":
    case "providerContract":
    case "purchaseContract":
    case "serviceContract":
    case "subscriptionContract":
    case "travelContract":
    case "vehicleContract":
      references.push(
        {
          type: "court",
          label: "Aktenzeichen Gericht",
          isHidden: !product.courtReference,
        },
        {
          type: "debtCollectionAgency",
          label: "Aktenzeichen Inkasso",
          isHidden: !product.debtCollectionAgencyReference,
        },
        {
          type: "opponentLawyer",
          label: "Aktenzeichen Anwalt Gegenseite",
          isHidden: !product.referenceOpponentLawyer,
        },
        {
          type: "contractNumber",
          label: "Vertragsnummer",
          isHidden: !product.contractNumber,
        },
        {
          type: "prosecution",
          label: "Aktenzeichen Staatsanwaltschaft",
          isHidden: !product.prosecutionReference,
        }
      );
      break;
    default:
      break;
  }

  return references;
};

export {
  isOpponentLawyerDataComplete,
  isContractualPartnerDataComplete,
  buildAddress,
  getExternalReference,
  isDebtCollectionAgencyDataComplete,
  getReferenceOptions,
};
