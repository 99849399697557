import React from "react";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { getBadges, getCaseLabel } from "../../../services/Product/ProductService";
import { AbstractCase } from "../../../types/AbstractCase";
import BadgesList from "../../Badges/BadgesList";
import InsuranceData from "../../Case/CaseProgressBar/InsuranceData";
import { useSelector } from "react-redux";
import { getBackofficeUser } from "../../../store/backofficeUser/reducer";

type CaseHeaderProps = {
  product: AbstractCase;
};

export default function CaseHeader({ product }: CaseHeaderProps) {
  const currentUser = useSelector((state) => getBackofficeUser(state));

  return (
    <Paper sx={{ width: "100%", padding: "2rem" }}>
      <Stack spacing={2}>
        <Typography variant="h1">{getCaseLabel(product)}</Typography>
        {!!product.opposingParty && <>./. {product.opposingParty}</>}
        <BadgesList badges={getBadges(product, currentUser)} />
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Box>Unser Zeichen: {product.reference}</Box>
          {product.paymentType === "insurance" && (
            <Box>
              <InsuranceData product={product} />
            </Box>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
}
