import customerTranslations from "./customerTranslations";
import basicCaseTranslations from "./basicCaseTranslations";
import litigationCaseTranslations from "./litigationCaseTranslations";
import contractClaimTranslations from "./contractClaimTranslations";

const travelContract = {
  customer: customerTranslations,
  ...basicCaseTranslations,
  ...litigationCaseTranslations,
  ...contractClaimTranslations,
  label: "Reisevertrag",
  reasonForDispute: {
    label: "Problem des Kunden",
    values: {
      cancelled: "Die Reise wurde abgesagt",
      participationImpossible: "Konnte nicht teilnehmen",
      notAsAdvertised: "Nicht wie beschrieben",
      other: "Sonstiges",
      custom: "Individueller Sachverhalt",
    },
  },
  contractNumber: {
    label: "Buchungsnummer",
  },
  bookingDate: {
    label: "Buchungsdatum",
  },
  journeyStart: {
    label: "Start der Reise am",
  },
  journeyEnd: {
    label: "Ende der Reise am",
  },
  contractualPartnerHasCancelled: {
    label: "Absage durch Reiseanbieter",
    values: {
      true: "Ja",
      false: "Nein",
    },
  },
  contractualPartnerCancellationDate: {
    label: "Absage am",
  },
};

export default travelContract;
