import * as types from "./actionTypes";
import Immutable from "seamless-immutable";
import ReactGA from "react-ga4";

const initialState = Immutable({
  backofficeUser: null,
});

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.BACKOFFICEUSER_LOGIN:
      ReactGA.set({ userId: action.backofficeUser.id });
      ReactGA.event({
        category: "BackofficeUser",
        action: "Login",
      });
      return state.merge({
        backofficeUser: action.backofficeUser,
      });
    case types.BACKOFFICEUSER_FETCH:
      return state.merge({
        backofficeUser: action.backofficeUser,
      });
    case types.BACKOFFICEUSER_LOGOUT:
      localStorage.removeItem("legalbirdServicesJWT");
      return state;
    default:
      return state;
  }
}

export function getBackofficeUser(state) {
  return state.backofficeUser.backofficeUser;
}
