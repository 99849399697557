import personTranslations from "./personTranslations";

const insuranceTranslations = {
  insurancePolicyNumber: {
    label: "Versicherungsnummer",
  },
  caseReferenceNumber: {
    label: "Schadennummer",
  },
  deductible: {
    label: "Selbstbehalt",
  },
  policyHolderType: {
    label: "Selbst oder mitversichert",
    values: {
      me: "Selbst versichert",
      familyMember: "Mitversichert",
    },
  },
  policyHolder: personTranslations,
  insurance: {
    label: "Versicherungsgesellschaft",
  },
};

export default insuranceTranslations;
