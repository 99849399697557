import React from "react";
import * as PropTypes from "prop-types";
import useStyles from "./linkListItemWithIconStyle";
import { Link } from "react-router-dom";
import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@mui/material";

const LinkListItemWithIcon = ({ to, MuiIcon, title, subtitle, description, imageIcon }) => {
  const classes = useStyles({});

  return (
    <ListItem alignItems="flex-start" component={Link} to={to} disableGutters>
      <ListItemAvatar>
        {MuiIcon ? (
          <Avatar>
            <MuiIcon />
          </Avatar>
        ) : (
          <Avatar variant={"square"} src={imageIcon} />
        )}
      </ListItemAvatar>
      <ListItemText
        primary={<div className={classes.listItemTitle}>{title}</div>}
        secondary={
          <>
            <span className={classes.bold}>{subtitle}</span> — {description}
          </>
        }
      />
    </ListItem>
  );
};

LinkListItemWithIcon.propTypes = {
  to: PropTypes.string.isRequired,
  MuiIcon: PropTypes.elementType,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageIcon: PropTypes.string,
};

export default LinkListItemWithIcon;
