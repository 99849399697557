import React from "react";
import PageContentContainer from "../../../Container/PageContentContainer";
import ActivitiesContainer from "../../../Activities/ActivityTable/ActivitiesContainer";

const ExternalsActivitiesPage = () => {
  return (
    <PageContentContainer>
      <ActivitiesContainer />
    </PageContentContainer>
  );
};

export default ExternalsActivitiesPage;
