import createCaseFormStyle from "./createCaseFormStyle";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import useForm from "../../hooks/useForm";
import ValidatorSelect from "../Validator/ValidatorSelect";
import MenuItem from "@mui/material/MenuItem";
import { convertToFloat, formValue } from "../../services/formServiceFunctions";
import { Checkbox, FormControlLabel, InputLabel, Stack, TextField } from "@mui/material";
import ValidatorTextField from "../Validator/ValidatorTextField";
import ButtonLoading from "../Button/ButtonLoading";
import _ from "lodash";
import { createResource, fetchCollection, fetchResource, updateResource } from "../../store/generic/actions";
import { getCaseLink, getProductData } from "../../services/Product/ProductService";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "../Snackbar/Snackbar";
import { useSnackbar } from "notistack";

import {
  atLeastOneFieldRequiredDefault,
  emailDefault,
  matchRegexp,
  moneyFieldDefault,
  requiredFieldDefault,
} from "../../services/validationRules";
import { translate } from "../../services/Translations/translatorService";
import ApiClient from "../../services/ApiClient";
import InputAdornment from "@mui/material/InputAdornment";
import PageHeadline from "../PageHeadline/PageHeadline";
import ContentBox from "../ContentBox/ContentBox";
import { getBackofficeUser } from "../../store/backofficeUser/reducer";
import { userHasRole } from "../../services/backofficeUserService";
import { Roles } from "../../types/BackofficeUser";

const useStyles = makeStyles(createCaseFormStyle);

const CreateCase = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoadingInternal, setIsLoadingInternal] = useState(false);
  const [customerStatus, setCustomerStatus] = useState("notChecked");
  const [hasNoEmail, setHasNoEmail] = useState(false);
  const currentUser = useSelector((state) => getBackofficeUser(state));

  const isCallcenterUser = userHasRole(currentUser, Roles.callcenter);

  const initialValues = {
    meta: {
      legalField: "",
      subLegalField: "",
    },
    customer: {
      email: "",
      telephone: "",
      gclid: "backoffice",
      person: {
        gender: "",
        givenName: "",
        familyName: "",
      },
      prelead: {
        initialProduct: "",
        landingPage: "Legalbird.io",
        partner: "",
      },
    },
    insurance: {
      caseReferenceNumber: "",
      insurancePolicyNumber: "",
      insuranceType: "full",
      deductible: "",
    },
    note: "",
  };

  const fetchCustomer = async (email) => {
    const customerCollection = await dispatch(fetchCollection("customers?email=" + encodeURIComponent(email)));
    if (customerCollection["hydra:totalItems"] === 0) {
      return null;
    }
    return _.head(customerCollection["hydra:member"]);
  };

  const onSubmit = async ({ values }) => {
    const productName = values.meta.legalField === "trafficLaw" ? "traffic" : values.meta.subLegalField;

    //-- manipulate initialProduct
    let customerData = _.merge({}, values.customer);
    customerData.prelead.initialProduct = productName;

    let customer;

    switch (true) {
      case hasNoEmail:
        customerData.email = `advocard.error+${values.insurance.insurancePolicyNumber}@gmail.com`;
        const backofficeCases = await getBackofficeCasesForEmail(customerData.email);
        if (backofficeCases.length > 0) {
          customer = await fetchCustomer(customerData.email);
          break;
        }
        customer = await dispatch(createResource("customers", customerData));
        break;
      default:
        customer =
          customerStatus === "newCustomer"
            ? await dispatch(createResource("customers", customerData))
            : await fetchCustomer(customerData.email);
        break;
    }

    if (!customer) {
      alert("Es ist ein technischer Fehler beim Auffinden des bestehenden Kunden aufgetreten!");
      return;
    }

    //-- add product
    const productUri = getProductData(productName, "apiUri");
    let productData = {
      customer: customer["@id"],
      acquisitionPartner: values.customer.prelead.partner,
      createdBy: isCallcenterUser ? "backoffice_callcenter" : "backoffice",
    };
    if (values.meta.legalField === "trafficLaw") {
      productData.accusation = values.meta.subLegalField;
    }
    const productEntity = await dispatch(createResource(productUri, productData));

    if (_.includes(["advocard", "roland", "arag"], values.customer.prelead.partner)) {
      //-- update insurance data
      const insuranceEntity = productEntity.insurance;
      let insuranceValues = _.cloneDeep(values.insurance);
      insuranceValues.deductible = insuranceValues.deductible ? convertToFloat(insuranceValues.deductible) : null;
      await dispatch(updateResource(insuranceEntity.id, "customer_insurances", insuranceValues));
    }

    const productEntityWithBackofficeCase = await dispatch(fetchResource(productEntity.id, productUri));

    if (!_.isEmpty(values.note)) {
      await dispatch(
        createResource("notes", {
          content: values.note,
          pinned: true,
          case: productEntityWithBackofficeCase.backofficeCase["@id"],
        })
      );
    }

    enqueueSnackbar("", {
      content: () => (
        <div>
          <Snackbar
            message={"Mandant erfolgreich angelegt"}
            buttonType={"link"}
            buttonLink={getCaseLink(productEntity, isCallcenterUser ? "/callcenter" : "")}
            buttonText={"Zum Fall"}
          />
        </div>
      ),
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
      autoHideDuration: 10000,
    });
    clearForm();
  };

  const getSubLegalFields = (legalField) => {
    switch (legalField) {
      case "familyLaw":
        return [
          <MenuItem key={"divorce"} value={"divorce"}>
            Trennung/Scheidung
          </MenuItem>,
          <MenuItem key={"alimony"} value={"alimony"}>
            Unterhalt
          </MenuItem>,
          <MenuItem key={"death"} value={"death"}>
            Erbe/Todesfall
          </MenuItem>,
          <MenuItem key={"familyLawCase"} value={"familyLawCase"}>
            Anderes Thema
          </MenuItem>,
        ];
      case "trafficLaw":
        return [
          <MenuItem key={"speeding"} value={"speeding"}>
            Geschwindigkeitsverstoß
          </MenuItem>,
          <MenuItem key={"red_light"} value={"red_light"}>
            Rotlichtverstoß
          </MenuItem>,
          <MenuItem key={"distance"} value={"distance"}>
            Abstandsverstoß
          </MenuItem>,
          <MenuItem key={"other"} value={"other"}>
            Handy/Drogen/Parken/Sonstiges
          </MenuItem>,
        ];
      case "labourLaw":
        return [
          <MenuItem key={"shortTimeWork"} value={"shortTimeWork"}>
            {getProductData("shortTimeWork", "label")}
          </MenuItem>,
        ];
      case "contractLaw":
        return [
          <MenuItem key={"purchaseContract"} value={"purchaseContract"}>
            {getProductData("purchaseContract", "label")}
          </MenuItem>,
          <MenuItem key={"vehicleContract"} value={"vehicleContract"}>
            {getProductData("vehicleContract", "label")}
          </MenuItem>,
          <MenuItem key={"serviceContract"} value={"serviceContract"}>
            {getProductData("serviceContract", "label")}
          </MenuItem>,
          <MenuItem key={"membershipContract"} value={"membershipContract"}>
            {getProductData("membershipContract", "label")}
          </MenuItem>,
          <MenuItem key={"subscriptionContract"} value={"subscriptionContract"}>
            {getProductData("subscriptionContract", "label")}
          </MenuItem>,
          <MenuItem key={"providerContract"} value={"providerContract"}>
            {getProductData("providerContract", "label")}
          </MenuItem>,
          <MenuItem key={"travelContract"} value={"travelContract"}>
            {getProductData("travelContract", "label")}
          </MenuItem>,
          <MenuItem key={"eventContract"} value={"eventContract"}>
            {getProductData("eventContract", "label")}
          </MenuItem>,
          <MenuItem key={"otherContract"} value={"otherContract"}>
            {getProductData("otherContract", "label")}
          </MenuItem>,
        ];
      default:
        return [];
    }
  };

  const hasLegalField = (legalField, partner) => {
    const legalFields = {
      advovard: ["familyLaw", "trafficLaw", "labourLaw", "contractLaw"],
      arag: ["familyLaw", "trafficLaw"],
      roland: ["familyLaw"],
      wbs: ["trafficLaw"],
    };

    if (!partner || !legalFields[partner]) {
      return true;
    }

    return _.includes(legalFields[partner], legalField);
  };

  const { values, handleChange, handleSubmit, handleBlur, clearForm, registerValidators, errors, isLoading } = useForm({
    initialValues,
    onSubmit,
    identifier: "registerCustomer",
  });

  const handlePartnerChange = (e) => {
    switch (e.target.value) {
      case "advocard":
        registerValidators("insurance.caseReferenceNumber", [
          ...atLeastOneFieldRequiredDefault("insurance.insurancePolicyNumber"),
          {
            validator: matchRegexp,
            params: ["^\\d{2}-?(33-?0001-?|\\d)\\d{5}-?\\d$"],
            message: "Bitte eine valide Advocard Schadennummer angeben",
          },
        ]);
        handleChange({ target: { type: "text", name: "meta.legalField", value: "" } });
        break;
      case "arag":
        registerValidators("insurance.caseReferenceNumber", requiredFieldDefault);
        handleChange({ target: { type: "text", name: "meta.legalField", value: "trafficLaw" } });
        break;
      case "roland":
        registerValidators("insurance.caseReferenceNumber", requiredFieldDefault);
        handleChange({ target: { type: "text", name: "meta.legalField", value: "familyLaw" } });
        break;
      case "wbs":
        registerValidators("insurance.caseReferenceNumber", []);
        handleChange({ target: { type: "text", name: "meta.legalField", value: "trafficLaw" } });
        break;
      default:
        return;
    }
    handleChange(e);
  };

  const getBackofficeCasesForEmail = async (email) => {
    const backofficeCasesResponse = await ApiClient.get("backoffice_cases?customerEmail=" + encodeURIComponent(email));
    return backofficeCasesResponse["hydra:member"];
  };

  const handleDuplicateBlur = async (e) => {
    handleBlur(e);
    if (values.customer.email === "") {
      return;
    }
    setCustomerStatus("notChecked");
    setIsLoadingInternal(true);
    const customer = await fetchCustomer(values.customer.email.toLowerCase());
    setCustomerStatus(!!customer ? "existingCustomer" : "newCustomer");
    setIsLoadingInternal(false);
  };

  useEffect(() => {
    if (hasNoEmail) {
      registerValidators("customer.email", []);
      registerValidators("insurance.insurancePolicyNumber", requiredFieldDefault);
      handleChange({ target: { name: "customer.email", value: "" } });
      handleBlur({ target: { name: "customer.email", value: "" } });
      return;
    }
    registerValidators("customer.email", [...emailDefault, ...requiredFieldDefault]);
  }, [hasNoEmail]);

  return (
    <>
      <PageHeadline main="Mandant/Fall anlegen" />
      <ContentBox>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4} alignItems={"flex-start"}>
            <Grid item lg={4} md={6} xs={12}>
              <ValidatorSelect
                label={"Kooperationspartner"}
                name={"customer.prelead.partner"}
                value={formValue(values, "customer.prelead.partner")}
                onChange={handlePartnerChange}
                onBlur={handleBlur}
                registerValidators={registerValidators}
                validators={requiredFieldDefault}
                error={!!errors["customer.prelead.partner"]}
                helperText={errors["customer.prelead.partner"]}
              >
                <MenuItem value={"arag"}>ARAG</MenuItem>
                <MenuItem value={"advocard"}>Advocard</MenuItem>
                <MenuItem value={"roland"}>Roland</MenuItem>
                {!isCallcenterUser && <MenuItem value={"wbs"}>WBS Rechtsanwälte</MenuItem>}
              </ValidatorSelect>
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <ValidatorSelect
                label={"Rechtsbereich"}
                name={"meta.legalField"}
                value={formValue(values, "meta.legalField")}
                onChange={handleChange}
                onBlur={handleBlur}
                registerValidators={registerValidators}
                validators={requiredFieldDefault}
                error={!!errors["meta.legalField"]}
                helperText={errors["meta.legalField"]}
              >
                {hasLegalField("familyLaw", values.customer.prelead.partner) && (
                  <MenuItem value={"familyLaw"}>Familienrecht</MenuItem>
                )}
                {hasLegalField("contractLaw", values.customer.prelead.partner) && (
                  <MenuItem value={"contractLaw"}>Vertragsrecht</MenuItem>
                )}
                {hasLegalField("trafficLaw", values.customer.prelead.partner) && (
                  <MenuItem value={"trafficLaw"}>Verkehrsrecht</MenuItem>
                )}
                {hasLegalField("labourLaw", values.customer.prelead.partner) && (
                  <MenuItem value={"labourLaw"}>Arbeitsrecht</MenuItem>
                )}
              </ValidatorSelect>
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <ValidatorSelect
                label={values.meta.legalField !== "trafficLaw" ? "Thema" : "Vorwurf"}
                name={"meta.subLegalField"}
                value={formValue(values, "meta.subLegalField")}
                onChange={handleChange}
                onBlur={handleDuplicateBlur}
                registerValidators={registerValidators}
                validators={requiredFieldDefault}
                error={!!errors["meta.subLegalField"]}
                helperText={errors["meta.subLegalField"]}
              >
                {getSubLegalFields(values.meta.legalField)}
              </ValidatorSelect>
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <ValidatorSelect
                label={"Anrede"}
                name={"customer.person.gender"}
                value={formValue(values, "customer.person.gender")}
                onChange={handleChange}
                onBlur={handleBlur}
                registerValidators={registerValidators}
                validators={requiredFieldDefault}
                error={!!errors["customer.person.gender"]}
                helperText={errors["customer.person.gender"]}
              >
                <MenuItem value={"female"}>Frau</MenuItem>
                <MenuItem value={"male"}>Herr</MenuItem>
              </ValidatorSelect>
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <ValidatorTextField
                fullWidth={true}
                name="customer.person.givenName"
                label="Vorname"
                value={formValue(values, "customer.person.givenName")}
                onChange={handleChange}
                onBlur={handleBlur}
                registerValidators={registerValidators}
                validators={requiredFieldDefault}
                error={!!errors["customer.person.givenName"]}
                helperText={errors["customer.person.givenName"]}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <ValidatorTextField
                fullWidth={true}
                name="customer.person.familyName"
                label="Nachname"
                value={formValue(values, "customer.person.familyName")}
                onChange={handleChange}
                onBlur={handleBlur}
                registerValidators={registerValidators}
                validators={requiredFieldDefault}
                error={!!errors["customer.person.familyName"]}
                helperText={errors["customer.person.familyName"]}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <ValidatorTextField
                fullWidth={true}
                name="customer.email"
                label="E-Mail-Adresse"
                value={formValue(values, "customer.email")}
                onChange={handleChange}
                onBlur={handleDuplicateBlur}
                disabled={values.meta.subLegalField === "" || hasNoEmail}
                registerValidators={registerValidators}
                validators={[...emailDefault, ...requiredFieldDefault]}
                error={!!errors["customer.email"]}
                helperText={errors["customer.email"]}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <Stack justifyContent={"center"} alignItems={"center"} sx={{ height: "80px" }}>
                <FormControlLabel
                  disabled={
                    values.customer.prelead.partner !== "advocard" ||
                    _.includes(["labourLaw", "trafficLaw"], values.meta.legalField)
                  }
                  control={<Checkbox checked={hasNoEmail} onChange={(e) => setHasNoEmail(e.target.checked)} />}
                  label={"Erstberatung ohne Email"}
                />
              </Stack>
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <ValidatorTextField
                fullWidth={true}
                name="customer.telephone"
                label="Telefonnummer"
                value={formValue(values, "customer.telephone")}
                onChange={handleChange}
                onBlur={handleBlur}
                registerValidators={registerValidators}
                validators={requiredFieldDefault}
                error={!!errors["customer.telephone"]}
                helperText={errors["customer.telephone"]}
              />
            </Grid>
            {values.customer.prelead.partner !== "wbs" && (
              <Grid item lg={4} md={6} xs={12}>
                <ValidatorTextField
                  fullWidth={true}
                  name="insurance.caseReferenceNumber"
                  label={translate("insurance.caseReferenceNumber.label")}
                  value={formValue(values, "insurance.caseReferenceNumber")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  registerValidators={registerValidators}
                  validators={atLeastOneFieldRequiredDefault("insurance.insurancePolicyNumber")}
                  error={!!errors["insurance.caseReferenceNumber"]}
                  helperText={errors["insurance.caseReferenceNumber"]}
                />
              </Grid>
            )}
            {values.customer.prelead.partner === "advocard" && (
              <Grid item lg={4} md={6} xs={12}>
                <ValidatorTextField
                  fullWidth={true}
                  name="insurance.insurancePolicyNumber"
                  label={translate("insurance.insurancePolicyNumber.label")}
                  value={formValue(values, "insurance.insurancePolicyNumber")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  registerValidators={registerValidators}
                  validators={atLeastOneFieldRequiredDefault("insurance.caseReferenceNumber")}
                  error={!!errors["insurance.insurancePolicyNumber"]}
                  helperText={errors["insurance.insurancePolicyNumber"]}
                />
              </Grid>
            )}
            {values.customer.prelead.partner === "roland" && (
              <Grid item lg={4} md={6} xs={12}>
                <ValidatorSelect
                  label={"Art der Versicherung"}
                  name={"insurance.insuranceType"}
                  value={formValue(values, "insurance.insuranceType")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  registerValidators={registerValidators}
                  validators={requiredFieldDefault}
                  error={!!errors["insurance.insuranceType"]}
                  helperText={errors["insurance.insuranceType"]}
                >
                  <MenuItem value={"full"}>Voll versichert</MenuItem>
                  <MenuItem value={"bonus"}>Nur Bonusberatung</MenuItem>
                  <MenuItem value={"uncovered"}>Nicht versichert</MenuItem>
                </ValidatorSelect>
              </Grid>
            )}
            {values.meta.legalField === "trafficLaw" && (
              <Grid item lg={4} md={6} xs={12}>
                <ValidatorTextField
                  fullWidth={true}
                  name="insurance.deductible"
                  label="Selbstbehalt"
                  value={formValue(values, "insurance.deductible")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  registerValidators={registerValidators}
                  validators={moneyFieldDefault}
                  error={!!errors["insurance.deductible"]}
                  helperText={errors["insurance.deductible"]}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">€</InputAdornment>,
                  }}
                />
              </Grid>
            )}
            {customerStatus === "existingCustomer" && (
              <Grid item xs={12}>
                <p className={classes.duplicateNotice}>
                  Wichtiger Hinweis: Zu dieser E-Mail liegt bereits ein Kundenkonto vor. Es wird ein neuer Fall
                  angelegt.
                </p>
              </Grid>
            )}
            <Grid item xs={12}>
              <InputLabel>Notiz</InputLabel>
              <TextField
                multiline
                rows={8}
                className={classes.textField}
                name={"note"}
                value={formValue(values, "note")}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={4} />
            <Grid item md={4} xs={12} className={classes.buttonContainer}>
              <ButtonLoading
                variant={"contained"}
                type={"submit"}
                disabled={customerStatus === "notChecked" && !hasNoEmail}
                isLoading={isLoading || isLoadingInternal}
              >
                Jetzt anlegen
              </ButtonLoading>
            </Grid>
            <Grid item md={4} />
            <Grid item md={4} />
            <Grid item md={4} xs={12} className={classes.buttonContainer}>
              <ButtonLoading variant={"text"} onClick={() => clearForm()}>
                Abbrechen
              </ButtonLoading>
            </Grid>
            <Grid item md={4} />
          </Grid>
        </form>
      </ContentBox>
    </>
  );
};

export default CreateCase;
