import React, { useEffect } from "react";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import { Typography } from "@mui/material";
import useForm from "../../hooks/useForm";
import ValidatorTextField from "../Validator/ValidatorTextField";
import { emailDefault, requiredFieldDefault } from "../../services/validationRules";
import { formValue } from "../../services/formServiceFunctions";
import ButtonLoading from "../Button/ButtonLoading";
import { UseForm } from "../../types/UseForm";
import Snackbar from "../Snackbar/Snackbar";
import { useSnackbar } from "notistack";
import ApiClient from "../../services/ApiClient";

type PasswordRecoveryModalProps = {
  recoverPasswordModalOpen: boolean;
  setRecoverPasswordModalOpen: (newState: boolean) => void;
  email: string;
};

export default function PasswordRecoveryModal({
  recoverPasswordModalOpen,
  setRecoverPasswordModalOpen,
  email,
}: PasswordRecoveryModalProps) {
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    email: "",
  };

  const onSubmit = async () => {
    await ApiClient.post("reset_user_password", { body: JSON.stringify({ email: formValue(values, "email") }) });

    enqueueSnackbar("", {
      content: () => (
        <div>
          <Snackbar
            message={"Wir haben einen Link zum Zurücksetzen des Passworts an Ihre E-Mail-Adresse gesendet."}
            isNonInteractive
          />
        </div>
      ),
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
    setRecoverPasswordModalOpen(false);
  };

  const { values, handleChange, handleSubmit, handleBlur, isLoading, errors, registerValidators }: UseForm = useForm({
    initialValues,
    onSubmit,
  });

  useEffect(() => {
    handleChange({ target: { name: "email", value: email } });
  }, [email]);

  return (
    <LegalbirdIoModal
      open={recoverPasswordModalOpen}
      handleClose={() => setRecoverPasswordModalOpen(false)}
      submitButton={
        <ButtonLoading isLoading={isLoading} onClick={handleSubmit} variant={"contained"}>
          Passwort zurücksetzen
        </ButtonLoading>
      }
      title={"Passwort vergessen?"}
    >
      <Typography sx={{ textAlign: "center" }}>
        Sie haben Ihr Passwort vergessen? Gerne schicken wir Ihnen einen Link zum Zurücksetzen Ihres Passworts zu
      </Typography>
      <ValidatorTextField
        name={"email"}
        label={"Ihre E-Mail-Adresse"}
        registerValidators={registerValidators}
        onChange={handleChange}
        onBlur={handleBlur}
        validators={[...emailDefault, ...requiredFieldDefault]}
        value={formValue(values, "email")}
        isMandatory={false}
        dependentValidationFields={[]}
        error={!!errors["email"]}
        helperText={errors["email"]}
      />
    </LegalbirdIoModal>
  );
}
