import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import ButtonLoading from "../../Button/ButtonLoading";
import React, { useState } from "react";
import { SubmissionError } from "../../../services/ApiClient";
import PropTypes from "prop-types";
import { getLeadStatusByStage, getNextStage, getProductData } from "../../../services/Product/ProductService";
import { updateResource } from "../../../store/generic/actions";
import _ from "lodash";
import StatusValidationErrors from "../CaseProgressBar/StatusValidationErrors";
import { validateStatusUpdate } from "../../../services/Product/statusChangeValidators";
import { useDispatch } from "react-redux";

function StageModal({ product, open, handleClose, refreshPage }) {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const dispatch = useDispatch();

  const nextStage = getNextStage(product);
  if (!nextStage) {
    return null;
  }

  const setNextStage = async () => {
    setIsLoading(true);
    const stage = getNextStage(product);

    const validationResult = validateStatusUpdate(product, stage.stage);
    if (validationResult.length > 0) {
      setErrors({
        stage: stage.label,
        error: new SubmissionError(validationResult, 400),
      });
      setIsLoading(false);
      return;
    }

    const statusChangeResult = await dispatch(
      updateResource(product.id, getProductData(product.productClassName, "apiUri"), {
        leadStatus: getLeadStatusByStage(product.productClassName, stage.stage),
      })
    );

    if (_.isError(statusChangeResult)) {
      setErrors({
        stage: stage.label,
        errorMessages: statusChangeResult,
      });
      setIsLoading(false);
      return;
    }

    refreshPage();
    handleClose();
  };

  if (errors !== null) {
    return (
      <StatusValidationErrors error={errors.errorMessages} stage={errors.stage} closeDialog={() => setErrors(null)} />
    );
  }

  return (
    <LegalbirdIoModal
      handleClose={handleClose}
      open={open}
      title={"Fall schieben"}
      submitButton={
        <ButtonLoading onClick={setNextStage} color="primary" variant={"contained"} isLoading={isLoading}>
          Fall schieben
        </ButtonLoading>
      }
    >
      <p className={"center"}>Wollen Sie den Fall wirklich auf "{nextStage.label}" schieben?</p>
    </LegalbirdIoModal>
  );
}
StageModal.propTypes = {
  product: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  refreshPage: PropTypes.func,
};

StageModal.defaultProps = {
  refreshPage: () => {},
};
export default StageModal;
