import React, { useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import { updateResource } from "../../../store/generic/actions";
import { useDispatch, useSelector } from "react-redux";
import * as PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import useStyles from "./activityActionsStyles";
import { getBackofficeUser } from "../../../store/backofficeUser/reducer";
import PopUpMenu from "../../../components/PopUpMenu/PopUpMenu";
import EditActivity from "../../../components/Activities/EditActivity/EditActivity";
import LegalbirdIoModal from "../../../components/Modal/LegalbirdIoModal";
import ButtonLoading from "../../../components/Button/ButtonLoading";
import CalendlyAssessmentModal from "../../../components/CalendlyAssessments/CalendlyAssessmentModal";

const ActivityActions = ({ activity, updateActivities, product }) => {
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [assessmentOpen, setAssessmentOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles({});
  const currentUser = useSelector((state) => getBackofficeUser(state));

  const isCalendlyActivity = useMemo(() => {
    return ["calendly_event", "lawyer_counseling_call"].includes(activity.type)
  }, [activity]);

  const handleDeleteActivity = async () => {
    setIsLoading(true);
    await dispatch(
      updateResource(activity.id, "activities", {
        deleted: true,
      })
    );
    setIsLoading(false);
    updateActivities();
    setDeleteOpen(false);
  };

  const toggleActivityDone = async () => {
    if (isCalendlyActivity) {
      if (activity.done === false) {
        setAssessmentOpen(true);
      }
      return;
    }
    setIsLoading(true);
    await dispatch(
      updateResource(activity.id, "activities", {
        done: !activity.done,
      })
    );
    updateActivities();
  };

  const buttonDisabled = !activity.assignedUser || (!product && isCalendlyActivity) || activity.done;

  return (
    <>
      <Grid item xs={3}>
        <ButtonLoading
          isLoading={isLoading}
          variant={"contained"}
          color={"primary"}
          onClick={toggleActivityDone}
          disabled={buttonDisabled}
          className={classes.doneButton}
          fullWidth={false}
        >
          Erledigt
        </ButtonLoading>
      </Grid>
      {!currentUser.isExternal && (
        <>
          <Grid item xs={5} className={classes.editMenu}>
            <PopUpMenu>
              <MenuItem onClick={() => setEditOpen(true)}>Bearbeiten</MenuItem>
              <MenuItem onClick={() => setDeleteOpen(true)}>Löschen</MenuItem>
            </PopUpMenu>
          </Grid>
          <EditActivity
            key={activity.id}
            activity={activity}
            open={editOpen}
            closeDialog={() => setEditOpen(false)}
            updateActivities={updateActivities}
          />
          <LegalbirdIoModal
            handleClose={() => setDeleteOpen(false)}
            open={deleteOpen}
            title={"Achtung"}
            submitButton={
              <ButtonLoading variant={"contained"} onClick={handleDeleteActivity} isLoading={isLoading}>
                Ja
              </ButtonLoading>
            }
          >
            <p className={classes.deleteDialogText}>
              Diese Aktivität wird unwiderruflich gelöscht. Sind Sie sicher, dass Sie die Aktivität löschen möchten?
            </p>
          </LegalbirdIoModal>
        </>
      )}
      {!!product && isCalendlyActivity && !activity.done && (
        <CalendlyAssessmentModal
          key={"calendlyAssessmentModal." + activity.id}
          open={assessmentOpen}
          handleClose={() => setAssessmentOpen(false)}
          activity={activity}
          update={updateActivities}
        />
      )}
    </>
  );
};

ActivityActions.propTypes = {
  activity: PropTypes.object.isRequired,
  updateActivities: PropTypes.func.isRequired,
  product: PropTypes.object,
};

export default ActivityActions;
