import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import * as PropTypes from "prop-types";
import Activity from "./Activity";
import _ from "lodash";
import { activitiesFilterString } from "../../hooks/useActivities";
import activitiesStyle from "./activitiesStyle";
import DoneActivity from "./DoneActivity";
import ButtonLoading from "../Button/ButtonLoading";
import useActivitiesFetching from "../../hooks/useActivitiesFetching";
import ContentBox from "../ContentBox/ContentBox";
import { Box, Typography } from "@mui/material";
import { scrollToAnchor } from "../../theme/commonStyles";
import { useBackofficeUser } from "../../provider/BackofficeUserProvider";

const useStyles = makeStyles(activitiesStyle);

const Activities = ({ product, additionalFilters, refreshCounter, refreshActivities }) => {
  const classes = useStyles({});
  const { backofficeUsers } = useBackofficeUser();
  const filter = _.merge({ deleted: false, pagination: false, refreshCounter: refreshCounter }, additionalFilters);
  const dataSource = activitiesFilterString(product, filter);
  const { activities, isLoading } = useActivitiesFetching(dataSource, backofficeUsers);
  const [doneResultCount, setDoneResultCount] = useState(3);

  if (isLoading || !activities) {
    return null;
  }

  const openActivities = _.filter(activities, (activity) => {
    return !activity.done;
  });
  const allDoneActivities = _.filter(activities, (activity) => {
    return activity.done;
  });
  const doneActivities = _.slice(_.orderBy(allDoneActivities, ["doneTime"], ["desc"]), 0, doneResultCount);

  return (
    <>
      <Box sx={scrollToAnchor} id={"activities"} />
      <ContentBox headline="Anstehende Aktivitäten">
        {openActivities.length > 0 ? (
          _.map(openActivities, (activity) => (
            <div className={classes.activityContainer} key={activity.id}>
              <Activity activity={activity} product={product} updateActivities={refreshActivities} />
            </div>
          ))
        ) : (
          <div className={classes.faded}>Es ist keine Aktivität geplant</div>
        )}
        <div className={classes.horizontalLine} />
        <Typography sx={{textAlign: "center"}} variant="h3">Letzte Aktivitäten</Typography>
        {doneActivities.length > 0 ? (
          _.map(doneActivities, (doneActivity) => (
            <div className={classes.activityContainer} key={doneActivity.id}>
              <DoneActivity
                productClassName={product.productClassName}
                activity={doneActivity}
                updateActivities={refreshActivities}
              />
            </div>
          ))
        ) : (
          <div className={classes.faded}>Keine erledigte Aktivität vorhanden</div>
        )}
        {allDoneActivities.length > doneResultCount && (
          <ButtonLoading onClick={() => setDoneResultCount(doneResultCount + 10)}>Mehr anzeigen</ButtonLoading>
        )}
        {doneActivities.length > 3 && (
          <ButtonLoading onClick={() => setDoneResultCount(3)} className={classes.button}>
            Weniger anzeigen
          </ButtonLoading>
        )}
      </ContentBox>
    </>
  );
};

Activities.propTypes = {
  product: PropTypes.object.isRequired,
  additionalFilters: PropTypes.object,
};

Activities.defaultProps = {
  additionalFilters: {},
};

export default Activities;
