import customerTranslations from "./customerTranslations";
import basicCaseTranslations from "./basicCaseTranslations";
import contractClaimTranslations from "./contractClaimTranslations";

const membershipContractTranslations = {
  customer: customerTranslations,
  ...basicCaseTranslations,
  ...contractClaimTranslations,
  label: "Mitgliedschaft",
  reasonForDispute: {
    label: "Problem mit Mitgliedschaft",
    values: {
      serviceUnavailable: "Mitgliedschaft nicht (voll) nutzbar",
      cancellationContractExtension: "Mitgliedschaft trotz Kündigung verlängert",
      serviceUnavailableCancellationCombined: "Kündigung UND Beitragserstattung",
      cancellationRefused: "Kündigung wurde nicht akzeptiert",
      other: "Anderes Problem",
      custom: "Individueller Sachverhalt",
    },
  },
  serviceUnavailableOffer: {
    label: "Ersatzangebot des Gegners",
    values: {
      coupon: "Gutscheine",
      freeMonths: "Freimonate",
      couponFreeMonths: "Gutscheine und Freimonate",
      noOffer: "Kein Angebot",
    },
  },
  cancellationDate: {
    label: "Kündigungsdatum",
  },
  endOfContractDate: {
    label: "Datum Vertragsende",
  },
  contractNumber: {
    label: "Vertrags-/Mitgliedsnummer",
  },
  membershipType: {
    label: "Art der Mitgliedschaft",
    values: {
      gym: "Fitnessstudio",
      club: "Verein",
      other: "Andere Mitgliedschaft",
    },
  },
  feePaymentFrequency: {
    label: "Zahlung erfolgt",
    values: {
      monthly: "Monatlich",
      quaterly: "Pro Quartal",
      yearly: "Jährlich",
    },
  },
  fee: {
    label: "Betrag in €",
  },
  serviceUnavailableFromDate: {
    label: "Nicht nutzbar von",
  },
  serviceUnavailableToDate: {
    label: "Nicht nutzbar bis",
  },
  numberDaysAvailableInBetween: {
    label: "Anzahl nutzbare Tage",
  },
  numberUnpaidPeriods: {
    label: "Anzahl nicht bezahlter Perioden",
  },
};

export default membershipContractTranslations;
