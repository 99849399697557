import React from "react";
import LinkListItemWithIcon from "../LinkListItemWithIcon/LinkListItemWithIcon";
import { PersonAddOutlined } from "@mui/icons-material";
import tasksImage from "../../assets/icon/icon_progress_100px.jpg";
import pcsImage from "../../assets/icon/icon_vkh_150px.png";
import trafficFineImage from "../../assets/icon/icon_bussgeldrechner.png";
import divorceCostImage from "../../assets/icon/icon_scheidungskosten.png";
import { List } from "@mui/material";

const ClientsAndCases = () => {
  return (
    <List>
      <LinkListItemWithIcon
        title={"Neuer Fall"}
        subtitle={"Mandant/Fall anlegen"}
        description={"hier können neue Mandanten hinzugefügt werden"}
        to={"/services/tools/mandant-anlegen"}
        MuiIcon={PersonAddOutlined}
      />
      <LinkListItemWithIcon
        title={"Ausschreibungen"}
        subtitle={"Ausgeschriebene Aufgaben verwalten"}
        description={"Alle Ausschreibungen (offen, in Bearbeitung, etc.) auf einen Blick"}
        to={"/services/ausgeschriebene-aufgaben/verwalten"}
        imageIcon={tasksImage}
      />
      <LinkListItemWithIcon
        title={"VKH Anträge"}
        subtitle={"Prüfen und freigeben"}
        description={"VKH Anträge einfach verwalten und bearbeiten"}
        to={"/services/tools/verfahrenskostenhilfe"}
        imageIcon={pcsImage}
      />
      <LinkListItemWithIcon
        title={"Bußgeld"}
        subtitle={"kurz berechnen"}
        description={"Vorwurf und Weiteres eingeben und Ergebnis erhalten"}
        to={"/services/tools/bussgeldrechner"}
        imageIcon={trafficFineImage}
      />
      <LinkListItemWithIcon
        title={"Scheidungskosten"}
        subtitle={"vorab berechnen"}
        description={"Nettoeinkommen und Renten eingeben und Ergebnis erhalten"}
        to={"/services/tools/scheidungskostenrechner"}
        imageIcon={divorceCostImage}
      />
    </List>
  );
};

export default ClientsAndCases;
