import React, { useMemo, useState } from "react";
import { getRoleToDisplay, sortByRole, userHasRole } from "../../services/backofficeUserService";
import { useDispatch, useSelector } from "react-redux";
import { getBackofficeUser } from "../../store/backofficeUser/reducer";
import ContentBox from "../ContentBox/ContentBox";
import _ from "lodash";
import { BackofficeUser } from "../../types/BackofficeUser";
import { useBackofficeUser } from "../../provider/BackofficeUserProvider";
import LegalbirdAutoComplete from "../AutoComplete/LegalbirdAutoComplete";
import ButtonLoading from "../Button/ButtonLoading";
import ApiClient from "../../services/ApiClient";
import { BACKOFFICEUSER_LOGIN } from "../../store/backofficeUser/actionTypes";
import { Navigate, useNavigate } from "react-router-dom";

interface AutoCompleteOption {
  label: string;
  id: string;
  groupBy?: string;
}

const getOptions = (backofficeUsers: Array<BackofficeUser>): Array<AutoCompleteOption> => {
  return _.map(sortByRole(backofficeUsers), (backofficeUser) => {
    return {
      label: backofficeUser.person.fullname,
      id: backofficeUser.email,
      groupBy: getRoleToDisplay(backofficeUser),
    };
  });
};

export default function Impersonate() {
  const currentUser = useSelector((state) => getBackofficeUser(state));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState<AutoCompleteOption | null | undefined>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { backofficeUsers } = useBackofficeUser();

  const handleImpersonate = (impersonateUser: string | undefined) => {
    if (!impersonateUser) {
      return;
    }
    setIsLoading(true);
    sessionStorage.setItem("impersonate", impersonateUser);
    ApiClient.get("backoffice_users/me").then(
      (apiResponseUser) => {
        dispatch({ type: BACKOFFICEUSER_LOGIN, backofficeUser: apiResponseUser["hydra:member"][0] });
        navigate("/");
      },
      (error) => {
        alert(JSON.stringify(error));
        setIsLoading(false);
      }
    );
  };

  const clearImpersonate = () => {
    sessionStorage.removeItem("impersonate");
    ApiClient.get("backoffice_users/me").then(
      (apiResponseUser) => {
        dispatch({ type: BACKOFFICEUSER_LOGIN, backofficeUser: apiResponseUser["hydra:member"][0] });
      },
      (error) => {
        alert(JSON.stringify(error));
        setIsLoading(false);
      }
    );
  };

  const options = useMemo(() => {
    return getOptions(backofficeUsers);
  }, [backofficeUsers]);

  if (!userHasRole(currentUser, "ROLE_ADMIN") && !sessionStorage.getItem("impersonate")) {
    return <Navigate to={"/"} />;
  }

  if (sessionStorage.getItem("impersonate")) {
    return (
      <ContentBox headline={"Benutzeransicht beenden"}>
        <ButtonLoading variant={"contained"} onClick={() => clearImpersonate()}>
          Nutzeransicht "{currentUser.email}" beenden
        </ButtonLoading>
      </ContentBox>
    );
  }

  return (
    <ContentBox headline={"Benutzeransicht wechseln"}>
      <LegalbirdAutoComplete
        value={selectedUser}
        options={options}
        groupBy={(option: AutoCompleteOption) => option.groupBy}
        setValue={(event: any, value: any) => {
          setSelectedUser(_.find(options, (option) => option.id === value.id));
        }}
        label={"Nutzer"}
      />
      <ButtonLoading
        variant={"contained"}
        isLoading={isLoading}
        disabled={isLoading || !selectedUser}
        onClick={() => handleImpersonate(selectedUser?.id)}
      >
        Nutzeransicht {selectedUser?.id} starten
      </ButtonLoading>
    </ContentBox>
  );
}
