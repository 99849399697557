import _ from "lodash";
import { getProductData, getStageList } from "./Product/ProductService";
import { convertToFormattedTime, insurances } from "./formServiceFunctions";
import moment from "moment";
import { translate } from "./Translations/translatorService";
const getInsuranceIdFromIri = (iri) => {
  return _.last(iri.split("/"));
};

export const prepareHistory = (history, productClassName) => {
  const historyCopy = _.merge([], history);
  _.forEach(historyCopy, (historyItem) => {
    _.forEach(historyItem.logData, (dataEntry, key) => {
      //convert leadStatus to stage
      if (key === "leadStatus") {
        const translatedCurrentStage = _.find(
          getStageList(productClassName),
          (stage) => stage.leadStatus === dataEntry.current
        );
        const translatedPreviousStage = _.find(
          getStageList(productClassName),
          (stage) => stage.leadStatus === dataEntry.previous
        );
        dataEntry.current = translatedCurrentStage
          ? translatedCurrentStage.label
          : translate(productClassName + ".leadStatus[" + dataEntry.current + "]");
        dataEntry.previous = translatedPreviousStage
          ? translatedPreviousStage.label
          : "Automatischer Statuswechsel: " + translate(productClassName + ".leadStatus[" + dataEntry.previous + "]");
      }

      //convert dates
      if (
        _.find(
          getProductData(productClassName, "formTransformations.dateFields"),
          (dateField) => dateField.path === key
        )
      ) {
        dataEntry.current = dataEntry.current ? moment(dataEntry.current).format("DD.MM.YYYY") : dataEntry.current;
        dataEntry.previous = dataEntry.previous ? moment(dataEntry.previous).format("DD.MM.YYYY") : dataEntry.previous;
      }

      //convert insurances
      //works as long as insurance has this path in all products
      if (key === "insurance.insurance") {
        if (dataEntry.current) {
          const currentInsurance = _.find(
            insurances,
            (insurance) => insurance.id + "" === getInsuranceIdFromIri(dataEntry.current)
          );
          dataEntry.current = currentInsurance ? currentInsurance.label : dataEntry.current;
        }
        if (dataEntry.previous) {
          const previousInsurance = _.find(
            insurances,
            (insurance) => insurance.id + "" === getInsuranceIdFromIri(dataEntry.previous)
          );
          dataEntry.previous = previousInsurance ? previousInsurance.label : dataEntry.previous;
        }
      }

      //convert times
      if (
        _.find(getProductData(productClassName, "formTransformations.timeFields"), (timeField) => timeField === key)
      ) {
        dataEntry.current = convertToFormattedTime(dataEntry.current);
        dataEntry.previous = convertToFormattedTime(dataEntry.previous);
      }
    });
  });
  return historyCopy;
};
