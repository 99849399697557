import React, { useState } from "react";
import { Paper, Switch } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import * as PropTypes from "prop-types";
import { STAGE_CHECKED, STAGE_DIVORCE_DONE } from "../../../services/Stages/StagesDivorce";
import { calculateFees, calculateValueInDispute } from "../../DivorceCalculator/costCalculation";
import FeesService from "../../DivorceCalculator/FeesService";
import CostCalculator from "../../DivorceCalculator/CostCalculator";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles(() => ({
  paper: {
    margin: "2rem 0 2rem 0",
    padding: "2rem",
    fontSize: "1rem",
  },
  headline: {
    marginBottom: "1rem",
    textAlign: "center",
    fontSize: "1.25rem",
  },
  realCostBox: {
    marginTop: "3rem",
  },
  hint: {
    textAlign: "center",
    padding: "1rem",
    border: "1px solid black",
    borderRadius: 5,
    fontWeight: 700,
  },
}));

const CostBoxDivorce = (props) => {
  const classes = useStyles(props);
  const { divorce } = props;

  const [isOldRvg, setIsOldRvg] = useState(false);
  const [isOldVat, setIsOldVat] = useState(false);

  const valueInDispute = calculateValueInDispute(divorce);
  const costs = calculateFees(valueInDispute.total, isOldRvg, isOldVat, divorce);
  const processCosts = costs.lawyerCourt + costs.expenses;
  const courtCosts = costs.court;
  const hearingCosts = costs.lawyerHearing;
  const differencePayment = Math.abs(
    divorce.costs.processReal +
      divorce.costs.hearingReal +
      23.8 -
      (divorce.costs.hearingPre + divorce.costs.processPre + FeesService.getExpenses(true, isOldVat))
  ).toLocaleString("de-DE", {
    style: "currency",
    currency: "EUR",
  });
  const installmentRate =
    (FeesService.getExpenses(true, isOldVat) +
      CostCalculator.getHearingCost(valueInDispute.total, true, isOldRvg, isOldVat) +
      CostCalculator.getProcessCost(valueInDispute.total, true, isOldRvg, isOldVat)) /
    10;
  const costJump =
    FeesService.getFeeByValue(divorce.costs.valueInDisputeReal, 1, false, isOldRvg, isOldVat) !==
    FeesService.getFeeByValue(valueInDispute.total, 1, false, isOldRvg, isOldVat);

  return (
    <Paper className={classes.paper}>
      <div className={classes.headline}>{"Berechnete Kosten"}</div>
      <Grid container alignItems={"center"}>
        <Grid item>Neues RVG</Grid>
        <Grid item>
          <Switch checked={isOldRvg} onChange={() => setIsOldRvg(!isOldRvg)} />
        </Grid>
        <Grid item>Altes RVG</Grid>
      </Grid>
      <Grid container alignItems={"center"}>
        <Grid item>19% MwSt</Grid>
        <Grid item>
          <Switch checked={isOldVat} onChange={() => setIsOldVat(!isOldVat)} />
        </Grid>
        <Grid item>16% MwSt</Grid>
      </Grid>
      <p>
        Verfahrensgebühr inkl. Auslagenpauschale:{" "}
        {processCosts.toLocaleString("de-DE", {
          style: "currency",
          currency: "EUR",
        })}
        <br />
        Gerichtsgebühr: {courtCosts.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
        <br />
        Terminsgebühr: {hearingCosts.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
        <br />
        <strong>
          Scheidungskosten Gesamt:{" "}
          {(processCosts + courtCosts + hearingCosts).toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
          })}
        </strong>
      </p>
      <p>
        Streitwert Scheidung:{" "}
        {valueInDispute.divorce.toLocaleString("de-DE", {
          style: "currency",
          currency: "EUR",
        })}
        <br />
        Streitwert VA: {valueInDispute.pensions.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
        <br />
        <strong>
          Streitwert Gesamt:{" "}
          {(valueInDispute.divorce + valueInDispute.pensions).toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
          })}
        </strong>
      </p>
      {(divorce.stage < STAGE_CHECKED || divorce.costs.installment) && (
        <p>
          <strong>Ratenzahlung</strong>
          <br />
          Anwaltskosten gesamt:{" "}
          {(installmentRate * 10).toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
          })}
          <br />
          10 Raten zu je: {installmentRate.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
        </p>
      )}
      {divorce.stage >= STAGE_DIVORCE_DONE &&
        divorce.costs.processReal &&
        divorce.costs.courtReal &&
        divorce.costs.hearingReal && (
          <div className={classes.realCostBox}>
            <div className={classes.headline}>{"Tatsächliche Kosten"}</div>
            <p>
              Finaler Streitwert:{" "}
              {divorce.costs.valueInDisputeReal.toLocaleString("de-DE", {
                style: "currency",
                currency: "EUR",
              })}
              <br />
              Verfahrensgebühr inkl. Auslagenpauschale:{" "}
              {(divorce.costs.processReal + costs.expenses).toLocaleString("de-DE", {
                style: "currency",
                currency: "EUR",
              })}
              <br />
              Gerichtsgebühr: {divorce.costs.courtReal.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
              <br />
              Terminsgebühr: {divorce.costs.hearingReal.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
            </p>
            {costJump && !divorce.processCostSupport && (
              <div className={classes.hint}>
                {valueInDispute.total < divorce.costs.valueInDisputeReal
                  ? `Kunde muss ${differencePayment} nachzahlen`
                  : `Kunde erhält Gutschrift über ${differencePayment}`}
              </div>
            )}
          </div>
        )}
    </Paper>
  );
};

CostBoxDivorce.propTypes = {
  divorce: PropTypes.object.isRequired,
};

export default CostBoxDivorce;
