import Dialog from "@mui/material/Dialog";
import React from "react";
import legalbirdIoModalStyle from "./legalbirdIoModalStyle";
import makeStyles from "@mui/styles/makeStyles";
import IconButton from "@mui/material/IconButton";
import { Close } from "@mui/icons-material";
import Button from "@mui/material/Button";
import * as PropTypes from "prop-types";
import { DialogActions, DialogContent, DialogTitle, Divider, Stack } from "@mui/material";

const useStyles = makeStyles(legalbirdIoModalStyle);

const LegalbirdIoModal = ({
  children,
  title,
  open,
  handleClose,
  hasActions = true,
  hideCancelButton = false,
  submitButton,
  disableBackdropClick = false,
  actionsInfo = undefined,
  ...rest
}) => {
  const classes = useStyles({});

  return (
    <Dialog open={open} onBackdropClick={disableBackdropClick ? null : handleClose} {...rest}>
      <DialogTitle>
        <div className={classes.flexContainer}>
          <div className={classes.fixedSizeFlexItem} />
          <div className={classes.flexHeadline}>{title}</div>
          <div className={classes.fixedSizeFlexItem}>
            <IconButton onClick={handleClose} size="large">
              <Close />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>{children}</DialogContent>
      {hasActions && (
        <>
          <div className={classes.dividerContainer}>
            <Divider className={classes.divider} />
          </div>
          {actionsInfo}
          <DialogActions>
            <Stack spacing={2} className={classes.stack}>
              {submitButton}
              {!hideCancelButton && <Button onClick={handleClose}>Abbrechen</Button>}
            </Stack>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

LegalbirdIoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  handleClose: PropTypes.func.isRequired,
  hasActions: PropTypes.bool,
  hideCancelButton: PropTypes.bool,
  submitButton: PropTypes.node,
  disableBackdropClick: PropTypes.bool,
};

LegalbirdIoModal.defaultProps = {
  hasActions: true,
  disableBackdropClick: false,
};

export default LegalbirdIoModal;
