import { Grid } from "@mui/material";
import React, { useState, useMemo } from "react";
import ValidatorSelect from "../Validator/ValidatorSelect";
import { formValue } from "../../services/formServiceFunctions";
import MenuItem from "@mui/material/MenuItem";
import { useSelector } from "react-redux";
import { getBackofficeUser } from "../../store/backofficeUser/reducer";
import _ from "lodash";

type LawyerTitlesProps = {
  titles: {
    title: string;
    product: string;
  }[];
  values: any;
  handleChange: (event: any) => void;
  handleBlur: (event: any) => void;
};

export default function LawyerTitles({ titles, values, handleChange, handleBlur }: LawyerTitlesProps) {
  const [nextIndex, setNextIndex] = useState<number>(1);
  const currentUser = useSelector((state) => getBackofficeUser(state));
  const specialistTitleBase = useMemo(() => {
    return currentUser.person.gender === "female" ? "Fachanwältin für " : "Fachanwalt für ";
  }, [currentUser.person.gender]);

  return (
    <>
      <Grid item xs={12}>
        <ValidatorSelect
          label={"Titel"}
          name={"person.titles[0].title"}
          value={formValue(values, "person.titles[0].title")}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={currentUser.isExternal}
        >
          {currentUser.person.gender === "female" ? (
            [<MenuItem key="rain" value={"Rechtsanwältin"}>Rechtsanwältin</MenuItem>,
              <MenuItem key="assin" value={"Assessorin"}>Assessorin</MenuItem>]
            ) : (
            [<MenuItem key="ra" value={"Rechtsanwalt"}>Rechtsanwalt</MenuItem>,
            <MenuItem key="ass" value={"Assessor"}>Assessor</MenuItem>]
            )}
        </ValidatorSelect>
        {_.map(_.slice(titles, 1), (titleData, index) => {
          return (
            <ValidatorSelect
              key={index}
              label={"Fachanwaltstitel"}
              name={`person.titles[${index+1}].title`}
              value={formValue(values, `person.titles[${index+1}].title`)}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <MenuItem value={""}>Kein Titel</MenuItem>
              {_.map(specialistLawyerTitles, (lawyerTitle, index) => (
                <MenuItem key={index} value={lawyerTitle}>
                  {lawyerTitle}
                </MenuItem>
              ))}
            </ValidatorSelect>
          );
        })}
        <ValidatorSelect
          label={"Weiterer Fachanwaltstitel"}
          name={`person.titles[${titles.length}].title`}
          value={formValue(values, `person.titles[${titles.length}].title`)}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {_.map(specialistLawyerTitles, (lawyerTitle, index) => (
            <MenuItem key={index} value={lawyerTitle}>
              {lawyerTitle}
            </MenuItem>
          ))}
        </ValidatorSelect>
      </Grid>
    </>
  );
}

const specialistLawyerTitles = [
  "Familienrecht",
  "Verkehrsrecht",
  "Arbeitsrecht",
  "Erbrecht",
  "Miet- und Wohnungseigentumsrecht",
  "Verwaltungsrecht",
  "Steuerrecht",
  "Sozialrecht",
  "Strafrecht",
  "Insolvenz- und Sanierungsrecht",
  "Versicherungsrecht",
  "Medizinrecht",
  "Bau- und Architektenrecht",
  "Transport- und Speditionsrecht",
  "Gewerblicher Rechtsschutz",
  "Handels- und Gesellschaftsrecht",
  "Urheber- und Medienrecht",
  "Informationstechnologierecht",
  "Bank- und Kapitalmarktrecht",
  "Agrarrecht",
  "Internationales Wirtschaftsrecht",
  "Vergaberecht",
  "Migrationsrecht",
  "Sportrecht"
];
