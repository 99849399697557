import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { AbstractCase } from "../types/AbstractCase";
import ApiClient from "../services/ApiClient";
import { getProductData, getProductName } from "../services/Product/ProductService";
import _ from "lodash";

interface CaseProviderData {
  case: AbstractCase | null;
  product: AbstractCase | null;
  isLoading: boolean;
  error: any;
  refreshCase: () => void;
}

const CaseProviderContext = createContext<CaseProviderData | null>(null);

export default function CaseProvider({
  children,
  caseUrlPart,
  caseId,
}: {
  children?: React.ReactNode;
  caseUrlPart: string;
  caseId: number;
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<any>();
  const [caseEntity, setCaseEntity] = useState<AbstractCase | null>(null);
  const [refreshId, setRefreshId] = useState<number>(1);

  const productClassName = useMemo(() => getProductName(caseUrlPart), [caseUrlPart]);

  const caseUrl = useMemo(() => {
    return getProductData(productClassName, "apiUri") + "/" + caseId;
  }, [productClassName, caseId]);

  const refreshCase = () => {
    setRefreshId(refreshId + 1);
  };

  useEffect(() => {
    setIsLoading(true);
    ApiClient.get(caseUrl).then(
      (apiResult) => {
        if (_.isError(apiResult)) {
          setCaseEntity(null);
          setError(apiResult);
        } else {
          setCaseEntity(apiResult);
          setError(null);
        }
        setIsLoading(false);
      },
      (error) => {
        setCaseEntity(null);
        setError(error);
        setIsLoading(false);
      }
    );
  }, [caseUrl, refreshId]);

  const data: CaseProviderData = useMemo(() => ({
    "case": caseEntity,
    product: caseEntity,
    isLoading,
    error,
    refreshCase,
  }), [caseEntity, isLoading, error]);

  return <CaseProviderContext.Provider value={data}>{children}</CaseProviderContext.Provider>
}

export const useCase = () => {
  const caseContext = useContext(CaseProviderContext);
  if (!caseContext) {
    throw new Error("useCase can only be used inside CaseProvider");
  }
  return caseContext;
}
