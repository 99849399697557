import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import useForm from "../../../hooks/useForm";
import ValidatorForm from "../../Validator/ValidatorForm";
import ValidatorTextField from "../../Validator/ValidatorTextField";
import ButtonLoading from "../../Button/ButtonLoading";
import _ from "lodash";
import { formValue } from "../../../services/formServiceFunctions";
import {
  ibanFieldDefault,
  postalCodeDefault,
  requiredFieldDefault,
  textFieldDefault,
} from "../../../services/validationRules";
import { IbanInput } from "../../MaskedInputs/IbanInput";
import { useDispatch } from "react-redux";
import ApiClient from "../../../services/ApiClient";
import { fetch } from "../../../store/backofficeUser/actions";

export default function BillingForm({ bankData, backofficeUser }) {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    streetAddress: backofficeUser.person.address.streetAddress,
    postalCode: backofficeUser.person.address.postalCode,
    addressLocality: backofficeUser.person.address.addressLocality,
    accountOwnerFullName: bankData.accountOwnerFullName,
    iban: bankData.iban,
  };

  const onSubmit = async () => {
    setIsLoading(true);
    if (
      initialValues.streetAddress !== values.streetAddress ||
      initialValues.postalCode !== values.postalCode ||
      initialValues.addressLocality !== values.addressLocality
    ) {
      await ApiClient.put("backoffice_addresses/" + backofficeUser.person.address.id, {
        body: JSON.stringify({
          streetAddress: values.streetAddress,
          postalCode: values.postalCode,
          addressLocality: values.addressLocality,
        }),
      });
    }
    if (initialValues.accountOwnerFullName !== values.accountOwnerFullName || initialValues.iban !== values.iban) {
      await ApiClient.put("backoffice_bank_datas/" + bankData.id, {
        body: JSON.stringify({
          accountOwnerFullName: values.accountOwnerFullName,
          iban: values.iban,
        }),
      });
    }
    await dispatch(fetch());
    setIsLoading(false);
  };

  const { values, errors, handleChange, handleSubmit, handleBlur, registerValidators, touchedValues } = useForm({
    initialValues,
    onSubmit,
  });

  return (
    <ValidatorForm onSubmit={handleSubmit}>
      <Grid container spacing={3} sx={{ marginTop: "2rem" }}>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography variant={"h5"}>Rechnungs-Adresse</Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ValidatorTextField
            name={"streetAddress"}
            label={"Straße und Hausnummer"}
            value={formValue(values, "streetAddress")}
            onChange={handleChange}
            registerValidators={registerValidators}
            validators={[...requiredFieldDefault, ...textFieldDefault]}
            onBlur={handleBlur}
            error={!!errors["streetAddress"]}
            helperText={errors["streetAddress"]}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ValidatorTextField
            name={"postalCode"}
            label={"PLZ"}
            value={formValue(values, "postalCode")}
            onChange={handleChange}
            registerValidators={registerValidators}
            validators={[...requiredFieldDefault, postalCodeDefault]}
            onBlur={handleBlur}
            error={!!errors["postalCode"]}
            helperText={errors["postalCode"]}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ValidatorTextField
            name={"addressLocality"}
            label={"Ort"}
            value={formValue(values, "addressLocality")}
            onChange={handleChange}
            registerValidators={registerValidators}
            validators={[...requiredFieldDefault, ...textFieldDefault]}
            onBlur={handleBlur}
            error={!!errors["addressLocality"]}
            helperText={errors["addressLocality"]}
          />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography variant={"h5"}>Bankdaten</Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ValidatorTextField
            name={"accountOwnerFullName"}
            label={"Kontoinhaber"}
            value={formValue(values, "accountOwnerFullName")}
            onChange={handleChange}
            registerValidators={registerValidators}
            validators={[...requiredFieldDefault, ...textFieldDefault]}
            onBlur={handleBlur}
            error={!!errors["accountOwnerFullName"]}
            helperText={errors["accountOwnerFullName"]}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ValidatorTextField
            name={"iban"}
            label={"IBAN"}
            value={formValue(values, "iban")}
            onChange={handleChange}
            registerValidators={registerValidators}
            validators={[...requiredFieldDefault, ...ibanFieldDefault]}
            InputProps={{ inputComponent: IbanInput }}
            onBlur={handleBlur}
            error={!!errors["iban"]}
            helperText={errors["iban"] || "Achtung: Diese IBAN wird für die Abrechnung verwendet. Bitte stellen Sie sicher, dass Sie die richtige Bankverbindung angeben."}
          />
        </Grid>
        <Grid item xs={12}>
          <ButtonLoading
            variant={"contained"}
            fullWidth={false}
            sx={{ margin: "0 auto", display: "block" }}
            disabled={_.keys(touchedValues).length === 0}
            isLoading={isLoading}
            type={"submit"}
          >
            Speichern
          </ButtonLoading>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
}
