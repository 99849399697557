import InputAdornment from "@mui/material/InputAdornment";
import React from "react";
import * as stagesCase from "../../Stages/StagesCase";

const membershipProductSpecificDefinition = (product) => [
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.reasonForDispute`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    options: () => [
      { value: "serviceUnavailable" },
      { value: "cancellationContractExtension" },
      { value: "serviceUnavailableCancellationCombined" },
      { value: "cancellationRefused" },
      { value: "other" },
      { value: "custom" },
    ],
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.membershipType`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    options: () => [{ value: "gym" }, { value: "club" }, { value: "other" }],
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.serviceUnavailableOffer`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    options: () => [{ value: "coupon" }, { value: "freeMonths" }, { value: "couponFreeMonths" }, { value: "noOffer" }],
  },
  {
    type: "ValidatorTextField",
    path: `${product.productClassName}.contractNumber`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.feePaymentFrequency`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    options: () => [{ value: "monthly" }, { value: "quaterly" }, { value: "yearly" }],
  },
  {
    type: "ValidatorTextField",
    path: `${product.productClassName}.fee`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    additionalProps: {
      InputProps: {
        endAdornment: <InputAdornment position="start">€</InputAdornment>,
      },
    },
  },
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.cancellationDate`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
  },
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.endOfContractDate`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
  },
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.serviceUnavailableFromDate`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
  },
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.serviceUnavailableToDate`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
  },
  {
    type: "ValidatorTextField",
    path: `${product.productClassName}.numberDaysAvailableInBetween`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
  },
  {
    type: "ValidatorTextField",
    path: `${product.productClassName}.numberUnpaidPeriods`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
  },
];

export default membershipProductSpecificDefinition;
