import React from "react";
import * as PropTypes from "prop-types";
import ButtonLoading from "../../Button/ButtonLoading";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import moment from "moment";
import ValidatorForm from "../../Validator/ValidatorForm";
import ValidatorTextField from "../../Validator/ValidatorTextField";
import useForm from "../../../hooks/useForm";
import { minStringLength, requiredFieldDefault } from "../../../services/validationRules";
import { formValue } from "../../../services/formServiceFunctions";
import { useDispatch } from "react-redux";
import { updateResource } from "../../../store/generic/actions";
import { PAID_TASK_STATUS_ASSIGNED } from "../paidTaskStatus";

const DeletePaidTaskModal = ({ paidTask, handleClose, open, setIsLoading, isLoading }) => {
  const dispatch = useDispatch();

  const initialValues = {
    deletedReason: "",
  };

  const displayDeletedReason = paidTask.status === PAID_TASK_STATUS_ASSIGNED;

  const onSubmit = async () => {
    setIsLoading(true);
    let payload = {
      deletedDate: moment().format("DD.MM.YYYY"),
    };

    if (displayDeletedReason) {
      payload.deletedReason = formValue(values, "deletedReason");
    }

    await dispatch(updateResource(paidTask.id, "paid_tasks", payload, "paidTask" + paidTask.id));

    handleClose();
  };

  const { values, handleChange, handleSubmit, handleBlur, registerValidators, errors } = useForm({
    initialValues,
    onSubmit,
    identifier: paidTask,
  });

  return (
    <LegalbirdIoModal
      handleClose={handleClose}
      open={open}
      title={"Möchten Sie diese Aufgabe wirklich löschen?"}
      submitButton={
        <ButtonLoading variant={"contained"} onClick={handleSubmit} isLoading={isLoading}>
          Löschen
        </ButtonLoading>
      }
    >
      {displayDeletedReason && (
        <>
          Der zugeordnete Anwalt wird per E-Mail über das Löschen informiert und ihm wird der unten angegebene Grund für
          das Löschen genannt. Bitte daher kurz begründen, wieso die Aufgabe trotz Annahme gelöscht wurde.
          <ValidatorForm>
            <ValidatorTextField
              label={"Grund für Löschung"}
              name={"deletedReason"}
              onChange={handleChange}
              onBlur={handleBlur}
              value={formValue(values, "deletedReason")}
              registerValidators={registerValidators}
              error={!!errors["deletedReason"]}
              helperText={errors["deletedReason"]}
              validators={[
                ...requiredFieldDefault,
                {
                  validator: minStringLength,
                  params: [25],
                  message: "Der Text muss mindestens 25 Zeichen lang sein",
                },
              ]}
            />
          </ValidatorForm>
        </>
      )}
    </LegalbirdIoModal>
  );
};

DeletePaidTaskModal.propTypes = {
  paidTask: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default DeletePaidTaskModal;
