import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import useStyles from "./caseRelatedActionsStyle";
import { Drawer, Fab, Grid, Paper } from "@mui/material";
import { Add, AddOutlined, RemoveOutlined } from "@mui/icons-material";
import { Transition } from "react-transition-group";
import classNames from "classnames";
import ActionButton from "../../Actions/ActionButton";
import { useSelector } from "react-redux";
import { getBackofficeUser } from "../../../store/backofficeUser/reducer";
import { Roles } from "../../../types/BackofficeUser";
import { userHasOneOfTheseRoles } from "../../../services/backofficeUserService";

const CaseRelatedContainer = ({ mainActions, moreActions, useCondensedView, setUseCondensedView }) => {
  const classes = useStyles();
  const currentUser = useSelector((state) => getBackofficeUser(state));
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  if (isMobile) {
    return (
      <>
        <Fab className={classes.fab} onClick={() => setIsDrawerOpen(true)}>
          <Add />
        </Fab>
        <Drawer open={isDrawerOpen} anchor={"bottom"} onClose={() => setIsDrawerOpen(false)}>
          <Grid container className={classes.drawer}>
            {mainActions}
            {!userHasOneOfTheseRoles(currentUser, [Roles.external, Roles.callcenter]) && (
              <>
                {showMore && moreActions}
                <ActionButton
                  text={showMore ? "Weniger" : "Mehr"}
                  onClick={toggleShowMore}
                  icon={showMore ? RemoveOutlined : AddOutlined}
                />
              </>
            )}
          </Grid>
        </Drawer>
      </>
    );
  } else {
    return (
      <Transition in={showMore} timeout={500}>
        {(state) => (
          <Grid
            container
            ref={(el) => {
              el && setUseCondensedView(el.getBoundingClientRect().height < 800);
            }}
            component={Paper}
            className={classNames(classes.actionsContainer, classes[state + "Transition"])}
          >
            <Grid item xs={6}>
              <Grid container direction={"column"} alignItems={"center"}>
                {mainActions}
                {!userHasOneOfTheseRoles(currentUser, [Roles.external, Roles.callcenter]) && (
                  <ActionButton
                    text={showMore ? "Weniger" : "Mehr"}
                    onClick={toggleShowMore}
                    icon={showMore ? RemoveOutlined : AddOutlined}
                    useCondensedView={useCondensedView}
                  />
                )}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              {!userHasOneOfTheseRoles(currentUser, [Roles.external, Roles.callcenter]) && (
                <Grid container direction={"column"} alignItems={"center"}>
                  {moreActions}
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Transition>
    );
  }
};
export default CaseRelatedContainer;
