import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  bold: {
    fontWeight: 500,
  },
  listItemTitle: {
    fontWeight: 500,
    fontSize: "1rem",
    color: "black",
  },
}));

export default useStyles;
