import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import ApiClient from "../services/ApiClient";

interface HolidayProviderData {
  holidays: Array<string>;
  isLoading: boolean;
}

const HolidaysProviderContext = createContext<HolidayProviderData | null>(null);

export default function HolidaysProvider({ children, type = "hr" }: { children?: React.ReactNode; type?: string }) {
  const [holidays, setHolidays] = useState<Array<string>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    ApiClient.get("holidays/1").then((apiResult) => {
      setHolidays(apiResult.holidays);
      setIsLoading(false);
    });
  }, []);

  const data: HolidayProviderData = useMemo(
    () => ({
      holidays,
      isLoading,
    }),
    [holidays, isLoading]
  );

  return <HolidaysProviderContext.Provider value={data}>{children}</HolidaysProviderContext.Provider>;
}

export const useHolidays = () => {
  const holidaysContext = useContext(HolidaysProviderContext);
  if (!holidaysContext) {
    throw new Error("useHolidays can only be used inside HolidaysProvider");
  }
  return holidaysContext;
};
