import React, { useState } from "react";
import * as PropTypes from "prop-types";
import dateRangePickerStyles from "./dateRangePickerStyles";
import moment from "moment";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import { Box, TextField } from "@mui/material";
import { StaticDatePicker } from "@mui/x-date-pickers";

const DateRangePicker = ({ open, closeModal, setDateRange }) => {
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());

  const onConfirm = () => {
    const dateRange = {
      startDate: startDate,
      endDate: endDate,
    };
    setDateRange(dateRange);
    closeModal();
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);

    if (date.isAfter(endDate)) {
      setEndDate(date);
    }
  };

  return (
    <LegalbirdIoModal
      handleClose={() => {
        setDateRange("today");
        closeModal();
      }}
      open={open}
      title={"Zeitraum auswählen"}
      submitButton={
        <Button variant={"contained"} onClick={onConfirm}>
          Anwenden
        </Button>
      }
      maxWidth={"md"}
      fullWidth
    >
      <Grid container justifyContent={"center"}>
        <Grid item xs={12} md={5}>
          <Box sx={dateRangePickerStyles.datePickerContainer}>
            <Paper sx={dateRangePickerStyles.datePicker} elevation={8}>
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                showToolbar
                label="Start"
                value={startDate}
                onChange={handleStartDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box sx={dateRangePickerStyles.datePickerContainer}>
            <Paper sx={dateRangePickerStyles.datePicker} elevation={8}>
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                showToolbar
                label="Ende"
                value={endDate}
                onChange={(date) => setEndDate(date)}
                renderInput={(params) => <TextField {...params} />}
                minDate={startDate}
              />
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </LegalbirdIoModal>
  );
};

DateRangePicker.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  setDateRange: PropTypes.func.isRequired,
};

DateRangePicker.defaultProps = {};

export default DateRangePicker;
