import React, { useMemo, useState } from "react";
import ContentBox from "../ContentBox/ContentBox";
import useBeaFetching from "../../hooks/useBeaFetching";
import _ from "lodash";
import BeaMessage from "./BeaMessage";
import { Box, Button } from "@mui/material";
import { scrollToAnchor } from "../../theme/commonStyles";
import beaMessageStyles from "./beaMessageStyles";
import PropTypes from "prop-types";

export default function BeaMessages({ product }) {
  const [beaMessagesDisplayCount, setBeaMessagesDisplayCount] = useState(3);
  const [beaMessagesRefreshCounter, setBeaMessagesRefreshCounter] = useState(0);

  let urlSearchParams = useMemo(() => {
    let params = new URLSearchParams();
    params.append("case", product.backofficeCase.id);
    params.append("beaMessagesRefreshCounter", "" + beaMessagesRefreshCounter);
    return params;
  }, [beaMessagesRefreshCounter, product.backofficeCase.id]);

  const { beaMessages, isLoading } = useBeaFetching(urlSearchParams.toString());

  if (isLoading || !beaMessages) {
    return null;
  }

  const beaMessagesToDisplay = _.slice(beaMessages, 0, beaMessagesDisplayCount);

  return (
    <>
      <Box sx={scrollToAnchor} id={"beaMessages"} />
      <ContentBox headline={"beA-Nachrichten"}>
        {_.map(beaMessagesToDisplay, (beaMessage) => (
          <BeaMessage
            beaMessage={beaMessage}
            key={beaMessage.id}
            product={product}
            refreshBeaMessages={() => setBeaMessagesRefreshCounter(beaMessagesRefreshCounter + 1)}
          />
        ))}
        {beaMessages.length > beaMessagesDisplayCount && (
          <Button onClick={() => setBeaMessagesDisplayCount(beaMessagesDisplayCount + 10)}>Mehr Anzeigen</Button>
        )}
        {beaMessagesToDisplay.length > 3 && (
          <Button onClick={() => setBeaMessagesDisplayCount(3)} sx={{ margin: "1rem auto 0 0" }}>
            Weniger anzeigen
          </Button>
        )}
        {beaMessages.length === 0 && <Box sx={beaMessageStyles.faded}>Keine beA-Nachrichten vorhanden</Box>}
      </ContentBox>
    </>
  );
}

BeaMessages.propTypes = {
  product: PropTypes.object.isRequired,
};
