import * as types from "./actionTypes";
import Immutable from "seamless-immutable";

const initialState = Immutable({});

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.GENERIC_RECEIVED:
      return state.merge({
        [action.name]: action.value,
      });
    default:
      return state;
  }
}

export function getGeneric(state, name) {
  if (!state.generic || !state.generic[name]) {
    return null;
  }
  return state.generic[name];
}
