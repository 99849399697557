import React from "react";
import LinkListItemWithIcon from "../LinkListItemWithIcon/LinkListItemWithIcon";
import { List } from "@mui/material";
import checkMediaObjectsImage from "../../assets/icon/icon_dokumente_prüfen.png";
import beaSearchIcon from "../../assets/icon/bea_search.png";
import beaAttachmentFilterIcon from "../../assets/icon/filter_listpx.png";
import beaListIcon from "../../assets/icon/Tools_beA.svg";

const Mail = () => {
  return (
    <List>
      <LinkListItemWithIcon
        title={"Eingehende Dokumente"}
        subtitle={"Prüfen und freigeben"}
        description={"Automatische Zuordnung und Kenner überprüfen"}
        to={"/services/tools/dokumente-zuweisen"}
        imageIcon={checkMediaObjectsImage}
      />
      <LinkListItemWithIcon
        title={"beA-Anlagen"}
        subtitle={"Sichten und zusammenfügen"}
        description={"Schriftsätze und Anlagen zuordnen"}
        to={"/services/tools/bea"}
        imageIcon={beaListIcon}
      />
      <LinkListItemWithIcon
        title={"beA-Adressen"}
        subtitle={"suchen und finden"}
        description={"Empfänger in beA suchen"}
        to={"/services/tools/bea/suche"}
        imageIcon={beaSearchIcon}
      />
      <LinkListItemWithIcon
        title={"beA-Anhangsfilter"}
        subtitle={"erstellen und pflegen"}
        description={"Nicht benötigte Anhänge aussortieren"}
        to={"/services/tools/bea/anhangs-filter"}
        imageIcon={beaAttachmentFilterIcon}
      />
    </List>
  );
};

export default Mail;
