const editActivityNoteStyle = {
  editor: {
    minHeight: 250,
    backgroundColor: "#f8f4d0",
  },
  iconButtonContainer: {
    display: "block",
    width: 48,
    marginLeft: "auto",
    marginRight: 0,
  },
};

export default editActivityNoteStyle;
