import React from "react";
import { NewReleasesOutlined } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import { Notification } from "../../../types/Notification";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import ApiClient from "../../../services/ApiClient";
import _ from "lodash";

const iconTypes = (type: string) => {
  switch (type) {
    case "release-update":
      return <NewReleasesOutlined />;
    default:
      return <NewReleasesOutlined />;
  }
};

export default function NavigationBarNotificationItem({notification, handleClose}: {notification: Notification; handleClose: () => void;}) {
  const navigate = useNavigate();
  const handleClick = () => {
    if (!notification.isRead) {
      ApiClient.put("notifications/" + notification.id, {
        body: JSON.stringify({ isRead: true })
      });
    }
    notification.link && navigate(notification.link);
    handleClose();
  }

  return (
    <Stack sx={{width: "100%"}} direction={"row"} alignItems={"center"} onClick={handleClick}>
      <Box sx={{paddingRight: "1rem"}}>{iconTypes(notification.type)}</Box>
      <Box sx={{width: "100%"}}>
        <Box
          fontWeight={notification.isRead ? "normal" : "bold"}
          sx={{width: "100%", maxWidth: "300px", whiteSpace: "normal"}}
        >
          <Stack sx={{width: "100%"}} direction={"row"} justifyContent={"space-between"} alignItems={"flex-start"}>
            <Box>{notification.message}</Box>
            {!notification.isRead && <Box sx={{color:"green", fontSize: "1.5rem", lineHeight: "1rem"}}>&#9679;</Box>}
          </Stack>
        </Box>
        <Box>{moment(_.isObjectLike(notification.created) ? notification.created.date : notification.created).format("DD.MM.YYYY HH:mm")} Uhr</Box>
      </Box>
    </Stack>
  );
}