import * as status from "../LeadStatus/StatusTraffic";
import _ from "lodash";

const STAGE_INCOMPLETE = 0;
const STAGE_READY = 10;
const STAGE_CHECKED = 20;
const STAGE_ACCESS_TO_RECORDS = 30;
const STAGE_EXTRAJUDICIAL_PAID = 40;
const STAGE_OPINION_SUBMITTED = 50;
const STAGE_AUTHORITY_ANSWERED = 60;
const STAGE_TRIAL = 70;
const STAGE_TRIAL_DATE_SET = 75;
const STAGE_TRIAL_PAID = 80;
const STAGE_DECISION_MADE = 90;
const STAGE_COMPLETED = 100;

export {
  STAGE_INCOMPLETE,
  STAGE_READY,
  STAGE_CHECKED,
  STAGE_ACCESS_TO_RECORDS,
  STAGE_EXTRAJUDICIAL_PAID,
  STAGE_OPINION_SUBMITTED,
  STAGE_AUTHORITY_ANSWERED,
  STAGE_TRIAL,
  STAGE_TRIAL_DATE_SET,
  STAGE_TRIAL_PAID,
  STAGE_DECISION_MADE,
  STAGE_COMPLETED,
};

export const stageMetaDataTraffic = {
  [STAGE_INCOMPLETE]: {
    label: "Daten vollständig",
    stage: STAGE_INCOMPLETE,
    leadStatus: status.STATUS_DOCUMENTS_COMPLETE,
    isActive: () => true,
    isClickable: () => false,
    showInBoard: true,
  },
  [STAGE_READY]: {
    label: "Vollmacht erteilt",
    stage: STAGE_READY,
    leadStatus: status.STATUS_POWER_OF_ATTORNEY_GRANTED,
    isActive: () => true,
    isClickable: ({ product }) => product.stage >= STAGE_CHECKED,
    showInBoard: true,
  },
  [STAGE_CHECKED]: {
    label: "Überprüft",
    stage: STAGE_CHECKED,
    leadStatus: status.STATUS_CHECKED,
    isActive: () => true,
    isClickable: ({ product }) => product.stage === STAGE_READY || product.stage >= STAGE_ACCESS_TO_RECORDS,
    showInBoard: true,
  },
  [STAGE_ACCESS_TO_RECORDS]: {
    label: "Einspruch/Akteneinsicht",
    stage: STAGE_ACCESS_TO_RECORDS,
    leadStatus: status.STATUS_ACCESS_TO_RECORDS,
    isActive: ({ product }) => product.extended.appealRevoked === null && !product.extended.processStoppedDate,
    isClickable: ({ product }) =>
      product.extended.appealRevoked === null &&
      !product.extended.processStoppedDate &&
      (product.stage === STAGE_CHECKED || product.stage >= STAGE_EXTRAJUDICIAL_PAID),
    showInBoard: true,
  },
  [STAGE_EXTRAJUDICIAL_PAID]: {
    label: "Kostendeckung/ bezahlt",
    stage: STAGE_EXTRAJUDICIAL_PAID,
    leadStatus: status.STATUS_EXTRAJUDICIAL_PAID,
    isActive: ({ product }) =>
      product.extended.appealRevoked === null &&
      !["advocard", "arag"].includes(_.get(product, "acquisitionPartner")) &&
      !product.extended.processStoppedDate,
    isClickable: ({ product }) =>
      product.extended.appealRevoked === null &&
      !product.extended.processStoppedDate &&
      (product.stage === STAGE_ACCESS_TO_RECORDS || product.stage >= STAGE_OPINION_SUBMITTED) &&
      !["advocard", "arag"].includes(_.get(product, "acquisitionPartner")),
    showInBoard: true,
  },
  [STAGE_OPINION_SUBMITTED]: {
    label: "Stellungnahme",
    stage: STAGE_OPINION_SUBMITTED,
    leadStatus: status.STATUS_OPINION_SUBMITTED,
    isActive: ({ product }) =>
      product.extended.appealRevoked === null &&
      !product.extended.processStoppedDate &&
      product.extended.opinionRequired !== false,
    isClickable: ({ product }) =>
      product.extended.appealRevoked === null &&
      !product.extended.processStoppedDate &&
      product.extended.opinionRequired !== false &&
      (product.stage === STAGE_EXTRAJUDICIAL_PAID ||
        (product.stage === STAGE_ACCESS_TO_RECORDS &&
          ["advocard", "arag"].includes(_.get(product, "acquisitionPartner"))) ||
        product.stage >= STAGE_AUTHORITY_ANSWERED),
    showInBoard: true,
  },
  [STAGE_AUTHORITY_ANSWERED]: {
    label: "Antwort Behörde",
    stage: STAGE_AUTHORITY_ANSWERED,
    leadStatus: status.STATUS_AUTHORITY_ANSWERED,
    isActive: ({ product }) =>
      product.extended.appealRevoked === null &&
      !product.extended.processStoppedDate &&
      product.extended.authorityDecision !== "changed",
    isClickable: ({ product }) =>
      product.extended.appealRevoked === null &&
      !product.extended.processStoppedDate &&
      product.extended.authorityDecision !== "changed" &&
      (product.stage === STAGE_OPINION_SUBMITTED ||
        product.stage >= STAGE_TRIAL ||
        (_.includes([STAGE_ACCESS_TO_RECORDS, STAGE_EXTRAJUDICIAL_PAID], product.stage) &&
          product.extended.opinionRequired !== true)),
    showInBoard: true,
  },
  [STAGE_TRIAL]: {
    label: "Gerichtsverfahren",
    stage: STAGE_TRIAL,
    leadStatus: status.STATUS_TRIAL,
    isActive: ({ product }) =>
      !(
        product.extended.appealRevoked !== null ||
        (product.extended.authorityDecision !== "court" && product.extended.authorityDecision !== null) ||
        product.extended.trialWanted === "no" ||
        product.extended.processStoppedDate
      ),
    isClickable: ({ product }) => {
      if (
        product.extended.appealRevoked !== null ||
        product.extended.processStoppedDate ||
        product.extended.authorityDecision !== "court" ||
        product.extended.trialWanted === "no"
      ) {
        return false;
      }
      return product.stage === STAGE_AUTHORITY_ANSWERED || product.stage >= STAGE_TRIAL_DATE_SET;
    },
    showInBoard: true,
  },
  [STAGE_TRIAL_DATE_SET]: {
    label: "Gerichtstermin",
    stage: STAGE_TRIAL_DATE_SET,
    leadStatus: status.STATUS_TRIAL_DATE_SET,
    isActive: ({ product }) =>
      !(
        product.extended.appealRevoked !== null ||
        (product.extended.authorityDecision !== "court" && product.extended.authorityDecision !== null) ||
        (product.extended.courtNotice !== "trial" && product.extended.courtNotice !== null) ||
        product.extended.trialWanted === "no" ||
        product.extended.processStoppedDate
      ),
    isClickable: ({ product }) => {
      if (
        product.extended.appealRevoked !== null ||
        product.extended.processStoppedDate ||
        product.extended.authorityDecision !== "court" ||
        (product.extended.courtNotice !== null && product.extended.courtNotice !== "trial")
      ) {
        return false;
      }
      return product.stage === STAGE_TRIAL || product.stage >= STAGE_TRIAL_PAID;
    },
    showInBoard: true,
  },
  [STAGE_TRIAL_PAID]: {
    label: "Selbstzahler Kosten bezahlt",
    stage: STAGE_TRIAL_PAID,
    leadStatus: status.STATUS_TRIAL_PAID,
    isActive: ({ product }) =>
      product.extended.appealRevoked === null &&
      product.extended.authorityDecision === "court" &&
      product.extended.courtNotice === "trial" &&
      product.extended.paymentType === "private" &&
      !product.extended.processStoppedDate,
    isClickable: ({ product }) => {
      if (
        product.extended.appealRevoked !== null ||
        product.extended.processStoppedDate ||
        product.extended.authorityDecision !== "court" ||
        product.extended.courtNotice !== "trial" ||
        product.extended.paymentType !== "private"
      ) {
        return false;
      }
      return product.stage === STAGE_TRIAL_DATE_SET || product.stage >= STAGE_DECISION_MADE;
    },
    showInBoard: true,
  },
  [STAGE_DECISION_MADE]: {
    label: "Entscheidung gefällt",
    stage: STAGE_DECISION_MADE,
    leadStatus: status.STATUS_DECISION_MADE,
    isActive: () => true,
    isClickable: ({ product }) => {
      if (
        product.extended.appealRevoked !== null ||
        (product.extended.authorityDecision !== null && product.extended.authorityDecision !== "court") ||
        (product.extended.courtNotice !== null && product.extended.courtNotice !== "trial") ||
        product.extended.trialWanted !== "yes" ||
        product.extended.processStoppedDate
      ) {
        return product.stage >= STAGE_CHECKED && product.stage !== STAGE_DECISION_MADE;
      }
      return product.stage >= STAGE_TRIAL_DATE_SET && product.stage !== STAGE_DECISION_MADE;
    },
    showInBoard: true,
  },
  [STAGE_COMPLETED]: {
    label: "Abgeschlossen",
    stage: STAGE_COMPLETED,
    leadStatus: status.STATUS_COMPLETED,
    isActive: () => true,
    isClickable: ({ product }) => product.stage === STAGE_DECISION_MADE,
    showInBoard: true,
  },
};
