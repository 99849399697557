import React, { useMemo } from "react";
import Grid from "@mui/material/Grid";
import Badge from "../../Badges/Badge";
import { useSelector } from "react-redux";
import { getBackofficeUser } from "../../../store/backofficeUser/reducer";
import { translate } from "../../../services/Translations/translatorService";
import RequestButton from "./RequestButton";
import RemindButton from "./RemindButton";
import { PaidTask } from "../../../types/PaidTask";

export default function PaidTaskViewHeader({ paidTask }: { paidTask: PaidTask }) {
  const currentUser = useSelector((state) => getBackofficeUser(state));
  const usernameInBadge = useMemo(() => {
    if (!paidTask) {
      return;
    }
    switch (true) {
      case !!paidTask.assignee:
        return paidTask?.assignee?.person?.fullname;
      case !!paidTask.requestedUser:
        return paidTask?.requestedUser?.person?.fullname;
      default:
        return;
    }
  }, [paidTask]);

  return (
    <Grid container alignItems={"center"} spacing={2}>
      <Grid item xs={12} md={3}>
        {paidTask && <Badge text={translate("paidTask.status." + paidTask.status)} />}
      </Grid>
      <Grid item xs={12} md={3}>
        {usernameInBadge && <Badge text={usernameInBadge} />}
      </Grid>
      <Grid item xs={12} md={3} />
      <Grid item xs={12} md={3}>
        <RequestButton paidTask={paidTask} />
        <RemindButton paidTask={paidTask} />
      </Grid>
    </Grid>
  );
};
