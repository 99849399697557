import { useState, useEffect } from "react";
import ApiClient from "../services/ApiClient";
import _ from "lodash";

function useActivitiesFetching(dataSource, backofficeUsers) {
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState();
  const [activities, setActivities] = useState();
  const [error, setError] = useState("");

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const fetchDataResponse = await ApiClient.get(`activities?${dataSource}`);
        if (fetchDataResponse) {
          setResponse(fetchDataResponse);
        }
      } catch (error) {
        setIsLoading(false);
        setError(error.message);
      }
    }
    fetchData();
  }, [dataSource]);

  useEffect(() => {
    function enrichActivities(response) {
      if (!response || !backofficeUsers) {
        return;
      }
      const enrichedActivities = response["hydra:member"];
      for (let activity of enrichedActivities) {
        if (!!activity.assignedUser) {
          activity.assignedUser = _.find(
            backofficeUsers,
            (backofficeUser) => backofficeUser["@id"] === activity.assignedUser
          );
        }
        if (!!activity.creator) {
          activity.creator = _.find(backofficeUsers, (backofficeUser) => backofficeUser["@id"] === activity.creator);
        }
      }
      setActivities(enrichedActivities);
      setIsLoading(false);
    }
    enrichActivities(response);
  }, [response, backofficeUsers]);

  return {
    error,
    isLoading,
    activities,
  };
}
export default useActivitiesFetching;
