import React, { useState } from "react";
import { ReleaseUpdate as ReleaseUpdateType } from "../../types/ReleaseUpdate";
import { Box, Stack, Typography } from "@mui/material";
import moment from "moment";
import { DeleteOutlineOutlined, Edit } from "@mui/icons-material";
import ToolTipIconButton from "../Button/ToolTipIconButton";
import { useSelector } from "react-redux";
import { getBackofficeUser } from "../../store/backofficeUser/reducer";
import { userHasRole } from "../../services/backofficeUserService";
import { Link } from "react-router-dom";
import { Roles } from "../../types/BackofficeUser";
import LegalbirdIoConfirm from "../Modal/LegalbirdIoConfirm";
import ApiClient from "../../services/ApiClient";

type ReleaseUpdateProps = {
  releaseUpdate: ReleaseUpdateType;
  fetchReleaseUpdates: Function;
};

export default function ReleaseUpdate({ releaseUpdate, fetchReleaseUpdates }: ReleaseUpdateProps) {
  const currentUser = useSelector((state) => getBackofficeUser(state));

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleDeleteReleaseUpdate = async () => {
    await ApiClient.delete(releaseUpdate["@id"]);
    await fetchReleaseUpdates();
  };

  return (
    <>
      <Stack direction={"row"} alignItems={"center"} spacing={1}>
        <Typography variant={"h4"}>Release-Update {moment(releaseUpdate.created).format("DD.MM.YYYY")}</Typography>
        {userHasRole(currentUser, Roles.admin) && (
          <>
            <ToolTipIconButton
              tooltip={"Release-Update bearbeiten"}
              icon={Edit}
              component={Link}
              to={"/services/tools/release-update/" + releaseUpdate.id}
            />
            <ToolTipIconButton
              tooltip={"Release-Update löschen"}
              icon={DeleteOutlineOutlined}
              onClick={() => setDeleteModalOpen(true)}
            />
            <LegalbirdIoConfirm
              handleClose={() => setDeleteModalOpen(false)}
              open={deleteModalOpen}
              handleConfirm={handleDeleteReleaseUpdate}
              content="Möchten Sie dieses Release-Update wirklich löschen?"
              confirmText="Release-Update löschen"
              headline="Release-Update löschen"
            />
          </>
        )}
      </Stack>
      <Box sx={{ marginTop: "1rem" }} dangerouslySetInnerHTML={{ __html: releaseUpdate.content }} />
    </>
  );
}
