import React, { useEffect, useState } from "react";
import ContentBox from "../../ContentBox/ContentBox";
import { useDispatch, useSelector } from "react-redux";
import { getBackofficeUser } from "../../../store/backofficeUser/reducer";
import { getGeneric } from "../../../store/generic/reducer";
import { fetchCollection } from "../../../store/generic/actions";
import BillingForm from "./BillingForm";
import LegalbirdLoader from "../../ContentLoader/LegalbirdLoader";
import { userHasRole } from "../../../services/backofficeUserService";
import { Box, Button, Divider, Typography } from "@mui/material";
import { scrollToAnchor } from "../../../theme/commonStyles";
import _ from "lodash";
import ExternalLawyerInvoiceRow from "./ExternalLawyerInvoiceRow";
import ApiClient from "../../../services/ApiClient";

const INITIAL_INVOICES_TO_SHOW_COUNT = 3;

export default function Billing() {
  const currentUser = useSelector((state) => getBackofficeUser(state));
  const externalUserCollection = useSelector((state) => getGeneric(state, "externalUser" + currentUser.id));
  const dispatch = useDispatch();
  const [invoicesToShowCount, setInvoicesToShowCount] = useState(INITIAL_INVOICES_TO_SHOW_COUNT);
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    if (!externalUserCollection) {
      dispatch(fetchCollection("external_users?backofficeUser=" + currentUser.id, "externalUser" + currentUser.id));
    }
  }, []);

  useEffect(() => {
    ApiClient.get("external_invoices?backofficeUser=" + currentUser.id).then(
      (invoices) => setInvoices(invoices["hydra:member"])
    );
  }, []);

  if (!userHasRole(currentUser, "ROLE_EXTERNAL")) {
    return null;
  }

  if (!externalUserCollection || !currentUser) {
    return <LegalbirdLoader centered />;
  }

  return (
    <>
      <Box sx={scrollToAnchor} id={"billing"} />
      <ContentBox headline={"Abrechnung"}>
        <BillingForm bankData={externalUserCollection["hydra:member"][0].bankData} backofficeUser={currentUser} />
        <Divider sx={{ margin: "3rem 0" }} />
        <Typography sx={{ textAlign: "center" }} variant={"h3"}>
          Meine Abrechnungen
        </Typography>
        <Box sx={{ marginTop: "4rem" }}>
          {invoices.length === 0 ? (
            <Typography sx={{ textAlign: "center", color: "text.light" }}>Noch kein Dokument vorhanden</Typography>
          ) : (
            <>
              {_.map(invoices.slice(0, invoicesToShowCount), (invoice) => (
                <ExternalLawyerInvoiceRow key={invoice.id} invoice={invoice} />
              ))}
              {invoices.length > invoicesToShowCount && (
                <Button onClick={() => setInvoicesToShowCount(invoicesToShowCount + 2)}>Mehr Anzeigen</Button>
              )}
              {(invoices.length <= invoicesToShowCount || invoicesToShowCount > INITIAL_INVOICES_TO_SHOW_COUNT) && (
                <Button onClick={() => setInvoicesToShowCount(INITIAL_INVOICES_TO_SHOW_COUNT)}>Weniger Anzeigen</Button>
              )}
            </>
          )}
        </Box>
      </ContentBox>
    </>
  );
}
