import { PaidTask } from "../../../types/PaidTask";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getBackofficeUser } from "../../../store/backofficeUser/reducer";
import { PAID_TASK_STATUS_ASSIGNED } from "../paidTaskStatus";
import Button from "@mui/material/Button";
import RemindExternalLawyerModal from "./RemindExternalLawyerModal";

export default function RemindButton({ paidTask }: { paidTask: PaidTask }) {
  const currentUser = useSelector((state) => getBackofficeUser(state));
  const [modalOpen, setModalOpen] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  if (currentUser.isExternal || paidTask.status !== PAID_TASK_STATUS_ASSIGNED) {
    return null;
  }

  return (
    <>
      <Button color={"primary"} onClick={() => setModalOpen("remindLawyer")}>
        Anwalt erinnern
      </Button>
      <RemindExternalLawyerModal
        paidTask={paidTask}
        open={modalOpen === "remindLawyer"}
        closeModal={() => setModalOpen("")}
      />
    </>
  );
}
