import React, { useEffect, useState } from "react";
import useStyles from "./caseAccountingPageStyle";
import ApiClient from "../../../services/ApiClient";
import PageContentContainer from "../../Container/PageContentContainer";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import _ from "lodash";
import BookingEntry from "../../Accounting/BookingEntry";
import Grid from "@mui/material/Grid";
import NewBookingEntry from "../../Accounting/NewBookingEntry";
import { getBookingEntriesWithDifferenceAndSummedValues } from "../../../services/accountingService";
import { useDispatch } from "react-redux";
import { fetchMediaObjects } from "../../../hooks/useMediaObjects";
import CaseRelatedActions from "../../Case/CaseRelatedActions/CaseRelatedActions";
import { useParams } from "react-router-dom";
import LegalBirdPageLoading from "../../ContentLoader/LegalBirdPageLoading";
import { ProductHeadline } from "../../PageHeadline/PageHeadline";
import { useBackofficeUser } from "../../../provider/BackofficeUserProvider";
import CaseProvider, { useCase } from "../../../provider/CaseProvider";

const CaseAccountingPage = () => {
  const { productUrlPart, productId } = useParams();
  return (
    <CaseProvider caseUrlPart={productUrlPart} caseId={productId}>
      <CaseAccountingPageInner />
    </CaseProvider>
  );
};

const CaseAccountingPageInner = () => {
  const classes = useStyles({});
  const { product } = useCase();
  const [updateCounter, setUpdateCounter] = useState(1);
  const [bookingEntries, setBookingEntries] = useState();
  const { backofficeUsers } = useBackofficeUser();
  const dispatch = useDispatch();

  const productId = product && product.id;
  useEffect(() => {
    async function updateMediaObjects() {
      fetchMediaObjects(product, dispatch);
    }
    productId && updateMediaObjects()
  }, [productId]);

  const dataSource = product && `booking_entries?deleted=false&productId=${product.id}&updateCounter=${updateCounter}`;

  useEffect(() => {
    async function fetchBookingEntries() {
      if (!dataSource) return;
      const bookingEntriesResult = await ApiClient.get(dataSource);
      setBookingEntries(bookingEntriesResult);
    }
    fetchBookingEntries().then();
  }, [dataSource]);

  if (!product || !bookingEntries) {
    return <LegalBirdPageLoading />;
  }

  const { bookingEntriesWithDifference, summedValues } = getBookingEntriesWithDifferenceAndSummedValues(
    _.sortBy(bookingEntries["hydra:member"], (bookingEntry) => bookingEntry.bookingDate)
  );
  const connectedReceiptIds = _.map(bookingEntries, (bookingEntry) =>
    bookingEntry.receipt ? bookingEntry.receipt.id : null
  );

  return (
    <>
      <CaseRelatedActions product={product} />
      <PageContentContainer>
        <ProductHeadline title="Konto von" product={product} />
        <Grid container justifyContent={"space-between"} alignItems={"center"} className={classes.addPaymentRow}>
          <Grid item className={classes.currentCredit}>
            Aktueller Kontostand:{" "}
            {summedValues.difference.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
          </Grid>
          <Grid item>
            <NewBookingEntry
              bookingEntries={bookingEntriesWithDifference}
              product={product}
              updateBookingEntries={() => setUpdateCounter(updateCounter + 1)}
            />
          </Grid>
        </Grid>
        <TableContainer component={Paper} className={classes.tablePaper}>
          <Table style={{ minWidth: 1000 }}>
            <TableHead>
              <TableRow>
                <TableCell>Typ</TableCell>
                <TableCell>Datum</TableCell>
                <TableCell>Belegnummer</TableCell>
                <TableCell>Fällig am</TableCell>
                <TableCell>Notiz</TableCell>
                <TableCell>Angelegt von</TableCell>
                <TableCell align={"right"}>Soll in €</TableCell>
                <TableCell align={"right"}>Haben in €</TableCell>
                <TableCell align={"right"}>Differenz</TableCell>
                <TableCell align={"right"}>Account</TableCell>
                <TableCell width={"10%"} />
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(bookingEntriesWithDifference, (bookingEntry, key) => {
                return (
                  <BookingEntry
                    key={key}
                    bookingEntry={bookingEntry}
                    updateBookingEntries={() => setUpdateCounter(updateCounter + 1)}
                    product={product}
                    connectedReceiptIds={connectedReceiptIds}
                    backofficeUsers={backofficeUsers}
                  />
                );
              })}
              <TableRow selected>
                <TableCell className={classes.summaryText}>Summe</TableCell>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell className={classes.summaryText} align={"right"}>
                  {summedValues.debit.toLocaleString("de-DE", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </TableCell>
                <TableCell className={classes.summaryText} align={"right"}>
                  {summedValues.credit.toLocaleString("de-DE", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </TableCell>
                <TableCell className={classes.summaryText} align={"right"}>
                  {Math.abs(summedValues.difference).toLocaleString("de-DE", {
                    style: "currency",
                    currency: "EUR",
                  })}
                  {summedValues.difference < 0 ? "S" : "H"}
                </TableCell>
                <TableCell />
                <TableCell className={classes.summaryText} />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </PageContentContainer>
    </>
  );
};

export default CaseAccountingPage;
