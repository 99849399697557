import personTranslations from "./personTranslations";
import litigationCaseTranslations from "./litigationCaseTranslations";
import abstractCaseTranslations from "./abstractCaseTranslations";
import { prosecutionTranslations } from "./prosecutionTranslations";
import contractualPartnerLegalEntityTranslations from "./contractualPartnerLegalEntityTranslations";
import debtCollectionAgencyTranslations from "./debtCollectionAgencyTranslations";

const objectivesLawFirmValues = {
  compensation: "Schadensersatz",
  contractNotMaterialized: "Vertrag nicht zustande gekommen",
  contractNotMaterializedPlusAppeal: "Vertrag nicht zustande gekommen (+Anfechtung)",
  reduction: "Minderung",
  replacement: "Ersatz oder Austausch",
  repair: "Nachbesserung oder Reparatur",
  withdrawal: "Rücktritt vom Vertrag",
  fulfillment: "Erfüllung des Vertrags",
  refund: "Erstattung",
  revocation: "Fristgerechter Widerruf Fernabsatz",
  contestation: "Anfechtung",
  willfulDeceptionContestation: "Anfechtung mit argl. Täuschung",
  willfulDeceptionContestationTerminationRevocation: "Argl. Täuschung: Anfechtung, Kündigung, Widerruf",
  cancellationConfirmation: "Kündigungsbestätigung",
  acknowledgeClaim: "Anerkennung der Forderung",
  specialTermination: "Sonderkündigung",
  custom: "Individuell (leer)",
};

const contractClaimTranslations = {
  ...litigationCaseTranslations,
  ...abstractCaseTranslations,
  additionalClaimText: {
    label: "Zusatztext Forderung",
  },
  additionalIssueDescriptionText: {
    label: "Zusatztext Schilderung Sachverhalt",
  },
  buyerPaidAmount: {
    label: "Gezahlter Betrag Käufer",
  },
  buyerPaidDate: {
    label: "Zahlungsdatum Käufer",
  },
  buyerPaidStatus: {
    label: "Bezahlstatus Käufer",
    values: {
      fully: "vollständig",
      partially: "teilweise",
      none: "noch gar nicht",
    },
  },
  responsibleProsecution: prosecutionTranslations,
  prosecutionReference: {
    label: "Aktenzeichen Staatsanwaltschaft"
  },
  clientHasSettlementIntent: {
    label: "Mandant einigungsbereit",
    values: {
      true: "Ja",
      false: "Nein",
    },
  },
  contractNumber: {
    label: "Vertragsnummer",
  },
  automaticallyGenerateExtrajudicialLetter: {
    label: "Automatisches Schreiben verwenden?",
  },
  contractDate: {
    label: "Vertragsabschluss am",
  },
  contractualPartnerType: {
    label: "Gegenseite ist",
    values: {
      contractualPartnerLegalEntity: "Unternehmen",
      contractualPartnerPerson: "Privatperson",
    },
  },
  contractualPartnerPerson: personTranslations,
  contractualPartnerLegalEntity: {
    label: "Gegenseite",
    ...contractualPartnerLegalEntityTranslations
  },
  debtCollectionAgencyReference: {
    label: "Aktenzeichen",
  },
  debtCollectionAgency: {
    label: "Inkasso",
    ...debtCollectionAgencyTranslations,
  },
  defenseProcessStatus: {
    label: "Status der Forderung gegen Mandant",
    values: {
      none: "Noch nichts erhalten",
      reminder: "Zahlungserinnerung / Mahnung",
      debtCollection: "Inkasso eingeschaltet",
      paymentOrder: "Mahnbescheid erhalten",
      enforcementOrder: "Vollstreckungsbescheid erhalten",
      lawsuit: "Klage erhalten",
    },
  },
  goodsOrServiceProvidedStatus: {
    label: "Status Leistungserbringung/Lieferung",
    values: {
      fully: "vollständig",
      partially: "teilweise",
      none: "noch gar nicht",
    },
  },
  goodsOrServiceProvidedDate: {
    label: "Datum der Leistung/Lieferung",
  },
  claimedDateClient: {
    label: "Datum Forderungsschreiben Mandant",
  },
  claimedDeadlineClient: {
    label: "Forderungsfrist Mandant",
  },
  clientHasAlreadyClaimed: {
    label: "Mandant hat bereits eingefordert",
    values: {
      true: "Ja",
      false: "Nein",
    },
  },
  objectiveClient: {
    label: "Zielsetzung Mandant",
    values: {
      fulfillment: "Erfüllung des Vertrags",
      withdrawal: "Rücktritt vom Vertrag",
      replacement: "Ersatz oder Austausch",
      repair: "Nachbesserung oder Reparatur",
      reduction: "Volle oder teilweise Erstattung",
      compensation: "Schadensersatz",
      other: "Andere Zielsetzung",
    },
  },
  objectiveLawFirm: {
    label: "Zielsetzung Kanzlei",
    values: objectivesLawFirmValues,
  },
  objectiveLawFirmSecondary: {
    label: "Sekundäre Zielsetzung Kanzlei",
    values: objectivesLawFirmValues,
  },
  objectiveLawFirmTertiary: {
    label: "Tertiäre Zielsetzung Kanzlei",
    values: objectivesLawFirmValues,
  },
  paymentOrderDate: {
    label: "Datum des Mahnbescheids",
  },
  enforcementOrderDate: {
    label: "Datum des Vollstreckungsbescheids",
  },
  claimedAmountClient: {
    label: "Forderungsbetrag Mandant",
  },
  overdueFees: {
    label: "Mahngebühren",
  },
  claimedAmountLawFirm: {
    label: "Forderungsbetrag Kanzlei",
  },
  extrajudicialCommunicationSent: {
    label: "Schreiben an Gegenseite am",
  },
  extrajudicialCommunicationDeadline: {
    label: "Gesetzte Frist für Gegenseite",
  },
  settlementConditionsClient: {
    label: "Einigungsbedingungen Mandant",
  },
  settlementConditionsLawFirm: {
    label: "Einigungsbedingungen Kanzlei",
  },
  subjectOfDispute: {
    label: "Forderung",
    values: {
      money: "Geld",
      goods: "Ware oder Leistung",
      other: "Sonstiges",
    },
    enforceClaim: {
      label: "Forderung Mandant",
      values: {
        money: "Geld",
        goods: "Ware oder Leistung",
        other: "Sonstiges",
      },
    },
    defendClaim: {
      label: "Forderung Gegenseite",
      values: {
        money: "Geld",
        goods: "Ware oder Leistung",
        other: "Sonstiges",
      },
    },
  },
  trialWanted: {
    label: "Gerichtsverfahren gewünscht",
    values: {
      true: "Ja",
      false: "Nein",
    },
  },
  advisoryAppointment: {
    label: "Beratungsgespräch",
  },
  extrajudicialReaction: {
    label: "Reaktion der Gegenseite",
    values: {
      noReaction: "Keine Reaktion",
      fullyAgreed: "Forderung wird vollständig erfüllt/aufgegeben",
      partiallyAgreed: "Forderung wird teilweise erfüllt/aufgegeben",
      disagreed: "Forderung wird nicht erfüllt/aufrechterhalten",
    },
    enforceClaim: {
      label: "Reaktion der Gegenseite",
      values: {
        noReaction: "Keine Reaktion",
        fullyAgreed: "Forderung wird voll erfüllt",
        partiallyAgreed: "Forderung wird teilweise erfüllt",
        disagreed: "Forderung wird nicht erfüllt",
      },
    },
    defendClaim: {
      label: "Reaktion der Gegenseite",
      values: {
        noReaction: "Keine Reaktion",
        fullyAgreed: "Forderung wird vollständig aufgegeben",
        partiallyAgreed: "Forderung wird teilweise aufgegeben",
        disagreed: "Forderung wird aufrechterhalten",
      },
    },
  },
  extrajudicialRecommendation: {
    label: "Empfehlung weiteres Vorgehen",
    values: {
      agreementDone: "Einigung erzielt / Forderung erledigt",
      lawsuit: "Klage einreichen / Klage abwarten",
      insufficientEvidence: "Schlechte Beweislage",
      insufficientChancesOfSuccess: "Schlechte Erfolgsaussicht",
      costBenefitConsiderations: "Schlechtes Kosten-Nutzen-Verhältnis",
      anonymousOpponent: "Gegenseite unklar",
      opponentUnableToPay: "Gegenseite nicht solvent",
      individualText: "Individuelle Empfehlung",
    },
  },
  extrajudicialRecommendationText: {
    label: "Zusatztext Empfehlung",
  },
  finalClaimStatusClient: {
    label: "Stand Forderung aus Mandantensicht",
    values: {
      fullyAgreed: "Voll erledigt",
      partiallyAgreed: "Teilweise erledigt",
      notAgreed: "Nicht erledigt",
      noReaction: "Keine erledigt",
    },
    enforceClaim: {
      label: "Stand Forderung aus Mandantensicht",
      values: {
        fullyAgreed: "Voll erfüllt",
        partiallyAgreed: "Teilweise erfüllt",
        notAgreed: "Nicht erfüllt",
        noReaction: "Keine Reaktion",
      },
    },
    defendClaim: {
      label: "Stand Forderung aus Mandantensicht",
      values: {
        fullyAgreed: "Forderung durch Gegenseite aufgegeben",
        partiallyAgreed: "Forderung durch Gegenseite teilweise aufgegeben",
        notAgreed: "Forderung durch Gegenseite nicht aufgegeben",
        noReaction: "Keine Reaktion",
      },
    },
  },
  finalProcessFeedbackClient: {
    label: "Finale Entscheidung Mandant",
    values: {
      agreement: "Fall abschließen",
      lawsuit: "Gerichtsverfahren",
    },
    enforceClaim: {
      label: "Finale Entscheidung Mandant",
      values: {
        agreement: "Forderung erledigt/zurücknehmen",
        lawsuit: "Forderung einklagen",
      },
    },
    defendClaim: {
      label: "Finale Entscheidung Mandant",
      values: {
        lawsuit: "Forderung nicht erfüllen und Klage abwarten",
        agreement: "Forderung erfüllen und Klage vermeiden",
      },
    },
  },
  extrajudicialReactionReceived: {
    label: "Reaktion der Gegenseite am",
  },
  timeBarredDate: {
    label: "Fall verjährt am",
  },
};

export default contractClaimTranslations;
