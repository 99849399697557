import React, { useEffect } from "react";
import { convertFromRaw, EditorState } from "draft-js";
import _ from "lodash";
import { editorStateToHTML } from "../../services/Editor/editorFunctions";
import { useDispatch, useSelector } from "react-redux";
import { getBackofficeUser } from "../../store/backofficeUser/reducer";
import { fetchMediaObjects, fetchMediaObjectsExternal, getMediaObjectsIdentifier } from "../../hooks/useMediaObjects";
import { getGeneric } from "../../store/generic/reducer";
import { loadSessionStorageState } from "../../services/browserStorageService";
import { buildAddress } from "../Case/CasePdfGenerator/casePdfGeneratorService";
import PdfGeneratorFormElements from "../Case/CasePdfGenerator/PdfGeneratorFormElements";
import PdfGeneratorPreview from "../Case/CasePdfGenerator/PdfGeneratorPreview";
import makeStyles from '@mui/styles/makeStyles';
import casePaidTaskPdfGeneratorStyle from "./casePaidTaskPdfGeneratorStyle";

const useStyles = makeStyles(casePaidTaskPdfGeneratorStyle);

const CasePaidTaskPdfGenerator = ({
  product,
  paidTask,
  values,
  handleChange,
  handleBlur,
  errors,
  registerValidators,
  updateAllValues,
  initialValues,
}) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => getBackofficeUser(state));
  const mediaObjects = useSelector((state) => getGeneric(state, getMediaObjectsIdentifier(product)));
  const classes = useStyles();

  useEffect(() => {
    if (!!product) {
      currentUser.isExternal
        ? fetchMediaObjectsExternal(product, dispatch)
        : fetchMediaObjects(product, dispatch);
    }
  }, [product, dispatch, currentUser]);

  const getParams = () => {
    return _.merge({}, values, {
      content: editorStateToHTML(values.content),
      address: buildAddress(values, product),
      externalReference: !_.isEmpty(values.externalReference) ? values.externalReference : null,
      senderMedium: values.senderMedium === "none" ? null : values.senderMedium,
      externalLawyer: paidTask.assignee,
    });
  };

  useEffect(() => {
    const sessionStorageValues = loadSessionStorageState("customPdf");
    if (sessionStorageValues) {
      updateAllValues(
        _.merge(initialValues, {
          recipientType: sessionStorageValues.recipientType,
          address: {
            name: sessionStorageValues.address.name,
            department: sessionStorageValues.address.department,
            subDepartment: sessionStorageValues.address.subDepartment,
            streetAddress: sessionStorageValues.address.streetAddress,
            postalCode: sessionStorageValues.address.postalCode,
            addressLocality: sessionStorageValues.address.addressLocality,
          },
          senderMedium: sessionStorageValues.senderMedium,
          externalReference: sessionStorageValues.externalReference,
          externalReferenceSelect: sessionStorageValues.externalReferenceSelect,
          filename: sessionStorageValues.filename,
          content: EditorState.createWithContent(convertFromRaw(sessionStorageValues.content)),
          showInGallery: sessionStorageValues.showInGallery,
          sendEmailToCustomer: sessionStorageValues.sendEmailToCustomer,
          selectedMediaObjects: sessionStorageValues.selectedMediaObjects,
        })
      );
    }
  }, [updateAllValues, initialValues]);

  return (
    <div className={classes.pdfGenerator}>
      <PdfGeneratorFormElements
        values={values}
        product={product}
        registerValidators={registerValidators}
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        mediaObjects={mediaObjects}
        minimalView
      />
      <PdfGeneratorPreview product={product} values={values} getParams={getParams} mediaObjects={mediaObjects} />
    </div>
  );
};

CasePaidTaskPdfGenerator.defaultProps = {
  documentTypeLabel: "Individuelles",
  ctaLabel: "Schreiben generieren",
};

export default CasePaidTaskPdfGenerator;
