import React from "react";

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import costBoxStyle from "./costBoxStyle";
import classNames from "classnames";
import { calculateFees, calculateValueInDispute } from "./costCalculation";
import { VAT, VAT_OLD } from "../../services/globalVars";
import ValidatorSelect from "../Validator/ValidatorSelect";
import moment from "moment";
import ValidatorTextField from "../Validator/ValidatorTextField";

class CostBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      netIncomePetitioner: "",
      netIncomePartner: "",
      pensionsExcluded: false,
      numberPensions: "",
      numberPensionsPartner: "",
      fees: null,
      valueInDispute: null,
      isOldRvg: false,
      isOldVat: false,
    };
  }

  calculate = (costCalculatorData) => {
    const proxyDivorce = {
      powerOfAttorney: moment().valueOf(),
    };
    const vid = calculateValueInDispute(costCalculatorData);
    this.setState({
      valueInDispute: vid,
      fees: calculateFees(vid.total, this.state.isOldRvg, this.state.isOldVat, proxyDivorce),
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { netIncomePetitioner, netIncomePartner, numberPensions, numberPensionsPartner, pensionsExcluded } =
      this.state;

    const costCalculatorData = {
      netIncomePartner: netIncomePartner,
      netIncomePetitioner: netIncomePetitioner,
      numberPensions: numberPensions,
      numberPensionsPartner: numberPensionsPartner,
      pensionsExcluded: pensionsExcluded,
    };

    this.calculate(costCalculatorData);
  };

  handleChange = (event) => {
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    this.setState({ [event.target.name]: value });
  };

  eur = (val) => {
    return val.toLocaleString("de-DE", { style: "currency", currency: "EUR" });
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Grid container alignItems={"center"}>
          <Grid item>Neues RVG</Grid>
          <Grid item>
            <Switch checked={this.state.isOldRvg} onChange={() => this.setState({ isOldRvg: !this.state.isOldRvg })} />
          </Grid>
          <Grid item>Altes RVG</Grid>
        </Grid>
        <Grid container alignItems={"center"}>
          <Grid item>19% MwSt</Grid>
          <Grid item>
            <Switch checked={this.state.isOldVat} onChange={() => this.setState({ isOldVat: !this.state.isOldVat })} />
          </Grid>
          <Grid item>16% Mwst</Grid>
        </Grid>
        <form onSubmit={this.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <FormControl fullWidth={true}>
                <ValidatorTextField
                  label="Ihr mtl. Nettoeinkommen"
                  id="netIncomePetitioner"
                  name="netIncomePetitioner"
                  value={this.state.netIncomePetitioner}
                  onChange={this.handleChange}
                  validators={["required", "isNumber", "isPositive"]}
                  errorMessages={[
                    "Bitte geben Sie Ihr Einkommen an",
                    "Bitte geben Sie Ihr Einkommen in EUR an",
                    "Bitte geben Sie eine positive Zahl an",
                  ]}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl fullWidth={true}>
                <ValidatorTextField
                  label="Mtl. Nettoeinkommen Partner"
                  id="netIncomePartner"
                  name="netIncomePartner"
                  value={this.state.netIncomePartner}
                  onChange={this.handleChange}
                  validators={["required", "isNumber", "isPositive"]}
                  errorMessages={[
                    "Bitte geben Sie das Einkommen Ihres Partners an",
                    "Bitte geben Sie das Einkommen Ihres Partners in EUR an",
                    "Bitte geben Sie eine positive Zahl an",
                  ]}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <br />
              <Grid container>
                <Grid item xs={10}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="pensionsExcluded"
                        name="pensionsExcluded"
                        checked={this.state.pensionsExcluded}
                        onChange={this.handleChange}
                        value="pensionsExcluded"
                        color="primary"
                      />
                    }
                    label="Wir möchten den Versorgungsausgleich ausschließen"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              <ValidatorSelect
                label={"Ihre Rentenversicherungen"}
                fullWidth
                margin={"none"}
                value={this.state.numberPensions}
                disabled={this.state.pensionsExcluded}
                onChange={this.handleChange}
                name={"numberPensions"}
                inputProps={{
                  name: "numberPensions",
                  id: "numberPensions",
                }}
                validators={!this.state.pensionsExcluded ? ["required"] : []}
                errorMessages={["Bitte geben Sie die Anzahl Ihrer Rentenversicherungen an"]}
                helperText={"Wie viele Versicherungen haben Sie?"}
              >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
              </ValidatorSelect>
            </Grid>
            <Grid item xs={12} lg={6}>
              <ValidatorSelect
                label={"Rentenversicherungen Partner"}
                fullWidth
                margin={"none"}
                value={this.state.numberPensionsPartner}
                disabled={this.state.pensionsExcluded}
                onChange={this.handleChange}
                name={"numberPensionsPartner"}
                inputProps={{
                  name: "numberPensionsPartner",
                  id: "numberPensionsPartner",
                }}
                validators={!this.state.pensionsExcluded ? ["required"] : []}
                errorMessages={["Bitte geben Sie die Anzahl der Rentenversicherungen Ihres Partners an"]}
                helperText={"Wie viele Versicherungen hat Ihr Partner?"}
              >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
              </ValidatorSelect>
            </Grid>
            <Grid item xs={12}>
              <Button className={classes.costButton} variant="contained" type="submit" fullWidth>
                {this.state.fees && this.state.valueInDispute ? "Kosten erneut berechnen" : "Kosten brechnen"}
              </Button>
            </Grid>

            {this.state.fees && this.state.valueInDispute && (
              <Grid item xs={12}>
                <>
                  <Table className={classes.table}>
                    <TableBody>
                      <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tableCell}>
                          <strong>Gegenstandswert der Scheidung</strong>
                        </TableCell>
                        <TableCell className={classes.tableCellValue} />
                      </TableRow>
                      <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tableCell}>Gegenstandswert Scheidungsverfahren</TableCell>
                        <TableCell className={classes.tableCellValue}>
                          {this.eur(this.state.valueInDispute.divorce)}
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tableCell}>Gegenstandswert Versorgungsausgleich</TableCell>
                        <TableCell className={classes.tableCellValue}>
                          {this.eur(this.state.valueInDispute.pensions)}
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.tableRow}>
                        <TableCell className={classNames(classes.tableCell, classes.underlined)}>
                          Gegenstandswert insgesamt
                        </TableCell>
                        <TableCell className={classNames(classes.tableCellValue, classes.underlined)}>
                          <strong>{this.eur(this.state.valueInDispute.total)}</strong>
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.tableRow}>
                        <TableCell className={classNames(classes.tableCell, classes.tableCellHeader)}>
                          <strong>
                            Rechtsanwaltsgebühren inkl. MwSt {(this.state.isOldVat ? VAT_OLD : VAT) * 100}%
                          </strong>
                        </TableCell>
                        <TableCell className={classes.tableCellValue} />
                      </TableRow>
                      <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tableCell}>Verfahrensgebühr</TableCell>
                        <TableCell className={classes.tableCellValue}>
                          {this.eur(this.state.fees.lawyerCourt)}
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tableCell}>Auslagenpauschale</TableCell>
                        <TableCell className={classes.tableCellValue}>{this.eur(this.state.fees.expenses)}</TableCell>
                      </TableRow>
                      <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tableCell}>Terminsgebühr</TableCell>
                        <TableCell className={classes.tableCellValue}>
                          {this.eur(this.state.fees.lawyerHearing)}
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.tableRow}>
                        <TableCell className={classNames(classes.tableCell, classes.underlined)}>
                          Rechtsanwaltsgebühren insgesamt
                        </TableCell>
                        <TableCell className={classNames(classes.tableCellValue, classes.underlined)}>
                          <strong>{this.eur(this.state.fees.lawyer)}</strong>
                        </TableCell>
                      </TableRow>
                      <TableRow className="line">
                        <TableCell className={classes.tableCell}>
                          <strong>Gerichtskosten</strong>
                        </TableCell>
                        <TableCell className={classes.tableCellValue}>
                          <strong>{this.eur(this.state.fees.court)}</strong>
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.doubleUnderlinedRow}>
                        <TableCell className={classes.doubleUnderlinedCell} />
                        <TableCell className={classes.doubleUnderlinedCell} />
                      </TableRow>
                      <TableRow className="line">
                        <TableCell className={classes.tableCell}>
                          <span className={classes.primary}>Scheidungskosten insgesamt</span>
                        </TableCell>
                        <TableCell className={classNames(classes.tableCellValue, classes.total)}>
                          {this.eur(this.state.fees.total)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <p className={classes.disclaimer}>
                    Diese Kostenberechnung ist lediglich eine grobe Schätzung. Die genauen Kosten ergeben sich erst nach
                    der Angabe aller Daten. Maßgeblich ist allein der vom Gericht festgesetzte Wert.
                  </p>
                </>
              </Grid>
            )}
          </Grid>
        </form>
      </>
    );
  }
}

const CostBoxFormWithStyles = withStyles(costBoxStyle)(CostBox);

export default CostBoxFormWithStyles;
