import React from "react";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import theme from "./theme/defaultTheme";
import { CssBaseline } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import "moment/locale/de";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import HomePage from "./components/_Pages/HomePage/HomePage";
import RequireAuth from "./components/RequireAuth/RequireAuth";
import BaseRoutes from "./routes/BaseRoutes";
import PublicRoutes from "./routes/PublicRoutes";

function App() {
  //configure moment for the whole project
  moment.fn.toJSON = function () {
    return this.format();
  };
  moment.locale("de");

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <SnackbarProvider
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            maxSnack={1}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <ScrollToTop>
                <Routes>
                  <Route
                    path={"/services/*"}
                    element={
                      <RequireAuth>
                        <BaseRoutes />
                      </RequireAuth>
                    }
                  />
                  <Route path={"/*"} element={<PublicRoutes />} />
                  <Route
                    path={"/"}
                    element={
                      <RequireAuth>
                        <HomePage />
                      </RequireAuth>
                    }
                  />
                </Routes>
              </ScrollToTop>
            </LocalizationProvider>
          </SnackbarProvider>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
