import React from "react";
import * as PropTypes from "prop-types";
import useStyles from "./caseHistoryItemStyle";
import Email from "../../Email/Email";
import { Grid } from "@mui/material";
import moment from "moment";
import {
  AccountBalanceOutlined,
  ArrowRightAlt,
  AssignmentTurnedInOutlined,
  Close,
  Create,
  EmailOutlined,
  HourglassEmptyOutlined,
  Launch,
  NoteOutlined,
  OpenInBrowser,
  PersonAdd,
  PrintOutlined,
  SendOutlined,
} from "@mui/icons-material";
import EmojiEvents from "../../../assets/icon/emoji_events-24px.svg";
import CircleIcon from "../../CircleIcon/CircleIcon";
import _ from "lodash";
import CaseHistoryActivity from "./CaseHistoryActivity";
import Note from "../CaseNote/Note";
import { translate } from "../../../services/Translations/translatorService";
import PaidTaskHistoryLog from "../../PaidTask/PaidTaskHistoryLog/PaidTaskHistoryLog";
import CaseHistoryMediaObject from "./CaseHistoryMediaObject";

const CaseHistoryItem = ({ historyItem, fetchHistory, product }) => {
  const { productClassName } = product;
  const classes = useStyles({});

  const buildFaxSubject = (fax) => {
    switch (fax.status) {
      case "INIT":
        return `Fax (${fax.sessionId}) an +49${fax.targetNumber} abgeschickt`;
      case "FAILED":
        return `Fax (${fax.sessionId}) an +49${fax.targetNumber} konnte nicht zugestellt werden`;
      case "SENT":
        return `Fax (${fax.sessionId}) an +49${fax.targetNumber} wurde zugestellt`;
      default:
        return `Fax (${fax.sessionId}) an +49${fax.targetNumber}`;
    }
  };

  const buildBeaMessageSubject = (logData) => {
    let baseSubject = `beA ${logData.beaMessage.beaMessageId} (${logData.beaMessage.subject}) an ${logData.recipientName}, ${logData.beaMessage.recipient?.safeId} wurde `;
    switch (logData.beaMessage.transmissionDirection) {
      case "sent":
        return baseSubject + "abgeschickt";
      case "received":
        return baseSubject + "zugestellt";
      default:
        return "";
    }
  };

  const LogEntry = ({ historyItem, subject, Icon, iconType }) => {
    return (
      <Grid container>
        <Grid item xs={1}>
          <CircleIcon icon={iconType === "muiIcon" ? <Icon /> : Icon} type={iconType} />
        </Grid>
        <Grid item xs={11}>
          <div className={classes.headline}>{subject}</div>
          <div className={classes.info}>
            {_.get(historyItem, "logData.caseStatus.current") === "lost" && (
              <>
                {_.get(historyItem, "logData.lostCategory.current") && (
                  <div>
                    Verlustkategorie:{" "}
                    {translate(
                      "historyItem.caseStatusChange.lost.lostCategory." + historyItem.logData.lostCategory.current
                    )}
                  </div>
                )}
                {_.get(historyItem, "logData.lostReason.current") && (
                  <div>Verlustgrund: {historyItem.logData.lostReason.current}</div>
                )}
              </>
            )}
            {historyItem.type === "entityDataChange" &&
              _.map(historyItem.logData, (logItem, index) => (
                <div key={index}>
                  {translate(productClassName + "." + index + ".label")}:{" "}
                  {!!logItem.previous
                    ? translate(productClassName + "." + index + ".values." + logItem.previous, "" + logItem.previous)
                    : "Leer "}
                  {" >> "}
                  {!!logItem.current
                    ? translate(productClassName + "." + index + ".values." + logItem.current, "" + logItem.current)
                    : "Leer"}
                </div>
              ))}
            {historyItem.type === "assignedUserChange" &&
              _.map(historyItem.logData, (logItem, index) => (
                <div key={index}>
                  {translate("historyItem." + index + ".label")}: {logItem.previous + " >> " + logItem.current}
                </div>
              ))}
            {historyItem.type === "customerDataChange" &&
              _.map(historyItem.logData, (logItem, index) => (
                <div key={index}>
                  {translate("customer." + index + ".label")}:{" "}
                  {!!logItem.previous
                    ? translate("customer." + index + ".values." + logItem.previous, "" + logItem.previous)
                    : "Leer "}
                  {" >> "}
                  {!!logItem.current
                    ? translate("customer." + index + ".values." + logItem.current, "" + logItem.current)
                    : "Leer"}
                </div>
              ))}
            {historyItem.type === "entityStatusChange" && (
              <div>
                {historyItem.logData.leadStatus.previous || "Leer"} {" >> "}
                {historyItem.logData.leadStatus.current || "Leer"}
              </div>
            )}
            {moment(historyItem.logDate).format("DD.MM.YYYY HH:mm") +
              " Uhr | " +
              (_.get(historyItem, "backofficeUser.person.fullname") || "System")}
          </div>
        </Grid>
      </Grid>
    );
  };

  switch (historyItem.type) {
    case "mailPause":
      return (
        <LogEntry
          subject={
            historyItem.logData && historyItem.logData.preventAllSystemEmails
              ? "E-Mails pausiert"
              : "E-Mails wieder angeschaltet"
          }
          historyItem={historyItem}
          Icon={EmailOutlined}
          iconType={"muiIcon"}
        />
      );
    case "beaOrFaxPause":
      return (
        <LogEntry
          subject={
            historyItem.logData && historyItem.logData.preventAllSystemBeaOrFax
              ? "Automatische beA/Faxe pausiert "
              : "Automatische beA/Faxe wieder aktiviert"
          }
          historyItem={historyItem}
          Icon={EmailOutlined}
          iconType={"muiIcon"}
        />
      );
    case "passwordReset":
      return (
        <LogEntry
          subject={"Passwort-Setzen-E-Mail versendet"}
          historyItem={historyItem}
          Icon={EmailOutlined}
          iconType={"muiIcon"}
        />
      );
    case "accountingPause":
      return (
        <LogEntry
          subject={
            historyItem.logData && historyItem.logData.preventAccounting
              ? "Konto/Rechnungen pausiert"
              : "Konto/Rechnungen wieder angeschaltet"
          }
          historyItem={historyItem}
          Icon={AccountBalanceOutlined}
          iconType={"muiIcon"}
        />
      );
    case "activitiesPause":
      return (
        <LogEntry
          subject={
            historyItem.logData && historyItem.logData.preventAllSystemActivities
              ? "Setzen von Aktivitäten pausiert"
              : "Setzen von Aktivitäten wieder angeschaltet"
          }
          historyItem={historyItem}
          Icon={AssignmentTurnedInOutlined}
          iconType={"muiIcon"}
        />
      );
    case "emailMessage":
      return (
        <Email isNonInteractive={true} emailMessage={historyItem.emailMessage} attachments={historyItem.attachments} />
      );
    case "activity":
      return (
        <CaseHistoryActivity
          productClassName={productClassName}
          historyItem={historyItem}
          updateActivities={fetchHistory}
          backofficeCaseId={product.backofficeCase.id}
        />
      );
    case "paidTask":
      return <PaidTaskHistoryLog paidTask={historyItem.paidTask} />;
    case "note":
      return (
        <Grid container>
          <Grid className={classes.imageGridItem} item>
            <CircleIcon icon={<NoteOutlined />} type={"muiIcon"} />
          </Grid>
          <Grid item className={classes.noteGridItem}>
            <Note note={historyItem.note} handleChange={fetchHistory} product={product} />
          </Grid>
        </Grid>
      );
    case "mediaObject":
      return <CaseHistoryMediaObject historyLog={historyItem} />;
    case "mediaObjectDelete":
      return <CaseHistoryMediaObject historyLog={historyItem} type={"mediaObjectDelete"} />;
    case "customerRegistration":
      return (
        <LogEntry
          subject={translate("historyItem.customerRegistration.label")}
          historyItem={historyItem}
          Icon={PersonAdd}
          iconType={"muiIcon"}
        />
      );
    case "caseCreated":
      return (
        <LogEntry
          subject={translate("historyItem.caseCreated.label")}
          historyItem={historyItem}
          Icon={Launch}
          iconType={"muiIcon"}
        />
      );
    case "entityStatusChange":
      return (
        <LogEntry
          subject={translate("historyItem.entityStatusChange.label")}
          historyItem={historyItem}
          Icon={ArrowRightAlt}
          iconType={"muiIcon"}
        />
      );
    case "assignedUserChange":
    case "customerDataChange":
    case "entityDataChange":
      return (
        <LogEntry
          subject={translate("historyItem.entityDataChange.label")}
          historyItem={historyItem}
          Icon={Create}
          iconType={"muiIcon"}
        />
      );
    case "caseStatusChange":
      switch (historyItem.logData.caseStatus.current) {
        case "inactive":
          return (
            <LogEntry
              subject={translate("historyItem.caseStatusChange.inactive.label")}
              historyItem={historyItem}
              Icon={HourglassEmptyOutlined}
              iconType={"muiIcon"}
            />
          );
        case "lost":
          return (
            <LogEntry
              subject={translate("historyItem.caseStatusChange.lost.label")}
              historyItem={historyItem}
              Icon={Close}
              iconType={"muiIcon"}
            />
          );
        case "won":
          return (
            <LogEntry
              subject={translate("historyItem.caseStatusChange.won.label")}
              historyItem={historyItem}
              Icon={EmojiEvents}
            />
          );
        case "open":
          return (
            <LogEntry
              subject={translate("historyItem.caseStatusChange.open.label")}
              historyItem={historyItem}
              Icon={OpenInBrowser}
              iconType={"muiIcon"}
            />
          );
        default:
          return null;
      }
    case "fax":
      return (
        <LogEntry
          subject={buildFaxSubject(historyItem.logData.fax)}
          historyItem={historyItem}
          Icon={PrintOutlined}
          iconType={"muiIcon"}
        />
      );
    case "beaMessage":
      return (
        <LogEntry
          subject={buildBeaMessageSubject(historyItem.logData)}
          historyItem={historyItem}
          Icon={SendOutlined}
          iconType={"muiIcon"}
        />
      );
    default:
      return null;
  }
};

CaseHistoryItem.propTypes = {
  historyItem: PropTypes.object.isRequired,
  fetchHistory: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
};

export default CaseHistoryItem;
