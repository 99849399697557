import React, { useMemo, useState } from "react";
import { Checkbox, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid";
import EditActivity from "../EditActivity/EditActivity";
import { updateResource } from "../../../store/generic/actions";
import { useDispatch, useSelector } from "react-redux";
import * as PropTypes from "prop-types";
import PopUpMenu from "../../PopUpMenu/PopUpMenu";
import MenuItem from "@mui/material/MenuItem";
import ButtonLoading from "../../Button/ButtonLoading";
import useStyles from "./activityActionsStyles";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import { getBackofficeUser } from "../../../store/backofficeUser/reducer";
import CalendlyAssessmentModal from "../../CalendlyAssessments/CalendlyAssessmentModal";
import CircularProgress from "@mui/material/CircularProgress";
import _ from "lodash";
import EditActivityNote from "./EditActivityNote";
import { Stack } from "@mui/material";

const ActivityActions = ({ activity, updateActivities, product }) => {
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [assessmentOpen, setAssessmentOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles({});
  const currentUser = useSelector((state) => getBackofficeUser(state));

  const isCalendlyActivity = useMemo(() => {
    return ["calendly_event", "lawyer_counseling_call"].includes(activity.type) && !!activity.calendlyEvent
  }, [activity]);


  const handleDeleteActivity = async () => {
    setIsLoading(true);
    await dispatch(
      updateResource(activity.id, "activities", {
        deleted: true,
      })
    );
    setIsLoading(false);
    updateActivities();
    setDeleteOpen(false);
  };

  const toggleActivityDone = async () => {
    if (isCalendlyActivity) {
      if (activity.done === false) {
        setAssessmentOpen(true);
      }
      return;
    }
    setIsLoading(true);
    await dispatch(
      updateResource(activity.id, "activities", {
        done: !activity.done,
      })
    );
    updateActivities();
  };

  const isCheckboxDisabled = !activity.assignedUser || (!product && isCalendlyActivity) || activity.done;

  if (isCalendlyActivity && !_.includes(currentUser.roles, "ROLE_ADMIN") && !currentUser.isExternal) {
    return (
      <>
        <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"}>
          <ActivityDoneCheckBox
            activityDone={activity.done}
            isLoading={isLoading}
            toggleActivityDone={toggleActivityDone}
            isCheckboxDisabled={isCheckboxDisabled}
          />
          <div className={classes.editMenu}>
            <EditActivityNote activity={activity} updateActivities={updateActivities} />
          </div>
        </Stack>
        {(!!product && isCalendlyActivity && !activity.done) && (
          <CalendlyAssessmentModal
            key={"calendlyAssessmentModal." + activity.id}
            open={assessmentOpen}
            handleClose={() => setAssessmentOpen(false)}
            activity={activity}
            product={product}
            update={updateActivities}
          />
        )}
      </>
    );
  }

  return (
    <Grid container wrap={"nowrap"} justifyContent={"flex-end"} alignItems={"center"}>
      <Grid item>
        <ActivityDoneCheckBox
          activityDone={activity.done}
          isLoading={isLoading}
          toggleActivityDone={toggleActivityDone}
          isCheckboxDisabled={isCheckboxDisabled}
        />
      </Grid>
      {!currentUser.isExternal && (
        <>
          <Grid item className={classes.editMenu}>
            <PopUpMenu tooltipTitle={"Mehr"}>
              <MenuItem onClick={() => setEditOpen(true)}>Bearbeiten</MenuItem>
              <MenuItem onClick={() => setDeleteOpen(true)}>Löschen</MenuItem>
            </PopUpMenu>
          </Grid>
          <EditActivity
            key={activity.id}
            activity={activity}
            open={editOpen}
            closeDialog={() => setEditOpen(false)}
            updateActivities={updateActivities}
          />
          <LegalbirdIoModal
            handleClose={() => setDeleteOpen(false)}
            open={deleteOpen}
            title={"Achtung"}
            submitButton={
              <ButtonLoading variant={"contained"} onClick={handleDeleteActivity} isLoading={isLoading}>
                Ja
              </ButtonLoading>
            }
          >
            <p className={classes.deleteDialogText}>
              Diese Aktivität wird unwiderruflich gelöscht. Sind Sie sicher, dass Sie die Aktivität löschen möchten?
            </p>
          </LegalbirdIoModal>
        </>
      )}
      {!!product && isCalendlyActivity && !activity.done && (
        <CalendlyAssessmentModal
          key={"calendlyAssessmentModal." + activity.id}
          open={assessmentOpen}
          handleClose={() => setAssessmentOpen(false)}
          activity={activity}
          product={product}
          update={updateActivities}
        />
      )}
    </Grid>
  );
};

const ActivityDoneCheckBox = ({ isLoading, toggleActivityDone, activityDone, isCheckboxDisabled }) => {
  if (isLoading) {
    return <CircularProgress size={20} />;
  }
  return (
    <Tooltip title={"Als erledigt markieren"}>
      <Checkbox onChange={toggleActivityDone} checked={activityDone} disabled={isCheckboxDisabled} />
    </Tooltip>
  );
};

ActivityActions.propTypes = {
  activity: PropTypes.object.isRequired,
  updateActivities: PropTypes.func.isRequired,
  product: PropTypes.object,
};

export default ActivityActions;
