import React, { useEffect, useState } from "react";
import { Box, Checkbox, FormControl, FormControlLabel, FormLabel, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import calendlyAssessmentStyle from "./calendlyAssessmentStyle";
import ValidatorSelect from "../Validator/ValidatorSelect";
import MenuItem from "@mui/material/MenuItem";
import useForm from "../../hooks/useForm";
import { requiredFieldDefault } from "../../services/validationRules";
import { Rating } from "@mui/material";
import { convertBooleanToYesNoString, formValue } from "../../services/formServiceFunctions";
import ButtonLoading from "../Button/ButtonLoading";
import ValidatorTextField from "../Validator/ValidatorTextField";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { updateResource } from "../../store/generic/actions";
import { getBackofficeUser } from "../../store/backofficeUser/reducer";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import ApiClient from "../../services/ApiClient";
import Snackbar from "../Snackbar/Snackbar";
import { useSnackbar } from "notistack";
import { userHasOneOfTheseRoles, userHasRole } from "../../services/backofficeUserService";

const useStyles = makeStyles(calendlyAssessmentStyle);

const CalendlyAssessmentModalInner = ({ activity, product, update, open, handleClose, calendlyEvent }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => getBackofficeUser(state));
  const { enqueueSnackbar } = useSnackbar();

  let initialValues = {
    cancelled: false,
    processed: convertBooleanToYesNoString(calendlyEvent.assessment.processed),
    notProcessedReason: formValue(activity, "calendlyEvent.assessment.notProcessedReason"),
    followUp: (calendlyEvent.assessment.followUp ? "yes" : "no"),
    followUpAction: formValue(activity, "calendlyEvent.assessment.followUpAction"),
    customerSatisfaction: calendlyEvent.assessment.customerSatisfaction,
    note: formValue(activity, "calendlyEvent.assessment.note"),
    cancelReason: formValue(
      activity,
      "calendlyEvent.assessment.cancelReason",
      "Leider müssen wir Ihnen den Beratungstermin krankheitsbedingt absagen. " +
      "Wir bitten die dadurch entstandenen Umstände zu entschuldigen! " +
      "Bitte buchen Sie sich über den Ihnen bekannten Link einen neuen Beratungstermin. " +
      "Mit freundlichen Grüßen, Ihr Team von Legalbird"
    ),
  };

  const islawyerCounselingCall = activity.type === "lawyer_counseling_call";

  const onSubmit = async ({values, e}) => {
    const updateData = _.merge(values, {
      processed: values.processed === "yes",
      followUp: islawyerCounselingCall ? null : values.followUp === "yes",
      followUpAction: islawyerCounselingCall ? null : values.followUpAction,
      customerSatisfaction: islawyerCounselingCall ? null : parseInt(values.customerSatisfaction),
      cancelReason: !values.cancelled ? null : values.cancelReason,
    });
    await dispatch(updateResource(calendlyEvent.assessment.id, "calendly_assessments", updateData));
    await update();
    clearForm();
    handleClose();
    if (values.cancelled) {
      enqueueSnackbar("", {
        content: () => (
          <Box>
            <Snackbar message={"Termin wird über Calendly abgesagt. Dies kann eine kurze Zeit in Anspruch nehmen."} />
          </Box>
        ),
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        autoHideDuration: 10000,
      });
    }
  };

  const { values, handleChange, handleSubmit, isLoading, registerValidators, errors, clearForm } = useForm({
    initialValues,
    onSubmit,
    identifier: activity,
  });

  const isFollowUpAllowed = product.acquisitionPartner !== "check24";

  const hasSubmitRights = (currentUser, activity, values) => {
    return (
      currentUser.id === activity.assignedUser.id ||
      userHasRole(currentUser, "ROLE_ADMIN") ||
      (activity.type === "lawyer_counseling_call" && userHasRole(currentUser, "ROLE_LAWYER")) ||
      (values.cancelled && userHasRole(currentUser, "ROLE_LAWYER"))
    );
  };

  const isSubmittable = () => {
    if (hasSubmitRights(currentUser, activity, values) === false) {
      return false;
    }

    if (values.cancelled === true) {
      return values.cancelReason.length >= 50;
    }

    if (values.processed === "no") {
      return true;
    }

    if (islawyerCounselingCall) {
      return !_.isEmpty(values.processed);
    }

    return !(
      _.isEmpty(values.processed) ||
      !values.customerSatisfaction ||
      (isFollowUpAllowed && _.isEmpty(values.followUp))
    );
  };

  useEffect(() => {
    registerValidators("processed", !values.cancelled ? requiredFieldDefault : []);
    registerValidators("followUp", !values.cancelled ? requiredFieldDefault : []);
    registerValidators("notProcessedReason", (!values.cancelled && values.processed === "no") ? requiredFieldDefault : []);
    if (values.processed === "no") {
      handleChange({ target: { name: "followUp", value: "no" } });
    }
  }, [values.cancelled, values.processed]);

  return (
    <LegalbirdIoModal
      handleClose={handleClose}
      open={open}
      title={"Ergebnis des Beratungstermins"}
      submitButton={
        <ButtonLoading
          type={"submit"}
          variant={"contained"}
          disabled={!isSubmittable()}
          isLoading={isLoading}
          onClick={isSubmittable() ? handleSubmit : () => {}}
        >
          {values.cancelled ? "Termin absagen" : "Ergebnis speichern"}
        </ButtonLoading>
      }
    >
      <Grid container>
        {userHasOneOfTheseRoles(currentUser, ["ROLE_LAWYER", "ROLE_ADMIN"]) && (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="cancelled"
                  checked={values.cancelled}
                  onChange={handleChange}
                  value="cancelled"
                  color="primary"
                />
              }
              label="Termin absagen"
            />
          </Grid>
        )}
        {!values.cancelled ? (
          <>
            <Grid item xs={12}>
              <ValidatorSelect
                label={"Hat das Gespräch wie geplant stattgefunden?"}
                name={"processed"}
                value={formValue(values, "processed")}
                onChange={handleChange}
                registerValidators={registerValidators}
                validators={!values.cancelled ? requiredFieldDefault : []}
                dependentValidationFields={["notProcessedReason"]}
                error={!!errors["processed"]}
                helperText={errors["processed"]}
                disabled={activity.done}
              >
                <MenuItem value={"yes"}>Ja</MenuItem>
                <MenuItem value={"no"}>Nein</MenuItem>
              </ValidatorSelect>
            </Grid>
            <Grid item xs={12}>
              <ValidatorSelect
                label={"Warum hat das Gespräch nicht stattgefunden? "}
                name={"notProcessedReason"}
                value={formValue(values, "notProcessedReason")}
                onChange={handleChange}
                disabled={values.processed !== "no" || activity.done}
                error={!!errors["notProcessedReason"]}
                helperText={errors["notProcessedReason"]}
              >
                <MenuItem value={"clientNotAvailable"}>Mandant nicht erreicht</MenuItem>
                <MenuItem value={"clientNumberIncorrect"}>Falsche Rufnummer</MenuItem>
                <MenuItem value={"clientCancelled"}>Mandant hat Termin abgesagt</MenuItem>
                <MenuItem value={"lawyerUnavailable"}>Ich konnte nicht teilnehmen</MenuItem>
              </ValidatorSelect>
            </Grid>
            {values.processed !== "no" && (
              <>
                {!islawyerCounselingCall && isFollowUpAllowed && (
                  <>
                    <Grid item xs={12}>
                      <ValidatorSelect
                        label={"Weitere Mandatstätigkeiten notwendig"}
                        name={"followUp"}
                        value={formValue(values, "followUp")}
                        onChange={(e) => {
                          handleChange(e);
                          registerValidators("followUpAction", e.target.value === "yes" ? requiredFieldDefault : []);
                          registerValidators("note", e.target.value === "yes" ? requiredFieldDefault : []);
                        }}
                        registerValidators={registerValidators}
                        validators={(!values.cancelled && values.processed === "no") ? requiredFieldDefault : []}
                        dependentValidationFields={["followUpAction", "note"]}
                        error={!!errors["followUp"]}
                        helperText={errors["followUp"]}
                        disabled={activity.done}
                      >
                        <MenuItem value={"yes"}>Ja, es sind weitere Mandatstätigkeiten notwendig</MenuItem>
                        <MenuItem value={"no"}>Nein, alle Fragen wurden beantwortet</MenuItem>
                      </ValidatorSelect>
                    </Grid>
                    <Grid item xs={12}>
                      <ValidatorSelect
                        label={"Welche weitere Mandatstätigkeit ist notwendig?"}
                        name={"followUpAction"}
                        value={formValue(values, "followUpAction")}
                        onChange={handleChange}
                        disabled={values.followUp !== "yes" || activity.done}
                        error={!!errors["followUpAction"]}
                        helperText={errors["followUpAction"]}
                      >
                        <MenuItem value={"counsultation"}>Weitere kurze Beratung</MenuItem>
                        <MenuItem value={"additionalActions"}>Außergerichtliches Schreiben</MenuItem>
                        <MenuItem value={"documentCheck"}>Prüfung von Dokumenten</MenuItem>
                        <MenuItem value={"courtProcess"}>Anwaltliche Betreuuung und ggf. Prozess</MenuItem>
                      </ValidatorSelect>
                    </Grid>
                  </>
                )}
                {!islawyerCounselingCall && (
                  <Grid item xs={12} className={classes.topSpace}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Zufriedenheit des Mandanten mit der Beratung</FormLabel>
                      <div className={classes.ratingContainer}>
                        <Rating
                          name={"customerSatisfaction"}
                          value={parseInt(values.customerSatisfaction)}
                          onChange={handleChange}
                          disabled={activity.done}
                        />
                      </div>
                    </FormControl>
                  </Grid>
                )}
              </>
            )}
            <Grid item xs={12}>
              <ValidatorTextField
                label={"Hinweise zum Gespräch oder weiteren Mandatstätigkeiten"}
                multiline
                rows={8}
                name={"note"}
                value={formValue(values, "note")}
                onChange={handleChange}
                error={!!errors["note"]}
                helperText={errors["note"]}
                disabled={activity.done}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <ValidatorTextField
                label={"Absagegrund für den Mandanten"}
                multiline
                rows={8}
                name={"cancelReason"}
                value={formValue(values, "cancelReason")}
                onChange={handleChange}
                helperText={<Box color={values.cancelReason.length < 50 ? "red" : "#999"}>Anzahl Zeichen {values.cancelReason.length} von mindestens 50</Box>}
              />
            </Grid>
          </>
        )}
      </Grid>
    </LegalbirdIoModal>
  );
};

const CalendlyAssessmentModal = (props) => {
  const { activity } = props;
  const [calendlyEvent, setCalendlyEvent] = useState();

  useEffect(() => {
    if (!!activity.calendlyEvent && !calendlyEvent) {
      ApiClient.get(activity.calendlyEvent).then((response) => setCalendlyEvent(response));
    }
  }, [activity, calendlyEvent]);

  if (!calendlyEvent) {
    return null;
  }

  return <CalendlyAssessmentModalInner {...props} calendlyEvent={calendlyEvent} />;
};
export default CalendlyAssessmentModal;
