import customerTranslations from "./customerTranslations";
import basicCaseTranslations from "./basicCaseTranslations";
import contractClaimTranslations from "./contractClaimTranslations";

const providerContractTranslations = {
  ...basicCaseTranslations,
  ...contractClaimTranslations,
  label: "Handy / DSL / Strom / Gas-Vertrag",
  providerType: {
    label: "Art des Anbieters",
    values: {
      mobile: "Mobilfunk",
      dsl: "DSL/Internet",
      electricity: "Strom",
      gas: "Gas",
      other: "Anderer Anbieter",
    },
  },
  reasonForDispute: {
    label: "Problem mit Anbieter",
    values: {
      extraOptionGas: "Von Gasumlage betroffen",
      cancellationByProvider: "Vertrag wurde von Anbieter gekündigt",
      priceIncrease: "Anbieter hat die Preise erhöht",
      serviceNotAsAgreed: "Leistung wird von Anbieter nicht erbracht",
      cancellationRefused: "Meine Kündigung wird nicht akzeptiert",
      invoiceIncorrect: "Fehlerhafte Rechnung erhalten",
      other: "Anderes Problem oder Frage",
      custom: "Individueller Sachverhalt",
    },
  },
  contractSignedBy: {
    label: "Vertrag geschlossen durch",
    values: {
      client: "Mandant",
      landlord: "Vermieter",
    },
  },
  extraOptionGasNotification: {
    label: "Ankündigung Gasumlage am"
  },
  contractHasPriceFixing: {
    label: "Preisbindung/Garantie",
    values: {
      yes: "Ja",
      no: "Nein",
      unknown: "Unbekannt"
    },
  },
  contractNumber: {
    label: "Kundennummer",
  },
  cancellationDate: {
    label: "Kündigungsdatum",
  },
  cancellationReceivedDate: {
    label: "Eingang der Kündigung"
  },
  endOfContractDate: {
    label: "Datum Vertragsende",
  },
  opponentReactionToCancellation: {
    label: "Reaktion Gegenseite auf Kündigung",
    values: {
      noReaction: "Keine Reaktion",
      cancellationRefused: "Kündigung abgelehnt",
      differentDate: "Anderes Kündigungsdatum/Verlängerung",
    }
  },
  endOfContractDateOpponent: {
    label: "Vertragsende laut Gegenseite",
  },
  priceIncreaseAnnouncementDate: {
    label: "Datum Ankündigung Preiserhöhung"
  },
  specialTerminationDate: {
    label: "Sonderkündigungsdatum"
  },
  customer: customerTranslations,
};

export default providerContractTranslations;
