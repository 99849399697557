import React from "react";
import * as PropTypes from "prop-types";
import LinkListItemWithIcon from "../LinkListItemWithIcon/LinkListItemWithIcon";
import { List } from "@mui/material";
import authorityImage from "../../assets/icon/icon_behoerde_100px.png";
import familyImage from "../../assets/icon/product/icon_familienrecht.png";
import techStatusImage from "../../assets/icon/icon_admintool.png";
import createReleaseUpdatesImage from "../../assets/icon/release_updates_creation.png";
import releaseUpdatesImage from "../../assets/icon/release_updates.png";
import applicationImage from "../../assets/icon/icon_antrag_erstellen_150px.png";
import lawyerIcon from "../../assets/icon/icon_anwalt_128px.png";
import personIcon from "../../assets/icon/person_faceless_250px.png";
import statsIcon from "../../assets/icon/icon_statistik.png";
import { userHasOneOfTheseRoles } from "../../services/backofficeUserService";

const Organisation = ({ currentUser }) => {

  return (
    <List>
      <LinkListItemWithIcon
        title={"Datensätze"}
        subtitle={"editieren"}
        description={"Daten für Behörde, StA, Inkasso oder Gegenseiten bearbeiten"}
        to={"/services/tools/datensaetze"}
        imageIcon={authorityImage}
      />
      <LinkListItemWithIcon
        title={"HR Planung"}
        subtitle={"konfigurieren"}
        description={"Expertise uns Arbeitsressourcen definieren und einteilen"}
        to={"/services/tools/hr-planung"}
        imageIcon={familyImage}
      />
      <LinkListItemWithIcon
        title={"Übersicht Release-Updates"}
        subtitle={"Updates einsehen"}
        description={"Archiv aktueller und vergangener Release-Updates"}
        to={"/services/tools/release-updates"}
        imageIcon={releaseUpdatesImage}
      />
      {userHasOneOfTheseRoles(currentUser, ["ROLE_ADMIN", "ROLE_DEVELOPER"]) && (
        <>
          <LinkListItemWithIcon
            title={"Editor Release-Updates"}
            subtitle={"Updates erstellen"}
            description={"Updatetexte erstellen und veröffentlichen"}
            to={"/services/tools/release-update/neu"}
            imageIcon={createReleaseUpdatesImage}
          />
          <LinkListItemWithIcon
            title={"Nutzer"}
            subtitle={"anlegen und verwalten"}
            description={"Nutzer hinzufügen, deaktivieren und Daten bearbeiten"}
            to={"/services/tools/nutzer-verwalten"}
            imageIcon={personIcon}
          />
          <LinkListItemWithIcon
            title={"Admin-Board"}
            subtitle={"Technische Funktionen"}
            description={"Tools und Funktionen zum Beheben technischer Probleme"}
            to={"/services/tools/admin-board"}
            imageIcon={techStatusImage}
          />
          <LinkListItemWithIcon
            title={"Nutzeransichten"}
            subtitle={"wechseln und prüfen"}
            description={"die Software aus der Sicht verschiedener Nutzerrollen ansehen"}
            to={"/services/papageienaufstand"}
            imageIcon={familyImage}
          />
          <LinkListItemWithIcon
            title={"Vorlagen"}
            subtitle={"erstellen und verwalten"}
            description={"Produktspezifische Vorlagen können hier mit Platzhaltern erstellt werden"}
            to={"/services/tools/vorlagen"}
            imageIcon={applicationImage}
          />
        </>
      )}
      <LinkListItemWithIcon
        title={"Bewerbungen"}
        subtitle={"externer Anwälte prüfen"}
        description={"eingehende Bewerbungen einsehen und annehmen oder ablehnen"}
        to={"/services/tools/bewerbungen"}
        imageIcon={lawyerIcon}
      />
      <LinkListItemWithIcon
        title={"Statistiken"}
        subtitle={"für Operations & Anwälte"}
        description={"Customer Happiness, Perfect Days & Aufgaben"}
        to={"/services/tools/statistiken"}
        imageIcon={statsIcon}
      />
    </List>
  );
};

Organisation.propTypes = {
  currentUser: PropTypes.object.isRequired,
};

export default Organisation;
