import React, { useEffect, useState } from "react";
import useStyles from "./caseHistoryPageStyle";
import PageContentContainer from "../../Container/PageContentContainer";
import { Box, CircularProgress } from "@mui/material";
import CaseHistoryFilter from "../../Case/CaseHistory/CaseHistoryFilter";
import ApiClient from "../../../services/ApiClient";
import _ from "lodash";
import CaseHistoryItem from "../../Case/CaseHistory/CaseHistoryItem";
import { prepareHistory } from "../../../services/historyService";
import CaseRelatedActions from "../../Case/CaseRelatedActions/CaseRelatedActions";
import { useParams } from "react-router-dom";
import ButtonLoading from "../../Button/ButtonLoading";
import LegalBirdPageLoading from "../../ContentLoader/LegalBirdPageLoading";
import { ProductHeadline } from "../../PageHeadline/PageHeadline";
import ContentBox from "../../ContentBox/ContentBox";
import CaseProvider, { useCase } from "../../../provider/CaseProvider";

function filterToParams(filter) {
  if (typeof filter === "object") {
    return `logDate[after]=${filter.startDate.format("YYYY-MM-DD")}&logDate[before]=${filter.endDate.format(
      "YYYY-MM-DD"
    )}T23:59:59`;
  }
  switch (filter) {
    case "all":
      return "";
    case "changes":
      return "type[]=entityDataChange&type[]=entityStatusChange&type[]=customerRegistration&type[]=caseCreated";
    case "mediaObject":
      return "type[]=mediaObject&type[]=mediaObjectDelete";
    default:
      return `type=${filter}`;
  }
}

const CaseHistoryPage = () => {
  const { productId, productUrlPart } = useParams();
  return (
    <CaseProvider caseUrlPart={productUrlPart} caseId={productId}>
      <CaseHistoryPageInner />
    </CaseProvider>
  );
}

const CaseHistoryPageInner = () => {
  const params = useParams();
  const classes = useStyles({});
  const { product, isLoading: productIsLoading } = useCase();
  const [filter, setFilter] = useState("all");
  const [updateCounter, setUpdateCounter] = useState(1);
  const [history, setHistory] = useState([]);
  const [historyCallResult, setHistoryCallResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const dataSource =
    product &&
    `backofficeCase=${product.backofficeCase["@id"]}&page=1&updateCounter=${updateCounter}&` + filterToParams(filter);
  useEffect(() => {
    async function fetchHistoryLogs() {
      if (!dataSource) return;
      setIsLoading(true);
      const historyLogs = await ApiClient.get("history_logs?" + dataSource);
      setHistory(historyLogs["hydra:member"]);
      setHistoryCallResult(historyLogs);
      setIsLoading(false);
    }

    fetchHistoryLogs();
  }, [dataSource]);

  const handleMoreButton = async () => {
    if (!historyCallResult || !historyCallResult["hydra:view"]["hydra:next"]) {
      return;
    }
    const url = historyCallResult["hydra:view"]["hydra:next"].substr(1);
    setIsLoadingMore(true);
    const result = await ApiClient.get(
      `${url}&backofficeCase=${product.backofficeCase["@id"]}&${filterToParams(filter)}`
    );
    setHistoryCallResult(result);
    setHistory([...history, ...result["hydra:member"]]);
    setIsLoadingMore(false);
  };

  if (productIsLoading || !historyCallResult) {
    return <LegalBirdPageLoading />;
  }

  let translatedHistory = prepareHistory(history, product.productClassName);
  return (
    <>
      <CaseRelatedActions product={product} />
      <PageContentContainer size={"small"}>
        <ProductHeadline title="Verlauf von" product={product} />
        <CaseHistoryFilter handleFilterChange={(filter) => setFilter(filter)} filter={filter} />
        <ContentBox>
          <div className={classes.historyContainer}>
            {isLoading ? (
              <div className={classes.spinner}>
                <CircularProgress />
              </div>
            ) : (
              <>
                {translatedHistory.length ? (
                  _.map(translatedHistory, (historyItem, index) => (
                    <Box sx={{ margin: "1rem 0" }} key={index}>
                      <CaseHistoryItem
                        fetchHistory={() => setUpdateCounter(updateCounter + 1)}
                        product={product}
                        historyItem={historyItem}
                      />
                    </Box>
                  ))
                ) : (
                  <div className={classes.faded}>Kein Eintrag vorhanden</div>
                )}
              </>
            )}
            <ButtonLoading
              className={classes.moreButton}
              onClick={handleMoreButton}
              isLoading={isLoading}
              disabled={!historyCallResult["hydra:view"]["hydra:next"] || isLoadingMore}
            >
              Mehr Anzeigen
            </ButtonLoading>
          </div>
        </ContentBox>
      </PageContentContainer>
    </>
  );
};

export default CaseHistoryPage;
