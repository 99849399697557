import React, { useCallback, useEffect, useState } from "react";
import PageContentContainer from "../../Container/PageContentContainer";
import _ from "lodash";
import ActivityOverviewHeader from "../../Activities/ActivityOverviewHeader/ActivityOverviewHeader";
import UnassignedActivities from "../../Activities/UnassignedActivities/UnassignedActivities";
import moment from "moment";
import { useSelector } from "react-redux";
import { getBackofficeUser } from "../../../store/backofficeUser/reducer";
import ApiClient from "../../../services/ApiClient";
import {
  buildApiFilters,
  buildSearchParams,
  postProcessFilterActivities, prepareFilters
} from "../../../services/Activities/activityFunctions";
import useActivitiesFetching from "../../../hooks/useActivitiesFetching";
import { makeStyles } from "@mui/styles";
import activityStyle, { activityClasses } from "../ActivityTable/activityStyle";
import { Box, Paper, Tab, TableContainer, Tabs } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import ActivityTable from "../ActivityTable/ActivityTable";
import PageHeadline from "../../PageHeadline/PageHeadline";
import { tabItemLabelGroupStyle, tabItemLabelStyle, tabItemTagStyle } from "./activityTabsStyle";
import { userHasOneOfTheseRoles } from "../../../services/backofficeUserService";
import { useBackofficeUser } from "../../../provider/BackofficeUserProvider";
import { Roles } from "../../../types/BackofficeUser";

const useStyle = makeStyles(activityStyle);

const ActivityOverview = () => {
  const classes = useStyle();
  const currentUser = useSelector((state) => getBackofficeUser(state));
  const { agents, backofficeUsers } = useBackofficeUser();

  const initialFilters = prepareFilters(currentUser, agents);
  const initialTab = localStorage.getItem("savedTab") || "activities";
  const [productFilter, setProductFilter] = useState(initialFilters.productFilter);
  const [activityTypeFilter, setActivityTypeFilter] = useState(initialFilters.activityTypeFilter);
  const [userFilter, setUserFilter] = useState(initialFilters.userFilter);
  const [timespanFilter, setTimespanFilter] = useState(initialFilters.timespanFilter);
  const [userActivitiesToday, setUserActivitiesToday] = useState([]);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [filteredActivities, setFilteredActivities] = useState([]);
  const [doneActivities, setDoneActivities] = useState([]);
  const [mailAndDocumentActivities, setMailAndDocumentActivities] = useState([]);
  const [checkCaseActivities, setCheckCaseActivities] = useState([]);
  const [selectedTab, setSelectedTab] = useState(initialTab);

  const dataSource = buildSearchParams(
    _.merge(
      buildApiFilters({
        productFilter: productFilter,
        activityTypeFilter: activityTypeFilter,
        userFilter: userFilter,
        timespanFilter: timespanFilter,
      }),
      {
        refreshCounter: refreshCounter,
      }
    )
  );
  const { activities, isLoading } = useActivitiesFetching(dataSource.toString(), backofficeUsers);

  const updateUserActivitiesToday = useCallback(async () => {
    if (userFilter === "all") {
      setUserActivitiesToday([]);
      return;
    }
    const filters = {
      assignedUser: userFilter,
      "dueDate[before]": moment().format("YYYY-MM-DD"),
      done: false,
      deleted: false,
      pagination: false,
    };

    let searchParams = new URLSearchParams();
    _.map(filters, (value, key) => {
      searchParams.append(key, value);
    });

    const userActivitiesResult = await ApiClient.get("activities?" + searchParams.toString());
    setUserActivitiesToday(userActivitiesResult["hydra:member"]);
  }, [userFilter]);

  const updateActivities = () => {
    setRefreshCounter(refreshCounter + 1);
  };

  useEffect(() => {
    localStorage.setItem(
      "activityFilters",
      JSON.stringify(
        _.merge(
          {},
          {
            productFilter: productFilter,
            activityTypeFilter: activityTypeFilter,
            userFilter: userFilter,
            timespanFilter: timespanFilter,
          }
        )
      )
    );
  }, [productFilter, userFilter, timespanFilter, activityTypeFilter]);

  useEffect(() => {
    updateUserActivitiesToday();
  }, [userFilter, updateUserActivitiesToday]);

  useEffect(() => {
    let filteredActivities = isLoading ? [] : postProcessFilterActivities(activities, timespanFilter);
    setDoneActivities(_.remove(filteredActivities, (activity) => activity.done));
    setMailAndDocumentActivities(
      _.remove(filteredActivities, (activity) => _.includes(["mail", "email", "check_document"], activity.type))
    );
    setCheckCaseActivities(_.remove(filteredActivities, (activity) => activity.type === "check_case"));
    setFilteredActivities(filteredActivities);
  }, [
    activities,
    isLoading,
    setDoneActivities,
    setMailAndDocumentActivities,
    setCheckCaseActivities,
    setFilteredActivities,
    timespanFilter,
  ]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    localStorage.setItem("savedTab", newValue);
  };

  return (
    <PageContentContainer>
      {userHasOneOfTheseRoles(currentUser, [Roles.accountmanager, Roles.traineeLawyer, Roles.admin]) && (
        <UnassignedActivities updateFilteredActivities={() => setRefreshCounter(refreshCounter + 1)} />
      )}
      <PageHeadline main="Aktivitätenübersicht" />
      <ActivityOverviewHeader
        activityCount={
          filteredActivities.length +
          mailAndDocumentActivities.length +
          checkCaseActivities.length +
          doneActivities.length
        }
        setProductFilter={(data) => {
          updateActivities();
          setProductFilter(data);
        }}
        productFilter={productFilter}
        setActivityTypeFilter={(data) => {
          updateActivities();
          setActivityTypeFilter(data);
        }}
        activityTypeFilter={activityTypeFilter}
        userFilter={userFilter}
        setUserFilter={(data) => {
          updateActivities();
          setUserFilter(data);
        }}
        timespanFilter={timespanFilter}
        setTimespanFilter={(data) => {
          updateActivities();
          setTimespanFilter(data);
        }}
        userActivitiesToday={userActivitiesToday}
        isLoading={isLoading}
        agents={agents}
      />
      <TableContainer component={Paper}>
        <TabContext value={selectedTab}>
          <Tabs value={selectedTab} onChange={handleTabChange}>
            <Tab
              sx={activityClasses.tableHeadline}
              label={
                <Box sx={tabItemLabelGroupStyle}>
                  <Box sx={tabItemLabelStyle}>
                    Aktivitäten{" "}
                    {filteredActivities?.length > 0 && <Box sx={tabItemTagStyle}>{filteredActivities.length}</Box>}
                  </Box>
                </Box>
              }
              value="activities"
            />
            <Tab
              sx={activityClasses.tableHeadline}
              label={
                <Box sx={tabItemLabelGroupStyle}>
                  <Box sx={tabItemLabelStyle}>
                    Posteingang{" "}
                    {mailAndDocumentActivities?.length > 0 && (
                      <Box sx={tabItemTagStyle}>{mailAndDocumentActivities.length}</Box>
                    )}
                  </Box>
                </Box>
              }
              value="inbox"
            />
            <Tab
              sx={activityClasses.tableHeadline}
              label={
                <Box sx={tabItemLabelGroupStyle}>
                  <Box sx={tabItemLabelStyle}>
                    Neue Fälle{" "}
                    {checkCaseActivities?.length > 0 && <Box sx={tabItemTagStyle}>{checkCaseActivities.length}</Box>}
                  </Box>
                </Box>
              }
              value="checkCases"
            />
            <Tab sx={activityClasses.tableHeadline} label="Erledigt" value="done" />
          </Tabs>
          <TabPanel className={classes.tabPanel} value="activities">
            {selectedTab === "activities" && (
              <ActivityTable isLoading={isLoading} activities={filteredActivities} update={updateActivities} />
            )}
          </TabPanel>
          <TabPanel className={classes.tabPanel} value="inbox">
            {selectedTab === "inbox" && (
              <ActivityTable isLoading={isLoading} activities={mailAndDocumentActivities} update={updateActivities} />
            )}
          </TabPanel>
          <TabPanel className={classes.tabPanel} value="checkCases">
            {selectedTab === "checkCases" && (
              <ActivityTable isLoading={isLoading} activities={checkCaseActivities} update={updateActivities} />
            )}
          </TabPanel>
          <TabPanel className={classes.tabPanel} value="done">
            {selectedTab === "done" && (
              <ActivityTable isLoading={isLoading} activities={doneActivities} update={updateActivities} />
            )}
          </TabPanel>
        </TabContext>
      </TableContainer>
    </PageContentContainer>
  );
};

export default ActivityOverview;
