import React, { useEffect, useMemo, useState } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import SmallWidget from "./SmallWidget";
import { BallotOutlined, AssignmentTurnedInOutlined, ListAltOutlined, MoveToInboxOutlined } from "@mui/icons-material";
import { PaidTask } from "../../../types/PaidTask";
import { Activity } from "../../../types/Activity";
import { get } from "../../../services/Api/apiCall";
import { Link } from "react-router-dom";
import LegalbirdLoader from "../../ContentLoader/LegalbirdLoader";
import ExternalPaidTaskTable from "../../PaidTask/PaidTaskTable/ExternalPaidTaskTable";
import AdvisoryCallList from "../../AdvisoryCallsTable/AdvisoryCallList";
import _ from "lodash";
import moment from "moment/moment";

interface DashboardData {
  requestedTasks: number;
  activeTasks: number;
  recentlyDoneTasks: number;
  unassignedPaidTasks: number;
  activeAdvisoryCallsList: Activity[];
  activeTasksList: PaidTask[];
  externalUser: {
    processableTaskTypes: { taskType: string }[];
  };
}

export default function ExternalsDashboard() {
  const [dashboardData, setDashboardData] = useState<DashboardData | null>(null);
  useEffect(() => {
    get("/services/external/dashboardData").then((res) => setDashboardData(res));
  }, []);

  const doesAdvisoryCalls: boolean = useMemo(() => {
    if (dashboardData === null) {
      return false;
    }
    let res = false;
    _.forEach(dashboardData.externalUser.processableTaskTypes, (processableTaskType) => {
      if (processableTaskType.taskType === "advisories") {
        res = true;
      }
    });
    return res;
  }, [dashboardData]);

  if (!dashboardData) {
    return <LegalbirdLoader centered={true} />;
  }

  return (
    <Grid container alignItems={"stretch"} spacing={4}>
      <Grid item xs={12} md={3}>
        <Link to={"/services/extern/aufgaben"}>
          <SmallWidget
            subject={"Anfragen für Aufgaben"}
            count={dashboardData.requestedTasks}
            icon={<MoveToInboxOutlined />}
          />
        </Link>
      </Grid>
      <Grid item xs={12} md={3}>
        <Link to={"/services/extern/aufgaben"}>
          <SmallWidget subject={" Angenommene Aufgaben"} count={dashboardData.activeTasks} icon={<ListAltOutlined />} />
        </Link>
      </Grid>
      <Grid item xs={12} md={3}>
        <SmallWidget
          subject={"Diesen Monat erledigt"}
          count={dashboardData.recentlyDoneTasks}
          icon={<AssignmentTurnedInOutlined />}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Link to={"/services/extern/ausgeschriebene-aufgaben"}>
          <SmallWidget
            subject={"Ausgeschriebene Aufgaben"}
            count={dashboardData.unassignedPaidTasks}
            icon={<BallotOutlined />}
          />
        </Link>
      </Grid>
      <Grid item xs={12} md={doesAdvisoryCalls ? 7 : 12}>
        <Paper sx={{ padding: "1.5rem" }}>
          <Typography variant={"h5"} sx={{ marginBottom: "1rem" }}>
            Zu erledigende Aufgaben
          </Typography>
          <ExternalPaidTaskTable paidTasks={dashboardData.activeTasksList} isMinimalView={true} />
          <Box sx={{ textAlign: "right", marginTop: "1rem" }}>
            <Link to={"/services/extern/aufgaben"}>Alle anzeigen</Link>
          </Box>
        </Paper>
      </Grid>
      {doesAdvisoryCalls && (
        <Grid item xs={12} md={5}>
          <Paper sx={{ padding: "1.5rem" }}>
            <Typography sx={{ marginBottom: "1.5rem" }} variant={"h5"}>
              Beratungstermine {moment().format("DD.MM.YYYY")}
            </Typography>
            <AdvisoryCallList advisoryCalls={dashboardData.activeAdvisoryCallsList} />
            <Box sx={{ textAlign: "right", marginTop: "1rem" }}>
              <Link to={"/services/extern/Termine"}>Alle anzeigen</Link>
            </Box>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
}
