export enum Roles {
  accountmanager = "ROLE_ACCOUNTMANAGER",
  accounting = "ROLE_ACCOUNTING",
  developer = "ROLE_DEVELOPER",
  lawyer = "ROLE_LAWYER",
  external = "ROLE_EXTERNAL",
  admin = "ROLE_ADMIN",
  system = "ROLE_SYSTEM",
  user = "ROLE_USER",
  banking = "ROLE_BANKING",
  callcenter = "ROLE_CALLCENTER",
  traineeLawyer = "ROLE_TRAINEE_LAWYER",
}
export interface BackofficeUser {
  '@id': string;
  id: number;
  roles: Array<Roles>;
  email: string;
  person: BackofficePerson;
  avatar: string | null;
  calendlyId: string | null;
  isAccountManager: boolean;
  isLawyer: boolean;
  isExternal: boolean;
  status: string;
}

interface LawyerTitle {
  title: string;
  fullTitle: string;
  product: string;
}
export interface BackofficePerson {
  '@id': string;
  id: number;
  titles: Array<LawyerTitle>;
  gender: string;
  givenName: string;
  familyName: string;
  encodedSignature: string | null;
  fullname: string;
  beaData: boolean;
}
