const trafficTransformations = {
  dateFields: [
    { path: "fineNoticeReceivedDate", type: "date" },
    { path: "extended.fineNoticeDate", type: "date" },
    { path: "extended.fineDeadline", type: "date" },
    { path: "powerOfAttorney", type: "dateTime" },
    { path: "extended.accessToRecordRequested", type: "date" },
    { path: "costs.insuranceCoverageDate", type: "date" },
    { path: "extended.opinionSubmitted", type: "date" },
    { path: "extended.authorityAnswered", type: "date" },
    { path: "extended.courtDecisionDate", type: "date" },
    { path: "petitioner.birthDate", type: "date" },
    { path: "verification", type: "dateTime" },
    { path: "extended.appealRevoked", type: "date" },
    { path: "customerAppealRevocationApproved", type: "date" },
    { path: "extended.processStoppedDate", type: "date" },
  ],
  numberFields: ["leadStatus", "applicationStatus", "extended.responsibleCourt.advoAssistId"],
  moneyFields: [
    "costs.fine",
    "costs.extrajudicialProcessFee",
    "costs.processFee",
    "costs.hearingFee",
    "insurance.deductible",
  ],
};
export default trafficTransformations;
