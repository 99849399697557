import customerTranslations from "./customerTranslations";
import abstractCaseTranslations from "./abstractCaseTranslations";
import { courtTranslations } from "./courtTranslations";
import personTranslations from "./personTranslations";
import { prosecutionTranslations } from "./prosecutionTranslations";
import authorityTranslations from "./authorityTranslations";

const trafficTranslations = {
  label: "Ordnungswidrigkeit",
  customer: customerTranslations,
  ...abstractCaseTranslations,
  leadStatus: {
    10: "Antrag erstellt",
    20: "Antrag ausgefüllt",
    30: "Dokumente hochgeladen",
  },
  reference: {
    label: "Unser Zeichen",
  },
  accusation: {
    label: "Verstoß",
    values: {
      speeding: "Geschwindigkeitsverstoß",
      red_light: "Rotlichtverstoß",
      distance: "Abstandsverstoß",
      other: "Handy/Drogen/Parken/Sonstiges",
    },
  },
  accusationOther: {
    label: "Verstoß im Detail",
    values: {
      mobile_phone: "Handyverstoß",
      drugs: "Alkohol- / Drogenverstoß",
      parking: "Parkverstoß",
      other: "Anderer Tatvorwurf",
    },
  },
  powerOfAttorney: {
    label: "Vollmacht erteilt am",
  },
  flensburgPoints: {
    label: "Punkte Flensburg",
    values: {
      none: "Keine",
      "1-3": "1 - 3 Punkte",
      "4-8": "4 - 8 Punkte",
      unknown: "Ich bin mir nicht sicher",
    },
  },
  driversLicenseIndispensable: {
    label: "Auf Führerschein angewiesen?",
    values: {
      yes_professional: "Ja, aus beruflichen Gründen",
      yes_other: "Ja, aus sonstigen Gründen",
      no: "Nein",
    },
  },
  flashed: {
    label: "Geblitzt letzte 12 Monate",
    values: {
      yes: "Ja",
      no: "Nein",
      unknown: "Ich bin mir nicht sicher",
    },
  },
  fineNoticeReceivedDate: {
    label: "Bescheid erhalten am",
  },
  driversLicensePeriod: {
    label: "Führerschein seit",
    values: {
      "<2": "Unter 2 Jahre (Probezeit)",
      "2-10": "2 - 10 Jahre",
      ">10": "Über 10 Jahre",
    },
  },
  authorityNotification: {
    label: "Schreiben von Behörde",
    values: {
      hearing_report: "Anhörungsbogen",
      witness_report: "Zeugenfragebogen",
      fine_notice: "Bußgeldbescheid",
      no_notice: "Noch keine Post von Behörde",
    },
  },
  legalExpenseInsurance: {
    label: "Rechtsschutz Verkehr",
    values: {
      true: "Ja",
      false: "Nein",
    },
  },
  costs: {
    fine: {
      label: "Bußgeldhöhe in €",
    },
    insuranceCoverageDate: {
      label: "Kostendeckung bestätigt",
    },
  },
  responsibleCourt: {
    name: {
      label: "Zuständiges Gericht",
    },
  },
  customerAppealRevocationApproved: {
    label: "Rücknahme bestätigt durch Mandant am",
  },
  insurance: {
    insurancePolicyNumber: {
      label: "Versicherungsnummer",
    },
    caseReferenceNumber: {
      label: "Schadennummer",
    },
    deductible: {
      label: "Selbstbehalt",
    },
    insurance: {
      label: "Versicherungsgesellschaft",
    },
  },
  extended: {
    responsibleAuthority: authorityTranslations,
    responsibleProsecution: prosecutionTranslations,
    responsibleCourt: courtTranslations,
    prosecutionReference: {
      label: "Aktenzeichen Staatsanwaltschaft",
    },
    authorityAddress: {
      streetAddress: {
        label: "Straße und Nr Behörde",
      },
      postalCode: {
        label: "PLZ Behörde",
      },
      addressLocality: {
        label: "Ort Behörde",
      },
      postOfficeBoxNumber: {
        label: "Postfach",
      },
    },
    responsibleCourtAddress: {
      streetAddress: {
        label: "Straße und Nr zuständiges Gericht",
      },
      postalCode: {
        label: "PLZ zuständiges Gericht",
      },
      addressLocality: {
        label: "Ort zuständiges Gericht",
      },
      postOfficeBoxNumber: {
        label: "Postfach zuständiges Gericht",
      },
    },
    authorityName: {
      label: "Name der Behörde",
    },
    opinionSubmitted: {
      label: "Stellungnahme abgegeben am",
    },
    opinionRequired: {
      label: "Stellungnahme benötigt?",
    },
    fineNoticeDate: {
      label: "Datum Bußgeldbescheid",
    },
    fineDeadline: {
      label: "Frist läuft ab am",
    },
    courtDecisionDate: {
      label: "Urteil Gericht am",
    },
    courtReference: {
      label: "Aktenzeichen Gericht",
    },
    courtNotice: {
      label: "Schreiben Gericht",
      values: {
        revoked: "Verfahren eingestellt",
        no_trial: "Beschluss ohne Verhandlung",
        trial: "Gerichtsverhandlung angeordnet",
      },
    },
    authorityAnswered: {
      label: "Antwort von Behörde am",
    },
    fineNoticeReference: {
      label: "Aktenzeichen Bescheid",
    },
    processStoppedDate: {
      label: "Verfahren eingestellt am",
    },
    accessToRecordRequested: {
      label: "Einspruch eingelegt am",
    },
    paymentType: {
      label: "Zahlart",
      values: {
        insurance: "Rechtsschutzversicherung",
        private: "Selbstzahler",
      },
    },
    trialCourtDate: {
      label: "Gerichtstermin",
      appointmentDate: {
        label: "Gerichtstermin Datum",
      },
      appointmentTime: {
        label: "Gerichtstermin Uhrzeit",
      },
    },
    authorityDecision: {
      label: "Entscheidung Behörde",
      values: {
        revoked: "Bescheid zurückgenommen",
        changed: "Änderung des Bescheids",
        court: "Weiterleitung an Gericht",
      },
    },
    appealRevoked: {
      label: "Einspruch zurückgenommen am",
    },
    trialWanted: {
      label: "Gerichtsverfahren gewünscht?",
      values: {
        yes: "Ja",
        no: "Nein",
      },
    },
  },
  mobile: {
    vehicle: {
      label: "Art des Fahrzeugs",
      values: {
        car: "PKW",
        bike: "Fahrrad",
        motorcycle: "Motorrad",
        truck: "LKW",
        bus: "Bus",
      },
    },
  },
  redLight: {
    roadUsersEndangered: {
      label: "Verkehrsteilnehmer gefährdet",
      values: {
        yes: "Ja",
        no: "Nein",
      },
    },
    damage: {
      label: "Beschädigung",
      values: {
        yes: "Ja",
        no: "Nein",
        unknown: "Bin mir nicht sicher",
      },
    },
    redLight: {
      label: "Dauer Rotphase",
      values: {
        under_1_sec: "Unter 1 Sekunde",
        "1_sec_or_more": "1 Sekunde oder länger",
        unknown: "Bin mir nicht sicher",
      },
    },
  },
  distance: {
    speed: {
      label: "Geschwindigkeit",
      values: {
        "<81": "Unter 81 km/h",
        "81-100": "81 - 100 km/h",
        "101-130": "101 - 130 km/h",
        ">130": "Über 130 km/h",
        unknown: "Bin mir nicht sicher",
      },
    },
    distance: {
      label: "Abstand",
      values: {
        "5/10": "5/10 des halben Tachowerts",
        "4/10": "4/10 des halben Tachowerts",
        "3/10": "3/10 des halben Tachowerts",
        "2/10": "2/10 des halben Tachowerts",
        "1/10": "1/10 des halben Tachowerts",
        unknown: "Bin mir nicht sicher",
      },
    },
  },
  speeding: {
    locality: {
      label: "Wo unterwegs?",
      values: {
        in_town: "Innerorts",
        out_of_town: "Außerorts",
        unknown: "Bin mir nicht sicher",
      },
    },
    speeding: {
      label: "Wie viel zu schnell?",
      values: {
        "0-20": "0 - 20 km/h",
        "21-30": "21 - 30 km/h",
        "31-40": "31 - 40 km/h",
        ">40": "> 40 km/h",
        "41-50": "41- 50 km/h",
        "51-60": "51- 60 km/h",
        "61-70": "61- 70 km/h",
        ">70": "> 70 km/h",
        unknown: "Bin mir nicht sicher",
      },
    },
  },
  client: personTranslations,
  petitioner: {
    gender: {
      label: "Anrede",
      values: {
        male: "Herr",
        female: "Frau",
      },
    },
    givenName: {
      label: "Vorname",
    },
    familyName: {
      label: "Nachname",
    },
    nationality: {
      label: "Staatsangehörigkeit",
    },
    birthDate: {
      label: "Geburtsdatum",
    },
    residenceAddress: {
      streetAddress: {
        label: "Straße und Hausnummer",
      },
      postalCode: {
        label: "PLZ",
      },
      addressLocality: {
        label: "Ort",
      },
    },
    addresses: [
      {
        streetAddress: {
          label: "Straße und Hausnummer",
        },
        postalCode: {
          label: "PLZ",
        },
        addressLocality: {
          label: "Ort",
        },
      },
    ],
  },
  requestDocumentText: {
    label: "Dokumente anfordern E-Mail-Text",
  },
  fineNotice: {
    label: "Bußgeldbescheid",
  },
};

export default trafficTranslations;
