import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import ButtonLoading from "../../Button/ButtonLoading";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { createResource } from "../../../store/generic/actions";
import { useDispatch } from "react-redux";

function CourtAppointmentModal({ product, open, handleClose, refreshPage }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  if (!product) {
    return null;
  }

  const createCourtAppointMent = () => {
    setIsLoading(true);
    dispatch(createResource("/court_appointments", { caseId: product.id })).then(() => {
      setIsLoading(false);
      refreshPage();
    });
  };

  return (
    <LegalbirdIoModal
      handleClose={handleClose}
      open={open}
      title={"Termin ohne Ladung erstellen"}
      submitButton={
        <ButtonLoading onClick={createCourtAppointMent} color="primary" variant={"contained"} isLoading={isLoading}>
          Termin ohne Ladung erstellen
        </ButtonLoading>
      }
    >
      <p className={"center"}>
        Sollte für den Gerichtstermin keine Ladung vorliegen oder muss der Termin kurzfristig ausgeschrieben werden, so
        kann das Terminfenster hier aktiviert werden.
        <br />
        Bitte beachten: Eine nachträglich hochgeladene Ladung kann diesem Termin nicht zugeordnet werden!
      </p>
    </LegalbirdIoModal>
  );
}

CourtAppointmentModal.propTypes = {
  product: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  refreshPage: PropTypes.func,
};

CourtAppointmentModal.defaultProps = {
  refreshPage: () => {},
};
export default CourtAppointmentModal;
