import React from "react";
import { Box, Hidden } from "@mui/material";
import Grid from "@mui/material/Grid";
import CircleIcon from "../../CircleIcon/CircleIcon";
import { getMediaObjectSize } from "../../../services/formServiceFunctions";
import MediaObjectChips from "../../MediaObjectSection/MediaObjectChips";
import { getIcon } from "../../../services/mediaObjectService";
import { HistoryLog } from "../../../types/HistoryLog";
import moment from "moment";
import { getCreatedBy } from "../../../services/backofficeUserService";
import { useBackofficeUser } from "../../../provider/BackofficeUserProvider";

type CaseHistoryMediaObjectProps = {
  historyLog: HistoryLog;
  type?: string;
};

export default function CaseHistoryMediaObject({ historyLog, type = "mediaObject" }: CaseHistoryMediaObjectProps) {
  const { backofficeUsers } = useBackofficeUser();

  if (!historyLog.mediaObject) {
    return null;
  }

  const mediaObject = JSON.parse(historyLog.mediaObject);

  const logDate = moment(historyLog.logDate);

  const getTextDecorationByType = (type: string) => {
    switch (type) {
      case "mediaObjectDelete":
        return "line-through";
      case "mediaObject":
      default:
        return "none";
    }
  };

  const InfoText = ({ type }: { type: string }) => {
    switch (type) {
      case "mediaObjectDelete":
        return (
          <>
            Gelöscht durch {historyLog.backofficeUser?.person?.fullname || "Unbekannt"} am{" "}
            {logDate.format("DD.MM.YYYY")} um {logDate.format("HH:mm")} Uhr | {getMediaObjectSize(mediaObject.size)}
          </>
        );
      case "mediaObject":
      default:
        return (
          <>
            Hinzugefügt durch {getCreatedBy(mediaObject.createdBy, backofficeUsers)} am {logDate.format("DD.MM.YYYY")}{" "}
            um {logDate.format("HH:mm")} Uhr | {getMediaObjectSize(mediaObject.size)}
          </>
        );
    }
  };

  return (
    <>
      <Grid container wrap={"nowrap"}>
        <Hidden only={"xs"}>
          <Grid item sx={{ paddingRight: "2rem" }}>
            <CircleIcon icon={getIcon(mediaObject)} />
          </Grid>
        </Hidden>
        <Grid item sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={12}>
              <Box
                sx={{
                  fontSize: "1rem",
                  textDecoration: getTextDecorationByType(type),
                }}
              >
                {mediaObject.originalName}
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ fontSize: "1rem" }}>
              {mediaObject.description}
            </Grid>
            <Grid item sx={{ paddingTop: 1 }}>
              <InfoText type={type} />
            </Grid>
            <Grid item xs={12}>
              <MediaObjectChips mediaObject={mediaObject} showFolder />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
