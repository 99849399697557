import React, { useState } from "react";
import { FormControl, Grid, MenuItem, Select } from "@mui/material";
import useStyles from "./fineCalculatorStyles";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";

const FineCalculator = () => {
  const classes = useStyles();

  const [selectedCalculator, setSelectedCalculator] = useState("");
  const [selectedSpeedingLocality, setSelectedSpeedingLocality] = useState("");
  const [selectedSpeedingSpeed, setSelectedSpeedingSpeed] = useState("");
  const [selectedDistanceSpeed, setSelectedDistanceSpeed] = useState("");
  const [selectedDistanceSpeedometer, setSelectedDistanceSpeedometer] = useState("");
  const [selectedTrafficLightTime, setSelectedTrafficLightTime] = useState("");
  const [selectedTrafficLightDamage, setSelectedTrafficLightDamage] = useState("");
  const [selectedTrafficLightDanger, setSelectedTrafficLightDanger] = useState("");
  const [selectedProbationTime, setSelectedProbationTime] = useState("");

  const fines = {
    speeding: {
      in_town: {
        s0_10: [30, 0, 0, true],
        s11_15: [50, 0, 0, true],
        s16_20: [70, 0, 0, true],
        s21_25: [115, 1, 0, true],
        s26_30: [180, 1, 1, true],
        s31_40: [260, 2, 1, true],
        s41_50: [400, 2, 1, true],
        s51_60: [560, 2, 2, true],
        s61_70: [700, 2, 3, true],
        sOver70: [800, 2, 3, true],
      },
      out_of_town: {
        s0_10: [20, 0, 0, true],
        s11_15: [40, 0, 0, true],
        s16_20: [60, 0, 0, true],
        s21_25: [100, 1, 0, true],
        s26_30: [150, 1, 1, true],
        s31_40: [200, 1, 1, true],
        s41_50: [320, 2, 1, true],
        s51_60: [480, 2, 1, true],
        s61_70: [600, 2, 2, true],
        sOver70: [700, 2, 3, true],
      },
    },
    distance: {
      dBelow81: {
        d5_10: [35, 0, 0, false],
        d4_10: [35, 0, 0, false],
        d3_10: [35, 0, 0, false],
        d2_10: [35, 0, 0, false],
        d1_10: [35, 0, 0, false],
      },
      d81_100: {
        d5_10: [75, 1, 0, true],
        d4_10: [100, 1, 0, true],
        d3_10: [160, 1, 0, true],
        d2_10: [240, 1, 0, true],
        d1_10: [320, 1, 0, true],
      },
      d101_130: {
        d5_10: [75, 1, 0, true],
        d4_10: [100, 1, 0, true],
        d3_10: [160, 2, 1, true],
        d2_10: [240, 2, 2, true],
        d1_10: [320, 2, 3, true],
      },
      dOver130: {
        d5_10: [100, 1, 0, true],
        d4_10: [180, 1, 0, true],
        d3_10: [240, 2, 1, true],
        d2_10: [320, 2, 2, true],
        d1_10: [400, 2, 3, true],
      },
    },
    trafficLight: {
      under_1_sec: {
        none: [90, 1, 0, true],
        danger: [200, 2, 1, true],
        damage: [240, 2, 1, true],
      },
      over_1_sec: {
        none: [200, 2, 1, true],
        danger: [320, 2, 1, true],
        damage: [360, 2, 1, true],
      },
    },
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;
    switch (event.target.name) {
      case "selectedCalculator":
        resetCalculator();
        setSelectedCalculator(value);
        break;
      case "selectedSpeedingLocality":
        setSelectedSpeedingLocality(value);
        break;
      case "selectedSpeedingSpeed":
        setSelectedSpeedingSpeed(value);
        break;
      case "selectedDistanceSpeed":
        setSelectedDistanceSpeed(value);
        break;
      case "selectedDistanceSpeedometer":
        setSelectedDistanceSpeedometer(value);
        break;
      case "selectedTrafficLightTime":
        setSelectedTrafficLightTime(value);
        break;
      case "selectedTrafficLightDamage":
        setSelectedTrafficLightDamage(value);
        break;
      case "selectedTrafficLightDanger":
        setSelectedTrafficLightDanger(value);
        break;
      case "selectedProbationTime":
        setSelectedProbationTime(value);
        break;
      default:
        break;
    }
  };

  const resetCalculator = () => {
    setSelectedCalculator("");
    setSelectedSpeedingLocality("");
    setSelectedSpeedingSpeed("");
    setSelectedDistanceSpeed("");
    setSelectedDistanceSpeedometer("");
    setSelectedTrafficLightTime("");
    setSelectedTrafficLightDamage("");
    setSelectedTrafficLightDanger("");
    setSelectedProbationTime("");
  };

  const renderInputs = (calculator) => {
    switch (calculator) {
      case "speeding":
        return renderSpeedingInputs();
      case "distance":
        return renderDistanceInputs();
      case "red_light":
        return renderTrafficLightInputs();
      default:
        return null;
    }
  };

  const renderDistanceInputs = () => {
    return (
      <>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Geschwindigkeit</InputLabel>
            <Select
              label="Geschwindigkeit"
              value={selectedDistanceSpeed}
              onChange={handleSelectChange}
              name={"selectedDistanceSpeed"}
            >
              <MenuItem value={"dBelow81"}>Weniger als 81 km/h</MenuItem>
              <MenuItem value={"d81_100"}>81-100 km/h</MenuItem>
              <MenuItem value={"d101_130"}>101-130 km/h</MenuItem>
              <MenuItem value={"dOver130"}>Mehr als 130 km/h</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Abstand</InputLabel>
            <Select
              label={"Abstand"}
              value={selectedDistanceSpeedometer}
              onChange={handleSelectChange}
              name={"selectedDistanceSpeedometer"}
            >
              <MenuItem value={"d5_10"}>5/10 des halben Tachowerts</MenuItem>
              <MenuItem value={"d4_10"}>4/10 des halben Tachowerts</MenuItem>
              <MenuItem value={"d3_10"}>3/10 des halben Tachowerts</MenuItem>
              <MenuItem value={"d2_10"}>2/10 des halben Tachowerts</MenuItem>
              <MenuItem value={"d1_10"}>1/10 des halben Tachowerts</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </>
    );
  };

  const renderSpeedingInputs = () => {
    return (
      <>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Ort</InputLabel>
            <Select
              label="Ort"
              value={selectedSpeedingLocality}
              onChange={handleSelectChange}
              name={"selectedSpeedingLocality"}
            >
              <MenuItem value={"in_town"}>Innerorts</MenuItem>
              <MenuItem value={"out_of_town"}>Außerorts</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Geschwindigkeit über dem Limit</InputLabel>
            <Select
              label="Geschwindigkeit über dem Limit"
              value={selectedSpeedingSpeed}
              onChange={handleSelectChange}
              name={"selectedSpeedingSpeed"}
            >
              <MenuItem value={"s0_10"}>0-10 km/h</MenuItem>
              <MenuItem value={"s11_15"}>11-15 km/h</MenuItem>
              <MenuItem value={"s16_20"}>16-20 km/h</MenuItem>
              <MenuItem value={"s21_25"}>21-25 km/h</MenuItem>
              <MenuItem value={"s26_30"}>26-30 km/h</MenuItem>
              <MenuItem value={"s31_40"}>31-40 km/h</MenuItem>
              <MenuItem value={"s41_50"}>41-50 km/h</MenuItem>
              <MenuItem value={"s51_60"}>51-60 km/h</MenuItem>
              <MenuItem value={"s61_70"}>61-70 km/h</MenuItem>
              <MenuItem value={"sOver70"}>Mehr als 70 km/h</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </>
    );
  };

  const renderTrafficLightInputs = () => {
    return (
      <>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Dauer Rotphase</InputLabel>
            <Select
              label="Dauer Rotphase"
              value={selectedTrafficLightTime}
              onChange={handleSelectChange}
              name={"selectedTrafficLightTime"}
            >
              <MenuItem value={"under_1_sec"}>Unter einer Sekunde</MenuItem>
              <MenuItem value={"over_1_sec"}>Eine Sekunde oder mehr</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Verkehrsteilnehmer gefährdet</InputLabel>
            <Select
              label={"Verkehrsteilnehmer gefährdet"}
              value={selectedTrafficLightDanger}
              onChange={handleSelectChange}
              name={"selectedTrafficLightDanger"}
            >
              <MenuItem value={"yes"}>Ja</MenuItem>
              <MenuItem value={"no"}>Nein</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Schaden verursacht</InputLabel>
            <Select
              label={"Schaden verursacht"}
              value={selectedTrafficLightDamage}
              onChange={handleSelectChange}
              name={"selectedTrafficLightDamage"}
            >
              <MenuItem value={"yes"}>Ja</MenuItem>
              <MenuItem value={"no"}>Nein</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </>
    );
  };

  const renderProbationPart = () => {
    if (
      (selectedCalculator === "speeding" && selectedSpeedingSpeed !== "s0_20") ||
      (selectedCalculator === "distance" && selectedDistanceSpeed !== "dBelow81") ||
      selectedCalculator === "red_light"
    ) {
      return "Achtung, Sie sind in der Probezeit! Dies erhöht die Strafe deutlich! Ihnen droht, zusätzlich zu Bußgeld und Punkten, die Verlängerung der Probezeit und ein Aufbauseminar. Sollte dies bereits der zweite Verstoß in der Probezeit sein, droht Ihnen sogar der Entzug der Fahrerlaubnis!";
    }
  };

  const renderResult = () => {
    switch (selectedCalculator) {
      case "speeding":
        if (selectedSpeedingLocality && selectedSpeedingSpeed) {
          return renderFineResult(fines.speeding[selectedSpeedingLocality][selectedSpeedingSpeed]);
        }
        break;
      case "distance":
        if (selectedDistanceSpeed && selectedDistanceSpeedometer) {
          return renderFineResult(fines.distance[selectedDistanceSpeed][selectedDistanceSpeedometer]);
        }
        break;
      case "red_light":
        if (selectedTrafficLightTime) {
          if (selectedTrafficLightDamage === "yes" && selectedTrafficLightDanger === "no") {
            return renderFineResult(fines.trafficLight[selectedTrafficLightTime]["damage"]);
          } else if (selectedTrafficLightDanger === "yes" && selectedTrafficLightDamage === "no") {
            return renderFineResult(fines.trafficLight[selectedTrafficLightTime]["danger"]);
          } else if (selectedTrafficLightDanger === "no" && selectedTrafficLightDamage === "no") {
            return renderFineResult(fines.trafficLight[selectedTrafficLightTime]["none"]);
          } else if (selectedTrafficLightDamage === "yes" && selectedTrafficLightDanger === "yes") {
            return renderFineResult(fines.trafficLight[selectedTrafficLightTime]["damage"]);
          }
        }
        break;
      default:
        return "Bitte wählen Sie einen Vorwurf aus";
    }
    return "Bitte füllen Sie alle Felder aus";
  };

  const renderFineResult = (fineResult) => {
    return (
      <Grid container className={classes.centered} spacing={3}>
        <Grid item>
          <Typography variant={"h2"} sx={{mt: 1, mb: 1}}>Ergebnis:</Typography>
          Bußgeld: {fineResult[0]} €<br />
          Punkte: {fineResult[1]}
          <br />
          Fahrverbot in Monaten: {fineResult[2]}
          <br />
          Ergebnis ist genau: {fineResult[3] === true ? "Ja" : "Nein"} <br />
        </Grid>
        <Grid item>{selectedProbationTime === "<2" && renderProbationPart()}</Grid>
      </Grid>
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel>Vorwurf</InputLabel>
          <Select label="Vorwurf" value={selectedCalculator} onChange={handleSelectChange} name={"selectedCalculator"}>
            <MenuItem value={"distance"}>Abstandsverstoß</MenuItem>
            <MenuItem value={"speeding"}>Geschwindigkeitsverstoß</MenuItem>
            <MenuItem value={"red_light"}>Rotlichtverstoß</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel>Führerscheinbesitz</InputLabel>
          <Select
            label="Führerscheinbesitz"
            value={selectedProbationTime}
            onChange={handleSelectChange}
            name={"selectedProbationTime"}
          >
            <MenuItem value={"<2"}>Kürzer als 2 Jahre (Probezeit)</MenuItem>
            <MenuItem value={"2-10"}>2-10 Jahre</MenuItem>
            <MenuItem value={">10"}>Über 10 Jahre</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {renderInputs(selectedCalculator)}
      <Grid item xs={12}>
        {renderResult()}
      </Grid>
    </Grid>
  );
};

export default FineCalculator;
