import React, { useState } from "react";
import * as PropTypes from "prop-types";
import useStyles from "./caseHistoryFilterStyle";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import moment from "moment";
import DateRangePicker from "../../DateRangePicker/DateRangePicker";
import { ButtonGroup } from "@mui/material";
import _ from "lodash";

const CaseHistoryFilter = ({ filter, handleFilterChange }) => {
  const classes = useStyles({});
  const [dateRangePickerOpen, setDateRangePickerOpen] = useState(false);

  const buttons = [
    {
      label: "Alles",
      filterName: "all",
    },
    {
      label: "Aktivitäten",
      filterName: "activity",
    },
    {
      label: "Notizen",
      filterName: "note",
    },
    {
      label: "E-Mails",
      filterName: "emailMessage",
    },
    {
      label: "Dokumente",
      filterName: "mediaObject",
    },
    {
      label: "Änderungen",
      filterName: "changes",
    },
    {
      label: "Aufgaben",
      filterName: "paidTask",
    },
  ];

  return (
    <Grid container justifyContent="space-between">
      <ButtonGroup color="primary">
        {_.map(buttons, (button) => (
          <Button
            key={button.filterName}
            variant={filter === button.filterName ? "contained" : "outlined"}
            onClick={() => handleFilterChange(button.filterName)}
          >
            {button.label}
          </Button>
        ))}
      </ButtonGroup>
      <Button
        className={classes.timespanButton}
        variant={typeof filter === "object" ? "contained" : "text"}
        onClick={() => setDateRangePickerOpen(true)}
      >
        {typeof filter === "object"
          ? moment(filter.startDate).format("DD.MM.YY") + " - " + moment(filter.endDate).format("DD.MM.YY")
          : "Zeitraum auswählen"}
      </Button>
      <DateRangePicker
        setDateRange={handleFilterChange}
        open={dateRangePickerOpen}
        closeModal={() => setDateRangePickerOpen(false)}
      />
    </Grid>
  );
};

CaseHistoryFilter.propTypes = {
  filter: PropTypes.any.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
};

export default CaseHistoryFilter;