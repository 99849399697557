import makeStyles from '@mui/styles/makeStyles';
import { scrollToAnchor } from "../../theme/commonStyles";

const useStyles = makeStyles(() => ({
  container: {
    padding: "2rem",
    marginTop: "2rem",
    width: "100%",
  },
  eFileButton: {
    width: "6.5rem",
    display: "block",
    margin: "0 0 0 auto",
  },
  headline: {
    textAlign: "center",
    fontSize: "1.25rem",
  },
  mediaObjectContainer: {
    marginBottom: "2rem",
  },
  faded: {
    textAlign: "center",
    color: "#dcdcdc",
    fontSize: "1.25rem",
    margin: "2rem",
  },
  button: {
    margin: "0 auto",
  },
  headlineContainer: {
    marginBottom: "1rem",
  },
  scrollToAnchor: {
    ...scrollToAnchor,
  },
}));

export default useStyles;
