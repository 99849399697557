import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBackofficeUser } from "../../store/backofficeUser/reducer";
import makeStyles from "@mui/styles/makeStyles";
import { Navigate, useLocation } from "react-router-dom";
import useForm from "../../hooks/useForm";
import { login } from "../../store/backofficeUser/actions";
import Paper from "@mui/material/Paper";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ButtonLoading from "../Button/ButtonLoading";
import Typography from "@mui/material/Typography";
import loginStyle from "./loginStyle";
import _ from "lodash";
import Snackbar from "../Snackbar/Snackbar";
import { useSnackbar } from "notistack";
import { emailDefault, requiredFieldDefault } from "../../services/validationRules";
import ValidatorTextField from "../Validator/ValidatorTextField";
import { Box, Button, Link } from "@mui/material";
import { LEGALBIRD_BASE_URL } from "../../config/_entrypoint";
import PasswordRecoveryModal from "../PasswordRecoveryModal/PasswordRecoveryModal";

const useStyles = makeStyles(loginStyle);

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [recoverPasswordModalOpen, setRecoverPasswordModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const backofficeUser = useSelector((state) => getBackofficeUser(state));
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const location = useLocation();
  const classes = useStyles();

  const initialValues = {
    email: "",
    password: "",
  };

  const onSubmit = async ({ values }) => {
    setIsLoading(true);
    dispatch(login(values)).then(
      () => {},
      (error) => {
        enqueueSnackbar("", {
          content: () => (
            <div>
              <Snackbar
                message={
                  "Für diese Kombination aus E-Mail-Adresse und Passwort konnten keine Daten gefunden werden. Bitte überprüfen Sie Ihre eingegebenen Daten."
                }
                isNonInteractive={true}
              />
            </div>
          ),
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          autoHideDuration: 3000,
        });
        clearForm();
        setIsLoading(false);
      }
    );
  };

  const { values, handleChange, handleSubmit, clearForm } = useForm({
    initialValues,
    onSubmit,
    hasRedirectOnSubmit: true,
  });

  const getTargetLocation = () => {
    let target = _.get(location, "state.from.pathname", "/");
    return target === "/services/login" ? "/" : target;
  };

  if (!!backofficeUser) {
    return <Navigate to={getTargetLocation()} />;
  }

  return (
    <Paper className={classes.loginBox}>
      <div className={classes.title}>
        <Typography color={"primary"} variant={"h5"}>
          Anmelden
        </Typography>
      </div>
      <div className={classes.content}>
        <form onSubmit={handleSubmit} className={classes.form}>
          <ValidatorTextField
            name={"email"}
            label={"E-Mail"}
            type={"email"}
            value={values.email}
            validators={[...requiredFieldDefault, ...emailDefault]}
            onChange={handleChange}
          />
          <ValidatorTextField
            type={showPassword ? "text" : "password"}
            label="Passwort"
            name="password"
            validators={requiredFieldDefault}
            value={values.password}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box sx={{ textAlign: "right", marginBottom: 1 }}>
            <Link underline={"none"} sx={{ cursor: "pointer" }} onClick={() => setRecoverPasswordModalOpen(true)}>
              Passwort vergessen?
            </Link>
            <PasswordRecoveryModal
              recoverPasswordModalOpen={recoverPasswordModalOpen}
              setRecoverPasswordModalOpen={setRecoverPasswordModalOpen}
              email={values.email}
            />
          </Box>
          <ButtonLoading className={classes.submit} variant={"contained"} type={"submit"} isLoading={isLoading}>
            Einloggen
          </ButtonLoading>
          <Button component={Link} href={LEGALBIRD_BASE_URL + "/lp/partneranwalt-werden"} sx={{ marginTop: 2 }}>
            Partneranwalt werden
          </Button>
        </form>
      </div>
    </Paper>
  );
};

export default Login;
