import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import React, { useEffect, useState } from "react";
import { hasBookingEntry } from "../../../services/accountingService";
import { getCaseDeletionProhibitedStatus } from "../../../services/Product/ProductService";
import CheckingDeleteModal from "./DeleteCaseModals/CheckingDeleteModal";
import DeletionDeniedModal from "./DeleteCaseModals/DeletionDeniedModal";
import { useSelector } from "react-redux";
import { getBackofficeUser } from "../../../store/backofficeUser/reducer";
import { userHasRole } from "../../../services/backofficeUserService";
import AdminDeleteModal from "./DeleteCaseModals/AdminDeleteModal";
import ButtonLoading from "../../Button/ButtonLoading";
import ApiClient from "../../../services/ApiClient";

function DeleteCaseModal({ product, open, handleClose, refreshPage }) {
  const [isLoading, setIsLoading] = useState(false);
  const [hasBookingEntries, setHasBookingEntries] = useState(null);
  const [statusProceededToFarOpen, setStatusProceededToFarOpen] = useState(null);
  const currentUser = useSelector((state) => getBackofficeUser(state));

  useEffect(() => {
    if (!open || statusProceededToFarOpen !== null) {
      return;
    }
    hasBookingEntry(product).then((res) => {
      setHasBookingEntries(res);
    });
    setStatusProceededToFarOpen(product.leadStatus >= getCaseDeletionProhibitedStatus(product));
  }, [open, product]);

  if (hasBookingEntries === null || statusProceededToFarOpen === null) {
    return <CheckingDeleteModal open={open} handleClose={handleClose} />;
  }

  if (userHasRole(currentUser, "ROLE_ADMIN")) {
    return (
      <AdminDeleteModal
        hasBookingEntries={hasBookingEntries}
        statusProceededToFarOpen={statusProceededToFarOpen}
        product={product}
        open={open}
        handleClose={handleClose}
        refreshPage={refreshPage}
      />
    );
  }

  if (hasBookingEntries === true || statusProceededToFarOpen === true) {
    return <DeletionDeniedModal open={open} handleClose={handleClose} />;
  }

  const deleteCase = async () => {
    setIsLoading(true);
    await ApiClient.delete("backoffice_cases/" + product.backofficeCase.id);
    refreshPage();
    handleClose();
  };

  return (
    <LegalbirdIoModal
      handleClose={handleClose}
      open={open}
      title={"Alle Daten zum Fall löschen?"}
      submitButton={
        <ButtonLoading isLoading={isLoading} onClick={deleteCase} color="primary" variant={"contained"}>
          Fall löschen
        </ButtonLoading>
      }
    >
      <p className={"center"}>Sind Sie sicher, dass alle Daten zu diesem Fall gelöscht werden sollen?</p>
    </LegalbirdIoModal>
  );
}
export default DeleteCaseModal;
