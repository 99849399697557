import React from "react";
import * as PropTypes from "prop-types";
import { InputLabel, Select } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { updateResource } from "../../../store/generic/actions";
import Snackbar from "../../../components/Snackbar/Snackbar";
import useStyles from "./assignedUserStyle";
import { useBackofficeUser } from "../../../provider/BackofficeUserProvider";

const AssignedUser = ({ activity, updateActivities }) => {
  const { agents } = useBackofficeUser();
  const classes = useStyles({});

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const revertUserAssignment = () => {
    dispatch(
      updateResource(activity.id, "activities", {
        assignedUser: null,
      })
    );
    closeSnackbar();
  };

  const setBackofficeUser = ({ target }) => {
    dispatch(
      updateResource(activity.id, "activities", {
        assignedUser: target.value,
      })
    ).then(() => {
      updateActivities();
      enqueueSnackbar("", {
        content: () => (
          <div>
            <Snackbar buttonType={"function"} buttonFunction={() => revertUserAssignment()} />
          </div>
        ),
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    });
  };

  if (!agents || !activity) {
    return null;
  }

  return (
    <>
      {activity.assignedUser ? (
        activity.assignedUser.person.fullname
      ) : (
        <FormControl>
          <InputLabel id={"userSelect"} className={classes.smallFont}>
            Zu erledigen durch
          </InputLabel>
          <Select
            disableUnderline={true}
            className={classes.smallFont}
            onChange={setBackofficeUser}
            value={"not_assigned"}
          >
            <MenuItem value={"not_assigned"}>Nicht zugeordnet</MenuItem>
            {_.map(agents, (agent) => (
              <MenuItem key={agent.id} value={agent["@id"]}>
                {agent.person.fullname}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};

AssignedUser.propTypes = {
  activity: PropTypes.object.isRequired,
  updateActivities: PropTypes.func,
};

AssignedUser.defaultProps = {
  updateActivities: () => {},
};

export default AssignedUser;
