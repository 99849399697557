import React, { useState } from "react";
import * as PropTypes from "prop-types";
import useStyles from "./createNoteStyles";
import { Checkbox } from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { createResource, fetchCollection, updateResource } from "../../../store/generic/actions";
import FormControlLabel from "@mui/material/FormControlLabel";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import { editorStateToHTML, htmlToEditorState } from "../../../services/Editor/editorFunctions";

const EditNote = ({ open, closeDialog, product, note, isPinnable }) => {
  const classes = useStyles({});
  const [pinned, setPinned] = useState(!!note ? note.pinned : true);
  const [isWarning, setIsWarning] = useState(!!note ? !!note.isWarning : false);
  const [editorState, setEditorState] = useState(htmlToEditorState(!!note && note.content));
  const dispatch = useDispatch();

  const saveNote = async () => {
    if (note && note.id) {
      await dispatch(
        updateResource(note.id, "notes", {
          content: editorStateToHTML(editorState),
          pinned: pinned,
          isWarning: isWarning,
          case: product.backofficeCase["@id"],
        })
      );
      dispatch(
        fetchCollection("notes?case=" + product.backofficeCase["@id"] + "&pinned=true", "pinnedNotes" + product.id)
      );
      closeDialog();
      return;
    }

    await dispatch(
      createResource("notes", {
        content: editorStateToHTML(editorState),
        pinned: pinned,
        isWarning: isWarning,
        case: product.backofficeCase["@id"],
      })
    );
    dispatch(
      fetchCollection("notes?case=" + product.backofficeCase["@id"] + "&pinned=true", "pinnedNotes" + product.id)
    );
    internalCloseDialog();
  };

  const internalCloseDialog = () => {
    if (!note) {
      setPinned(true);
      setIsWarning(false);
      setEditorState(EditorState.createEmpty());
    }
    closeDialog();
  };

  const saveDisabled = !!note
    ? //convert note content and editor content to html produced by editorState so that we are able to compare both
      note.pinned === pinned &&
      note.isWarning === isWarning &&
      editorStateToHTML(editorState) === editorStateToHTML(htmlToEditorState(note.content))
    : !editorState.getCurrentContent().hasText();

  return (
    <LegalbirdIoModal
      handleClose={internalCloseDialog}
      open={open}
      disableBackdropClick
      title={"Notiz " + (note ? "bearbeiten" : "erstellen")}
      submitButton={
        <Button variant={"contained"} onClick={saveNote} disabled={saveDisabled}>
          Speichern
        </Button>
      }
    >
      <Grid container>
        <Grid item xs={12}>
          <Editor
            wrapperClassName={classes.editorWrapper}
            editorClassName={classes.editor}
            toolbar={{
              options: ["inline", "list", "history", "link"],
              inline: {
                options: ["bold", "italic", "underline"],
              },
              list: {
                options: ["unordered", "ordered"],
              },
            }}
            stripPastedStyles
            editorState={editorState}
            onEditorStateChange={setEditorState}
            localization={{ locale: "de" }}
          />
        </Grid>
        {isPinnable && (
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={pinned}
                  onChange={({ target }) => setPinned(target.checked)}
                  className={classes.checkbox}
                />
              }
              label="Notiz am Fall anheften"
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isWarning}
                onChange={({ target }) => setIsWarning(target.checked)}
                className={classes.checkbox}
              />
            }
            label="Als Warnung anzeigen"
          />
        </Grid>
      </Grid>
    </LegalbirdIoModal>
  );
};

EditNote.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  note: PropTypes.object,
  isPinnable: PropTypes.bool,
};

EditNote.defaultProps = {
  isPinnable: true,
};

export default EditNote;
