import customerTranslations from "./customerTranslations";
import abstractCaseTranslations from "./abstractCaseTranslations";
import { courtTranslations } from "./courtTranslations";
import personTranslations from "./personTranslations";

const divorceTranslations = {
  label: "Scheidung",
  ...abstractCaseTranslations,
  responsibleLawyer: {
    label: "Anwalt",
  },
  personalTextMessage: {
    label: "Fragen und Anmerkungen zum Fall",
  },
  customer: customerTranslations,
  insurance: {
    caseReferenceNumber: {
      label: "Schadennummer",
    },
    insurancePolicyNumber: {
      label: "Versicherungsnummer",
    },
    insurance: {
      label: "Versicherungsgesellschaft",
    },
  },
  leadStatus: {
    10: "Antrag erstellt",
    20: "Antrag ausgefüllt",
    30: "Vollmacht erteilt",
    75: "Gerichtsgebühr bezahlt",
  },
  responsibleCourt: courtTranslations,
  applicationSubmittedDate: {
    label: "Scheidung eingereicht am",
  },
  netIncomePetitioner: {
    label: "Nettoeinkommen",
  },
  netIncomePartner: {
    label: "Nettoeinkommen Partner",
  },
  reference: {
    label: "Unser Zeichen",
  },
  pensionsQuestions: {
    label: "Versorgungsausgleich Fragen",
    values: {
      true: "Ja",
      false: "Nein",
    },
  },
  shortMarriage: {
    label: "Ehe unter 3 Jahren",
    values: {
      true: "Ja",
      false: "Nein",
    },
  },
  processCostSupport: {
    label: "Verfahrenskostenhilfe beantragt",
    values: {
      true: "Ja",
      false: "Nein",
    },
  },
  powerOfAttorney: {
    label: "Vollmacht erteilt am",
  },
  referenceCourt: {
    label: "Aktenzeichen Gericht",
  },
  dateMarriage: {
    label: "Datum Heirat",
  },
  numberPensions: {
    label: "Anzahl Rentenversicherungen",
  },
  numberPensionsPartner: {
    label: "Anzahl Rentenversicherungen Partner",
  },
  cityMarriage: {
    label: "Ort der Heirat",
  },
  marriageRegistrationNumber: {
    label: "Heiratsregisternummer",
  },
  statusDivorce: {
    label: "Einvernehmliche Scheidung?",
    values: {
      mutuallyAgreed: "Einvernehmlich",
      unsure: "Ich bin mir nicht sicher",
      debatable: "Offene Streitpunkte",
    },
  },
  processCostSupportVerified: {
    label: "VKH geprüft am",
  },
  numberChildren: {
    label: "Anzahl gemeinsame Kinder",
    values: {
      0: "Keine gemeinsamen Kinder",
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      5: "5",
      6: "6",
    },
  },
  dateSeparation: {
    label: "Datum Trennung",
  },
  pensionsExcluded: {
    label: "Versorgungsausgleich ausgeschlossen",
    values: {
      true: "Ja",
      false: "Nein",
    },
  },
  movedOut: {
    label: "Aktuelle Wohnsituation",
    values: {
      petitioner: "Antragsteller ist ausgezogen",
      partner: "Ehepartner ist ausgezogen",
      nobody: "Keiner ist ausgezogen",
      both: "Beide sind ausgezogen",
    },
  },
  dateMovedOut: {
    label: "Datum des Auszugs",
  },
  children: [
    {
      name: {
        label: "Name Kind 1",
      },
      livesWith: {
        label: "Wohnort Kind 1",
        values: {
          petitioner: "Lebt beim Antragsteller",
          partner: "Lebt beim Ehepartner",
        },
      },
      birthCertificate: {
        label: "Geburtsurkunde Kind 1",
      },
    },
    {
      name: {
        label: "Name Kind 2",
      },
      livesWith: {
        label: "Wohnort Kind 2",
        values: {
          petitioner: "Lebt beim Antragsteller",
          partner: "Lebt beim Ehepartner",
        },
      },
      birthCertificate: {
        label: "Geburtsurkunde Kind 2",
      },
    },
    {
      name: {
        label: "Name Kind 3",
      },
      livesWith: {
        label: "Wohnort Kind 3",
        values: {
          petitioner: "Lebt beim Antragsteller",
          partner: "Lebt beim Ehepartner",
        },
      },
      birthCertificate: {
        label: "Geburtsurkunde Kind 3",
      },
    },
    {
      name: {
        label: "Name Kind 4",
      },
      livesWith: {
        label: "Wohnort Kind 4",
        values: {
          petitioner: "Lebt beim Antragsteller",
          partner: "Lebt beim Ehepartner",
        },
      },
      birthCertificate: {
        label: "Geburtsurkunde Kind 4",
      },
    },
    {
      name: {
        label: "Name Kind 5",
      },
      livesWith: {
        label: "Wohnort Kind 5",
        values: {
          petitioner: "Lebt beim Antragsteller",
          partner: "Lebt beim Ehepartner",
        },
      },
      birthCertificate: {
        label: "Geburtsurkunde Kind 5",
      },
    },
    {
      name: {
        label: "Name Kind 6",
      },
      livesWith: {
        label: "Wohnort Kind 6",
        values: {
          petitioner: "Lebt beim Antragsteller",
          partner: "Lebt beim Ehepartner",
        },
      },
      birthCertificate: {
        label: "Geburtsurkunde Kind 6",
      },
    },
    {
      name: {
        label: "Name Kind 7",
      },
      livesWith: {
        label: "Wohnort Kind 7",
        values: {
          petitioner: "Lebt beim Antragsteller",
          partner: "Lebt beim Ehepartner",
        },
      },
      birthCertificate: {
        label: "Geburtsurkunde Kind 7",
      },
    },
  ],
  costs: {
    installment: {
      label: "Ratenzahlung",
      values: {
        true: "Ja",
        false: "Nein",
      },
    },
    installmentRateOverdue: {
      label: "Rate überfällig",
      values: {
        true: "Ja",
        false: "Nein",
      },
    },
    installmentRateOverdueSince: {
      label: "Ratenzahlung überfällig seit",
    },
    courtFeeInvoiceReceivedDate: {
      label: "Rechnung Gerichtsgebühr erhalten am",
    },
    courtFeePaidDate: {
      label: "Gerichtsgebühr gezahlt am",
    },
    valueInDisputeReal: {
      label: "Finaler Streitwert",
    },
  },
  courtDate: {
    appointmentDate: {
      label: "Scheidungstermin Datum",
    },
    appointmentTime: {
      label: "Scheidungstermin Uhrzeit",
    },
  },
  commonAddress: {
    streetAddress: {
      label: "Gemeinsame Adresse Straße und Nr",
    },
    postalCode: {
      label: "Gemeinsame Adresse PLZ",
    },
    addressLocality: {
      label: "Gemeinsame Adresse Ort",
    },
  },
  client: personTranslations,
  petitioner: {
    gender: {
      label: "Anrede",
      values: {
        male: "Herr",
        female: "Frau",
      },
    },
    givenName: {
      label: "Vorname",
    },
    familyName: {
      label: "Nachname",
    },
    nationality: {
      label: "Staatsangehörigkeit",
    },
    residenceAddress: {
      streetAddress: {
        label: "Straße und Hausnummer",
      },
      postalCode: {
        label: "PLZ",
      },
      addressLocality: {
        label: "Ort",
      },
    },
    addresses: [
      {
        streetAddress: {
          label: "Straße und Hausnummer",
        },
        postalCode: {
          label: "PLZ",
        },
        addressLocality: {
          label: "Ort",
        },
      },
    ],
  },
  partner: {
    gender: {
      label: "Anrede Partner",
      values: {
        male: "Herr",
        female: "Frau",
      },
    },
    givenName: {
      label: "Vorname Partner",
    },
    familyName: {
      label: "Nachname Partner",
    },
    nationality: {
      label: "Staatsangehörigkeit Partner",
    },
    residenceAddress: {
      streetAddress: {
        label: "Straße und Hausnummer Partner",
      },
      postalCode: {
        label: "PLZ Partner",
      },
      addressLocality: {
        label: "Ort Partner",
      },
    },
    addresses: [
      {
        streetAddress: {
          label: "Straße und Hausnummer Partner",
        },
        postalCode: {
          label: "PLZ Partner",
        },
        addressLocality: {
          label: "Ort Partner",
        },
      },
    ],
  },
  requestDocumentText: {
    label: "Dokumente anfordern E-Mail-Text",
  },
  marriageCertificate: {
    label: "Heiratsurkunde",
  },
};

export default divorceTranslations;
