import React, { useState } from "react";
import * as PropTypes from "prop-types";
import useStyles from "./bankPaymentEntryRowStyle";
import { Checkbox, Grid, TableCell, TableRow } from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";
import { getCaseLink } from "../../services/Product/ProductService";
import { ALGOLIA_INDEX } from "../../config/_entrypoint";
import ApiClient from "../../services/ApiClient";
import _ from "lodash";
import ToolTipIconButton from "../Button/ToolTipIconButton";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { getBackofficeUser } from "../../store/backofficeUser/reducer";
import { userHasOneOfTheseRoles } from "../../services/backofficeUserService";
import { Roles } from "../../types/BackofficeUser";
import LegalbirdIoConfirm from "../Modal/LegalbirdIoConfirm";
import SearchBar from "../Algolia/SearchBar";
import CaseHit from "../Case/CaseEntityPicker/Hit/CaseHit";

const BankPaymentEntryRow = ({ bankPaymentEntry, refreshList }) => {
  const classes = useStyles({});

  const [currentPaymentEntry, setCurrentPaymentEntry] = useState(bankPaymentEntry);
  const [isBlocked, setIsBlocked] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const currentUser = useSelector((state) => getBackofficeUser(state));

  const onHitClick = async ({ product: productType, productId }, event) => {
    event.preventDefault();
    if (isBlocked) {
      return;
    }
    setIsBlocked(true);
    const currentEntry = await ApiClient.put(bankPaymentEntry["@id"], {
      body: JSON.stringify({
        productType: productType,
        productId: productId,
      }),
    });
    setCurrentPaymentEntry(currentEntry);
    setIsBlocked(false);
    refreshList();
  };

  const onCheckboxChange = async (event) => {
    if (isBlocked) {
      return;
    }
    const now = moment();
    setIsBlocked(true);
    const currentEntry = await ApiClient.put(bankPaymentEntry["@id"], {
      body: JSON.stringify({
        checkedDate: event.target.checked ? now : null,
      }),
    });
    setCurrentPaymentEntry(currentEntry);
    setIsBlocked(false);
    refreshList();
  };

  const handleDeleteBankPaymentEntry = async () => {
    await ApiClient.delete(bankPaymentEntry["@id"]);
    refreshList();
  };

  return (
    <TableRow>
      <TableCell className={classes.cellSmall}>
        {moment(currentPaymentEntry.bookingDate).format("DD.MM.YYYY")}
      </TableCell>
      <TableCell className={classes.cellSmall}>
        {_.has(currentPaymentEntry, "organization.abbreviation")
          ? currentPaymentEntry.organization.abbreviation
          : currentPaymentEntry.organization.abbreviation}
      </TableCell>
      <TableCell className={classes.cellSmall}>{_.capitalize(currentPaymentEntry.payer)}</TableCell>
      <TableCell className={classes.cellText}>{currentPaymentEntry.bookingText}</TableCell>
      <TableCell className={classes.cellSmall}>
        {currentPaymentEntry.amount.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
      </TableCell>
      <TableCell className={classes.cell}>
        {currentPaymentEntry.backofficeCase && (
          <Link
            className={classes.link}
            to={
              getCaseLink({ productClassName: currentPaymentEntry.productType, id: currentPaymentEntry.productId }) +
              "/konto"
            }
            target={"_blank"}
          >
            {currentPaymentEntry.backofficeCase.label || currentPaymentEntry.backofficeCase.lbProductId}
          </Link>
        )}
      </TableCell>
      <TableCell className={classes.cell}>
        <Grid container alignItems={"center"} justifyContent={"flex-start"}>
          <Grid item xs={2} className={classes.checkboxCell}>
            <Checkbox
              disabled={!currentPaymentEntry.productType || !currentPaymentEntry.productId}
              onChange={onCheckboxChange}
              checked={!!currentPaymentEntry.checkedDate}
            />
          </Grid>
          <Grid item xs={10}>
            <SearchBar
              searchConfig={{
                indexName: ALGOLIA_INDEX,
              }}
              resultListConfig={{
                itemSize: 270,
                width: "100%"
              }}
              HitComponent={CaseHit}
              onHitClick={onHitClick}
            />
          </Grid>
        </Grid>
      </TableCell>
      <TableCell className={classes.cellSmall}>
        <ToolTipIconButton
          disabled={!userHasOneOfTheseRoles(currentUser, [Roles.admin, Roles.accounting])}
          icon={DeleteOutlineOutlined}
          tooltip={"Zahlungseingang löschen"}
          onClick={() => setDeleteModalOpen(true)}
        />
        <LegalbirdIoConfirm
          handleClose={() => setDeleteModalOpen(false)}
          open={deleteModalOpen}
          content={"Mit Klick auf “Zahlungseingang löschen” wird der ausgewählte Eintrag aus der Liste gelöscht."}
          confirmText={"Zahlungseintrag löschen"}
          handleConfirm={handleDeleteBankPaymentEntry}
          headline={"Zahlungseingang löschen"}
        />
      </TableCell>
    </TableRow>
  );
};

BankPaymentEntryRow.propTypes = {
  bankPaymentEntry: PropTypes.object.isRequired
};

export default BankPaymentEntryRow;
