import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBackofficeUser } from "../../store/backofficeUser/reducer";
import PropTypes from "prop-types";
import { checkToken, loadToken } from "../../services/localStorage";
import { logout } from "../../store/backofficeUser/actions";

export default function RequireAuth({ children, redirectTo }) {
  const currentUser = useSelector((state) => getBackofficeUser(state));
  const dispatch = useDispatch();
  const location = useLocation();
  const isAuthenticated = checkToken(loadToken(), currentUser);
  useEffect(() => {
    !isAuthenticated && dispatch(logout())
  }, [isAuthenticated, dispatch])

  return isAuthenticated ? children : <Navigate to={redirectTo} state={{from: location}} />;
}

RequireAuth.propTypes = {
  redirectTo: PropTypes.string.isRequired,
};

RequireAuth.defaultProps = {
  redirectTo: "/login",
};