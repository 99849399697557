import {getServiceWorldCaseLink} from "./Product/ProductService";

const EXTERNAL_CALENDLY_URL = "https://calendly.com";

const getProductCalendlyLink = (product) => {
  return getServiceWorldCaseLink(product) + "/calendly"
};

export const buildCalendlyLinkLawyer = (product, isExternal = false) => {
  if (!product.backofficeCase.lawyer) {
    return (isExternal ? EXTERNAL_CALENDLY_URL : getProductCalendlyLink(product)) + "/legalbird-frank-riedel/beratungsgespraech?utm_source=" + encodeURI(btoa(product.backofficeCaseLb.id));
  }

  const lawyerSlug = product.backofficeCase.lawyer.person.givenName.replace(/\s/, '').toLowerCase() + "-" + product.backofficeCase.lawyer.person.familyName.replace(/\s/, '').toLowerCase();
  return (isExternal ? EXTERNAL_CALENDLY_URL : getProductCalendlyLink(product)) + `/legalbird-${lawyerSlug}/beratungsgespraech?utm_source=` + encodeURI(btoa(product.backofficeCaseLb.id));
};

export const buildCalendlyLink = (product, isExternal = false) => {
  let linkExtension = "/legalbird-frank-riedel/beratungsgespraech";
  switch (product.productClassName) {
    case "death":
      linkExtension = "/legalbird/erstberatung-todesfall-erbrecht";
      break;
    case "alimony":
      linkExtension = "/legalbird/erstberatung-unterhalt";
      break;
    case "familyLawCase":
      linkExtension = "/legalbird/erstberatung-familienrecht";
      break;
    case "divorce":
      linkExtension = "/legalbird/erstberatung-trennung-scheidung";
      break;
    case "shortTimeWork":
      linkExtension = "/legalbird/erstberatung-kurzarbeit";
      break;
    case "eventContract":
    case "membershipContract":
    case "otherContract":
    case "providerContract":
    case "purchaseContract":
    case "serviceContract":
    case "subscriptionContract":
    case "travelContract":
    case "vehicleContract":
      linkExtension = "/legalbird/erstberatung-vertragsrecht";
      break;
    default:
      return buildCalendlyLinkLawyer(product, isExternal);
  }
  return (isExternal ? EXTERNAL_CALENDLY_URL : getProductCalendlyLink(product)) + linkExtension + '?utm_source=' + encodeURI(btoa(product.backofficeCaseLb.id));
}
