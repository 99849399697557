import React, { useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import _ from "lodash";
import { BeaMessage } from "../../types/Bea/BeaMessage";
import { BeaAttachment } from "../../types/Bea/BeaAttachment";
import BeaMessagesTable from "./BeaMessagesTable";
import ContentBox from "../ContentBox/ContentBox";
import BeaMediaObjects from "./BeaMediaObjects";
import BeaCombinable from "./BeaCombinable";
import { Box, Button, Grid } from "@mui/material";
import ApiClient from "../../services/ApiClient";
import beaAttachmentsStyles from "./BeaAttachmentsStyles";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { PlusOneOutlined } from "@mui/icons-material";
import BeaDroppable from "./BeaDroppable";
import deleteIcon from "../../assets/icon/delete_outline_black_24dp.svg";
import { useSnackbar } from "notistack";
import Snackbar from "../Snackbar/Snackbar";

type BeaAttachmentsProps = {
  beaMessage: BeaMessage;
};

export default function BeaAttachments({ beaMessage }: BeaAttachmentsProps) {
  const [combinables, setCombinables] = useState<Array<Array<BeaAttachment>>>([
    _.filter(beaMessage.attachments, (beaAttachment) => !!beaAttachment.mergeable)
  ]);
  const [submitError, setSubmitError] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  if (_.includes(["merged", "processing"], beaMessage.mergeStatus)) {
    return <Navigate to={"/services/tools/bea"} />;
  }

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const sourceItems = [...combinables[parseInt(result.source.droppableId)]];
    const [currentItem] = sourceItems.splice(result.source.index, 1);
    const updatedCombinables = [...combinables];

    if (result.destination.droppableId !== "filesToIgnore") {
      let targetItems =
        result.source.droppableId === result.destination.droppableId
          ? sourceItems
          : [...combinables[parseInt(result.destination.droppableId)]];
      targetItems.splice(result.destination.index, 0, currentItem);
      updatedCombinables[parseInt(result.destination.droppableId)] = targetItems;
    }

    updatedCombinables[parseInt(result.source.droppableId)] = sourceItems;
    setCombinables(updatedCombinables);
  };

  const addCombinable = () => {
    setCombinables([...combinables, []]);
  };

  const removeCombinable = (index: number) => {
    let combinablesCopy = _.cloneDeep(combinables);
    combinablesCopy.splice(index, 1);
    setCombinables(combinablesCopy);
  };

  const splitCombinables = () => {
    let splitted: BeaAttachment[][] = [];

    _.forEach(combinables, (document) => {
      _.forEach(document, (beaAttachment) => splitted.push([beaAttachment]));
    });

    setCombinables(splitted);
  };

  const handleSubmit = async () => {
    setSubmitError("");

    if (_.some(combinables, (combinable) => combinable.length === 0)) {
      setSubmitError("Es darf keine leeren Dokumentenboxen geben. Zum Fortfahren die leeren Boxen bitte löschen");
      return;
    }

    const requestData = {
      beaMessage: beaMessage,
      mergeArray: _.map(combinables, (document) => {
        return _.map(document, (beaAttachment) => beaAttachment.mediaObjectId);
      }),
    };
    try {
      await ApiClient.post("lb/services/bea_merge", { body: JSON.stringify(requestData) });
      await ApiClient.put(beaMessage["@id"], {
        body: JSON.stringify({
          mergeStatus: "processing",
        }),
      });
      enqueueSnackbar("", {
        content: () => (
          <div>
            <Snackbar message={"Dokument wurde erfolgreich verarbeitet"} isNonInteractive />
          </div>
        ),
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
      navigate("/services/tools/bea");
    } catch (error) {
      alert("Es ist ein Fehler aufgetreten");
    }
  };

  return (
    <ContentBox>
      <BeaMessagesTable beaMessages={[beaMessage]} />
      <BeaMediaObjects beaMessage={beaMessage} />
      <Box>
        <Button fullWidth={false} onClick={() => splitCombinables()}>
          Dokumente aufteilen
        </Button>
      </Box>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Grid container alignItems="flex-start" spacing={2}>
          <Grid item xs={2}>
            <Box sx={{ height: 40, display: "flex", alignItems: "center" }}>
              <strong>Papierkorb</strong>
            </Box>
            <BeaDroppable combinable={[]} index={"filesToIgnore"} backgroundImage={deleteIcon} />
          </Grid>
          {_.map(combinables, (combinable, index) => (
            <Grid item xs={3} key={"box-" + index}>
              <BeaCombinable combinable={combinable} index={index} removeCombinable={removeCombinable} />
            </Grid>
          ))}
          <Grid item xs={2} onClick={() => addCombinable()} sx={{ cursor: "pointer" }}>
            <Box sx={{ height: 40, display: "flex", alignItems: "center" }}>
              <strong>Weiteres Dokument ergänzen</strong>
            </Box>
            <Box sx={placeHolderBoxStyle}>
              <PlusOneOutlined />
            </Box>
          </Grid>
        </Grid>
      </DragDropContext>
      <Box sx={{ textAlign: "center", color: "red" }}>{submitError}</Box>
      <Button sx={beaAttachmentsStyles.button} variant={"contained"} fullWidth={false} onClick={() => handleSubmit()}>
        Dokumente erstellen
      </Button>
      <Button sx={beaAttachmentsStyles.button} fullWidth={false} component={Link} to={"/services/tools/bea"}>
        Zurück
      </Button>
    </ContentBox>
  );
}

const placeHolderBoxStyle = {
  width: 200,
  height: 200,
  border: "1px solid #333333",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
