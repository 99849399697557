import React, { useEffect, useMemo } from "react";
import makeStyles from '@mui/styles/makeStyles';
import infoBarStyle from "./infoBarStyle";
import classNames from "classnames";
import { isCookieFetched, mercureFetchCookie, mercureSubscribe } from "../../services/mercure";
import { useDispatch, useSelector } from "react-redux";
import { getGeneric } from "../../store/generic/reducer";
import { fetchCollection } from "../../store/generic/actions";
import _ from "lodash";
import { GENERIC_RECEIVED } from "../../store/generic/actionTypes";
import { getBackofficeUser } from "../../store/backofficeUser/reducer";

const useStyles = makeStyles(infoBarStyle);

const InfoBar = () => {
  const classes = useStyles();
  const techStatus = useSelector((state) => getGeneric(state, "techStatus"));
  const currentUser = useSelector((state) => getBackofficeUser(state));
  const dispatch = useDispatch();

  const cookieFetched = isCookieFetched();
  useEffect(() => {
    if (cookieFetched) {
      const setNotification = (eventData) => {
        const newTechStatus = _.merge({}, techStatus, eventData);
        dispatch({ type: GENERIC_RECEIVED, name: "techStatus", value: newTechStatus });
      };

      const eventSource = mercureSubscribe(["/tech_status/error", "/tech_status/release"], setNotification);
      return () => {
        eventSource.close();
      };
    } else {
      mercureFetchCookie();
    }
  }, [cookieFetched, dispatch]);

  useEffect(() => {
    if (!techStatus) {
      dispatch(fetchCollection("notification/tech_status", "techStatus"));
    }
  }, [techStatus]);

  const showTechnicalError = useMemo(() => {
    if (!techStatus?.technicalError) {
      return false;
    }

    if (
      currentUser.isExternal && !techStatus.technicalError.showExternal ||
      !currentUser.isExternal && !techStatus.technicalError.showInternal
    ) {
      return false;
    }

    return (
      techStatus?.technicalError &&
      techStatus.technicalError.isActive
    );
  }, [techStatus?.technicalError]);

  if (!techStatus || !(showTechnicalError || techStatus.release)) {
    return null;
  }

  return (
    <div className={classNames(classes.container, classes[showTechnicalError ? "error" : "release"])}>
      <div className={classes.innerContainer}>
        {showTechnicalError && techStatus.technicalError.errorMessage}
        <br />
        {!!techStatus.release && <>Release läuft - Legalbird.io kann in wenigen Minuten wieder benutzt werden.</>}
      </div>
    </div>
  );
};

export default InfoBar;
