import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import ButtonLoading from "../../Button/ButtonLoading";
import React, { useCallback, useState } from "react";
import ApiClient from "../../../services/ApiClient";
import PropTypes from "prop-types";

function PrivatePayerModal({ product, open, handleClose, refreshPage }) {
  const [isLoading, setIsLoading] = useState(false);

  const setPaymentMethod = useCallback(async () => {
    setIsLoading(true);
    await ApiClient.put(`traffic_extendeds/${product.extended.id}`, {
      body: JSON.stringify({paymentType: "private"}),
    });
    setIsLoading(false);
    handleClose();
    refreshPage();
  }, [product]);

  return (
    <LegalbirdIoModal
      handleClose={handleClose}
      open={open}
      title="Zahlart auf Selbstzahler ändern"
      submitButton={
        <ButtonLoading onClick={setPaymentMethod} color="primary" variant={"contained"} isLoading={isLoading}>
          Zahlart ändern
        </ButtonLoading>
      }
    >
      <p className={"center"}>
        Sind Sie sicher, dass Sie die Zahlart auf “Selbstzahler” ändern möchten? Der Mandant erhält dann per E-Mail eine
        Rechnung und das Mandantenkonto wird entsprechend angepasst.
      </p>
    </LegalbirdIoModal>
  );
}

PrivatePayerModal.propTypes = {
  product: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  refreshPage: PropTypes.func,
};

PrivatePayerModal.defaultProps = {
  refreshPage: () => {},
};

export default PrivatePayerModal;
