import React from "react";
import * as trafficStages from "../../services/Stages/StagesTraffic";
import InvestigationAssessmentForm from "./InvestigationAssessmentForm";

const InvestigationAssessment = ({ product }) => {
  if (
    product.productClassName !== "traffic" ||
    product.stage < trafficStages.STAGE_ACCESS_TO_RECORDS ||
    product.extended.investigation === null
  ) {
    return null;
  }

  return <InvestigationAssessmentForm />;
};

export default InvestigationAssessment;
