import React from "react";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { HashLink as Link } from "react-router-hash-link";
import useStyles from "./generalActionsStyle";
import _ from "lodash";
import { useSelector } from "react-redux";
import { getBackofficeUser } from "../../store/backofficeUser/reducer";
import { getGeneralActionsByUser } from "../../services/navigationActionsService";

const GeneralActions = () => {
  const classes = useStyles({});
  const currentUser = useSelector((state) => getBackofficeUser(state));
  const actions = getGeneralActionsByUser(currentUser);

  return (
    <Paper className={classes.bottomNavigationContainer}>
      <BottomNavigation showLabels className={classes.navigation}>
        {_.map(actions, (action, index) => {
          if (action.isHidden) {
            return null;
          }
          const ActionIcon = action.icon;
          return (
            <BottomNavigationAction
              key={index}
              className={classes.navigationAction}
              label={<span className={classes.navigationActionLabel}>{action.text}</span>}
              component={Link}
              icon={<ActionIcon />}
              to={action.to}
            />
          );
        })}
      </BottomNavigation>
    </Paper>
  );
};

export default GeneralActions;
