import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getBackofficeUser } from "../../store/backofficeUser/reducer";
import _ from "lodash";
import { Navigate, useLocation } from "react-router-dom";
import React from "react";

export default function RequireRole({ children, allowedRoles, redirectTo }) {
  const currentUser = useSelector((state) => getBackofficeUser(state));
  const location = useLocation();
  if (!currentUser || _.intersection(currentUser.roles, allowedRoles).length === 0) {
    return <Navigate to={redirectTo} state={{from: location}} />;
  }
  return children;
}
RequireRole.propTypes = {
  allowedRoles: PropTypes.array.isRequired,
  redirectTo: PropTypes.string,
};
RequireRole.defaultProps = {
  redirectTo: "/login",
};
