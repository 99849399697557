import React from "react";
import * as PropTypes from "prop-types";
import _ from "lodash";
import Grid from "@mui/material/Grid";
import ValidatorSelect from "../Validator/ValidatorSelect";
import * as divorceStatus from "../../services/LeadStatus/StatusDivorce";
import * as settlementStatus from "../../services/LeadStatus/StatusSettlement";
import * as trafficStatus from "../../services/LeadStatus/StatusTraffic";
import * as documentStatus from "../../services/LeadStatus/StatusDocument";
import ButtonLoading from "../Button/ButtonLoading";
import MenuItem from "@mui/material/MenuItem";
import ApiClient from "../../services/ApiClient";
import Snackbar from "../Snackbar/Snackbar";
import { useSnackbar } from "notistack";
import useForm from "../../hooks/useForm";
import { formValue } from "../../services/formServiceFunctions";
import { fetchMediaObjects } from "../../hooks/useMediaObjects";
import { useDispatch } from "react-redux";
import * as caseStatus from "../../services/LeadStatus/StatusCase";
import { getCaseLink } from "../../services/Product/ProductService";
import ContentBox from "../ContentBox/ContentBox";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack } from "@mui/material";

const CaseFileGenerateForm = ({ product }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const generatableContractFiles = [
    {
      value: "powerOfAttorney",
      label: "Vollmacht",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_DATA_COMPLETED;
      },
    },
    {
      value: "powerOfAttorneyWithoutSignature",
      label: "Leere Vollmacht",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_DATA_COMPLETED;
      },
    },
    {
      value: "claimLetter",
      label: "Außergerichtliches Schreiben",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_DATA_COMPLETED;
      },
    },
    {
      value: "statementOfDefenseWithApplication",
      label: "Verteidigungsanzeige mit Antrag",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_LAWSUIT || !product.statementOfClaimFiledDate;
      },
    },
    {
      value: "statementOfDefenseWithApplicationNoDeadlineExtension",
      label: "Verteidigungsanzeige mit Antrag (ohne Fristverlängerung)",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_LAWSUIT || !product.statementOfClaimFiledDate;
      },
    },
    {
      value: "statementOfDefense",
      label: "Verteidigungsanzeige ohne Antrag",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_LAWSUIT || !product.statementOfClaimFiledDate;
      },
    },
    {
      value: "statementOfDefenseNoDeadlineExtension",
      label: "Verteidigungsanzeige ohne Antrag (ohne Fristverlängerung)",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_LAWSUIT || !product.statementOfClaimFiledDate;
      },
    },
    {
      value: "declarationOfCompletion",
      label: "Erledigungserklärung",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_LAWSUIT;
      },
    },
    {
      value: "abandonmentOfAction",
      label: "Klagerücknahme",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_LAWSUIT;
      },
    },
    {
      value: "extrajudicialDealineExtension",
      label: "Außergerichtliche Fristverlängerung",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_WAITING_FOR_FEEDBACK;
      },
    },
    {
      value: "assessmentOfCostsRequest",
      label: "Kostenfestsetzungsantrag",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_LAWSUIT_COMPLETED;
      },
    },
    {
      value: "enforcementInstrument",
      label: "Zwangsvollstreckung: Versand Titel an Kunden",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_DATA_COMPLETED;
      },
    },
    {
      value: "costCompensationRequest",
      label: "Kostenausgleichsantrag",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_LAWSUIT_COMPLETED;
      },
    },
    {
      value: "formalNoticeCostsRequest",
      label: "Zahlungsaufforderung aus KFB",
      isDisabled: (product) => {
        return (
          product.leadStatus < caseStatus.STATUS_LAWSUIT_COMPLETED ||
          product.costBearingByOpponentFinalAbsolute === null ||
          product.courtCostsFinal === null
        )
      },
    },
    {
      value: "formalPaymentNotice",
      label: "Zahlungsaufforderungsschreiben",
      isDisabled: (product) => {
        return (
          product.leadStatus < caseStatus.STATUS_LAWSUIT_COMPLETED ||
          !product.principalClaim ||
          !product.principalClaimDefaultDate ||
          !product.accessoryClaim ||
          !product.accessoryClaimDefaultDate
        );
      },
    },
    {
      value: "statusRequestCourt",
      label: "Sachstandsanfrage Gericht",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_LAWSUIT;
      },
    },
    {
      value: "briefing",
      label: "Briefing TV Gerichtstermin",
      isDisabled: (product) => {
        return product.leadStatus !== caseStatus.STATUS_TRIAL || product.courtAppointments?.length < 1;
      },
    },
    {
      value: "accessToInvestigationFile",
      label: "Akteneinsicht Ermittlungsakte",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_WAITING_FOR_FEEDBACK || !product.responsibleProsecution;
      },
    },
  ];

  const generatableFiles = {
    divorce: [
      {
        value: "divorceApplicationComplete",
        label: "Scheidungsantrag",
        isDisabled: (divorce) => {
          return divorce.leadStatus < divorceStatus.STATUS_CHECKED;
        },
      },
      {
        value: "divorceApplication",
        label: "Scheidungsantrag (ohne VKH Anhänge)",
        isDisabled: (divorce) => {
          return divorce.processCostSupport === false || divorce.leadStatus < divorceStatus.STATUS_CHECKED;
        },
      },
      {
        value: "installmentOffer",
        label: "Ratenzahlungsangebot",
        isDisabled: (divorce) => {
          return divorce.leadStatus < divorceStatus.STATUS_DATA_COMPLETED;
        },
      },
      {
        value: "installmentPlan",
        label: "Ratenzahlungsplan",
        isDisabled: (divorce) => {
          return divorce.leadStatus < divorceStatus.STATUS_DATA_COMPLETED;
        },
      },
      {
        value: "legalEffect",
        label: "Scheidungsbeschluss mit Rechtskraftvermerk",
        isDisabled: (divorce) => {
          return divorce.leadStatus < divorceStatus.STATUS_DIVORCE_DONE;
        },
      },
      {
        value: "powerOfAttorney",
        label: "Vollmacht",
        isDisabled: (divorce) => {
          return divorce.leadStatus < divorceStatus.STATUS_POWER_OF_ATTORNEY_GRANTED;
        },
      },
      {
        value: "powerOfAttorneyWithoutSignature",
        label: "Leere Vollmacht",
        isDisabled: (divorce) => {
          return divorce.leadStatus < divorceStatus.STATUS_POWER_OF_ATTORNEY_GRANTED;
        },
      },
      {
        value: "briefing",
        label: "Briefing TV Scheidungstermin",
        isDisabled: (divorce) => {
          return divorce.leadStatus < divorceStatus.STATUS_DATE_SET || divorce.advoAssistCourtDate === null;
        },
      },
      {
        value: "questionnairePensionAdjustment",
        label: "Übersendung Fragebogen Versorgungsausgleich",
        isDisabled: (divorce) => {
          return divorce.leadStatus < divorceStatus.STATUS_SUBMITTED;
        },
      },
      {
        value: "transmissionNotarisedAgreement",
        label: "Übersendung Notarvertrag",
        isDisabled: (divorce) => {
          return divorce.leadStatus < divorceStatus.STATUS_SUBMITTED;
        },
      },
      {
        value: "transmissionInquiredDocuments",
        label: "Übersendung geforderte Unterlagen",
        isDisabled: (divorce) => {
          return divorce.leadStatus < divorceStatus.STATUS_SUBMITTED;
        },
      },
      {
        value: "notificationMissingDocumentsPensionAdjustment",
        label: "Mitteilung fehlende Unterlagen Versorgungsausgleich",
        isDisabled: (divorce) => {
          return divorce.leadStatus < divorceStatus.STATUS_SUBMITTED;
        },
      },
      {
        value: "notificationNewAddress",
        label: "Mitteilung neue Anschrift Mandant",
        isDisabled: (divorce) => {
          return divorce.leadStatus < divorceStatus.STATUS_SUBMITTED;
        },
      },
      {
        value: "statusRequestCourt",
        label: "Sachstandsanfrage Gericht",
        isDisabled: (divorce) => {
          return divorce.leadStatus < divorceStatus.STATUS_SUBMITTED;
        },
      },
      {
        value: "requestDivorceDecision",
        label: "Anforderung Scheidungsbeschluss RKV",
        isDisabled: (divorce) => {
          return divorce.leadStatus < divorceStatus.STATUS_SUBMITTED;
        },
      },
    ],
    settlement: [
      {
        value: "dismissalProtectionSuitComplete",
        label: "Kündigungsschutzklage",
        isDisabled: (settlement) => {
          return settlement.leadStatus < settlementStatus.STATUS_CHECKED;
        },
      },
      {
        value: "powerOfAttorneySettlement",
        label: "Vollmacht",
        isDisabled: (settlement) => {
          return settlement.leadStatus < settlementStatus.STATUS_POWER_OF_ATTORNEY_GRANTED;
        },
      },
      {
        value: "powerOfAttorneySettlementWithoutSignature",
        label: "Leere Vollmacht",
        isDisabled: (settlement) => {
          return settlement.leadStatus < settlementStatus.STATUS_POWER_OF_ATTORNEY_GRANTED;
        },
      },
      {
        value: "briefing",
        label: "Briefing TV Gütetermin",
        isDisabled: (settlement) => {
          return (
            settlement.leadStatus < settlementStatus.STATUS_DATE_SET_SETTLEMENT ||
            settlement.extended.advoAssistSettlement === null
          );
        },
      },
      {
        value: "transmissionInquiredDocuments",
        label: "Übersendung geforderte Unterlagen",
        isDisabled: (settlement) => {
          return settlement.leadStatus < settlementStatus.STATUS_SUBMITTED;
        },
      },
      {
        value: "cancellationAppointmentComparison",
        label: "Aufhebung Termin wegen Vergleich",
        isDisabled: (settlement) => {
          return settlement.leadStatus < settlementStatus.STATUS_DATE_SET_SETTLEMENT;
        },
      },
      {
        value: "transmissionEnforceableComparison",
        label: "Übersendung vollstreckbarer Vergleich",
        isDisabled: (settlement) => {
          return settlement.leadStatus < settlementStatus.STATUS_SUBMITTED;
        },
      },
    ],
    traffic: [
      {
        value: "returnOfRecordsPdf",
        label: "Rücksendung Akte",
        isDisabled: (traffic) => {
          return (
            traffic.leadStatus < trafficStatus.STATUS_POWER_OF_ATTORNEY_GRANTED ||
            !traffic.extended.fineNoticeReference ||
            !traffic.extended.responsibleAuthority
          );
        },
      },
      {
        value: "entitlementLetterPdf",
        label: "Bestellungsschreiben",
        isDisabled: (traffic) => {
          return (
            traffic.leadStatus < trafficStatus.STATUS_POWER_OF_ATTORNEY_GRANTED ||
            !traffic.extended.responsibleAuthority
          );
        },
      },
      {
        value: "accessToRecordsMessage",
        label: "Einspruch/Akteneinsicht",
        isDisabled: (traffic) => {
          return traffic.leadStatus < trafficStatus.STATUS_CHECKED || !traffic.extended.responsibleAuthority;
        },
      },
      {
        value: "powerOfAttorneyTraffic",
        label: "Vollmacht",
        isDisabled: (traffic) => {
          return traffic.leadStatus < trafficStatus.STATUS_POWER_OF_ATTORNEY_GRANTED;
        },
      },
      {
        value: "powerOfAttorneyTrafficWithoutSignature",
        label: "Leere Vollmacht",
        isDisabled: (traffic) => {
          return traffic.leadStatus < trafficStatus.STATUS_POWER_OF_ATTORNEY_GRANTED;
        },
      },
      {
        value: "briefing",
        label: "Briefing TV Hauptverhandlung",
        isDisabled: (traffic) => {
          return traffic.leadStatus < trafficStatus.STATUS_TRIAL_DATE_SET || traffic.extended.advoAssistTrial === null;
        },
      },
      {
        value: "transmissionInquiredDocumentsAuthority",
        label: "Übersendung geforderte Unterlagen Behörde",
        isDisabled: (traffic) => {
          return !traffic.extended.responsibleAuthority;
        },
      },
      {
        value: "dealineExtension",
        label: "Fristverlängerung Einspruchsbegründung Behörde",
        isDisabled: (traffic) => {
          return traffic.leadStatus < trafficStatus.STATUS_CHECKED;
        },
      },
      {
        value: "transmissionInquiredDocumentsCourt",
        label: "Übersendung geforderte Unterlagen Gericht",
        isDisabled: (traffic) => {
          return traffic.leadStatus < trafficStatus.STATUS_TRIAL;
        },
      },
      {
        value: "appealWithdrawalAuthority",
        label: "Rückname Einspruch Behörde",
        isDisabled: (traffic) => {
          return traffic.leadStatus < trafficStatus.STATUS_CHECKED || !traffic.extended.responsibleAuthority;
        },
      },
      {
        value: "appealWithdrawalProsecution",
        label: "Rückname Einspruch Staatsanwaltschaft",
        isDisabled: (traffic) => {
          return (
            traffic.leadStatus < trafficStatus.STATUS_CHECKED ||
            !traffic.extended.responsibleProsecution ||
            !traffic.extended.prosecutionReference
          );
        },
      },
      {
        value: "appealWithdrawalCourt",
        label: "Rückname Einspruch Gericht",
        isDisabled: (traffic) => {
          return traffic.leadStatus < trafficStatus.STATUS_TRIAL;
        },
      },
      {
        value: "requestVideoAuthority",
        label: "Videoanfrage bei Behörde",
        isDisabled: (traffic) => {
          return traffic.leadStatus < trafficStatus.STATUS_ACCESS_TO_RECORDS;
        },
      },
      {
        value: "requestReferenceProsecution",
        label: "Anfrage Aktenzeichen Staatsanwaltschaft",
        isDisabled: (traffic) =>
          !traffic.extended.responsibleProsecution ||
          !traffic.extended.responsibleAuthority ||
          !traffic.extended.fineNoticeReference,
      },
    ],
    alimony: [
      {
        value: "alimony",
        label: "Unterhaltsanalyse",
        isDisabled: (alimony) => {
          return alimony.leadStatus < documentStatus.STATUS_COMPLETED;
        },
      },
    ],
    death: [
      {
        value: "deathAndInheritance",
        label: "Tod und Erbe",
        isDisabled: (death) => {
          return death.leadStatus < documentStatus.STATUS_COMPLETED;
        },
      },
    ],
    patientDecree: [
      {
        value: "patientDecree",
        label: "Patientenverfügung",
        isDisabled: (patientDecree) => {
          return patientDecree.leadStatus < documentStatus.STATUS_COMPLETED;
        },
      },
    ],
    powerOfAttorney: [
      {
        value: "powerOfAttorney",
        label: "Vorsorgevollmacht",
        isDisabled: (powerOfAttorney) => {
          return powerOfAttorney.leadStatus < documentStatus.STATUS_COMPLETED;
        },
      },
    ],
    shortTimeWork: [
      {
        value: "shortTimeWorkAnalysis",
        label: "Kurzarbeit Analyse",
        isDisabled: (shortTimeWork) => {
          return shortTimeWork.leadStatus < documentStatus.STATUS_COMPLETED;
        },
      },
    ],
    eventContract: generatableContractFiles,
    membershipContract: generatableContractFiles,
    otherContract: generatableContractFiles,
    providerContract: generatableContractFiles,
    purchaseContract: generatableContractFiles,
    serviceContract: generatableContractFiles,
    subscriptionContract: generatableContractFiles,
    travelContract: generatableContractFiles,
    vehicleContract: generatableContractFiles,
  };

  const onSubmit = async () => {
    const response = await ApiClient.post("files/generate/" + product.productClassName + "/" + product.id, {
      body: JSON.stringify({
        documentType: "pdf",
        documentIdentifier: values.fileType,
        sendEmailToCustomer: values.sendEmailToCustomer,
        showInGallery: values.showInGallery,
      }),
    });
    const messageText =
      response && response.status === "ok"
        ? "Dokument wurde erfolgreich erstellt"
        : "Dokument konnte nicht erstellt werden";
    enqueueSnackbar("", {
      content: () => (
        <div>
          <Snackbar
            message={messageText}
            buttonType={"link"}
            buttonLink={getCaseLink(product)}
            buttonTarget={"_self"}
            buttonText={"Zum Fall"}
          />
        </div>
      ),
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
    await fetchMediaObjects(product, dispatch);
  };

  const initialValues = { fileType: "", sendEmailToCustomer: false, showInGallery: false };
  const { values, handleChange, handleSubmit, isLoading } = useForm({
    initialValues,
    onSubmit,
  });

  const productGeneratableFiles = generatableFiles[product.productClassName];

  if (_.isEmpty(productGeneratableFiles)) {
    return null;
  }

  return (
    <ContentBox headline="Dokument generieren">
      <form onSubmit={handleSubmit}>
        <Grid container alignItems={"center"} justifyContent={"center"} spacing={3}>
          <Grid item xs={12} md={6}>
            <ValidatorSelect
              label={"Welche Datei?"}
              name={"fileType"}
              value={formValue(values, "fileType")}
              helperText={"Art der Datei: z.B. Rechnung alle Gebühren"}
              onChange={handleChange}
            >
              {_.map(productGeneratableFiles, (fileData) => {
                return (
                  <MenuItem key={fileData.value} value={fileData.value} disabled={fileData.isDisabled(product)}>
                    {fileData.label}
                  </MenuItem>
                );
              })}
            </ValidatorSelect>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction={"row"} justifyContent={"space-evenly"}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Für Kunden sichtbar</FormLabel>
                <RadioGroup row={true} name={"showInGallery"} value={values.showInGallery} onChange={handleChange}>
                  <FormControlLabel
                    disabled={values.sendEmailToCustomer === "true"}
                    value={"true"}
                    control={<Radio />}
                    label={"Ja"}
                  />
                  <FormControlLabel
                    disabled={values.sendEmailToCustomer === "true"}
                    value={"false"}
                    control={<Radio />}
                    label={"Nein"}
                  />
                </RadioGroup>
              </FormControl>
              <FormControl component="fieldset">
                <FormLabel component="legend">E-Mail an Kunden schicken</FormLabel>
                <RadioGroup
                  row={true}
                  name={"sendEmailToCustomer"}
                  value={values.sendEmailToCustomer}
                  onChange={(e) => {
                    handleChange(e);
                    handleChange({ target: { name: "showInGallery", value: "true" } });
                  }}
                >
                  <FormControlLabel value={"true"} control={<Radio />} label={"Ja"} />
                  <FormControlLabel value={"false"} control={<Radio />} label={"Nein"} />
                </RadioGroup>
              </FormControl>
            </Stack>
          </Grid>
          <Grid item>
            <ButtonLoading
              sx={{ width: "250px" }}
              isLoading={isLoading}
              disabled={isLoading}
              type={"submit"}
              color={"primary"}
              variant={"contained"}
            >
              Dokument generieren
            </ButtonLoading>
          </Grid>
        </Grid>
      </form>
    </ContentBox>
  );
};

CaseFileGenerateForm.propTypes = {
  product: PropTypes.object.isRequired,
};

export default CaseFileGenerateForm;
