import { Button, Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import ButtonLoading from "../Button/ButtonLoading";
import React, { useState } from "react";
import ApiClient from "../../services/ApiClient";
import _ from "lodash";
import AdvoAssistOpenFeeAgreementModal from "./Modals/AdvoAssistOpenFeeAgreementModal";
import PropTypes from "prop-types";
import AdvoAssistPostponeModal from "./Modals/AdvoAssistPostponeModal";
import advoAssistActionsStyle from "./advoAssistActionsStyle";
import AdvoAssistCancelModal from "./Modals/AdvoAssistCancelModal";

const useStyles = makeStyles(advoAssistActionsStyle);

export default function AdvoAssistActions({ advoAssist, legalbirdAppointment, product, refreshPage }) {
  const [modal, setModal] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  const submitAction = (action, data) => {
    setIsLoading(true);
    ApiClient.post("services/advo_assist/" + action + "/" + _.toNumber(advoAssist?.id), {
      body: JSON.stringify(data),
    }).then(
      (response) => {
        if (!_.isError(response)) {
          refreshPage();
          return;
        }
        setIsLoading(false);
        alert("[" + response.status + "]: " + response.error);
      },
      (error) => {
        setIsLoading(false);
        alert("[" + error.status + "]: " + error.error);
      }
    );
  };

  return (
    <>
      <Grid item md={6} xs={12} className={classes.actionButton}>
        <ButtonLoading
          onClick={() => setModal("openFeeAgreement")}
          variant={"contained"}
          isLoading={isLoading}
          disabled={!!advoAssist.appointmentAssigned}
        >
          Neu ausschreiben
        </ButtonLoading>
        <AdvoAssistOpenFeeAgreementModal
          open={modal === "openFeeAgreement"}
          handleClose={() => setModal("")}
          advoAssist={advoAssist}
          submitAction={submitAction}
          refreshPage={refreshPage}
        />
      </Grid>

      <Grid item md={6} xs={12} className={classes.actionButton}>
        <ButtonLoading onClick={() => setModal("postpone")} variant={"contained"} isLoading={isLoading}>
          Termin verschieben
        </ButtonLoading>
        <AdvoAssistPostponeModal
          open={modal === "postpone"}
          handleClose={() => setModal("")}
          advoAssist={advoAssist}
          legalbirdAppointment={legalbirdAppointment}
          product={product}
          submitAction={submitAction}
          refreshPage={refreshPage}
        />
      </Grid>

      <Grid item md={6} xs={12} className={classes.actionButton}>
        <ButtonLoading
          onClick={() => submitAction("create_credit_note", {})}
          disabled={!advoAssist.appointmentAssigned || !!advoAssist.creditNoteCreated}
          variant={"contained"}
          isLoading={isLoading}
        >
          Gutschrift erstellen
        </ButtonLoading>
      </Grid>

      <Grid item md={6} xs={12} className={classes.actionButton}>
        <ButtonLoading
          onClick={() => setModal("cancel")}
          disabled={!!advoAssist.creditNoteCreated}
          variant={"contained"}
          isLoading={isLoading}
        >
          Termin absagen
        </ButtonLoading>
        <AdvoAssistCancelModal
          open={modal === "cancel"}
          handleClose={() => setModal("")}
          advoAssist={advoAssist}
          submitAction={submitAction}
          refreshPage={refreshPage}
        />
      </Grid>
      <Grid item md={6} xs={12} className={classes.actionButton}>
        <Button
          component="a"
          variant="contained"
          href={"https://www.advo-assist.de/mitglieder/details/?Nr=" + advoAssist.advoAssistAppointmentId}
          target="_blank"
          rel="noreferrer"
        >
          Zum AdvoAssist Termin
        </Button>
      </Grid>
      <Grid item md={6} xs={12} className={classes.actionButton}>
        <ButtonLoading
          onClick={() => submitAction("reload_appointment", {})}
          variant={"contained"}
          isLoading={isLoading}
        >
          AdvoAssist synchronisieren
        </ButtonLoading>
      </Grid>
    </>
  );
}
AdvoAssistActions.propTypes = {
  advoAssist: PropTypes.object.isRequired,
  legalbirdAppointment: PropTypes.object.isRequired,
  refreshPage: PropTypes.func,
};
AdvoAssistActions.defaultProps = {
  refreshPage: () => {},
};
