import FeesService from "../components/DivorceCalculator/FeesService";
import moment from "moment";

const settlementCalculator = (settlement, valueInDispute) => {
  const lastWorkDay = settlement.extended.lastWorkDayCalculated
    ? moment(settlement.extended.lastWorkDayCalculated)
    : moment();
  const employedSince = moment(settlement.employedSince);
  const workDurationInYears = Math.round(lastWorkDay.diff(employedSince, "years", true));
  const settlementValue = settlement.grossSalary * workDurationInYears * 0.5;
  const processCosts = FeesService.getFeeByValue(valueInDispute, 1.3, true);
  const hearingCosts = FeesService.getFeeByValue(valueInDispute, 1.2, true);
  const fullCosts = processCosts + hearingCosts + FeesService.getExpenses(true);
  const settlementCosts = FeesService.getFeeByValue(valueInDispute, 1, true);

  return {
    settlementValue,
    processCosts,
    hearingCosts,
    fullCosts,
    settlementCosts,
  };
};

export default settlementCalculator;
