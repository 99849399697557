import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  headline: {
    fontSize: "1rem",
    textDecoration: "line-through",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontWeight: 700,
    flexGrow: 1,
  },
  activityActions: {
    minWidth: 180,
  },
  placeholderGridItem: {
    width: 42,
  },
  overdue: {
    color: "#ca0d0d",
  },
  mandatory: {
    color: "#de840f",
  },
  noteContainer: {
    margin: "1rem 0 0 72px",
    padding: "1rem",
    backgroundColor: "#f8f4d0",
    overflowWrap: "break-word",
  },
  imageGridItem: {
    width: 72,
  },
  activityContent: {
    width: "calc(100% - 72px)",
  },
  activityInfo: {
    flexGrow: 1,
    marginLeft: ".5rem",
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 0,
      width: "100%",
    },
  },
  activityContainer: {
    margin: "30px 0 0 0",
  },
  outerActivityContainer: {
    position: "relative",
    width: "100%",
  },
  overlay: {
    backgroundColor: "#FFFFFF99",
    zIndex: 1,
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
  },
  deletedInfo: {
    position: "relative",
    zIndex: 2,
    margin: "1rem 0 0 72px",
  },
}));

export default useStyles;
