import React, { useEffect, useState } from "react";
import { splitFileNameAndExtension } from "../../services/Files/fileService";
import moment from "moment";
import _ from "lodash";
import DisplayableMediaObject from "../MediaObject/DisplayableMediaObject";

export default function ScannedFile({ mediaObject, product, identifier }) {
  const [filename, setFilename] = useState();
  const [displayMediaObjectOpen, setDisplayMediaObjectOpen] = useState(false);
  const originalFilename = mediaObject.originalName;
  const fileDescription = mediaObject.description;
  const scanMetaData = mediaObject.scanMetaData;
  const scanDate = mediaObject.created;
  useEffect(() => {
    const dateString = moment(scanDate).format("YYYYMMDD");
    const reference = _.get(scanMetaData, "reference", "LB-XXX-1-2022");
    const familyName = _.replace(_.trim(_.get(scanMetaData, "familyName", "Unbekannt")), /\s/g, "-");
    const { extension: fileEnding } = splitFileNameAndExtension(originalFilename);
    const description = _.includes(["KON", "INT"], identifier) ? _.replace("_" + fileDescription, /\s/g, "-") : "";
    setFilename(dateString + "_" + identifier + "_" + reference + "_" + familyName + description + "." + fileEnding);
  }, [originalFilename, scanDate, scanMetaData, identifier, fileDescription]);

  return <DisplayableMediaObject mediaObject={mediaObject} label={filename} />;
}
