import React from "react";
import useForm from "../../../../hooks/useForm";
import { useDispatch } from "react-redux";
import { transformToFormValues } from "../../../../services/formServiceFunctions";
import { persist } from "../../../../services/Persist/Persister";
import { getRequiredFields } from "../../../../services/Product/statusChangeValidators";
import _ from "lodash";
import FormElement from "../FormElement";
import SubmitButton from "../../../Button/SubmitButton";
import { getFormDefinition } from "../../../../services/Product/ProductService";
import CaseDetailsSpecialActions from "./CaseDetailsSpecialActions";
import ContentBox from "../../../ContentBox/ContentBox";
import { AbstractCase } from "../../../../types/AbstractCase";

interface CaseDetailsFormProps {
  product: AbstractCase;
  headline?: string;
  refreshCase: () => void;
}

export default function CaseDetailsForm({ product, refreshCase, headline = "Details zum Fall" }: CaseDetailsFormProps) {
  const dispatch = useDispatch();
  const initialValues = { [product.productClassName]: transformToFormValues(product) };
  const onSubmit = async ({ values }: { values: any }) => {
    await persist(values, product, dispatch);
    refreshCase();
  };

  const useFormProps = useForm({
    initialValues,
    onSubmit,
    identifier: product.id.toString(),
  });

  const mandatoryFields = getRequiredFields(product);
  const formDefinition = getFormDefinition(product);

  return (
    <ContentBox headline={headline}>
      <form onSubmit={useFormProps.handleSubmit}>
        {_.map(formDefinition[product.stage || 10], (formElementProps) => {
          return (
            <FormElement
              product={product}
              key={formElementProps.path}
              {...formElementProps}
              {...useFormProps}
              mandatoryFields={mandatoryFields}
            />
          );
        })}
        <SubmitButton
          error={_.keys(useFormProps.errors).length > 0}
          isLoading={useFormProps.isLoading}
          disabled={_.keys(useFormProps.touchedValues).length === 0}
          variant={"contained"}
          type={"submit"}
          sx={{ marginTop: "1rem" }}
        >
          Speichern
        </SubmitButton>
      </form>
      <CaseDetailsSpecialActions product={product} />
    </ContentBox>
  );
}
