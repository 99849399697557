import React from "react";
import LinkListItemWithIcon from "../LinkListItemWithIcon/LinkListItemWithIcon";
import divorceCostImage from "../../assets/icon/icon_scheidungskosten.png";
import { List } from "@mui/material";
import birdImage from "../../assets/icon/bird-icon_96px.jpg";
import bookingImage from "../../assets/icon/icon_buchen_100px.png";
import authorityImage from "../../assets/icon/icon_behoerde_100px.png";

const Controlling = () => {
  return (
    <List>
      <LinkListItemWithIcon
        title={"Zahlläufe"}
        subtitle={"erstellen"}
        description={"AdvoAssist, ausgeschriebene Aufgaben und aus CSV Dateien"}
        to={"/services/tools/zahllauf"}
        imageIcon={divorceCostImage}
      />
      <LinkListItemWithIcon
        title={"Monatscontrolling LBRA UG"}
        subtitle={"Tools für den Abschluss"}
        description={"Abrechnung Softwarelizenzgebühren, Datev Stammdaten, etc."}
        to={"/services/tools/controlling"}
        imageIcon={birdImage}
      />
      <LinkListItemWithIcon
        title={"Externe Gutschriften"}
        subtitle={"erstellen und versenden"}
        description={"CSV hochladen und erstellte Gutschrift versenden + Zahllauf"}
        to={"/services/tools/externe-rechnungen-erstellen"}
        imageIcon={bookingImage}
      />
      <LinkListItemWithIcon
        title={"Rechnungen Ermittlungsakten"}
        subtitle={"verarbeiten"}
        description={"aus den Rechnungen automatisch alle Rechnungsdaten auslesen"}
        to={"/services/tools/bearbeitung-rechnungen-ermittlungsakten"}
        imageIcon={authorityImage}
      />
    </List>
  );
};

export default Controlling;
