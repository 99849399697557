import _ from "lodash";
import { API_HOST, API_PATH } from "../config/_entrypoint";
import { loadToken } from "./localStorage";
import ExtendableError from "es6-error";

const apiEndpoint = API_HOST + API_PATH;
const defaultAcceptHeader = "application/ld+json";
const defaultContentTypeHeader = "application/ld+json";

export class SubmissionError extends ExtendableError {
  constructor(errors, status) {
    super(errors["_error"]);
    this.errors = errors;
    this.status = status;
  }
}

class ResponseError extends ExtendableError {
  constructor(error, status, response) {
    super(error);
    this.error = error;
    this.status = status;
    this.response = response;
  }
}

class ApiClient {
  async getToken(credentials) {
    const json = this.post("login_check", { body: JSON.stringify(credentials) });
    const token = _.get(await json, "token");
    if (!token || !_.isString(token)) {
      throw new Error(
        "Für diese Kombination aus E-Mail-Adresse und Passwort konnten keine Daten gefunden werden." +
          " Bitte überprüfen Sie Ihre eingegebenen Daten."
      );
    }
    return token;
  }

  async get(iri, options = {}) {
    return this.call("GET", iri, options);
  }

  async post(iri, options = {}) {
    return this.call("POST", iri, options);
  }

  async put(iri, options = {}) {
    return this.call("PUT", iri, options);
  }

  async delete(iri, options = {}) {
    return this.call("DELETE", iri, options);
  }

  async call(method, iri, options) {
    const url = apiEndpoint + _.trimStart(iri, "/");

    options.method = method;
    this.defaultOptions(options);

    const response = await fetch(url, options);
    if (response.ok === true && response.status === 204) {
      return null;
    }

    if (response.status === 403) {
      return new ResponseError("Access Denied", 403);
    }

    const json = await response.json();
    if (!response.ok) {
      return this.handleError(json, response);
    }
    return json;
  }

  defaultOptions = (options) => {
    if ("undefined" === typeof options.headers) {
      options.headers = new Headers();
    }

    if (null === options.headers.get("Accept")) {
      options.headers.set("Accept", defaultAcceptHeader);
    }

    if (
      "undefined" !== options.body &&
      !(options.body instanceof FormData) &&
      null === options.headers.get("Content-Type")
    ) {
      options.headers.set("Content-Type", defaultContentTypeHeader);
    }

    if (options.headers.get("Content-Type") === "multipart/form-data") {
      options.headers.delete("Content-Type");
    }

    const token = loadToken();
    if (null !== token && null === options.headers.get("Authorization")) {
      options.headers.set("Authorization", "Bearer " + token);
    }

    if (sessionStorage.getItem("impersonate")) {
      options.headers.set("X-Switch-User", sessionStorage.getItem("impersonate"));
    }
  };

  handleError(json, response) {
    const error = json["hydra:description"] || response.statusText || json;
    if (!json.violations) {
      return new ResponseError(error, response.status, json);
    }
    let errors = {};
    _.map(json.violations, (violation) => (errors[violation.propertyPath] = violation.message));
    return new SubmissionError(errors, response.status);
  }
}

export default new ApiClient();
