import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import ApiClient from "../services/ApiClient";
import _ from "lodash";

interface IdentifierData {
  description: string;
  showInGallery?: boolean;
}

interface Identifiers {
  [identifier: string]: IdentifierData;
}

interface IdentifiersByProductClass {
  [productClass: string]: Identifiers;
}

interface FileIdentifierContextInterface {
  identifiers: IdentifiersByProductClass | null;
  getIdentifiersByProductClass: (a: string) => Identifiers | null;
  isLoading: boolean;
}

const FileIdentifierContext = React.createContext<FileIdentifierContextInterface | null>(null);

export default function FileIdentifierProvider({ children }: { children?: React.ReactNode }) {
  const [identifiers, setIdentifiers] = useState<IdentifiersByProductClass | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    ApiClient.get("lb/services/document_labels").then((apiResult) => {
      setIdentifiers(apiResult);
      setIsLoading(false);
    });
  }, []);

  const getIdentifiersByProductClass = useCallback(
    (productClassName: string) => {
      if (!identifiers) {
        return null;
      }
      return _.merge(identifiers[productClassName], identifiers.common);
    },
    [identifiers]
  );

  const data: FileIdentifierContextInterface = useMemo(
    () => ({
      identifiers,
      getIdentifiersByProductClass,
      isLoading,
    }),
    [identifiers, getIdentifiersByProductClass, isLoading]
  );

  return <FileIdentifierContext.Provider value={data}>{children}</FileIdentifierContext.Provider>;
}

export const useFileIdentifier = () => {
  const fileIdentifierContext = useContext(FileIdentifierContext);
  if (!fileIdentifierContext) {
    throw new Error("useFileIdentifier can only be used inside FileIdentifierProvider");
  }
  return fileIdentifierContext;
};
