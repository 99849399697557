import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import ApiClient from "../services/ApiClient";
import { sortAlphabetically } from "../services/backofficeUserService";
import _ from "lodash";
import { BackofficeUser, Roles } from "../types/BackofficeUser";

interface BackofficeUserProviderData {
  backofficeUsers: Array<BackofficeUser>;
  accountManagers: Array<BackofficeUser>;
  lawyers: Array<BackofficeUser>;
  agents: Array<BackofficeUser>;
  internals: Array<BackofficeUser>;
  externals: Array<BackofficeUser>;
  systemUser: BackofficeUser | undefined;
  isLoading: boolean;
  fetchBackofficeUsers: Function;
}

const BackofficeUserProviderContext = createContext<BackofficeUserProviderData | null>(null);

export default function BackofficeUserProvider({ children }: { children?: React.ReactNode }) {
  const [backofficeUserList, setBackofficeUserList] = useState<Array<BackofficeUser>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchBackofficeUsers();
  }, []);

  const fetchBackofficeUsers = async () => {
    setIsLoading(true);
    const apiResult = await ApiClient.get("backoffice_users");
    setBackofficeUserList(apiResult["hydra:member"]);
    setIsLoading(false);
  };

  const accountManagers = useMemo(() => {
    return backofficeUserList ? sortAlphabetically(_.filter(backofficeUserList, "isAccountManager")) : [];
  }, [backofficeUserList]);

  const lawyers = useMemo(() => {
    return backofficeUserList ? sortAlphabetically(_.filter(backofficeUserList, "isLawyer")) : [];
  }, [backofficeUserList]);

  const internals = useMemo(() => {
    return _.sortBy(
      _.filter(backofficeUserList, (backofficeUser) => {
        return _.intersection(backofficeUser.roles, [Roles.lawyer, Roles.accountmanager, Roles.traineeLawyer]).length > 0;
      }),
      (internal) => {
        return internal.person.fullname;
      }
    );
  }, [backofficeUserList]);

  const agents = useMemo(() => {
    return _.sortBy(
      _.filter(backofficeUserList, (backofficeUser) => {
        return _.intersection(backofficeUser.roles, [Roles.lawyer, Roles.accountmanager, Roles.external, Roles.traineeLawyer]).length > 0;
      }),
      (agent) => {
        return agent.person.fullname;
      }
    );
  }, [backofficeUserList]);

  const externals = useMemo(() => {
    return _.sortBy(
      _.filter(backofficeUserList, (backofficeUser) => {
        return _.includes(backofficeUser.roles, Roles.external);
      }),
      (externalUser) => {
        return externalUser.person.fullname;
      }
    );
  }, [backofficeUserList]);

  const systemUser = useMemo(() => {
    return _.find(backofficeUserList, (backofficeUser) => {
      return _.includes(backofficeUser.roles, Roles.system);
    })
  }, [backofficeUserList]);

  const data: BackofficeUserProviderData = useMemo(
    () => ({
      isLoading,
      backofficeUsers: backofficeUserList,
      accountManagers,
      lawyers,
      agents,
      internals,
      externals,
      systemUser,
      fetchBackofficeUsers,
    }),
    [backofficeUserList, isLoading]
  );

  return <BackofficeUserProviderContext.Provider value={data}>{children}</BackofficeUserProviderContext.Provider>;
}

export const useBackofficeUser = () => {
  const backofficeUserContext = useContext(BackofficeUserProviderContext);
  if (!backofficeUserContext) {
    throw new Error("useBackofficeUser can only be used inside BackofficeUserProvider");
  }
  return backofficeUserContext;
};
