import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  deleteDialog: {
    padding: "2rem",
  },
  deleteDialogHeadline: {
    textAlign: "center",
    fontSize: "1.25rem",
    marginBottom: "1rem",
  },
  deleteDialogText: {
    textAlign: "center",
    marginBottom: "1rem",
  },
  editMenu: {
    width: "50%",
    textAlign: "right",
  },
}));

export default useStyles;
