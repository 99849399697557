import { fetchCollection } from "../store/generic/actions";
import { GENERIC_RECEIVED } from "../store/generic/actionTypes";

export const getMediaObjectsIdentifier = (product) => {
  return "mediaObjects" + product.productClassName + product.id;
};

export const fetchMediaObjects = (
  product,
  dispatch,
  showInGallery = null,
  showInExternalView = null,
  name,
) => {
  if (!product) return;
  let uri = `media_objects?product=${product.productClassName}&productId=${product.id}`;
  if (showInGallery !== null) {
    uri = uri + `&showInGallery=${showInGallery}`;
  }
  if (showInExternalView !== null) {
    uri = uri + `&showInExternalView=${showInExternalView}`;
  }
  return dispatch(fetchCollection(uri, name || getMediaObjectsIdentifier(product)));
};

export const fetchMediaObjectsExternal = async (product, dispatch) => {
  if (!product) {
    return;
  }
  let uri = `media_objects?product=${product.productClassName}&productId=${product.id}`;
  const mediaObjects = await dispatch(fetchCollection(uri + "&showInExternalView=true"));
  dispatch({ type: GENERIC_RECEIVED, name: getMediaObjectsIdentifier(product), value: mediaObjects });
};
