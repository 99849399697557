import React, { useState } from "react";
import * as PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import { updateResource } from "../../store/generic/actions";
import { useDispatch } from "react-redux";

export default function ChangeMediaObjectDescriptionModal({
  mediaObject,
  updateMediaObjects,
  changeDescriptionOpen,
  setChangeDescriptionOpen,
  closeMenu,
}) {
  const [description, setDescription] = useState(mediaObject.description || "");
  const dispatch = useDispatch();

  const handleChangeDescription = () => {
    dispatch(
      updateResource(mediaObject.id, "media_objects", {
        description: description,
      })
    ).then(() => updateMediaObjects());
    setChangeDescriptionOpen(false);
    closeMenu();
  };

  return (
    <LegalbirdIoModal
      handleClose={() => {
        setChangeDescriptionOpen(false);
        closeMenu();
      }}
      open={changeDescriptionOpen}
      title={"Beschreibungstext ändern"}
      submitButton={
        <Button disabled={description.length < 5} variant={"contained"} onClick={handleChangeDescription}>
          Speichern
        </Button>
      }
    >
      <TextField value={description} onChange={({ target }) => setDescription(target.value)} />
    </LegalbirdIoModal>
  );
}

ChangeMediaObjectDescriptionModal.propTypes = {
  mediaObject: PropTypes.object.isRequired,
  updateMediaObjects: PropTypes.func.isRequired,
  changeDescriptionOpen: PropTypes.bool.isRequired,
  setChangeDescriptionOpen: PropTypes.func.isRequired,
  closeMenu: PropTypes.func,
};

ChangeMediaObjectDescriptionModal.defaultProps = {
  closeMenu: () => {},
};
