import { fetchCollection } from "../../store/generic/actions";
import { useDispatch, useSelector } from "react-redux";
import { getGeneric } from "../../store/generic/reducer";
import React, { useEffect } from "react";
import _ from "lodash";
import Note from "../Case/CaseNote/Note";
import useStyles from "./pinnedNotesStyle";
import * as PropTypes from "prop-types";
import { getBackofficeUser } from "../../store/backofficeUser/reducer";

const PinnedNotes = ({ product, isEditable }) => {
  const fetchPinnedNotes = () => {
    if (product && product.backofficeCase) {
      dispatch(
        fetchCollection("notes?case=" + product.backofficeCase["@id"] + "&pinned=true", "pinnedNotes" + product.id)
      );
    }
  };

  const classes = useStyles({});
  const dispatch = useDispatch();
  const pinnedNotes = useSelector((state) => getGeneric(state, "pinnedNotes" + product.id));
  const currentUser = useSelector((state) => getBackofficeUser(state));

  useEffect(fetchPinnedNotes, [product]);

  return (
    <div>
      {pinnedNotes &&
        _.map(pinnedNotes["hydra:member"], (pinnedNote) => (
          <div key={pinnedNote.id} className={classes.note}>
            <Note
              note={pinnedNote}
              handleChange={() => fetchPinnedNotes()}
              startsCollapsed
              product={product}
              isEditable={isEditable || pinnedNote.author.id === currentUser.id}
            />
          </div>
        ))}
    </div>
  );
};

PinnedNotes.propTypes = {
  isEditable: PropTypes.bool,
  product: PropTypes.object,
};

PinnedNotes.defaultProps = {
  isEditable: true,
};

export default PinnedNotes;
