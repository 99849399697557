import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import * as PropTypes from "prop-types";
import moment from "moment";
import { ACTIVITY_OVERDUE } from "../../services/Stages/Stages";

const useStyles = makeStyles((theme) => ({
  today: {
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  overdue: {
    color: "#ca0d0d",
  },
  todayAndOverdue: {
    color: "#ca0d0d",
    fontWeight: 700,
  },
  spanContainer: {
    [theme.breakpoints.up("md")]: {
      whiteSpace: "nowrap",
    },
  },
}));

const ActivityInfo = ({ activity }) => {
  const classes = useStyles({});

  const getClassName = (activity, isToday) => {
    if (isToday && activity.activityStatus === ACTIVITY_OVERDUE) {
      return classes.todayAndOverdue;
    }
    if (isToday) {
      return classes.today;
    }
    if (activity.activityStatus === ACTIVITY_OVERDUE) {
      return classes.overdue;
    }
  };

  const isToday = moment(activity.dueDate).isSame(moment(), "day");
  const dateString = isToday ? "Heute" : moment(activity.dueDate).format("DD.MM.YYYY");
  const timeString = activity.dueTime !== "allDay" ? "um " + activity.dueTime + " Uhr" : "";

  return (
    <div className={classes.spanContainer}>
      <span className={getClassName(activity, isToday)}>
        {dateString} {timeString}
      </span>{" "}
      |{" "}
      <span className={activity.activityStatus === ACTIVITY_OVERDUE ? classes.overdue : null}>
        {activity.assignedUser ? activity.assignedUser.person.fullname : "Nicht zugewiesen"}
      </span>
    </div>
  );
};

ActivityInfo.propTypes = {
  activity: PropTypes.object.isRequired,
};

export default ActivityInfo;
