import React, { useEffect, useMemo, useState } from "react";
import * as PropTypes from "prop-types";
import useStyles from "./writeEmailStyles";
import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select, Tooltip
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { FileCopyOutlined, SettingsBackupRestore } from "@mui/icons-material";
import ButtonLoading from "../../Button/ButtonLoading";
import _ from "lodash";
import ApiClient from "../../../services/ApiClient";
import { fillTemplate } from "../../../services/Template/templateService";
import { useSelector } from "react-redux";
import { getBackofficeUser } from "../../../store/backofficeUser/reducer";
import { emailDefault, requiredFieldDefault } from "../../../services/validationRules";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import { getEmailSignature } from "../../../services/Template/emailSignatures";
import { editorStateToHTML, htmlToEditorState } from "../../../services/Editor/editorFunctions";
import useForm from "../../../hooks/useForm";
import ValidatorForm from "../../Validator/ValidatorForm";
import ValidatorTextField from "../../Validator/ValidatorTextField";
import { formValue } from "../../../services/formServiceFunctions";
import ValidatorSelect from "../../Validator/ValidatorSelect";
import { translate } from "../../../services/Translations/translatorService";
import { getMediaObjectsIdentifier } from "../../../hooks/useMediaObjects";
import { getGeneric } from "../../../store/generic/reducer";
import {
  clearSessionStorageState,
  loadSessionStorageState,
  saveSessionStorageState,
} from "../../../services/browserStorageService";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import AddTextBlock from "../../Templates/AddTextBlock";
import { getAdvoAssistRepresentative } from "../../../services/Product/ProductService";

const WriteEmail = ({ open, closeDialog, product, refreshEmails }) => {
  const classes = useStyles({});
  const currentUser = useSelector((state) => getBackofficeUser(state));
  const mediaObjects = useSelector((state) => getGeneric(state, getMediaObjectsIdentifier(product)));
  const signature = getEmailSignature(currentUser, product);

  const [isLoading, setIsLoading] = useState(false);
  const [withCC, setWithCC] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [attachmentSize, setAttachmentSize] = useState(0);

  const initialValues = {
    recipient: product.customer.email,
    subject: " (Unser Zeichen: " + product.reference + ")",
    editorState: htmlToEditorState(signature),
    chosenTemplate: "no_template",
    recipientType: "customer",
    ccType: "customer",
    attachedMediaObjects: [],
    cc: product.customer.email,
  };

  const sendMail = async () => {
    setIsLoading(true);
    let mailData = {
      toMail: formValue(values, "recipient"),
      recipientType: values.recipientType,
      productString: `${product.productClassName}${product.id}`,
      subject: formValue(values, "subject"),
      html: editorStateToHTML(formValue(values, "editorState")),
      mediaObjectIds: getMediaObjectIds(),
    };
    if (values.recipientType === "customer") {
      mailData.feedbackData = {
        customerId: product.customer.id,
        product: product.productClassName,
        caseId: product.id,
      };
    }
    if (withCC && formValue(values, "cc")) {
      mailData.cc = formValue(values, "cc");
    }
    const result = await ApiClient.post("email/email_send", { body: JSON.stringify(mailData) });
    if (result.status === 400) {
      alert(
        "Mailversand fehlgeschlagen, sollte der Fehler nach einem Neuladen der Seite weiterhin auftreten, " +
          "kontaktieren Sie bitte die IT."
      );
    }
    closeDialog();
    clearSessionStorageState("email");
    setIsLoading(false);
    refreshEmails();
  };

  const { values, errors, handleChange, handleSubmit, handleBlur, registerValidators, clearForm, updateAllValues } = useForm({
    initialValues,
    onSubmit: sendMail,
  });

  const loadContentFromSessionStorage = () => {
    const sessionStorageValues = loadSessionStorageState("email");
    if (sessionStorageValues) {
      updateAllValues({
        recipient: sessionStorageValues.recipient,
        subject: sessionStorageValues.subject,
        editorState: EditorState.createWithContent(convertFromRaw(sessionStorageValues.editorState)),
        chosenTemplate: sessionStorageValues.chosenTemplate,
        recipientType: sessionStorageValues.recipientType,
        ccType: sessionStorageValues.ccType,
        attachedMediaObjects: sessionStorageValues.attachedMediaObjects,
        cc: sessionStorageValues.cc,
      });
    }
  };

  useEffect(() => {
    if (!open) return;
    ApiClient.get("templates").then((result) => {
      const mailTemplates = _.filter(result["hydra:member"], (template) => template.type === "email");
      setTemplates(mailTemplates);
    });
  }, [open]);

  const advoAssistRepresentative = useMemo(() => getAdvoAssistRepresentative(product), [product]);

  const handleRecipientTypeChange = (event, fieldName) => {
    handleChange(event);
    let email = "";
    switch (event.target.value) {
      case "customer":
        email = _.get(product, "customer.email", "Nicht befüllt");
        break;
      case "contractualPartner":
        if (product.contractualPartnerType === "contractualPartnerLegalEntity") {
          email = _.get(product, "contractualPartnerLegalEntity.email", "Nicht befüllt");
          break;
        }
        email = _.get(product, "contractualPartnerPerson.email", "Nicht befüllt");
        break;
      case "opponentLawFirm":
        email = _.get(product, "opponentLawFirm.email", "Nicht befüllt");
        break;
      case "debtCollectionAgency":
        email = _.get(product, "debtCollectionAgency.email", "Nicht befüllt");
        break;
      case "insurance":
        email = _.get(product, "insurance.insurance.email", "Nicht befüllt");
        break;
      case "advoAssistRepresentative":
        email = advoAssistRepresentative.email;
        break;
      case "custom":
        break;
      default:
        email = _.get(product, event.target.value);
        break;
    }
    handleChange({
      target: {
        name: fieldName,
        value: email,
      },
    });
  };

  useEffect(() => {
    if (values.chosenTemplate === "no_template") {
      handleChange({ target: { name: "subject", value: " (Unser Zeichen: " + product.reference + ")" } });
      handleChange({
        target: {
          name: "editorState",
          value: htmlToEditorState("<p></p>" + signature),
        },
      });
      return;
    }
    const templateToDisplay = _.find(templates, (template) => template.id === values.chosenTemplate);
    handleChange({
      target: {
        name: "subject",
        value: fillTemplate(templateToDisplay.subject, product, values) + " (Unser Zeichen: " + product.reference + ")",
      },
    });
    handleChange({
      target: {
        name: "editorState",
        value: htmlToEditorState(fillTemplate(templateToDisplay.content, product, values) + signature),
      },
    });
  }, [values.chosenTemplate, handleChange, signature, product, templates]);

  useMemo(() => {
    let combinedAttachmentSize = 0;
    _.forEach(formValue(values, "attachedMediaObjects"), (mediaObject) => (combinedAttachmentSize += mediaObject.size));
    setAttachmentSize(combinedAttachmentSize);
  }, [values.attachedMediaObjects]);

  const closeAndResetDialog = () => {
    clearForm();
    setWithCC(false);
    closeDialog();
  };

  const handleEditorStateChange = (editorState) => {
    handleChange({
      target: {
        name: "editorState",
        value: editorState,
      },
    });
    saveFormDataToSessionStorage();
  };

  const saveFormDataToSessionStorage = _.throttle(() => {
    let valuesToPersist = _.merge({}, values);
    valuesToPersist.editorState = convertToRaw(values.editorState.getCurrentContent());
    saveSessionStorageState("email", valuesToPersist);
  }, 3000);

  const getMediaObjectIds = () => {
    let mediaObjectIds = [];
    _.forEach(formValue(values, "attachedMediaObjects"), (mediaObject) => mediaObjectIds.push(mediaObject.id));
    return mediaObjectIds;
  };

  const copyBccMailToClipboard = async () => {
    await navigator.clipboard.writeText(bccMail);
  };

  const generalTemplateList = _.filter(templates, (template) => template.productClassName === "general");
  const categoryTemplateList = _.filter(templates, (template) => template.productClassName === product.productCategory);
  const productTemplateList = _.filter(templates, (template) => template.productClassName === product.productClassName);
  const bccMail = `legalbird-rechtsanwaelte-bcc+${product.productClassName}${product.id}@legalbird.de`;

  //20MB
  const attachmentsFileLimitReached = attachmentSize > 20971520;

  const toggleWithCC = () => {
    //reset fields on hide
    if (withCC) {
      handleChange({
        target: {
          name: "cc",
          value: product.customer.email,
        },
      });
      handleChange({
        target: {
          name: "ccType",
          value: "customer",
        },
      });
    }
    setWithCC(!withCC);
  };

  const getRecipientMenuItems = () => {
    let menuItems = [];
    menuItems.push(
      <MenuItem value={"customer"} key={"customer"}>
        Kunde
      </MenuItem>
    );
    if (product.productCategory === "contractLaw") {
      menuItems.push(
        <MenuItem
          key={"contractualPartner"}
          value={"contractualPartner"}
          disabled={
            !(product.contractualPartnerType === "contractualPartnerLegalEntity"
              ? product.contractualPartnerLegalEntity && product.contractualPartnerLegalEntity.email
              : product.contractualPartnerPerson && product.contractualPartnerPerson.email)
          }
        >
          Gegenseite
        </MenuItem>,
        <MenuItem
          key={"opponentLawFirm"}
          disabled={!(product.opponentLawFirm && product.opponentLawFirm.email)}
          value={"opponentLawFirm"}
        >
          Anwalt Gegenseite
        </MenuItem>,
        <MenuItem
          key={"debtCollectionAgency"}
          disabled={!(product.debtCollectionAgency && product.debtCollectionAgency.email)}
          value={"debtCollectionAgency"}
        >
          Inkassobüro
        </MenuItem>
      );
    }
    if (!!product.processParticipantsPeople) {
      menuItems.push(
        _.map(product.processParticipantsPeople, (person, index) => {
          return (
            <MenuItem key={index} value={"processParticipantsPeople[" + index + "].email"} disabled={!person.email}>
              {translate("participant.labelType.values." + person.labelType) +
                " - " +
                person.givenName +
                " " +
                person.familyName}
            </MenuItem>
          );
        })
      );
    }
    if (!!product.processParticipantsOrganizations) {
      menuItems.push(
        _.map(product.processParticipantsOrganizations, (organization, index) => {
          return (
            <MenuItem
              key={index}
              value={"processParticipantsOrganizations[" + index + "].email"}
              disabled={!organization.email}
            >
              {translate("participant.labelType.values." + organization.labelType) + " - " + organization.legalName}
            </MenuItem>
          );
        })
      );
    }
    if (product.paymentType === "insurance") {
      menuItems.push(
        <MenuItem key={"insurance"} disabled={!product.insurance.insurance?.email} value={"insurance"}>
          Rechtsschutzversicherung
        </MenuItem>
      );
    }
    if (advoAssistRepresentative) {
      menuItems.push(
        <MenuItem key={"advoAssistRepresentative"} value={"advoAssistRepresentative"}>
          Terminsvertreter
        </MenuItem>
      );
    }
    menuItems.push(
      <MenuItem value={"custom"} key={"custom"}>
        Frei auswählen
      </MenuItem>
    );

    return menuItems;
  };

  //array because select only accept menuitems as direct children
  const recipientMenuitems = getRecipientMenuItems();

  return (
    <LegalbirdIoModal
      handleClose={closeAndResetDialog}
      open={open}
      disableBackdropClick
      title={"Email schreiben"}
      hideCancelButton
      onEnter={clearForm}
      hasActions={false}
    >
      <ValidatorForm onSubmit={handleSubmit} submitOnEnter={false}>
        <Grid container alignItems={"center"} justifyContent={"center"}>
          <Grid item xs={6} className={classes.gridItemLeft}>
            <ValidatorSelect
              label={"Empfänger"}
              value={formValue(values, "recipientType")}
              onChange={(event) => handleRecipientTypeChange(event, "recipient")}
              name={"recipientType"}
            >
              {recipientMenuitems}
            </ValidatorSelect>
          </Grid>
          <Grid item xs={6} className={classes.gridItemRight}>
            <ValidatorTextField
              label={"Email-Adresse Empfänger"}
              name={"recipient"}
              value={formValue(values, "recipient")}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={formValue(values, "recipientType") !== "custom"}
              registerValidators={registerValidators}
              validators={[...requiredFieldDefault, ...emailDefault]}
              error={!!errors["recipient"]}
              helperText={errors["recipient"]}
            />
          </Grid>
          <Grid item xs={6} className={classes.gridItemLeft}>
            <ValidatorSelect
              label={"E-Mail-Vorlage"}
              value={formValue(values, "chosenTemplate")}
              onChange={handleChange}
              name={`chosenTemplate`}
            >
              <MenuItem value={"no_template"}>Leerer Entwurf</MenuItem>
              {_.map(generalTemplateList, (template) => (
                <MenuItem key={template.id} value={template.id}>
                  {template.label}
                </MenuItem>
              ))}
              {_.map(categoryTemplateList, (template) => (
                <MenuItem key={template.id} value={template.id}>
                  {template.label}
                </MenuItem>
              ))}
              {_.map(productTemplateList, (template) => (
                <MenuItem key={template.id} value={template.id}>
                  {template.label}
                </MenuItem>
              ))}
            </ValidatorSelect>
          </Grid>
          <Grid item xs={6} className={classes.gridItemRight}>
            <Grid container>
              <Grid item xs={12} md={8}>
                <Button onClick={toggleWithCC}>{withCC ? "CC entfernen" : "CC hinzufügen"}</Button>
              </Grid>
              <Grid item xs={12} md={4}>
                <Button onClick={copyBccMailToClipboard} endIcon={<FileCopyOutlined />}>
                  BCC
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {withCC && (
            <>
              <Grid item xs={6} className={classes.gridItemLeft}>
                <ValidatorSelect
                  label={"CC Empfänger "}
                  value={formValue(values, "ccType")}
                  onChange={(event) => handleRecipientTypeChange(event, "cc")}
                  name={"ccType"}
                >
                  {recipientMenuitems}
                </ValidatorSelect>
              </Grid>
              <Grid item xs={6} className={classes.gridItemRight}>
                <ValidatorTextField
                  label={"CC E-Mail-Adresse"}
                  name={"cc"}
                  value={formValue(values, "cc")}
                  onChange={handleChange}
                  disabled={formValue(values, "ccType") !== "custom"}
                  registerValidators={registerValidators}
                  validators={emailDefault}
                  error={!!errors["cc"]}
                  helperText={errors["cc"]}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <ValidatorTextField
              label={"Betreff"}
              name={"subject"}
              value={formValue(values, "subject")}
              onChange={handleChange}
              registerValidators={registerValidators}
              validators={requiredFieldDefault}
              error={!!errors["subject"]}
              helperText={errors["subject"]}
            />
          </Grid>
          <Grid item xs={12} className={classes.mailContent}>
            <Editor
              toolbar={{
                options: ["inline", "list", "link"],
                inline: {
                  options: ["bold", "italic", "underline"],
                },
                list: {
                  options: ["unordered", "ordered"],
                },
              }}
              stripPastedStyles
              editorState={formValue(values, "editorState")}
              onEditorStateChange={handleEditorStateChange}
              localization={{ locale: "de" }}
              toolbarCustomButtons={[<AddTextBlock product={product} formValues={values} />]}
            />
          </Grid>
          {mediaObjects && (
            <Grid item xs={12}>
              <FormControl className={classes.attachmentsSelect}>
                <InputLabel id="attached-media-objects-label">Datei anhängen</InputLabel>
                <Select
                  label="Datei anhängen"
                  labelId="attached-media-objects-label"
                  multiple
                  value={formValue(values, "attachedMediaObjects")}
                  onChange={handleChange}
                  name={"attachedMediaObjects"}
                  disabled={mediaObjects["hydra:member"].length < 1}
                  renderValue={(selected) =>
                    _.map(selected, (selectedMediaObject) => selectedMediaObject.description).join(", ")
                  }
                >
                  {_.map(mediaObjects["hydra:member"], (mediaObject) => (
                    <MenuItem key={mediaObject.id} value={mediaObject}>
                      <Checkbox
                        checked={
                          !!_.find(
                            formValue(values, "attachedMediaObjects"),
                            (selectedMediaObject) => selectedMediaObject.id === mediaObject.id
                          )
                        }
                      />
                      <ListItemText primary={mediaObject.description} />
                    </MenuItem>
                  ))}
                </Select>
                {attachmentsFileLimitReached && (
                  <FormHelperText className={classes.helperText}>
                    Die Anhänge dürfen in Ihrer Gesamtmenge nicht größer als 20 MB sein
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
          <Grid item xs={3} />
          <Grid item xs={12} md={6} className={classes.createTemplateButton}>
            <ButtonLoading
              variant={"contained"}
              type={"submit"}
              isLoading={isLoading}
              disabled={attachmentsFileLimitReached}
            >
              E-Mail senden
            </ButtonLoading>
          </Grid>
          <Grid item xs={3} className={classes.createTemplateButton}>
            <Tooltip title={"Inhalt wiederherstellen"}>
              <IconButton
                className={classes.icon}
                onClick={loadContentFromSessionStorage}
                size="large">
                <SettingsBackupRestore />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </ValidatorForm>
    </LegalbirdIoModal>
  );
};

WriteEmail.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  refreshEmails: PropTypes.func,
};

WriteEmail.defaultProps = {
  refreshEmails: () => {},
};

export default WriteEmail;
