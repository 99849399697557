import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import moment from "moment";
import _ from "lodash";
import React, { useState } from "react";
import useStyles from "./activityHistoryStyles";
import { getActivityLabel } from "../../../services/Activities/activityTypes";
import { useBackofficeUser } from "../../../provider/BackofficeUserProvider";

const ActivityHistory = ({ activity, productClassName }) => {
  const classes = useStyles();
  const [showHistory, setShowHistory] = useState(false);
  const { backofficeUsers } = useBackofficeUser();

  const historyTranslator = (field, oldEntry, newEntry) => {
    switch (field) {
      case "mandatory":
        return (
          "Pflicht?: " + (oldEntry ? "Pflicht" : "keine Pflicht") + " >> " + (newEntry ? "Pflicht" : "keine Pflicht")
        );
      case "dueDate":
        return "Datum: " + moment(oldEntry).format("DD.MM.YYYY") + " >> " + moment(newEntry).format("DD.MM.YYYY");
      case "dueTime":
        const oldValue = oldEntry === "allDay" ? "Ganztägig" : oldEntry + " Uhr";
        const newValue = newEntry === "allDay" ? "Ganztägig" : newEntry + " Uhr";
        return "Uhrzeit: " + oldValue + " >> " + newValue;
      case "type":
        return (
          "Aktivitätentyp: " +
          getActivityLabel(productClassName, oldEntry) +
          " >> " +
          getActivityLabel(productClassName, newEntry)
        );
      case "subject":
        return "Beschreibung: " + oldEntry + " >> " + newEntry;
      case "note":
        return (
          <>
            Notiz: <span className={classes.note}>{oldEntry?.replace(/(<([^>]+)>)/gi, "")}</span> {">> "}
            <span className={classes.note}>{newEntry.replace(/(<([^>]+)>)/gi, "")}</span>
          </>
        );
      case "assignedUser":
        const oldUser = _.find(backofficeUsers, (user) => user["@id"] === oldEntry);
        const newUser = _.find(backofficeUsers, (user) => user["@id"] === newEntry);
        return (
          "Zu erledigen durch: " +
          (oldUser ? oldUser.person.fullname : "Unbekannt") +
          " >> " +
          (newUser ? newUser.person.fullname : "Unbekannt")
        );
      default:
        break;
    }
  };

  const differenceHeadline = (done) => {
    if (done === true) {
      return "Erledigt";
    }
    if (done === false) {
      return "Wieder geöffnet";
    }

    return "Geändert";
  };

  return (
    <Grid container justifyContent={"center"}>
      <Grid item xs={12}>
        <Button className={classes.button} fullWidth={false} onClick={() => setShowHistory(!showHistory)}>
          Historie {showHistory ? "ausblenden" : "anzeigen"}
        </Button>
      </Grid>
      {showHistory && (
        <Grid item xs={10} className={classes.listContainer}>
          <div>
            <strong>Erstellt:</strong> {moment(activity.created).format("DD.MM.YYYY | HH:mm")} Uhr |{" "}
            {activity.creator ? activity.creator.person.fullname : "Unbekannt"}
          </div>
          {_.map(activity.activityLog, (logEntry, created) => (
            <div key={created}>
              <strong>{differenceHeadline(logEntry.done && logEntry.done.current)}:</strong>{" "}
              {moment(created).format("DD.MM.YYYY | HH:mm")} Uhr | {logEntry.updatedBy}
              <div className={classes.activityLogEntries}>
                {_.map(logEntry, (entry, key) => {
                  return (
                    <div key={key} className={classes.changeRow}>
                      {historyTranslator(key, entry.previous, entry.current)}
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export default ActivityHistory;
