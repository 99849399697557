import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import rootReducer from "./store/reducers";
import { loadState, saveState } from "./services/localStorage";
import _ from "lodash";
import thunk from "redux-thunk";

const persistedState = loadState();
const store = createStore(rootReducer, persistedState, applyMiddleware(thunk));

store.subscribe(
    _.throttle(() => {
        saveState({
            backofficeUser: store.getState().backofficeUser,
        });
    }, 1000)
);

const container = document.getElementById("root");
// @ts-ignore
let root = createRoot(container);

root.render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>
);
