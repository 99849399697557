import { fetchCollection } from "../store/generic/actions";
import _ from "lodash";

export const activitiesFilterString = (product, filter) => {
  const urlFilterParams = new URLSearchParams(
    _.merge({ "case": product?.backofficeCase ? product.backofficeCase["@id"] : 0 }, filter)
  );
  return urlFilterParams.toString();
};

export const updateActivities = (product, filter, dispatch) => {
  const urlFilterString = activitiesFilterString(product, filter);
  dispatch(fetchCollection("activities?" + urlFilterString, "activities?" + urlFilterString));
};
