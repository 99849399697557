import React from "react";
import { Paper } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import * as PropTypes from "prop-types";
import settlementCalculator from "../../../services/settlementCalculator";

const useStyles = makeStyles(() => ({
  paper: {
    margin: "2rem 0 2rem 0",
    padding: "2rem",
    fontSize: "1rem",
  },
  headline: {
    marginBottom: "1rem",
    textAlign: "center",
    fontSize: "1.25rem",
  },
  realCostBox: {
    marginTop: "3rem",
  },
}));

const CostBoxSettlement = (props) => {
  const classes = useStyles(props);
  const { settlement } = props;

  // const currentCosts = settlementCalculator(settlement, settlement.costs.valueInDispute);
  const oldCosts = settlementCalculator(settlement, settlement.costs.valueInDispute);

  return (
    <Paper className={classes.paper}>
      <div className={classes.headline}>{"Berechnete Kosten"}</div>
      <p>
        <strong>Erfolgsprovision</strong>
        <br />
        Geschätzte Abfindung: {oldCosts.settlementValue.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
        <br />
        {settlement.paymentType === "nwnf" && settlement.costs.nWnFAmount && (
          <>
            NWNF-Gebühr (berechnet):{" "}
            {settlement.costs.nWnFAmount.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
          </>
        )}
      </p>
      <p>
        <strong>Nach RVG</strong>
        <br />
        Streitwert in Klage:{" "}
        {settlement.costs.valueInDispute.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
        <br />
        Verfahrensgebühr: {oldCosts.processCosts.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
        <br />
        Terminsgebühr: {oldCosts.hearingCosts.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
        <br />
        TG&VG inkl. Auslage: {oldCosts.fullCosts.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
        <br />
        Einigungsgebühr: {oldCosts.settlementCosts.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
      </p>
      {/*
        (((settlement.paymentType === "insurance" || settlement.paymentType === "private") && settlement.costs.valueInDispute !== settlement.costs.valueInDisputeOld) || (settlement.paymentType === "nwnf" && settlement.costs.nWnFAmountOld !== settlement.costs.nWnFAmount)) &&
        <div className={classes.realCostBox}>
          <div className={classes.headline}>{"Tatsächliche Kosten"}</div>
          {
            ((settlement.paymentType === "insurance" || settlement.paymentType === "private") && settlement.costs.valueInDispute !== settlement.costs.valueInDisputeOld) &&
            <p>
            Streitwert in Klage: {settlement.costs.valueInDispute.toLocaleString("de-DE", {style: "currency", currency: "EUR"})}<br/>
            Verfahrensgebühr: {currentCosts.processCosts.toLocaleString("de-DE", {style: "currency", currency: "EUR"})}<br/>
            Terminsgebühr: {currentCosts.hearingCosts.toLocaleString("de-DE", {style: "currency", currency: "EUR"})}<br/>
            TG&VG inkl. Auslage: {currentCosts.fullCosts.toLocaleString("de-DE", {style: "currency", currency: "EUR"})}<br/>
            Einigungsgebühr: {currentCosts.settlementCosts.toLocaleString("de-DE", {style: "currency", currency: "EUR"})}
          </p>
          }
          {
            (settlement.paymentType === "nwnf" && settlement.costs.nWnFAmountOld !== settlement.costs.nWnFAmount) &&
            <p>
            Finale NWNF-Gebühr: {settlement.costs.nWnFAmount.toLocaleString("de-DE", {style: "currency", currency: "EUR"})}
          </p>
          }
        </div>
      */}
    </Paper>
  );
};

CostBoxSettlement.propTypes = {
  settlement: PropTypes.object.isRequired,
};

export default CostBoxSettlement;
