import { useSelector } from "react-redux";
import { getGeneric } from "../../../store/generic/reducer";
import { getBackofficeUser } from "../../../store/backofficeUser/reducer";
import React from "react";
import { Box } from "@mui/material";
import LegalbirdLoader from "../../ContentLoader/LegalbirdLoader";
import layoutStyle from "../layoutStyle";
import { userHasRole } from "../../../services/backofficeUserService";
import { Roles } from "../../../types/BackofficeUser";

export default function BlockPage() {
  const currentUser = useSelector((state) => getBackofficeUser(state));
  const techStatus = useSelector((state) => getGeneric(state, "techStatus"));

  if (userHasRole(currentUser, Roles.admin) || !techStatus?.release) {
    return null;
  }

  return (
    <Box sx={layoutStyle.blockPage}>
      <LegalbirdLoader />
    </Box>
  );
}