import React, { useEffect } from "react";
import useForm from "../../../hooks/useForm";
import { convertFromRaw, EditorState } from "draft-js";
import moment from "moment";
import ApiClient from "../../../services/ApiClient";
import _ from "lodash";
import { editorStateToHTML } from "../../../services/Editor/editorFunctions";
import { useSnackbar } from "notistack";
import Snackbar from "../../Snackbar/Snackbar";
import { getCaseLink } from "../../../services/Product/ProductService";
import { buildAddress } from "./casePdfGeneratorService";
import { useDispatch, useSelector } from "react-redux";
import { getBackofficeUser } from "../../../store/backofficeUser/reducer";
import {
  fetchMediaObjects,
  fetchMediaObjectsExternal,
  getMediaObjectsIdentifier,
} from "../../../hooks/useMediaObjects";
import { getGeneric } from "../../../store/generic/reducer";
import { clearSessionStorageState, loadSessionStorageState } from "../../../services/browserStorageService";
import SubmitButton from "../../Button/SubmitButton";
import makeStyles from "@mui/styles/makeStyles";
import casePdfGeneratorStyle from "./casePdfGeneratorStyle";
import PdfGeneratorFormElements from "./PdfGeneratorFormElements";
import PdfGeneratorPreview from "./PdfGeneratorPreview";
import ValidatorForm from "../../Validator/ValidatorForm";
import AddTextBlock from "../../Templates/AddTextBlock";
import ContentBox from "../../ContentBox/ContentBox";
import { formValue } from "../../../services/formServiceFunctions";
import { Box, MenuItem } from "@mui/material";
import ValidatorSelect from "../../Validator/ValidatorSelect";
import { notNoneSelectValidator } from "../../../services/validationRules";

const useStyles = makeStyles(casePdfGeneratorStyle);

const CasePdfGenerator = ({ product, documentTypeLabel, ctaLabel }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => getBackofficeUser(state));
  const mediaObjects = useSelector((state) => getGeneric(state, getMediaObjectsIdentifier(product)));
  const classes = useStyles();
  useEffect(() => {
    if (!!product) {
      currentUser.isExternal
        ? fetchMediaObjectsExternal(product, dispatch)
        : fetchMediaObjects(product, dispatch);
    }
  }, [product, dispatch, currentUser]);

  const getParams = () => {
    return _.merge({}, values, {
      content: editorStateToHTML(values.content),
      address: buildAddress(values, product),
      externalReference: !_.isEmpty(values.externalReference) ? values.externalReference : null,
      senderMedium: values.senderMedium === "none" ? null : values.senderMedium,
      externalLawyer: currentUser.isExternal ? currentUser : null,
    });
  };

  const onSubmit = async () => {
    await ApiClient.post("files/generate/" + product.productClassName + "/" + product.id, {
      body: JSON.stringify(_.merge({}, getParams(), { documentIdentifier: "customPdf" })),
    });

    enqueueSnackbar("", {
      content: () => (
        <div>
          <Snackbar
            message={
              currentUser.isExternal
                ? "Schriftsatz wurde erfolgreich erstellt und am Fall angehängt"
                : "Schreiben wurde erfolgreich erstellt"
            }
            buttonType={"link"}
            buttonLink={getCaseLink(product, currentUser.isExternal ? "/extern" : "")}
            buttonTarget={"_self"}
            buttonText={"Zum Fall"}
          />
        </div>
      ),
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
      autoHideDuration: 10000,
    });

    clearForm();
    clearSessionStorageState("customPdf");
  };

  const initialValues = {
    recipientType: "custom",
    address: {
      name: "",
      department: "",
      subDepartment: "",
      streetAddress: "",
      postalCode: "",
      addressLocality: "",
    },
    senderMedium: "none",
    externalReference: "",
    externalReferenceSelect: "free",
    filename: moment().format("YYYYMMDD") + "_" + documentTypeLabel + "_Schreiben",
    content: EditorState.createEmpty(),
    showInGallery: "false",
    sendEmailToCustomer: "false",
    selectedMediaObjects: [],
    electronicFileFolderPath: "none",
  };

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    isLoading,
    clearForm,
    errors,
    registerValidators,
    updateAllValues,
  } = useForm({
    initialValues,
    onSubmit,
  });

  useEffect(() => {
    const sessionStorageValues = loadSessionStorageState("customPdf");
    if (sessionStorageValues) {
      updateAllValues({
        recipientType: sessionStorageValues.recipientType,
        address: {
          name: sessionStorageValues.address.name,
          department: sessionStorageValues.address.department,
          subDepartment: sessionStorageValues.address.subDepartment,
          streetAddress: sessionStorageValues.address.streetAddress,
          postalCode: sessionStorageValues.address.postalCode,
          addressLocality: sessionStorageValues.address.addressLocality,
        },
        senderMedium: sessionStorageValues.senderMedium,
        externalReference: sessionStorageValues.externalReference,
        externalReferenceSelect: sessionStorageValues.externalReferenceSelect,
        filename: sessionStorageValues.filename,
        content: EditorState.createWithContent(convertFromRaw(sessionStorageValues.content)),
        showInGallery: sessionStorageValues.showInGallery,
        sendEmailToCustomer: sessionStorageValues.sendEmailToCustomer,
        selectedMediaObjects: sessionStorageValues.selectedMediaObjects,
        electronicFileFolderPath: sessionStorageValues.electronicFileFolderPath,
      });
    }
  }, [updateAllValues]);

  return (
    <ContentBox headline={documentTypeLabel + " Schreiben erstellen"}>
      <ValidatorForm onSubmit={handleSubmit} submitOnEnter={false}>
        <PdfGeneratorFormElements
          values={values}
          product={product}
          registerValidators={registerValidators}
          errors={errors}
          handleBlur={handleBlur}
          handleChange={handleChange}
          mediaObjects={mediaObjects}
          editorToolBarCustomButtons={[<AddTextBlock product={product} formValues={values} />]}
        />
        <PdfGeneratorPreview product={product} values={values} getParams={getParams} mediaObjects={mediaObjects} />
        <Box sx={{width: 200, margin: "0 auto"}}>
          <ValidatorSelect
            label={"Ablage in Ordner"}
            name={"electronicFileFolderPath"}
            value={formValue(values, "electronicFileFolderPath")}
            onChange={handleChange}
            onBlur={handleBlur}
            registerValidators={registerValidators}
            validators={notNoneSelectValidator}
            error={!!errors["electronicFileFolderPath"]}
            helperText={errors["electronicFileFolderPath"]}
          >
            <MenuItem value={"none"} disabled>
              {" "}
            </MenuItem>
            <MenuItem value={"Außergerichtliches Verfahren"}>Außergerichtliches Verfahren</MenuItem>
            <MenuItem value={"Gerichtliches Verfahren"}>Gerichtliches Verfahren</MenuItem>
            <MenuItem value={"Mandant"}>Mandant</MenuItem>
            <MenuItem value={"RSV"}>RSV</MenuItem>
            <MenuItem value={"ToDo"}>ToDo</MenuItem>
            <MenuItem value={"Sonstiges"}>Sonstiges</MenuItem>
          </ValidatorSelect>
        </Box>
        <SubmitButton
          className={classes.submitButton}
          error={_.keys(errors).length > 0}
          type={"submit"}
          fullWidth={false}
          disabled={!values.content.getCurrentContent().hasText()}
          isLoading={isLoading}
          variant={"contained"}
        >
          {ctaLabel}
        </SubmitButton>
      </ValidatorForm>
    </ContentBox>
  );
};

CasePdfGenerator.defaultProps = {
  documentTypeLabel: "Individuelles",
  ctaLabel: "Schreiben generieren",
};

export default CasePdfGenerator;
