const legalbirdIoModalStyle = (theme) => ({
  dividerContainer: {
    marginBottom: "1rem",
  },
  divider: {
    backgroundColor: "#cdcdcd",
  },
  dialogContent: {
    [theme.breakpoints.up("md")]: {
      minWidth: 600,
    }
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
  flexHeadline: {
    flexGrow: 1,
    textAlign: "center",
  },
  fixedSizeFlexItem: {
    width: 48,
  },
  stack: {
    margin: "0 auto",
    minWidth: 200,
  },
});

export default legalbirdIoModalStyle;
