import { fetchCollection } from "../store/generic/actions";
import {
  PAID_TASK_STATUS_ASSIGNED,
  PAID_TASK_STATUS_CREATED,
  PAID_TASK_STATUS_IN_REVIEW, PAID_TASK_STATUS_REQUESTED
} from "../components/PaidTask/paidTaskStatus";

export const fetchPaidTasks = (product, dispatch) => {
  if (!product) return;
  const uri = `paid_tasks?backofficeCase=${product.backofficeCase["@id"]}&exists[deletedDate]=false&status[]=${PAID_TASK_STATUS_CREATED}&status[]=${PAID_TASK_STATUS_REQUESTED}&status[]=${PAID_TASK_STATUS_ASSIGNED}&status[]=${PAID_TASK_STATUS_IN_REVIEW}`;
  return dispatch(fetchCollection(uri, `paidTasks${product.backofficeCase?.id}`));
};
