import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import ValidatorSelect from "../../../Validator/ValidatorSelect";
import _ from "lodash";
import useForm from "../../../../hooks/useForm";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { updateResource } from "../../../../store/generic/actions";
import ApiClient from "../../../../services/ApiClient";
import { TextField } from "@mui/material";
import LegalbirdIoModal from "../../../Modal/LegalbirdIoModal";

const TrafficRelatedMediaObjects = ({ product, mediaObjects, refreshPage }) => {
  const dispatch = useDispatch();
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [requestDocumentText, setRequestDocumentText] = useState("");

  let initialValues = {
    fineNotice: product.fineNotice ? product.fineNotice["@id"] : "__null__",
  };

  const handleUpdate = async (withReset) => {
    await dispatch(
      updateResource(product.id, "traffic", {
        fineNotice: values.fineNotice === "__null__" ? null : values.fineNotice,
        requestDocumentText: withReset ? requestDocumentText : null,
      })
    );

    let updateMediaObjects = [];
    _.forEach(values, (value, key) => {
      if (initialValues[key] !== value && product[key]) {
        updateMediaObjects.push(
          ApiClient.put(product[key]["@id"], {
            body: JSON.stringify({
              description: product[key].description + " (ungültig)",
              fieldname: key + ".previous",
            }),
          })
        );
        if (value !== "__null__") {
          updateMediaObjects.push(
            ApiClient.put(value, {
              body: JSON.stringify({
                description: "Bußgeldbescheid",
                fieldname: key,
              }),
            })
          );
        }
      }
    });

    Promise.all(updateMediaObjects).then(() => refreshPage());
  };

  const onSubmit = async ({ values }) => {
    const reset = product.fineNotice !== null && values.fineNotice === "__null__";
    if (reset) {
      setConfirmationDialogOpen(true);
      return;
    }
    await handleUpdate(false);
  };

  const { values, handleChange, handleSubmit, handleBlur, touchedValues } = useForm({
    initialValues,
    onSubmit,
    identifier: mediaObjects,
  });

  return (
    <>
      <form onSubmit={handleSubmit}>
        <ValidatorSelect
          label={"Bußgeldbescheid"}
          name={"fineNotice"}
          value={values.fineNotice}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {_.map(mediaObjects, (mediaObject) => (
            <MenuItem
              key={mediaObject.id}
              value={mediaObject["@id"]}
              disabled={
                !!_.find(values, (value) => {
                  return value === mediaObject["@id"];
                })
              }
            >
              {mediaObject.description}
            </MenuItem>
          ))}
          <MenuItem value={"__null__"}>{product.fineNotice === null ? "Nicht gesetzt" : "Erneut anfordern"}</MenuItem>
        </ValidatorSelect>
        <br />
        <br />
        <br />
        <Button variant={"contained"} type={"submit"} disabled={_.keys(touchedValues).length === 0}>
          Speichern und Abschicken
        </Button>
      </form>
      <LegalbirdIoModal
        handleClose={() => setConfirmationDialogOpen(false)}
        open={confirmationDialogOpen}
        title={"Dokumente neu anfordern?"}
        submitButton={
          <Button
            variant={"contained"}
            disabled={requestDocumentText.length <= 30}
            onClick={() => {
              setConfirmationDialogOpen(false);
              handleUpdate(true);
            }}
          >
            Dokumente anfordern
          </Button>
        }
      >
        <p className={"center"}>
          Der Kunde erhält eine E-Mail, dass er etwas neu einreichen muss. Bitte geben Sie im Feld unten genau an, was
          noch fehlt und was der Kunde beim Hochladen der Dokumente beachten soll.{" "}
          <strong>Bitte auf Rechtschreibung achten - der Kunde erhält diesen Text als Hinweis in der Email!</strong>
        </p>
        <TextField
          label={"Begründung/Text an den Kunden"}
          multiline
          rows={8}
          value={requestDocumentText}
          onChange={({ target }) => setRequestDocumentText(target.value)}
          placeholder={"Hinweise/Anregungen an den Kunden, was fehlt oder was neu hochgeladen werden muss."}
        />
      </LegalbirdIoModal>
    </>
  );
};

export default TrafficRelatedMediaObjects;
