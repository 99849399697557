import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import scannedMediaObjectListStyle from "./scannedMediaObjectListStyle";
import { fetchCollection } from "../../store/generic/actions";
import { useDispatch, useSelector } from "react-redux";
import { getGeneric } from "../../store/generic/reducer";
import LegalbirdLoader from "../ContentLoader/LegalbirdLoader";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import ContentBox from "../ContentBox/ContentBox";
import _ from "lodash";
import ScannedMediaObjectRow from "./ScannedMediaObjectRow";
import ButtonLoading from "../Button/ButtonLoading";
import ApiClient from "../../services/ApiClient";
import FileIdentifierProvider from "../../provider/FileIdentifierProvider";

const useStyles = makeStyles(scannedMediaObjectListStyle);

export default function ScannedMediaObjectList({}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const mediaObjectsToCheck = useSelector((state) => getGeneric(state, "mediaObjectsToCheck"));

  useEffect(() => {
    fetchMediaObjectsToCheck();
  }, []);

  const fetchMediaObjectsToCheck = async () => {
    setIsLoading(true);
    await dispatch(fetchCollection("media_objects?scanCheck=required", "mediaObjectsToCheck"));
    setIsLoading(false);
  };

  if (!mediaObjectsToCheck) {
    return <LegalbirdLoader centered />;
  }

  const mediaObjects = mediaObjectsToCheck["hydra:member"];

  const handleConfirmClick = async () => {
    setIsLoading(true);
    let checkedMediaObjects = [];
    _.forEach(mediaObjects, (mediaObject) => {
      if (mediaObject.scanMetaData?.checkedDate) {
        checkedMediaObjects.push(mediaObject.id);
      }
    });
    await ApiClient.post("lb/services/checked_media_objects", { body: JSON.stringify(checkedMediaObjects) });
    await fetchMediaObjectsToCheck();
    setIsLoading(false);
  };

  return (
    <ContentBox>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Datum</TableCell>
            <TableCell>Dateiname</TableCell>
            <TableCell />
            <TableCell>Zugeordneter Fall</TableCell>
            <TableCell />
            <TableCell className={classes.searchColumn}>Suche</TableCell>
            <TableCell className={classes.identifierColumn}>Kenner</TableCell>
            <TableCell>Prüfen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <FileIdentifierProvider>
          {mediaObjects.length < 1 ? (
            <TableRow>
              <TableCell colspan={7} className={classes.centerText}>
                Keine zuzuordnenden Dateien
              </TableCell>
            </TableRow>
          ):(
            _.map(mediaObjects, (mediaObject) => (
              <ScannedMediaObjectRow
                mediaObject={mediaObject}
                key={mediaObject.id}
                refreshList={fetchMediaObjectsToCheck}
              />
            ))
          )}
          </FileIdentifierProvider>
        </TableBody>
      </Table>
      <ButtonLoading
        variant={"contained"}
        className={classes.button}
        onClick={handleConfirmClick}
        isLoading={isLoading}
        disabled={!_.find(mediaObjects, mediaObject => mediaObject.scanMetaData?.checkedDate)}
      >
        Prüfung bestätigen
      </ButtonLoading>
    </ContentBox>
  );
}
