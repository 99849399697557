import React, { useState } from "react";
import * as PropTypes from "prop-types";
import editActivityNoteStyle from "./editActivityNoteStyle";
import { IconButton } from "@mui/material";
import { NoteAddOutlined } from "@mui/icons-material";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import { formValue } from "../../../services/formServiceFunctions";
import { Editor } from "react-draft-wysiwyg";
import useForm from "../../../hooks/useForm";
import _ from "lodash";
import { updateResource } from "../../../store/generic/actions";
import { useDispatch } from "react-redux";
import SubmitButton from "../../Button/SubmitButton";
import { editorStateToHTML, htmlToEditorState } from "../../../services/Editor/editorFunctions";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(editActivityNoteStyle);

export default function EditActivityNote({ activity, updateActivities }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [editNoteOpen, setEditNoteOpen] = useState(false);

  const initialValues = {
    note: htmlToEditorState(activity.note),
  };

  const onSubmit = async () => {
    let updateData = _.merge({}, values);
    updateData.note = editorStateToHTML(updateData.note, true);
    await dispatch(updateResource(activity.id, "activities", updateData));
    await updateActivities();
    setEditNoteOpen(false);
  };

  const { values, handleChange, handleSubmit, touchedValues, isLoading } = useForm({
    initialValues,
    onSubmit,
    identifier: activity,
    clearFormAfterSubmit: true,
  });

  const handleEditorStateChange = (editorState) => {
    handleChange({
      target: {
        name: "note",
        value: editorState,
      },
    });
  };

  return (
    <div className={classes.iconButtonContainer}>
      <IconButton onClick={() => setEditNoteOpen(true)}>
        <NoteAddOutlined />
      </IconButton>
      <LegalbirdIoModal
        handleClose={() => setEditNoteOpen(false)}
        open={editNoteOpen}
        submitButton={
          <SubmitButton
            onClick={handleSubmit}
            variant={"contained"}
            disabled={_.keys(touchedValues).length === 0}
            isLoading={isLoading}
          >
            Speichern
          </SubmitButton>
        }
        title={"Notiz für Beratungstermin"}
      >
        <Editor
          toolbar={{
            options: ["inline", "list", "link"],
            inline: {
              options: ["bold", "italic", "underline"],
            },
            list: {
              options: ["unordered", "ordered"],
            },
          }}
          stripPastedStyles
          editorState={formValue(values, "note")}
          onEditorStateChange={handleEditorStateChange}
          editorClassName={classes.editor}
          placeholder={"Notiz"}
          localization={{ locale: "de" }}
        />
      </LegalbirdIoModal>
    </div>
  );
}

EditActivityNote.propTypes = {
  activity: PropTypes.object.isRequired,
  updateActivities: PropTypes.func.isRequired,
};
