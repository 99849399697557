import React, { useEffect, useState } from "react";
import PageContentContainer from "../../../Container/PageContentContainer";
import CaseOverviewPageLoader from "../../../ContentLoader/CaseOverviewPageLoader";
import CaseNotFound from "../../../Case/CaseNotFound/CaseNotFound";
import CaseError from "../../../Case/CaseError/CaseError";
import { Grid } from "@mui/material";
import CaseHeader from "../../../Externals/Case/CaseHeader/CaseHeader";
import Contact from "../../../Externals/Case/Contact/Contact";
import CaseData from "../../../Externals/Case/CaseData/CaseData";
import Activities from "../../../Activities/Activities";
import { useDispatch, useSelector } from "react-redux";
import { getBackofficeUser } from "../../../../store/backofficeUser/reducer";
import MediaObjects from "../../../MediaObjectSection/MediaObjects";
import { fetchMediaObjectsExternal } from "../../../../hooks/useMediaObjects";
import PinnedNotes from "../../../PinnedNotes/PinnedNotes";
import { fetchCollection } from "../../../../store/generic/actions";
import {
  PAID_TASK_STATUS_ASSIGNED,
  PAID_TASK_STATUS_CREATED,
  PAID_TASK_STATUS_IN_REVIEW,
} from "../../../PaidTask/paidTaskStatus";
import PaidTasksCase from "../../../PaidTask/PaidTasksCase/PaidTasksCase";
import Emails from "../../../Email/Emails";
import { useParams } from "react-router-dom";
import CaseRelatedActions from "../../../Case/CaseRelatedActions/CaseRelatedActions";
import NavigationExtensionCasePage from "../../../NavigationBar/NavigationExtensionCasePage";
import CaseProvider, { useCase } from "../../../../provider/CaseProvider";
import CaseNotAllowed from "../../../Case/CaseNotFound/CaseNotAllowed";

const refreshPaidTasks = async (product, dispatch) => {
  await dispatch(
    fetchCollection(
      "paid_tasks?backofficeCase=" +
        product.backofficeCase["@id"] +
        "&exists[deletedDate]=false" +
        "&status[]=" +
        PAID_TASK_STATUS_CREATED +
        "&status[]=" +
        PAID_TASK_STATUS_ASSIGNED +
        "&status[]=" +
        PAID_TASK_STATUS_IN_REVIEW,
      "paidTasks" + product.backofficeCase.id
    )
  );
};

const ExternalsCasePage = () => {
  const { productId, productUrlPart } = useParams();
  return (
    <CaseProvider caseUrlPart={productUrlPart} caseId={productId}>
      <ExternalsCasePageInner />
    </CaseProvider>
  );
}
const ExternalsCasePageInner = () => {
  const { product, error, isLoading } = useCase();
  const currentUser = useSelector((state) => getBackofficeUser(state));
  const [activitiesRefreshCounter, setActivitiesRefreshCounter] = useState(0);
  const [emailsRefreshCounter, setEmailsRefreshCounter] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!product) {
      fetchMediaObjectsExternal(product, dispatch);
      refreshPaidTasks(product, dispatch);
    }
  }, [product, dispatch, currentUser]);

  const refreshActivities = () => {
    setActivitiesRefreshCounter(activitiesRefreshCounter + 1);
  };

  const refreshEmails = () => {
    setEmailsRefreshCounter(emailsRefreshCounter + 1);
  };

  if (isLoading) {
    return (
      <PageContentContainer>
        <CaseOverviewPageLoader />
      </PageContentContainer>
    );
  }

  if (error?.status === 404 || product?.customer?.isFake) {
    return <CaseNotFound />;
  }

  if (error?.status === 403) {
    return <CaseNotAllowed />;
  }

  if (!!error) {
    return <CaseError errorMessage={error.error} errorStatus={error.status} />;
  }

  return (
    <>
      <CaseRelatedActions product={product} refreshEmails={refreshEmails} refreshActivities={refreshActivities} />
      <NavigationExtensionCasePage product={product} />
      <PageContentContainer>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CaseHeader product={product} />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Contact product={product} />
            <CaseData product={product} />
          </Grid>
          <Grid item xs={12} lg={8}>
            <PinnedNotes product={product} isEditable={false} />
            <PaidTasksCase product={product} refreshFunction={() => refreshPaidTasks(product, dispatch)} />
            <Activities
              product={product}
              additionalFilters={{ assignedUser: currentUser["@id"] }}
              refreshCounter={activitiesRefreshCounter}
              refreshActivities={() => setActivitiesRefreshCounter(activitiesRefreshCounter + 1)}
            />
            <MediaObjects product={product} />
            <Emails
              product={product}
              isExternalUser={true}
              refreshCounter={emailsRefreshCounter}
              refreshEmails={() => setEmailsRefreshCounter(emailsRefreshCounter + 1)}
            />
          </Grid>
        </Grid>
      </PageContentContainer>
    </>
  );
};

export default ExternalsCasePage;
