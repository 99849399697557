import React, { useState } from "react";
import * as PropTypes from "prop-types";
import useStyles from "./bookingEntryStyle";
import { IconButton, MenuItem, TableCell, TableRow, Tooltip } from "@mui/material";
import moment from "moment";
import Grid from "@mui/material/Grid";
import { Attachment, DeleteOutline, NoteAddOutlined } from "@mui/icons-material";
import ApiClient from "../../services/ApiClient";
import DisplayMediaObject from "../MediaObjectSection/DisplayMediaObject";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { getGeneric } from "../../store/generic/reducer";
import { getMediaObjectsIdentifier } from "../../hooks/useMediaObjects";
import _ from "lodash";
import ValidatorSelect from "../Validator/ValidatorSelect";
import ButtonLoading from "../Button/ButtonLoading";

const BookingEntry = ({ bookingEntry, updateBookingEntries, product, connectedReceiptIds, backofficeUsers }) => {
  const classes = useStyles({});
  const [isLoading, setIsLoading] = useState(false);
  const [displayMediaObject, setDisplayMediaObject] = useState(false);
  const [mediaObjectToUpload, setMediaObjectToUpload] = useState(0);
  const [deleteBookingentryOpen, setDeleteBookingentryOpen] = useState(false);
  const [addReceiptOpen, setAddReceiptOpen] = useState(false);
  const mediaObjects = useSelector((state) => getGeneric(state, getMediaObjectsIdentifier(product)));

  const isAdminUser = true;

  if (!mediaObjects) {
    return null;
  }

  const availableMediaObjects = _.filter(mediaObjects["hydra:member"], (mediaObject) => {
    return mediaObject.mimeType === "application/pdf" && !connectedReceiptIds.includes(mediaObject.id);
  });

  const saveReceipt = async () => {
    setIsLoading(true);
    await ApiClient.put("booking_entries/" + bookingEntry.id, {
      body: JSON.stringify({
        receipt: "/media_objects/" + mediaObjectToUpload,
        organization: "/organizations/" + bookingEntry.organization.id,
      }),
    });
    updateBookingEntries();
    setAddReceiptOpen(false);
    setIsLoading(false);
  };

  const deleteBookingEntry = async () => {
    setIsLoading(true);
    await ApiClient.put("booking_entries/" + bookingEntry.id, {
      body: JSON.stringify({
        deleted: true,
        receipt: null,
        organization: null,
      }),
    });
    updateBookingEntries();
    setIsLoading(false);
    setDeleteBookingentryOpen(false);
  };

  const BookingType = ({ bookingEntry }) => {
    let displayedType;
    switch (true) {
      case bookingEntry.type === "invoice" && bookingEntry.debitOrCredit === "debit":
        displayedType = "Rechnung";
        break;
      case bookingEntry.type === "invoice" && bookingEntry.debitOrCredit === "credit":
        displayedType = "Gutschrift";
        break;
      case bookingEntry.type === "payment" && bookingEntry.debitOrCredit === "debit":
        displayedType = "Auszahlung";
        break;
      case bookingEntry.type === "payment" && bookingEntry.debitOrCredit === "credit":
        displayedType = "Einzahlung";
        break;
      default:
        displayedType = "";
    }
    if (bookingEntry.invoiceTypeIdentifier) {
      return (
        <Tooltip title={bookingEntry.invoiceTypeIdentifier}>
          <div>{displayedType}</div>
        </Tooltip>
      );
    }
    return displayedType;
  };

  const assignedUser = _.find(backofficeUsers, { id: parseInt(_.split(bookingEntry.createdBy, "/")[2]) });
  return (
    <TableRow>
      <TableCell>
        <BookingType bookingEntry={bookingEntry} />
      </TableCell>
      <TableCell>{moment(bookingEntry.bookingDate).format("DD.MM.YYYY")}</TableCell>
      <TableCell>{bookingEntry.invoiceNumber !== "unknown" && bookingEntry.invoiceNumber}</TableCell>
      <TableCell>{bookingEntry.dueDate && moment(bookingEntry.dueDate).format("DD.MM.YYYY")}</TableCell>
      <TableCell>
        <Tooltip placement={"top-start"} title={<div dangerouslySetInnerHTML={{ __html: bookingEntry.bookingText }} />}>
          <div>{_.truncate(bookingEntry.bookingText)}</div>
        </Tooltip>
      </TableCell>
      <TableCell>{_.get(assignedUser, "person.fullname")}</TableCell>
      <TableCell align={"right"}>
        {bookingEntry.debitOrCredit === "debit" &&
          bookingEntry.amount.toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
          })}
      </TableCell>
      <TableCell align={"right"}>
        {bookingEntry.debitOrCredit === "credit" &&
          bookingEntry.amount.toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
          })}
      </TableCell>
      <TableCell align={"right"}>
        {Math.abs(bookingEntry.difference).toLocaleString("de-DE", {
          style: "currency",
          currency: "EUR",
        })}{" "}
        {bookingEntry.difference < 0 ? "S" : "H"}
      </TableCell>
      <TableCell align={"right"}>{bookingEntry.organization.abbreviation}</TableCell>
      <TableCell>
        <Grid container>
          <Grid item xs={6}>
            {
              // "/backoffice_users/13" is the system user
              moment().diff(bookingEntry.bookingDate, "hours") < 24 &&
                bookingEntry.createdBy !== "/backoffice_users/13" &&
                bookingEntry.type === "payment" && (
                  <>
                    <IconButton
                      className={classes.icon}
                      disabled={isLoading}
                      onClick={() => setDeleteBookingentryOpen(true)}
                      size="large"
                    >
                      <DeleteOutline />
                    </IconButton>
                    <LegalbirdIoModal
                      handleClose={() => setDeleteBookingentryOpen(false)}
                      open={deleteBookingentryOpen}
                      title={"Möchten Sie diesen Eintrag wirklich löschen? Dies kann nicht rückgängig gemacht werden!"}
                      submitButton={
                        <Button onClick={deleteBookingEntry} variant={"contained"}>
                          Eintrag löschen
                        </Button>
                      }
                    />
                  </>
                )
            }
          </Grid>
          <Grid item xs={6}>
            {bookingEntry.receipt && (
              <>
                <Tooltip title={"Rechnung anzeigen"}>
                  <IconButton className={classes.icon} onClick={() => setDisplayMediaObject(true)} size="large">
                    <Attachment />
                  </IconButton>
                </Tooltip>
                <DisplayMediaObject
                  closeDialog={() => {
                    setDisplayMediaObject(false);
                  }}
                  mediaObject={displayMediaObject ? bookingEntry.receipt : null}
                />
              </>
            )}
            {!bookingEntry.receipt && isAdminUser && bookingEntry.type === "invoice" && (
              <>
                <IconButton className={classes.icon} onClick={() => setAddReceiptOpen(true)} size="large">
                  <NoteAddOutlined />
                </IconButton>
                <LegalbirdIoModal
                  handleClose={() => setAddReceiptOpen(false)}
                  open={addReceiptOpen}
                  title={"Beleg verknüpfen"}
                  submitButton={
                    <ButtonLoading isLoading={isLoading} onClick={saveReceipt} variant={"contained"}>
                      Beleg verknüpfen
                    </ButtonLoading>
                  }
                >
                  <ValidatorSelect
                    label={"Beleg"}
                    value={mediaObjectToUpload}
                    onChange={(event) => setMediaObjectToUpload(event.target.value)}
                  >
                    <MenuItem value={0} disabled>
                      Bitte auswählen
                    </MenuItem>
                    {_.map(availableMediaObjects, (mediaObject) => (
                      <MenuItem key={mediaObject.id} value={mediaObject.id}>
                        {mediaObject.description}
                      </MenuItem>
                    ))}
                  </ValidatorSelect>
                </LegalbirdIoModal>
              </>
            )}
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

BookingEntry.propTypes = {
  bookingEntry: PropTypes.object.isRequired,
  difference: PropTypes.number,
  updateBookingEntries: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  connectedReceiptIds: PropTypes.array.isRequired,
};

export default BookingEntry;
