import React, { useRef, useState } from "react";
import * as PropTypes from "prop-types";
import useStyles from "./emailStyle";
import { Box, Grid, IconButton, Stack, Tooltip } from "@mui/material";
import CircleIcon from "../CircleIcon/CircleIcon";
import { Attachment, MailOutline, UnfoldLess, UnfoldMore, VisibilityOffOutlined } from "@mui/icons-material";
import moment from "moment";
import EmailAttachment from "./EmailAttachment";
import _ from "lodash";
import { useSelector } from "react-redux";
import { getBackofficeUser } from "../../store/backofficeUser/reducer";
import EmailMoreMenu from "./EmailMoreMenu";

const Email = ({ emailMessage, isNonInteractive, updateEmailListEntry, product }) => {
  const classes = useStyles({});
  const currentUser = useSelector((state) => getBackofficeUser(state));
  const [emailFolded, setEmailFolded] = useState(true);

  const Icon = <MailOutline color={"secondary"} />;

  const getValidAttachmentsArray = (attachments) => {
    return _.filter(attachments, function (attachment) {
      if (attachment.mimeType !== "text/html" && attachment.mimeType !== "text/plain") {
        return attachment;
      }
    });
  };

  const EmailIFrame = ({ emailMessage }) => {
    const content = emailMessage.messageContent.html || "<div>" + emailMessage.messageContent.text + "</div>";
    const iFrameRef = useRef();
    const [iFrameHeight, setIFrameHeight] = useState(0);
    return (
      <iframe
        title={"email-" + emailMessage.id}
        ref={iFrameRef}
        onLoad={() => setIFrameHeight(iFrameRef.current.contentWindow.document.body.scrollHeight + 30)}
        srcDoc={content}
        style={{ width: "100%", border: "none" }}
        frameBorder={0}
        scrolling={"no"}
        height={iFrameHeight}
      />
    );
  };

  return (
    <Grid container className={classes.emailContainer} alignItems={"flex-start"}>
      <Grid item className={classes.icon}>
        <CircleIcon icon={Icon} type={"muiIcon"} />
      </Grid>
      <Grid item className={classes.mailContent}>
        <Grid container>
          <Grid item xs={12} className={classes.headline}>
            {emailMessage.messageContent.subject}
            {emailMessage.attachments && getValidAttachmentsArray(emailMessage.attachments).length > 0 && (
              <Tooltip title={"Anhang vorhanden"}>
                <Attachment />
              </Tooltip>
            )}
          </Grid>
          <Grid item xs={12} className={classes.info}>
            {moment(emailMessage.created).format("DD.MM.YYYY")} um {moment(emailMessage.created).format("HH:mm")} Uhr
            {emailMessage.from && emailMessage.to && (
              <>
                {" "}
                | {emailMessage.from.email} `{">>"}` {emailMessage.to.email}
              </>
            )}
            {emailMessage.cc && <div> Cc: {emailMessage.cc}</div>}
          </Grid>
          {emailFolded ? (
            <Grid item xs={12} className={classes.textPreview}>
              {_.truncate(emailMessage.messageContent.snippet, { length: 80 })}
            </Grid>
          ) : (
            <Grid item xs={12}>
              <EmailIFrame emailMessage={emailMessage} />
              <Grid container spacing={3} className={classes.attachmentContainer}>
                {getValidAttachmentsArray(emailMessage.attachments) &&
                  _.map(getValidAttachmentsArray(emailMessage.attachments), (attachment) => (
                    <Grid item xs={6} key={attachment.attachmentId}>
                      <EmailAttachment attachment={attachment} emailId={emailMessage.id} product={product} />
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item sx={{flexGrow: 1}}>
        {!isNonInteractive && (
          <Stack direction="row" justifyContent="flex-end">
            {!currentUser.isExternal && (
              <>
                {emailMessage.hiddenExternally && (
                  <Box sx={{ padding: "12px" }}>
                    <VisibilityOffOutlined />
                  </Box>
                )}
                <Box>
                  <EmailMoreMenu
                    emailMessage={emailMessage}
                    product={product}
                    updateEmailListEntry={updateEmailListEntry}
                  />
                </Box>
              </>
            )}
            <Box>
              <IconButton className={classes.expandButton} onClick={() => setEmailFolded(!emailFolded)} size="large">
                {emailFolded ? <UnfoldMore /> : <UnfoldLess />}
              </IconButton>
            </Box>
          </Stack>
        )}
      </Grid>
    </Grid>
  );
};

Email.propTypes = {
  emailMessage: PropTypes.object.isRequired,
  isNonInteractive: PropTypes.bool,
  updateEmailListEntry: PropTypes.func,
};

Email.defaultProps = {
  isNonInteractive: false,
  updateEmailListEntry: () => {},
};

export default Email;
