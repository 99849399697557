import React, {useEffect, useMemo} from "react";
import ButtonLoading from "../../Button/ButtonLoading";
import {Box, Grid} from "@mui/material";
import {useCommunicationMedium} from "./CommunicationMediumProvider";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import SendForm from "./SendForm";
import ValidatorTextField from "../../Validator/ValidatorTextField";
import {formValue} from "../../../services/formServiceFunctions";
import {matchRegexp, requiredFieldDefault} from "../../../services/validationRules";
import ValidatorSelect from "../../Validator/ValidatorSelect";
import MenuItem from "@mui/material/MenuItem";
import _ from "lodash";
import useForm from "../../../hooks/useForm";
import {createResource} from "../../../store/generic/actions";
import {useDispatch, useSelector} from "react-redux";
import {getBackofficeUser} from "../../../store/backofficeUser/reducer";
import {getGeneric} from "../../../store/generic/reducer";
import {getMediaObjectsIdentifier} from "../../../hooks/useMediaObjects";
import {getFaxNumber} from "./communicationHelperFunctions";

export default function FaxSend() {
  const {
    product,
    open,
    handleClose,
    assignedLawyerHasBea,
    recipientType,
    externalReference,
    externalReferenceIsEditable,
  } = useCommunicationMedium();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => getBackofficeUser(state));
  const mediaObjects = _.get(
    useSelector((state) => getGeneric(state, getMediaObjectsIdentifier(product))),
    "hydra:member"
  );

  const faxNumber = useMemo(() => {
    return getFaxNumber(product, recipientType);
  }, [product, recipientType]);

  const initialValues = {
    attachments: [],
    legalDocuments: [],
    fax: "",
    faxAttachment: "",
  };

  const onSubmit = async ({values}: { values: any }) => {
    try {
      const backofficeCase = _.get(product, "backofficeCase") || {"@id": "unknown"};
      await dispatch(
        createResource("faxes", {
          mediaObject: values.faxAttachment,
          targetNumber: values.fax,
          backofficeCase: backofficeCase["@id"],
          createdBy: currentUser["@id"],
          forceSend: true
        })
      );
    } catch (e) {
      alert(JSON.stringify(e));
    }
    handleClose();
  };

  const {values, handleChange, handleBlur, handleSubmit, isLoading, registerValidators, errors} = useForm({
    initialValues,
    onSubmit,
    identifier: "" + product.id,
    clearFormAfterSubmit: true,
  });

  useEffect(() => {
    handleChange({
      target: {
        name: "fax",
        value: faxNumber,
      },
    });
  }, [faxNumber]);

  return (
    <LegalbirdIoModal
      handleClose={handleClose}
      open={open}
      disableBackdropClick
      title={"beA versenden"}
      submitButton={
        <ButtonLoading type={"submit"} variant={"contained"} onClick={handleSubmit} isLoading={isLoading}>
          Nachricht Versenden
        </ButtonLoading>
      }
    >
      {!assignedLawyerHasBea && (
        <Box sx={{color: "red", textAlign: "center"}}>
          Achtung: beA-Versand nicht möglich. Es sind keine beA-Daten für den zugeordneten Anwalt hinterlegt.
        </Box>
      )}
      <SendForm>
        <Grid item xs={12}>
          <ValidatorTextField
            onChange={handleChange}
            onBlur={handleBlur}
            name={"fax"}
            value={formValue(values, "fax")}
            label={"Faxnummer"}
            registerValidators={registerValidators}
            validators={[
              ...requiredFieldDefault,
              {
                validator: matchRegexp,
                params: [new RegExp(/^[1-9]\d+$/)],
                message: "Bitte das Format beachten (Nur Ziffern ohne führende Null)",
              },
            ]}
            error={!!_.get(errors, "fax")}
            helperText={_.get(errors, "fax")}
            disabled={recipientType !== "custom"}
            isMandatory={false}
            dependentValidationFields={[]}
          />
        </Grid>
        <Grid item xs={12}>
          <ValidatorSelect
            label={"Dokument"}
            name={"faxAttachment"}
            value={formValue(values, "faxAttachment")}
            onChange={handleChange}
            onBlur={handleBlur}
            registerValidators={registerValidators}
            validators={requiredFieldDefault}
            error={!!_.get(errors, "faxAttachment")}
            helperText={_.get(errors, "faxAttachment")}
          >
            <MenuItem value={"none"} disabled>
              {" "}
            </MenuItem>
            {_.map(mediaObjects, (mediaObject) => (
              <MenuItem
                key={mediaObject.id}
                value={mediaObject["@id"]}
                disabled={mediaObject.mimeType !== "application/pdf" || mediaObject.size > 2000000}
              >
                {mediaObject.description}
              </MenuItem>
            ))}
          </ValidatorSelect>
        </Grid>
      </SendForm>
    </LegalbirdIoModal>
  );
}
