import React, { useEffect } from "react";
import { Divider } from "@mui/material";
import _ from "lodash";
import { makeStyles } from "@mui/styles";
import paidTaskListStyle from "./paidTaskListStyle";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { getBackofficeUser } from "../../../store/backofficeUser/reducer";
import { fetchCollection } from "../../../store/generic/actions";
import { getGeneric } from "../../../store/generic/reducer";
import { PAID_TASK_STATUS_REQUESTED } from "../../../components/PaidTask/paidTaskStatus";
import PaidTaskListItem from "./PaidTaskListItem";

const useStyles = makeStyles(paidTaskListStyle);

const RequestedPaidTasks = () => {
  const classes = useStyles();
  const currentUser = useSelector((state) => getBackofficeUser(state));
  const dispatch = useDispatch();
  const requestedPaidTasksUri = `paid_tasks?exists[deletedDate]=false&status=${PAID_TASK_STATUS_REQUESTED}&requestedUser=${currentUser["@id"]}`;
  const paidTasks = useSelector((state) => getGeneric(state, _.camelCase(requestedPaidTasksUri)));

  useEffect(() => {
    dispatch(fetchCollection(requestedPaidTasksUri, _.camelCase(requestedPaidTasksUri)));
  }, [currentUser]);

  if (!paidTasks || !paidTasks["hydra:member"] || paidTasks["hydra:member"].length === 0) {
    return null;
  }

  return (
    <>
      <Typography variant={"h2"} align={"center"}>
        Anfragen für Aufgaben
      </Typography>
      <br />
      {_.map(paidTasks["hydra:member"], (paidTask, index) => (
        <React.Fragment key={paidTask.id}>
          <PaidTaskListItem paidTask={paidTask} hideCase isExternalView />
          {index !== paidTasks.length - 1 && (
            <div className={classes.divider}>
              <Divider />
            </div>
          )}
        </React.Fragment>
      ))}
    </>
  );
};
export default RequestedPaidTasks;
