import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  imageContainer: {
    maxHeight: "65vh",
    overflow: "auto",
  },
  embedContainer: {
    minWidth: 800,
    width: "100%",
    height: "60vh",
  },
  imageDialog: {
    textAlign: "center",
  },
  closeIcon: {
    margin: "0 0 2rem auto",
    display: "block",
  },
  buttonContainer: {
    marginTop: "2rem",
  },
  rotate0: {},
  rotate90: {
    transform: "rotate(90deg)",
  },
  rotate180: {
    transform: "rotate(180deg)",
  },
  rotate270: {
    transform: "rotate(270deg)",
  },
  rotate360: {},
  headline: {
    fontSize: "1rem",
    color: "#317ae2",
    cursor: "pointer",
    display: "inline-block",
  },
  loadingWrapper: {
    marginTop: "5rem",
    marginBottom: "5rem",
  },
}));

export default useStyles;
