import Immutable from "seamless-immutable";
import jwtDecode from "jwt-decode";
import _ from "lodash";

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return Immutable(JSON.parse(serializedState));
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (err) {
    // Ignore errors for now
  }
};

export const persistToken = (token) => {
  localStorage.setItem("legalbirdServicesJWT", token);
};

export const loadToken = () => {
  return localStorage.getItem("legalbirdServicesJWT");
};

export const checkToken = (token, userState = null) => {
  if (!_.isString(token) || token.length === 0 || !userState) {
    return false;
  }

  try {
    const decodedToken = jwtDecode(token);
    return (
      (decodedToken.username === userState.email || decodedToken.roles.includes("ROLE_ADMIN")) &&
      decodedToken.exp > Date.now() / 1000
    );
  } catch (err) {
    return false;
  }
};
