import React, { useEffect } from "react";
import _ from "lodash";
import { PAID_TASK_STATUS_REQUESTED } from "../paidTaskStatus";
import { useDispatch, useSelector } from "react-redux";
import { getBackofficeUser } from "../../../store/backofficeUser/reducer";
import { fetchCollection } from "../../../store/generic/actions";
import { getGeneric } from "../../../store/generic/reducer";
import ExternalPaidTaskTable from "./ExternalPaidTaskTable";
import { Box, Paper } from "@mui/material";
import PageHeadline from "../../PageHeadline/PageHeadline";

const RequestedPaidTasks = () => {
  const currentUser = useSelector((state) => getBackofficeUser(state));
  const dispatch = useDispatch();
  const requestedPaidTasksUri = `paid_tasks?exists[deletedDate]=false&status=${PAID_TASK_STATUS_REQUESTED}&requestedUser=${currentUser["@id"]}`;
  const paidTasks = useSelector((state) => getGeneric(state, _.camelCase(requestedPaidTasksUri)));

  useEffect(() => {
    dispatch(fetchCollection(requestedPaidTasksUri, _.camelCase(requestedPaidTasksUri)));
  }, [currentUser]);

  if (!paidTasks || !paidTasks["hydra:member"] || paidTasks["hydra:member"].length === 0) {
    return null;
  }

  return (
    <Box>
      <PageHeadline main={"Anfragen für Aufgaben"} />
      <Paper sx={{border: "1px solid red;"}}>
        <ExternalPaidTaskTable
          paidTasks={paidTasks["hydra:member"]}
          isRequestedView={true}
          noPaidTasksText={"Keine Anfragen für Aufgaben"}
        />
      </Paper>
    </Box>
  );
};
export default RequestedPaidTasks;
