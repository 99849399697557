const divorceTransformations = {
  dateFields: [
    { path: "dateMovedOut", type: "date" },
    { path: "dateSeparation", type: "date" },
    { path: "dateMarriage", type: "date" },
    { path: "powerOfAttorney", type: "dateTime" },
    { path: "costs.installmentRateOverdueSince", type: "date" },
    { path: "costs.courtFeeInvoiceReceivedDate", type: "date" },
    { path: "costs.courtFeePaidDate", type: "date" },
    { path: "processCostSupportVerified", type: "date" },
    { path: "courtDate.appointmentDate", type: "date" },
    { path: "verification", type: "dateTime" },
  ],
  numberFields: [
    "leadStatus",
    "applicationStatus",
    "numberPensions",
    "numberPensionsPartner",
    "numberChildren",
    "netIncomePetitioner",
    "netIncomePartner",
    "responsibleCourt.advoAssistId"
  ],
  moneyFields: [
    "costs.processPre",
    "costs.hearingPre",
    "costs.courtPre",
    "costs.valueInDisputeReal",
    "costs.processReal",
    "costs.hearingReal",
    "costs.courtReal",
  ],
  timeFields: ["courtDate.appointmentTime"],
};
export default divorceTransformations;
