import { combineReducers } from "redux";
import generic from "./generic/reducer";
import backofficeUser from "./backofficeUser/reducer";
import { BACKOFFICEUSER_LOGOUT } from "./backofficeUser/actionTypes";

const appReducer = combineReducers({ generic, backofficeUser });

const rootReducer = (state, action) => {
  if (action.type === BACKOFFICEUSER_LOGOUT) {
    localStorage.removeItem("legalbirdServicesJWT");
    sessionStorage.clear();
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
