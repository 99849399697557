import React from "react";
import * as PropTypes from "prop-types";
import ValidatorTextField from "../Validator/ValidatorTextField";
import MenuItem from "@mui/material/MenuItem";
import useForm from "../../hooks/useForm";
import { formValue, prepareFormCustomerDates } from "../../services/formServiceFunctions";
import ValidatorSelect from "../Validator/ValidatorSelect";
import Grid from "@mui/material/Grid";
import _ from "lodash";
import { getProductData } from "../../services/Product/ProductService";
import { difference, persistCustomer } from "../../services/Persist/Persister";
import {
  duplicateMailDefault,
  emailDefault,
  requiredFieldDefault,
  textFieldDefault,
} from "../../services/validationRules";
import SubmitButton from "../Button/SubmitButton";
import IconButton from "@mui/material/IconButton";
import { Call } from "@mui/icons-material";
import useStyles from "./contactStyle";
import { translate } from "../../services/Translations/translatorService";
import { useDispatch } from "react-redux";
import { fetchResource } from "../../store/generic/actions";
import ValidatorForm from "../Validator/ValidatorForm";
import InputAdornment from "@mui/material/InputAdornment";

const AllDataContact = ({ product, formDisabled }) => {
  const classes = useStyles({});
  const customer = product.customer;
  const dispatch = useDispatch();

  const timeSlots = [];
  for (let i = 8; i <= 18; i++) {
    timeSlots.push(
      <MenuItem key={i} value={"" + i}>
        {i} {translate("customer.contactTime.values.time")}
      </MenuItem>
    );
  }

  const initialValues = { customer: prepareFormCustomerDates(customer) };
  const onSubmit = async () => {
    await persistCustomer(customer.id, difference(values.customer, prepareFormCustomerDates(customer)));
    await dispatch(fetchResource(product.id, getProductData(product.productClassName, "apiUri")));
  };

  const { values, errors, handleChange, handleSubmit, handleBlur, registerValidators, touchedValues, isLoading } =
    useForm({
      initialValues,
      onSubmit,
      identifier: product,
    });

  return (
    <ValidatorForm onSubmit={handleSubmit}>
      <Grid container className={classes.grid}>
        <Grid item xs={12} md={6} lg={4}>
          <ValidatorTextField
            onChange={handleChange}
            onBlur={handleBlur}
            name={"customer.telephone"}
            value={formValue(values, "customer.telephone")}
            label={translate("customer.telephone.label")}
            disabled={formDisabled}
            registerValidators={registerValidators}
            validators={textFieldDefault}
            error={!!errors["customer.telephone"]}
            helperText={errors["customer.telephone"]}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    color={"secondary"}
                    className={classes.callIcon}
                    href={"tel:" + formValue(values, "customer.telephone")}
                    disabled={!formValue(values, "customer.telephone")}
                    size="large"
                  >
                    <Call />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ValidatorTextField
            onChange={handleChange}
            onBlur={handleBlur}
            name={"customer.email"}
            value={formValue(values, "customer.email")}
            label={translate("customer.email.label")}
            disabled={formDisabled}
            registerValidators={registerValidators}
            validators={[...emailDefault, ...requiredFieldDefault, ...duplicateMailDefault(customer.email)]}
            error={!!errors["customer.email"]}
            helperText={errors["customer.email"]}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ValidatorTextField
            name="customer.verification"
            label={translate("customer.verification.label")}
            value={_.defaultTo(values.customer.verification, "")}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ValidatorSelect
            label={translate("customer.contactTime.label")}
            name={"customer.contactTime"}
            value={formValue(values, "customer.contactTime")}
            disabled={formDisabled}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <MenuItem value={"flexible"}>{translate("customer.contactTime.values.flexible")}</MenuItem>
            {timeSlots}
          </ValidatorSelect>
        </Grid>
        <Grid item xs={12}>
          <SubmitButton
            error={_.keys(errors).length > 0}
            isLoading={isLoading}
            disabled={_.keys(touchedValues).length === 0}
            variant={"contained"}
            type={"submit"}
          >
            Änderungen Speichern
          </SubmitButton>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
};

AllDataContact.propTypes = {
  product: PropTypes.object.isRequired,
  formDisabled: PropTypes.bool,
};

AllDataContact.defaultProps = {
  formDisabled: false,
};

export default AllDataContact;
