import useStyles from "../../MediaObjectSection/mediaObjectsStyle";
import React from "react";
import { getMediaObjectsIdentifier } from "../../../hooks/useMediaObjects";
import DivorceRelatedMediaObjects from "./Divorce/DivorceRelatedMediaObjects";
import SettlementRelatedMediaObjects from "./Settlement/SettlementRelatedMediaObjects";
import TrafficRelatedMediaObjects from "./Traffic/TrafficRelatedMediaObjects";
import { getIsReady } from "../../../services/Product/ProductService";
import { useSelector } from "react-redux";
import { getGeneric } from "../../../store/generic/reducer";
import ContentBox from "../../ContentBox/ContentBox";

const CaseRelatedMediaObjects = ({ product, refreshPage }) => {
  const classes = useStyles({});
  const mediaObjects = useSelector((state) => getGeneric(state, getMediaObjectsIdentifier(product)));

  const CaseRelatedFields = ({ product, mediaObjects }) => {
    switch (product.productClassName) {
      case "divorce":
        return <DivorceRelatedMediaObjects product={product} mediaObjects={mediaObjects} refreshPage={refreshPage} />;
      case "settlement":
        return (
          <SettlementRelatedMediaObjects product={product} mediaObjects={mediaObjects} refreshPage={refreshPage} />
        );
      case "traffic":
        return <TrafficRelatedMediaObjects product={product} mediaObjects={mediaObjects} refreshPage={refreshPage} />;
      default:
        return null;
    }
  };

  if (
    !mediaObjects ||
    !getIsReady(product) ||
    !["divorce", "settlement", "traffic"].includes(product.productClassName)
  ) {
    return null;
  }

  return (
    <ContentBox headline="Prozessrelevante Dokumente">
      <CaseRelatedFields product={product} mediaObjects={mediaObjects["hydra:member"]} />
    </ContentBox>
  );
};

export default CaseRelatedMediaObjects;
