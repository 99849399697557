import React from "react";
import * as PropTypes from "prop-types";
import _ from "lodash";
import Grid from "@mui/material/Grid";
import ValidatorSelect from "../Validator/ValidatorSelect";
import * as divorceStatus from "../../services/LeadStatus/StatusDivorce";
import * as settlementStatus from "../../services/LeadStatus/StatusSettlement";
import * as trafficStatus from "../../services/LeadStatus/StatusTraffic";
import * as caseStatus from "../../services/LeadStatus/StatusCase";
import ButtonLoading from "../Button/ButtonLoading";
import MenuItem from "@mui/material/MenuItem";
import ApiClient from "../../services/ApiClient";
import Snackbar from "../Snackbar/Snackbar";
import { useSnackbar } from "notistack";
import ValidatorTextField from "../Validator/ValidatorTextField";
import useForm from "../../hooks/useForm";
import { convertToFloat, formValue } from "../../services/formServiceFunctions";
import { fetchMediaObjects } from "../../hooks/useMediaObjects";
import { useDispatch, useSelector } from "react-redux";
import { getBackofficeUser } from "../../store/backofficeUser/reducer";
import ValidatorForm from "../Validator/ValidatorForm";
import ContentBox from "../ContentBox/ContentBox";
import { Typography } from "@mui/material";

const CaseInvoiceGenerateForm = ({ product }) => {
  const currentUser = useSelector((state) => getBackofficeUser(state));
  const hasAccountingRights = _.intersection(currentUser.roles, ["ROLE_ACCOUNTING", "ROLE_ADMIN"]).length > 0;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const generatableInvoicesFamilyLaw = [
    {
      value: "additionalActionsInvoice",
      label: "Außergerichtliche Folgetätigkeit",
      isDisabled: (product) => {
        if (product.insurance && product.insurance.insurance) {
          return !_.includes([17, 2], product.insurance.insurance.id);
        }
        return true;
      },
      isHidden: () => !hasAccountingRights,
    },
    {
      value: "genericInvoiceCreditNote",
      label: "Allgemeine Rechnung/Gutschrift",
      isDisabled: () => {
        return false;
      },
      isHidden: () => false,
    },
    {
      value: "advisoryFlatFee",
      label: "Erstberatung Partnerversicherung",
      isDisabled: (product) => {
        //-- @Todo: Implement function that determines partner INSURER instead of ANY partner (e.g., WBS)
        return product.customer.prelead.partner === null || product.leadStatus < caseStatus.STATUS_DATA_COMPLETED;
      },
      isHidden: () => {
        return !hasAccountingRights;
      },
    },
  ];

  const generatableInvoicesContractLaw = [
    {
      value: "genericInvoiceCreditNote",
      label: "Allgemeine Rechnung/Gutschrift",
      isDisabled: () => false,
      isHidden: () => false,
    },
    {
      value: "extrajudicialFlatFee",
      label: "Außergerichtliche Tätigkeit Partnerversicherung",
      isDisabled: (product) => {
        //-- @Todo: Implement function that determines partner INSURER instead of ANY partner (e.g., WBS)
        return product.customer.prelead.partner === null || product.leadStatus < caseStatus.STATUS_DATA_COMPLETED;
      },
      isHidden: () => false,
    },
    {
      value: "advisoryFlatFee",
      label: "Erstberatung Partnerversicherung",
      isDisabled: (product) => {
        //-- @Todo: Implement function that determines partner INSURER instead of ANY partner (e.g., WBS)
        return product.customer.prelead.partner === null || product.leadStatus < caseStatus.STATUS_DATA_COMPLETED;
      },
      isHidden: () => {
        return !hasAccountingRights;
      },
    },
    {
      value: "invoiceExtrajudicialRvg",
      label: "Rechnung außergerichtliche Tätigkeit",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_LAWSUIT_COMPLETED;
      },
      isHidden: () => false,
    },
    {
      value: "invoiceLitigationRvg",
      label: "Rechnung gerichtliche Tätigkeit",
      isDisabled: (product) => {
        return product.leadStatus < caseStatus.STATUS_LAWSUIT_COMPLETED;
      },
      isHidden: () => false,
    },
  ];

  const generatableInvoices = {
    divorce: [
      {
        value: "invoiceProcess",
        label: "Scheidung Rechnung Verfahrensgebühr",
        isDisabled: (divorce) => {
          return divorce.leadStatus < divorceStatus.STATUS_CHECKED;
        },
        isHidden: () => {
          return !hasAccountingRights;
        },
      },
      {
        value: "invoiceHearing",
        label: "Scheidung Rechnung Terminsgebühr",
        isDisabled: (divorce) => {
          return divorce.leadStatus < divorceStatus.STATUS_DATE_SET;
        },
        isHidden: () => {
          return !hasAccountingRights;
        },
      },
      {
        value: "invoiceInstallment",
        label: "Rechnung Ratenzahlung",
        isDisabled: (divorce) => {
          return divorce.leadStatus < divorceStatus.STATUS_CHECKED;
        },
        isHidden: () => {
          return !hasAccountingRights;
        },
      },
      {
        value: "cancellationFee",
        label: "Scheidung Rechnung 0,8",
        isDisabled: (divorce) => {
          return divorce.leadStatus < divorceStatus.STATUS_POWER_OF_ATTORNEY_GRANTED;
        },
        isHidden: () => false,
      },
      {
        value: "differentialCostsApplication",
        label: "Scheidung Differenzkostenantrag",
        isDisabled: (divorce) => {
          return divorce.leadStatus < divorceStatus.STATUS_COMPLETED;
        },
        isHidden: () => false,
      },
      {
        value: "invoiceFinal",
        label: "Abschlussrechnung / Abschlussgutschrift",
        isDisabled: (divorce) => {
          return divorce.leadStatus < divorceStatus.STATUS_DIVORCE_DONE;
        },
        isHidden: () => {
          return !hasAccountingRights;
        },
      },
      {
        value: "invoiceProcessCostSupportPre",
        label: "Scheidung VKH Vorschusskostenrechnung",
        isDisabled: (divorce) => {
          return divorce.processCostSupport === false || divorce.leadStatus < divorceStatus.STATUS_CHECKED;
        },
        isHidden: () => false,
      },
      {
        value: "invoiceProcessCostSupportFinal",
        label: "Scheidung VKH Abschlussrechnung",
        isDisabled: (divorce) => {
          return divorce.processCostSupport === false || divorce.leadStatus < divorceStatus.STATUS_DIVORCE_DONE;
        },
        isHidden: () => {
          return !hasAccountingRights;
        },
      },
      {
        value: "genericInvoiceCreditNote",
        label: "Allgemeine Rechnung/Gutschrift",
        isDisabled: () => {
          return false;
        },
        isHidden: () => false,
      },
      {
        value: "additionalActionsInvoice",
        label: "Außergerichtliche Folgetätigkeit",
        isDisabled: (product) => {
          if (product.insurance && product.insurance.insurance) {
            return !_.includes([17, 2], product.insurance.insurance.id);
          }
          return true;
        },
        isHidden: () => {
          return !hasAccountingRights;
        },
      },
    ],
    settlement: [
      {
        value: "invoiceProcessHearing",
        label: "Rechnung Verfahrens- und Terminsgebühr",
        isDisabled: (settlement) => {
          return settlement.extended.noWinNoFee === true || settlement.leadStatus < settlementStatus.STATUS_CHECKED;
        },
        isHidden: () => {
          return !hasAccountingRights;
        },
      },
      {
        value: "invoiceSettlement",
        label: "Rechnung Einigungsgebühr",
        isDisabled: (settlement) => {
          return (
            settlement.extended.noWinNoFee === true || settlement.leadStatus < settlementStatus.STATUS_DECISION_MADE
          );
        },
        isHidden: () => {
          return !hasAccountingRights;
        },
      },
      {
        value: "creditNoteDeductible",
        label: "Gutschrift Selbstbehalt",
        isDisabled: (settlement) => {
          return (
            settlement.extended.noWinNoFee === true || settlement.leadStatus < settlementStatus.STATUS_DECISION_MADE
          );
        },
        isHidden: () => {
          return !hasAccountingRights;
        },
      },
      {
        value: "genericInvoiceCreditNote",
        label: "Allgemeine Rechnung/Gutschrift",
        isDisabled: () => {
          return false;
        },
        isHidden: () => false,
      },
    ],
    traffic: [
      {
        value: "invoiceCoverageAndInvoice",
        label: "Deckungsanfrage inkl Anlagen",
        isDisabled: (traffic) => {
          return traffic.legalExpenseInsurance !== true || traffic.leadStatus < trafficStatus.STATUS_CHECKED;
        },
        isHidden: () => {
          return !hasAccountingRights;
        },
      },
      {
        value: "invoiceHearing",
        label: "Rechnung Anwaltsgebühren",
        isDisabled: (traffic) => {
          return traffic.leadStatus < trafficStatus.STATUS_CHECKED;
        },
        isHidden: () => {
          return !hasAccountingRights;
        },
      },
      {
        value: "invoiceFinalPrivatePayer",
        label: "Abschlussrechnung Privatzahler",
        isDisabled: (traffic) => {
          return traffic.paymentType !== "private" || traffic.leadStatus < trafficStatus.STATUS_TRIAL;
        },
        isHidden: () => {
          return !hasAccountingRights;
        },
      },
      {
        value: "creditNoteFinalPrivatePayer",
        label: "Gutschrift Privatzahler",
        isDisabled: (traffic) => {
          return traffic.paymentType !== "private" || traffic.leadStatus < trafficStatus.STATUS_TRIAL;
        },
        isHidden: () => {
          return !hasAccountingRights;
        },
      },
      {
        value: "invoiceFinalInsurer",
        label: "Abschlussrechnung RSV",
        isDisabled: (traffic) => {
          return traffic.paymentType === "private" || traffic.leadStatus < trafficStatus.STATUS_TRIAL;
        },
        isHidden: () => {
          return !hasAccountingRights;
        },
      },
      {
        value: "creditNoteDeductible",
        label: "Gutschrift Selbstbehalt",
        isDisabled: (traffic) => {
          return traffic.paymentType === "private" || traffic.leadStatus < trafficStatus.STATUS_TRIAL;
        },
        isHidden: () => {
          return !hasAccountingRights;
        },
      },
      {
        value: "genericInvoiceCreditNote",
        label: "Allgemeine Rechnung/Gutschrift",
        isDisabled: () => {
          return false;
        },
        isHidden: () => false,
      },
    ],
    shortTimeWork: [
      {
        value: "genericInvoiceCreditNote",
        label: "Allgemeine Rechnung/Gutschrift",
        isDisabled: () => {
          return false;
        },
        isHidden: () => false,
      },
    ],
    alimony: generatableInvoicesFamilyLaw,
    death: generatableInvoicesFamilyLaw,
    familyLawCase: generatableInvoicesFamilyLaw,
    eventContract: generatableInvoicesContractLaw,
    membershipContract: generatableInvoicesContractLaw,
    otherContract: generatableInvoicesContractLaw,
    providerContract: generatableInvoicesContractLaw,
    purchaseContract: generatableInvoicesContractLaw,
    serviceContract: generatableInvoicesContractLaw,
    subscriptionContract: generatableInvoicesContractLaw,
    travelContract: generatableInvoicesContractLaw,
    vehicleContract: generatableInvoicesContractLaw,
  };

  const onSubmit = async () => {
    let paramValues = _.merge({}, values);
    if (paramValues.netAmount) {
      paramValues.netAmount = convertToFloat(paramValues.netAmount);
    }
    const response = await ApiClient.post(
      "invoice/generate/" + product.productClassName + "/" + product.id + "/" + values.fileType,
      {
        body: JSON.stringify(paramValues),
      }
    );
    const messageText =
      response && response.status === "ok" ? "Rechnung wurde generiert" : "Rechnung konnte nicht generiert werden";

    enqueueSnackbar("", {
      content: () => (
        <div>
          <Snackbar message={messageText} isNonInteractive={true} />
        </div>
      ),
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });

    await fetchMediaObjects(product, dispatch);
  };

  const initialValues = {
    netAmount: "",
    fileType: "",
    debitOrCredit: "debit",
    vatOverwrite: "1.19",
    bookingText: "",
    suppressEmails: true,
  };
  const { values, handleChange, handleSubmit, handleBlur, isLoading } = useForm({
    initialValues,
    onSubmit,
  });

  if (product.preventAccounting === true) {
    return (
      <Typography variant="h3" sx={{ textAlign: "center" }}>
        Konto/Rechnungen sind pausiert!
      </Typography>
    );
  }

  return (
    <ContentBox headline="Rechnung erstellen">
      <ValidatorForm onSubmit={handleSubmit} submitOnEnter={false}>
        <Grid container alignItems={"center"} spacing={3}>
          <Grid item xs={12} md={6}>
            <ValidatorSelect
              label={"Welche Rechnung?"}
              name={"fileType"}
              value={formValue(values, "fileType")}
              helperText={"Art der Rechnung"}
              onChange={handleChange}
            >
              {_.map(generatableInvoices[product.productClassName], (fileData) => {
                return fileData.isHidden(product) ? null : (
                  <MenuItem key={fileData.value} value={fileData.value} disabled={fileData.isDisabled(product)}>
                    {fileData.label}
                  </MenuItem>
                );
              })}
            </ValidatorSelect>
          </Grid>
          {values.fileType === "genericInvoiceCreditNote" && (
            <>
              <Grid item xs={12} md={6}>
                <ValidatorSelect
                  label={"Gutschrift oder Rechnung?"}
                  name={"debitOrCredit"}
                  value={formValue(values, "debitOrCredit")}
                  helperText={"Soll eine Gutschrift oder eine Rechnung erstellt werden?"}
                  onChange={handleChange}
                >
                  <MenuItem value={"debit"}>Rechnung</MenuItem>
                  <MenuItem value={"credit"}>Gutschrift</MenuItem>
                </ValidatorSelect>
              </Grid>
              <Grid item xs={12} md={6}>
                <ValidatorTextField
                  label={"Betrag (netto)"}
                  name={"netAmount"}
                  value={formValue(values, "netAmount")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={"Bitte trage hier den Nettobetrag ein."}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ValidatorTextField
                  label={"Buchungstext Mandantenkonto"}
                  name={"bookingText"}
                  value={formValue(values, "bookingText")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={"Soll ein Buchungstext im Mandantenkonto zu sehen sein?"}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ValidatorSelect
                  label={"16% oder 19%"}
                  name={"vatOverwrite"}
                  value={formValue(values, "vatOverwrite")}
                  helperText={"Mit welcher MWSt. soll das Dokument erstellt werden?"}
                  onChange={handleChange}
                >
                  <MenuItem value={"1.16"}>16%</MenuItem>
                  <MenuItem value={"1.19"}>19%</MenuItem>
                </ValidatorSelect>
              </Grid>
            </>
          )}
          <Grid item xs={12} md={6}>
            <ButtonLoading
              isLoading={isLoading}
              disabled={isLoading}
              type={"submit"}
              color={"primary"}
              variant={"contained"}
              fullWidth={true}
            >
              Rechnung erstellen
            </ButtonLoading>
          </Grid>
        </Grid>
      </ValidatorForm>
    </ContentBox>
  );
};

CaseInvoiceGenerateForm.propTypes = {
  product: PropTypes.object.isRequired,
};

export default CaseInvoiceGenerateForm;
