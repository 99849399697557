import React from "react";
import Grid from "@mui/material/Grid";
import { ButtonGroup } from "@mui/material";
import Button from "@mui/material/Button";

const TimeFilter = ({ timespanFilter, setTimespanFilter, isDeadlineView }) => {
  return (
    <Grid container justifyContent={"flex-end"}>
      <ButtonGroup color="primary">
        <Button
          variant={timespanFilter === "overdue" ? "contained" : "outlined"}
          onClick={() => setTimespanFilter("overdue")}
        >
          Überfällig
        </Button>
        <Button
          variant={timespanFilter === "today" ? "contained" : "outlined"}
          onClick={() => setTimespanFilter("today")}
        >
          Heute
        </Button>
        <Button
          variant={timespanFilter === "tomorrow" ? "contained" : "outlined"}
          onClick={() => setTimespanFilter("tomorrow")}
        >
          Morgen
        </Button>
      </ButtonGroup>
    </Grid>
  );
};

export default TimeFilter;
