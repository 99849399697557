import React, { useEffect } from "react";
import * as PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import useStyles from "./validatorStyle";

const ValidatorTextField = ({
  isMandatory,
  name,
  value,
  registerValidators,
  validators,
  dependentValidationFields,
  label,
  ...rest
}) => {
  const classes = useStyles();
  useEffect(() => {
    registerValidators && validators && registerValidators(name, validators, dependentValidationFields);
  }, [registerValidators, dependentValidationFields, name]);

  return (
    <TextField
      {...rest}
      name={name}
      value={value}
      label={label}
      className={isMandatory && (value === null || value === "") ? classes.mandatory : null}
    />
  );
};

ValidatorTextField.propTypes = {
  validators: PropTypes.arrayOf(PropTypes.object),
  dependentValidationFields: PropTypes.array,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  registerValidators: PropTypes.func,
  isMandatory: PropTypes.bool,
};

ValidatorTextField.defaultProps = {
  isMandatory: false,
  dependentValidationFields: [],
};

export default ValidatorTextField;
