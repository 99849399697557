import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBackofficeUser } from "../../store/backofficeUser/reducer";
import { Box, Grid } from "@mui/material";
import _ from "lodash";
import FormElement from "../Case/CaseForm/FormElement";
import ButtonLoading from "../Button/ButtonLoading";
import useForm from "../../hooks/useForm";
import { requiredFieldDefault } from "../../services/validationRules";
import ApiClient from "../../services/ApiClient";
import ContentBox from "../ContentBox/ContentBox";
import { userHasRole } from "../../services/backofficeUserService";
import EmailChangeModal from "../EmailChangeModal/EmailChangeModal";
import ChangePassword from "./ChangePassword";
import { scrollToAnchor } from "../../theme/commonStyles";
import LawyerTitles from "./LawyerTitles";
import { fetch } from "../../store/backofficeUser/actions";

const PersonalData = () => {
  const currentUser = useSelector((state) => getBackofficeUser(state));
  const dispatch = useDispatch();
  const signaturePadRef = useRef();
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async ({ values }) => {
    setIsLoading(true);
    if (values.email && values.email !== currentUser.email) {
      const emailCheck = await ApiClient.post("check_user_email", {
        body: JSON.stringify({ email: _.toLower(values.email) }),
      });
      if (emailCheck.customerExists) {
        setIsLoading(false);
        alert(emailCheck.errorText || "Es existiert bereits ein Konto mit dieser E-Mail-Adresse");
        return;
      }
      setIsEmailModalOpen(true);
    }

    let personData = {
      gender: values.person.gender,
      givenName: values.person.givenName,
      familyName: values.person.familyName,
      telephone: values.person.telephone,
      titles: _.filter(values.person.titles, (titleData) => {
        return titleData && titleData.title !== "";
      }),
    };
    if (!!signaturePadRef.current) {
      personData.encodedSignature = signaturePadRef.current.toDataURL("image/svg+xml");
    }
    await ApiClient.put(currentUser.person["@id"], {
      body: JSON.stringify(personData),
    });
    dispatch(fetch());
    setIsLoading(false);
  };

  const formElements = [
    {
      type: "ValidatorSelect",
      path: `person.gender`,
      label: "Anrede",
      validators: requiredFieldDefault,
      options: () => [
        { value: "male", label: "Herr" },
        { value: "female", label: "Frau" },
      ],
    },
    {
      type: "ValidatorTextField",
      path: `person.givenName`,
      label: "Vorname",
      validators: requiredFieldDefault,
    },
    {
      type: "ValidatorTextField",
      path: `person.familyName`,
      label: "Nachname",
      validators: requiredFieldDefault,
    },
    {
      type: "ValidatorTextField",
      path: `person.telephone`,
      label: "Telefonnummer",
      validators: requiredFieldDefault,
      isHidden: ({ values }) => !userHasRole(values, "ROLE_EXTERNAL"),
    },
    {
      type: "ValidatorTextField",
      path: "email",
      label: "E-Mail Adresse",
      isDisabled: ({ values }) => !userHasRole(values, "ROLE_EXTERNAL"),
    },
  ];

  const initialValues = _.merge({}, currentUser);
  const useFormProps = useForm({
    initialValues,
    onSubmit,
    identifier: currentUser["@id"],
  });
  const { values, handleChange, handleBlur } = useFormProps;
  return (
    <>
      <Box sx={scrollToAnchor} id={"myData"} />
      <ContentBox headline={"Persönliche Daten"}>
        <Grid container spacing={4}>
          {_.map(formElements, (formElementProps) => {
            if (formElementProps.isHidden && formElementProps.isHidden({ values: useFormProps.values })) {
              return null;
            }
            const fullWidth = formElementProps.additionalProps && formElementProps.additionalProps.fullWidth;
            return (
              <Grid key={formElementProps.path} item xs={12} md={!fullWidth && 6} lg={!fullWidth && 4}>
                <FormElement product={{ productClassName: "" }} {...formElementProps} {...useFormProps} />
              </Grid>
            );
          })}
          {!!currentUser.person.titles && (
            <LawyerTitles
              titles={currentUser.person.titles}
              values={values}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          )}
          <Grid item xs={12}>
            <ButtonLoading
              sx={{ width: "200px", margin: "0 auto", display: "block" }}
              variant="contained"
              onClick={useFormProps.handleSubmit}
              isLoading={isLoading}
              disabled={_.keys(useFormProps.touchedValues).length === 0}
            >
              Speichern
            </ButtonLoading>
          </Grid>
        </Grid>
        <Box sx={{ marginTop: "2rem" }}>
          <ChangePassword />
        </Box>
        <EmailChangeModal
          newEmail={values.email}
          setIsEmailModalOpen={setIsEmailModalOpen}
          isEmailModalOpen={isEmailModalOpen}
        />
      </ContentBox>
    </>
  );
};
export default PersonalData;
