import React, { useMemo } from "react";
import useStyles from "./humanResourceManagementStyle";
import { Grid, MenuItem, Typography } from "@mui/material";
import _ from "lodash";
import { translate } from "../../services/Translations/translatorService";
import { Calendar } from "react-multi-date-picker";
import { formValue } from "../../services/formServiceFunctions";
import ValidatorSelect from "../Validator/ValidatorSelect";

const UserPresenceConfiguratorFormElements = ({ values, handleChange, handleBlur }) => {
  const classes = useStyles({});

  const timeSlots = useMemo(() => {
    const startTime = 7;
    const endTime = 20;

    let slots = [];
    for (let i = startTime; i <= endTime; i++) {
      const timeSlotFullHour = i.toLocaleString("de-DE", { minimumIntegerDigits: 2 }) + ":00";
      const timeSlotHalfHour = i.toLocaleString("de-DE", { minimumIntegerDigits: 2 }) + ":30";

      slots.push(
        <MenuItem key={timeSlotFullHour} value={timeSlotFullHour}>
          {timeSlotFullHour} Uhr
        </MenuItem>
      );

      if (i < endTime) {
        slots.push(
          <MenuItem key={timeSlotHalfHour} value={timeSlotHalfHour}>
            {timeSlotHalfHour} Uhr
          </MenuItem>
        );
      }
    }
    return slots;
  }, []);

  return (
    <Grid container justifyContent={"center"} spacing={2}>
      <Grid item md={6} xs={12}>
        <Typography variant="h3" className={classes.subHeadline}>
          Abwesenheit eintragen
        </Typography>
        <Calendar
          className={classes.calender}
          value={formValue(values, "blockedDays")}
          onChange={(dates) =>
            handleChange({
              target: {
                name: "blockedDays",
                value: dates,
              },
            })
          }
          showOtherDays
          weekDays={["S", "M", "D", "M", "D", "F", "S"]}
          months={[
            "Januar",
            "Februar",
            "März",
            "April",
            "Mai",
            "Juni",
            "Juli",
            "August",
            "September",
            "Oktober",
            "November",
            "Dezember",
          ]}
          mapDays={({ date }) => {
            const isWeekend = _.includes([0, 6], date.weekDay.index);
            if (isWeekend) {
              return {
                disabled: true,
              };
            }
          }}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <Typography variant="h3" className={classes.subHeadline}>
          Arbeitszeit eintragen
        </Typography>
        <Grid container>
          {_.map(values.workingHours, (workingHourTimes, workingHourDay) => (
            <React.Fragment key={workingHourDay}>
              <Grid item xs={6} sx={{ paddingRight: "1rem" }}>
                <ValidatorSelect
                  label={"Start " + translate("general.dateTime." + workingHourDay, workingHourDay)}
                  name={"workingHours." + workingHourDay + ".start"}
                  value={formValue(values, "workingHours." + workingHourDay + ".start")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {timeSlots}
                </ValidatorSelect>
              </Grid>
              <Grid item xs={6}>
                <ValidatorSelect
                  label={"Ende " + translate("general.dateTime." + workingHourDay, workingHourDay)}
                  name={"workingHours." + workingHourDay + ".end"}
                  value={formValue(values, "workingHours." + workingHourDay + ".end")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {timeSlots}
                </ValidatorSelect>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserPresenceConfiguratorFormElements;
