import React from "react";
import { Box, Card, CardActionArea, Fade, Typography } from "@mui/material";
import { BackofficeCase } from "../../types/BackofficeCase";
import { translate } from "../../services/Translations/translatorService";
import { getCaseLinkByBackofficeCase, getProductNameBy } from "../../services/Product/ProductService";
import moment from "moment";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getBackofficeUser } from "../../store/backofficeUser/reducer";
import _ from "lodash";
import newCaseCardStyle from "./newCaseCardStyle";
import { getSubFolderByUser } from "../../services/navigationService";

type NewCaseCardProps = {
  backofficeCase: BackofficeCase;
};

export default function NewCaseCard({ backofficeCase }: NewCaseCardProps) {
  const productClassName = getProductNameBy("lbProductClass", backofficeCase.lbProductClass);
  const currentUser = useSelector((state) => getBackofficeUser(state));

  return (
    <Fade in timeout={2000}>
      <Card sx={newCaseCardStyle.cardContainer}>
        <CardActionArea
          sx={{ padding: "1rem" }}
          component={Link}
          to={getCaseLinkByBackofficeCase(backofficeCase, getSubFolderByUser(currentUser))}
        >
          <strong>Name:</strong> {backofficeCase.label}
          <br />
          <Box sx={_.includes(backofficeCase.customerEmail, "advocard.error+") ? { color: "red" } : {}}>
            <strong>Email:</strong>{" "}
            <Box sx={{ display: "inline", wordBreak: "break-all" }}>{backofficeCase.customerEmail}</Box>
          </Box>
          <strong>Produkt:</strong> {translate(productClassName + ".label")}
          <br />
          <strong>Partner:</strong> {_.capitalize(backofficeCase.acquisitionPartner || "")}
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <span>
            <strong>Erstellt:</strong> {moment(backofficeCase.created).format("DD.MM.YYYY, HH:mm")} Uhr
          </span>
            <Typography variant={"h5"} sx={{ color: "primary.main", textAlign: "right", fontWeight: 500 }}>
              ZUM FALL
            </Typography>
          </Box>
        </CardActionArea>
      </Card>
    </Fade>
  );
}
