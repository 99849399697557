import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  headline: {
    textAlign: "center",
    fontSize: "1.25rem",
  },
  dialogContainer: {
    padding: "2rem",
    maxWidth: 700,
  },
  mailContent: {
    marginTop: "1rem",
    minHeight: "37vh",
    border: "1px solid grey",
    padding: "1rem",
  },
  gridItemLeft: {
    paddingRight: "1rem",
  },
  gridItemRight: {
    paddingLeft: "1rem",
  },
  verticalSpace: {
    marginTop: "1rem",
  },
  icon: {
    color: "#333333",
    [theme.breakpoints.up("md")]: {
      marginLeft: "2rem",
    },
  },
  createTemplateButton: {
    marginTop: "1rem",
  },
  attachmentsSelect: {
    width: "100%",
    marginTop: "1rem",
  },
  helperText: {
    color: "red",
  },
}));

export default useStyles;
