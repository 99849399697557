import React from "react";
import * as PropTypes from "prop-types";
import useStyles from "./caseHistoryActivityStyle";
import CircleIcon from "../../CircleIcon/CircleIcon";
import _ from "lodash";
import { getActivityIcon } from "../../../services/Activities/activityTypes";
import moment from "moment";
import { Grid } from "@mui/material";

const CaseHistoryActivity = ({ historyItem, productClassName }) => {
  const classes = useStyles({});
  const activity = historyItem.activity;

  const dateString = moment(activity.dueDate).format("DD.MM.YYYY");
  const timeString = activity.dueTime !== "allDay" ? "um " + activity.dueTime + " Uhr" : "";
  const subject =
    activity.subject +
    " " +
    dateString +
    (timeString ? " " + timeString : "") +
    " | " +
    (activity.assignedUser ? activity.assignedUser.person.fullname : "Nicht zugewiesen");

  return (
    <div className={classes.outerActivityContainer}>
      <Grid container className={classes.activityContainer}>
        <Grid className={classes.imageGridItem} item>
          <CircleIcon icon={getActivityIcon(productClassName, activity.type)} type={"muiIcon"} />
        </Grid>
        <Grid item className={classes.activityContent}>
          <Grid container alignItems={"center"} wrap={"nowrap"}>
            <Grid item className={classes.headline}>
              {subject}
            </Grid>
          </Grid>
          {_.get(historyItem, "logData.activityEventType") !== "deleted" &&
            "Erledigt: " +
              moment(historyItem.logDate).format("DD.MM.YYYY HH:mm") +
              " Uhr | " +
              historyItem.backofficeUser.person.fullname}
        </Grid>
      </Grid>
      {activity.note && <div className={classes.noteContainer} dangerouslySetInnerHTML={{ __html: activity.note }} />}
      {activity.deleted && (
        <>
          <div className={classes.overlay} />
          <div className={classes.deletedInfo}>
            {_.get(historyItem, "logData.activityEventType") === "deleted" &&
              "Gelöscht durch " +
                historyItem.backofficeUser.person.fullname +
                " | " +
                moment(historyItem.logDate).format("DD.MM.YYYY | HH:mm")}
          </div>
        </>
      )}
    </div>
  );
};

CaseHistoryActivity.propTypes = {
  historyItem: PropTypes.object.isRequired,
  backofficeCaseId: PropTypes.number.isRequired,
  productClassName: PropTypes.string.isRequired,
  updateActivities: PropTypes.func,
};

export default CaseHistoryActivity;
