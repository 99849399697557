import React, { SyntheticEvent } from "react";
import { HitProperties } from "./hitType";
import { getCaseLink, getProductData } from "../../../../services/Product/ProductService";
import { Box, CardActionArea } from "@mui/material";
import { Link } from "react-router-dom";
import { getSubFolderByUser } from "../../../../services/navigationService";
import { translate } from "../../../../services/Translations/translatorService";
import hitStyles from "./hitStyles";
import { useSelector } from "react-redux";
import { getBackofficeUser } from "../../../../store/backofficeUser/reducer";

export default function CaseHit({ hit, onHitClick, resetHits, itemSize }: HitProperties) {
  const currentUser = useSelector((state) => getBackofficeUser(state));
  const highlightResult = hit._highlightResult;
  if (!highlightResult) {
    return null;
  }

  const handleCardClick = (event: SyntheticEvent<HTMLElement>) => {
    resetHits();
    onHitClick && onHitClick(hit, event);
  };

  const productLabel = getProductData(hit.product, "label");
  return (
    <CardActionArea
      sx={{ height: itemSize + "px", ...hitStyles.cardActionArea }}
      onClick={handleCardClick}
      component={Link}
      to={getCaseLink({ productClassName: hit.product, id: hit.productId }, getSubFolderByUser(currentUser))}
    >
      <Box sx={hitStyles.headline}>
        {hit.gender && translate("person.gender.values." + hit.gender) + " "}
        {highlightResult.givenName && (
          <span dangerouslySetInnerHTML={{ __html: highlightResult.givenName.value + " " }} />
        )}
        {highlightResult.familyName && <span dangerouslySetInnerHTML={{ __html: highlightResult.familyName.value }} />}
        <br />
        {productLabel}
      </Box>

      {highlightResult.phoneNumber && (
        <div dangerouslySetInnerHTML={{ __html: "Telefonnummer: " + highlightResult.phoneNumber.value }} />
      )}
      {highlightResult.email && (
        <div dangerouslySetInnerHTML={{ __html: "E-Mail-Adresse: " + highlightResult.email.value }} />
      )}
      {highlightResult.reference && (
        <div dangerouslySetInnerHTML={{ __html: "Unser Zeichen: " + highlightResult.reference.value }} />
      )}
      {highlightResult.externalReference && (
        <div dangerouslySetInnerHTML={{ __html: "Externes Zeichen: " + highlightResult.externalReference.value }} />
      )}
      {highlightResult.caseReferenceNumber && highlightResult.caseReferenceNumber.value !== "" && (
        <div dangerouslySetInnerHTML={{ __html: "Schadennummer: " + highlightResult.caseReferenceNumber.value }} />
      )}
      {highlightResult.insurancePolicyNumber && highlightResult.insurancePolicyNumber.value !== "" && (
        <div
          dangerouslySetInnerHTML={{
            __html: "Versicherungsnummer: " + highlightResult.insurancePolicyNumber.value,
          }}
        />
      )}
      {highlightResult.contractNumber && highlightResult.contractNumber.value !== "" && (
        <div
          dangerouslySetInnerHTML={{
            __html: `${translate(hit.product + ".contractNumber.label")}: ${highlightResult.contractNumber.value}`,
          }}
        />
      )}
      {highlightResult.opposingParty && (
        <div dangerouslySetInnerHTML={{ __html: "Gegenseite: " + highlightResult.opposingParty.value }} />
      )}
    </CardActionArea>
  );
}
