import React from "react";
import * as PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import displayOrDownloadMediaObjectCellStyle from "./displayOrDownloadMediaObjectCellStyle";
import _ from "lodash";
import { mimeTypes } from "../../services/Files/fileService";

const useStyles = makeStyles(displayOrDownloadMediaObjectCellStyle);

export default function DisplayOrDownloadMediaObjectWrapper({ children, mediaObject, setMediaObjectToDisplay }) {
  const classes = useStyles();

  if (_.includes([...mimeTypes.pdf, ...mimeTypes.image], mediaObject.mimeType)) {
    return <div onClick={() => setMediaObjectToDisplay(mediaObject)}>{children}</div>;
  }
  //using displayUrlhere lets the browser decide which files he wants to display or download
  return (
    <a href={mediaObject.displayUrl} target={"_blank"} download className={classes.link}>
      {children}
    </a>
  );
}

DisplayOrDownloadMediaObjectWrapper.propTypes = {
  mediaObject: PropTypes.object.isRequired,
};
