import _ from "lodash";
import ApiClient from "../ApiClient";

export async function call(
  method: string,
  iri: string,
  data: { [x: string]: any },
  options: { [x: string]: any } = {}
): Promise<any> {
  options.body = JSON.stringify(data);
  return ApiClient.call(method, iri, options);
}

export async function get(
  iri: string,
  id: number | null = null,
  data: { [x: string]: any } = {},
  options: { [x: string]: any } = {}
): Promise<any> {
  let fullIri = id ? iri + "/" + id : iri;
  if (!_.isEmpty(data)) {
    const urlParams = new URLSearchParams(data);
    fullIri = fullIri + "?" + urlParams.toString();
  }
  return ApiClient.call("get", fullIri, options);
}

export async function post(iri: string, data: { [x: string]: any }, options: { [x: string]: any } = {}): Promise<any> {
  options.body = JSON.stringify(data);
  return ApiClient.call("post", iri, options);
}

export async function put(
  iri: string,
  id: number | null,
  data: { [x: string]: any },
  options: { [x: string]: any } = {}
): Promise<any> {
  const fullIri = id ? iri + "/" + id : iri;
  options.body = JSON.stringify(data);
  return ApiClient.call("put", fullIri, options);
}
