import React from "react";
import HolidaysProvider from "../../provider/HolidayProvider";
import BackofficeUserProvider from "../../provider/BackofficeUserProvider";
import { useSelector } from "react-redux";
import { getBackofficeUser } from "../../store/backofficeUser/reducer";
import AccessibleCasesProvider from "../../provider/AccessibleCasesProvider";

export default function GlobalProvider({ children }: { children?: React.ReactNode }) {
  const currentUser = useSelector((state) => getBackofficeUser(state));
  return (
    <BackofficeUserProvider>
      <HolidaysProvider>
        {currentUser.isExternal ? (
          <>
            <AccessibleCasesProvider>{children}</AccessibleCasesProvider>
          </>
        ) : (
          <>{children}</>
        )}
      </HolidaysProvider>
    </BackofficeUserProvider>
  );
}
