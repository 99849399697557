const abstractCaseTranslations = {
  preventAccounting: {
    label: "Konto/Rechnungen pausieren",
    values: {
      true: "inaktiv",
      false: "aktiv",
    },
  },
  preventAllSystemEmails: {
    label: "Emails pausieren",
    values: {
      true: "inaktiv",
      false: "aktiv",
    },
  },
};

export default abstractCaseTranslations;
