import _ from "lodash";
import moment from "moment";
import { ACTIVITY_OVERDUE } from "../Stages/Stages";
import ApiClient from "../ApiClient";
import { deadlineActivityTypes } from "./activityTypes";

export const prepareFilters = (currentUser, backofficeUsers = [], storageKey = "activityFilters", defaultActivityTypeFilter = "all") => {
  const storedFilters = JSON.parse(localStorage.getItem(storageKey));
  let currentFilters = _.merge(
    {
      productFilter: "all",
      activityTypeFilter: defaultActivityTypeFilter,
      timespanFilter: "today",
    },
    storedFilters
  );

  if (_.isObjectLike(currentFilters.timespanFilter)) {
    currentFilters.timespanFilter.startDate = _.isString(currentFilters.timespanFilter.startDate)
      ? moment(currentFilters.timespanFilter.startDate)
      : currentFilters.timespanFilter.startDate;
    currentFilters.timespanFilter.endDate = _.isString(currentFilters.timespanFilter.endDate)
      ? moment(currentFilters.timespanFilter.endDate)
      : currentFilters.timespanFilter.endDate;
  }

  if (!currentFilters.userFilter) {
    currentFilters.userFilter = !!_.find(backofficeUsers, (user) => user.id === currentUser.id) ? currentUser["@id"] : "all";
  }

  return currentFilters;
};

export const postProcessFilterActivities = (activities, timespanFilter) => {
  if (timespanFilter === "overdue") {
    return _.filter(activities, ["activityStatus", ACTIVITY_OVERDUE]);
  }
  return activities;
};

export const getUserActivitiesToday = async (user) => {
  const filters = {
    assignedUser: user["@id"],
    "dueDate[before]": moment().format("YYYY-MM-DD"),
    done: false,
    deleted: false,
    pagination: false,
  };

  let searchParams = buildSearchParams(filters);

  const userActivitiesResult = await ApiClient.get("activities?" + searchParams.toString());
  return userActivitiesResult["hydra:member"];
};

export const buildApiFilters = ({ productFilter, userFilter, timespanFilter, activityTypeFilter }) => {
  let filters = {
    "exists[assignedUser]": true,
    deleted: false,
    pagination: false,
  };

  switch (productFilter) {
    case "all":
      break;
    case "contractLaw":
      filters["case.lbProductClass[0]"] = "App\\Entity\\Legalbird\\ContractLaw\\EventContract\\EventContract";
      filters["case.lbProductClass[1]"] = "App\\Entity\\Legalbird\\ContractLaw\\MembershipContract\\MembershipContract";
      filters["case.lbProductClass[2]"] = "App\\Entity\\Legalbird\\ContractLaw\\OtherContract\\OtherContract";
      filters["case.lbProductClass[3]"] = "App\\Entity\\Legalbird\\ContractLaw\\ProviderContract\\ProviderContract";
      filters["case.lbProductClass[4]"] = "App\\Entity\\Legalbird\\ContractLaw\\PurchaseContract\\PurchaseContract";
      filters["case.lbProductClass[5]"] = "App\\Entity\\Legalbird\\ContractLaw\\ServiceContract\\ServiceContract";
      filters["case.lbProductClass[6]"] =
        "App\\Entity\\Legalbird\\ContractLaw\\SubscriptionContract\\SubscriptionContract";
      filters["case.lbProductClass[7]"] = "App\\Entity\\Legalbird\\ContractLaw\\TravelContract\\TravelContract";
      filters["case.lbProductClass[8]"] = "App\\Entity\\Legalbird\\ContractLaw\\VehicleContract\\VehicleContract";
      break;
    default:
      filters["case.lbProductClass"] = productFilter;
      break;
  }

  if (userFilter !== "all") {
    filters["assignedUser"] = userFilter;
  }

  switch (timespanFilter) {
    case "overdue":
      filters["done"] = "false";
      break;
    case "today":
      filters["dueDate"] = moment().format("YYYY-MM-DD");
      break;
    case "tomorrow":
      filters["dueDate"] = moment().add(1, "d").format("YYYY-MM-DD");
      break;
    default:
      if (typeof timespanFilter === "object") {
        filters["dueDate[after]"] = timespanFilter.startDate.format("YYYY-MM-DD");
        filters["dueDate[before]"] = timespanFilter.endDate.format("YYYY-MM-DD");
        break;
      }
      //-- fallback is today
      filters["dueDate"] = moment().format("YYYY-MM-DD");
      break;
  }

  if (activityTypeFilter) {
    switch (activityTypeFilter) {
      case "all":
        break;
      case "allDeadlines":
        filters["type"] = deadlineActivityTypes;
        break;
      default:
        filters["type"] = activityTypeFilter;
        break;
    }
  }

  return filters;
};

export const buildSearchParams = (filters) => {
  let searchParams = new URLSearchParams();
  _.map(filters, (value, key) => {
    if (_.isArray(value)) {
      //afaik arrays only work for exact SearchFilters!
      _.forEach(value, (arrayValue) => {
        searchParams.append(key + "[]", arrayValue);
      });
    } else {
      searchParams.append(key, value);
    }
  });
  return searchParams;
};
