import React from "react";
import { useSelector } from "react-redux";
import { getGeneric } from "../../../store/generic/reducer";
import _ from "lodash";
import { makeStyles } from "@mui/styles";
import paidTasksCaseStyle from "./paidTasksCaseStyle";
import {
  PAID_TASK_STATUS_ASSIGNED,
  PAID_TASK_STATUS_CREATED,
  PAID_TASK_STATUS_IN_REVIEW,
  PAID_TASK_STATUS_REQUESTED,
} from "../paidTaskStatus";
import PaidTaskCase from "./PaidTaskCase";
import { getBackofficeUser } from "../../../store/backofficeUser/reducer";
import ContentBox from "../../ContentBox/ContentBox";

const useStyles = makeStyles(paidTasksCaseStyle);
const PaidTasksCase = ({ product, refreshFunction }) => {
  const classes = useStyles();
  const paidTasks = useSelector((state) => getGeneric(state, "paidTasks" + product.backofficeCase.id));
  const currentUser = useSelector((state) => getBackofficeUser(state));
  if (!paidTasks || paidTasks["hydra:totalItems"] === 0) {
    return null;
  }

  const paidTasksFiltered = _.filter(paidTasks["hydra:member"], (paidTask) => {
    return (
      !currentUser.isExternal || paidTask.status === PAID_TASK_STATUS_CREATED || paidTask.assignee.id === currentUser.id
    );
  });
  const paidTasksOpen = _.filter(paidTasksFiltered, (paidTask) => {
    return (
      paidTask.status === PAID_TASK_STATUS_CREATED ||
      paidTask.status === PAID_TASK_STATUS_ASSIGNED ||
      paidTask.status === PAID_TASK_STATUS_REQUESTED
    );
  });
  const paidTasksInReview = _.filter(paidTasksFiltered, (paidTask) => {
    return paidTask.status === PAID_TASK_STATUS_IN_REVIEW;
  });

  return (
    <ContentBox headline="Ausgeschriebene Aufgaben">
      {paidTasksOpen.length > 0 ? (
        _.map(paidTasksOpen, (paidTask) => (
          <div className={classes.activityContainer} key={paidTask.id}>
            <PaidTaskCase paidTask={paidTask} refreshFunction={refreshFunction} />
          </div>
        ))
      ) : (
        <div className={classes.faded}>Keine Aufgabe vorhanden</div>
      )}
      <div className={classes.horizontalLine} />
      <div className={classes.headline}>Abzunehmende Aufgaben</div>
      {paidTasksInReview.length > 0 ? (
        _.map(paidTasksInReview, (paidTask) => (
          <div className={classes.activityContainer} key={paidTask.id}>
            <PaidTaskCase paidTask={paidTask} refreshFunction={refreshFunction} />
          </div>
        ))
      ) : (
        <div className={classes.faded}>Keine Aufgabe vorhanden</div>
      )}
    </ContentBox>
  );
};
export default PaidTasksCase;
