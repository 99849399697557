import CaseRefreshNotification from "../Notifications/CaseRefreshNotification";
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { getBackofficeUser } from "../../store/backofficeUser/reducer";
import { isCookieFetched, mercureFetchCookie, mercureSubscribe } from "../../services/mercure";
import { getCaseLink } from "../../services/Product/ProductService";
import SystemNoteNotification from "../Notifications/SystemNoteNotification";

const CaseNotifications = ({ product, setHasUpdates, refreshPage }) => {
  const [notification, setNotification] = useState(null);
  const currentUser = useSelector((state) => getBackofficeUser(state));
  const { enqueueSnackbar } = useSnackbar();

  const cookieFetched = isCookieFetched();
  useEffect(() => {
    if (cookieFetched) {
      const eventSource = mercureSubscribe(product["@id"], setNotification);
      return () => {
        eventSource.close();
      };
    } else {
      mercureFetchCookie();
    }
  }, [cookieFetched, product]);

  useEffect(() => {
    if (!notification || notification.backofficeUser === currentUser["@id"]) {
      return;
    }

    setHasUpdates(true);
    if (!!notification.backofficeUser && notification.backofficeUser !== "/backoffice_users/13") {
      showCaseUpdate(notification);
    } else {
      showSystemNotice(notification);
    }
  }, [notification]);

  const showCaseUpdate = (caseUpdate) => {
    setHasUpdates(true);
    enqueueSnackbar("", {
      content: (key) => <CaseRefreshNotification id={key} refreshPage={refreshPage} caseUpdate={caseUpdate} />,
    });
  };

  const showSystemNotice = (notification) => {
    if (
      notification.type !== "historyLog" ||
      notification.historyLog?.type !== "note" ||
      notification.historyLog.note.pinned === true
    ) {
      return;
    }
    enqueueSnackbar("", {
      content: (key) => (
        <SystemNoteNotification id={key} systemNote={notification.historyLog.note} caseLink={getCaseLink(product)} />
      ),
      persist: true,
    });
  };
  return null;
};
export default CaseNotifications;
