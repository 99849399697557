const costBoxStyle = (theme) => ({
  costText: {
    margin: 0,
  },
  costHintText: {
    textAlign: "justify",
    fontSize: 14,
  },
  costButton: {
    paddingLeft: 8,
    paddingRight: 8,
  },
  costDisclaimerContainer: {
    lineHeight: "0.8rem",
  },
  costDisclaimerText: {
    fontSize: 9,
    lineHeight: 1.5,
  },
  xsSpace: {
    [theme.breakpoints.down('sm')]: {
      marginTop: "1rem",
      paddingTop: "1rem",
    },
  },
  costEstimateLabel: {
    fontSize: "1.25rem",
    fontWeight: 400,
    textAlign: "left",
  },
  costEstimateLabelSmall: {
    fontSize: "1.25rem",
  },
  costEstimateValue: {
    fontSize: "1.25rem",
    fontWeight: 500,
  },
  costEstimateValueSmall: {
    fontSize: "1.125rem",
    fontWeight: 500,
  },
  costRealLabel: {
    fontWeight: 500,
    paddingBottom: 0,
  },
  costRealValue: {
    fontWeight: 500,
    paddingBottom: 0,
    textAlign: "right",
  },
  costRealSub: {
    paddingTop: 0,
    paddingBottom: 20,
  },
  paymentHint: {
    textAlign: "center",
    fontWeight: 500,
  },
  paymentHintSub: {
    textAlign: "center",
  },
  paymentInfoButton: {
    marginBottom: 25,
    paddingLeft: 0,
    paddingRight: 0,
  },
  infoIconContainer: {
    textAlign: "right",
    padding: 5,
  },
  info: {
    height: 20,
    width: 20,
    cursor: "pointer",
  },
  loadingContainer: {
    margin: "50px 230px",
  },
  hintBox: {
    textAlign: "center",
    border: "1px solid " + theme.palette.primary.main,
    borderRadius: "5px",
    padding: "0.5rem",
    marginTop: "1.5rem",
  },
  table: {
    margin: "2rem auto",
    maxWidth: 400,
  },
  tableCell: {
    fontSize: 10,
    padding: 0,
    borderBottom: "none",
  },
  tableCellHeader: {
    paddingTop: "1rem",
  },
  tableCellValue: {
    fontSize: 10,
    padding: "0 !important",
    borderBottom: "none",
    textAlign: "right",
  },
  underlined: {
    borderBottom: "1px solid #333333",
  },
  doubleUnderlinedRow: {
    height: 3,
    padding: 0,
  },
  doubleUnderlinedCell: {
    height: 3,
    borderBottom: "1px solid #333333",
    borderTop: "1px solid #333333",
    padding: 0,
  },
  tableRow: {
    height: 24,
  },
  disclaimer: {
    fontSize: 10,
    lineHeight: "1rem",
  },
  total: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  primary: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
});

export default costBoxStyle;
