import React from "react";
import { Grid } from "@mui/material";
import TimeFilter from "../ActivityOverviewHeader/TimeFilter/TimeFilter";
import DateRangeFilter from "../ActivityOverviewHeader/DateRangeFilter/DateRangeFilter";
import { makeStyles } from "@mui/styles";
import activityStyle from "./activityStyle";
import _ from "lodash";
import { ACTIVITY_OVERDUE } from "../../../services/Stages/Stages";

const useStyle = makeStyles(activityStyle);

const ActivityFilter = ({ isLoading, activityCount, userActivitiesToday, timespanFilter, setTimespanFilter }) => {
  const classes = useStyle();
  const userActivitiesOverdue = _.filter(
    userActivitiesToday,
    (activity) => activity.activityStatus === ACTIVITY_OVERDUE
  );
  return (
    <Grid container className={classes.filterContainer} spacing={2} alignItems={"center"}>
      <Grid item md={3} xs={12} className={classes.activityCount}>
        {!isLoading && (
          <>
            <span>Heute noch: {userActivitiesToday.length}</span>
            <span className={classes.activitiesOverdue}>Davon überfällig: {userActivitiesOverdue.length}</span>
          </>
        )}
      </Grid>
      <Grid item md={7} xs={12}>
        <TimeFilter timespanFilter={timespanFilter} setTimespanFilter={setTimespanFilter} />
      </Grid>
      <Grid item md={2} xs={12}>
        <DateRangeFilter timespanFilter={timespanFilter} setTimespanFilter={setTimespanFilter} />
      </Grid>
    </Grid>
  );
};

export default ActivityFilter;
