import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import useStyles from "./displayMediaObjectStyle";
import IconButton from "@mui/material/IconButton";
import {
  ArrowBackIos,
  ArrowForwardIos,
  RotateLeft,
  RotateRight,
  ZoomIn,
  ZoomOut,
  ZoomOutMap,
} from "@mui/icons-material";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { CircularProgress, Grid } from "@mui/material";
import EditableTitle from "./EditableTitle";
import ApiClient from "../../services/ApiClient";
import _ from "lodash";
import { fetchResource } from "../../store/generic/actions";
import { useDispatch } from "react-redux";

const DisplayMediaObject = ({
  mediaObject,
  closeDialog,
  switchImage,
  hasPreviousImage,
  hasNextImage,
  refreshMediaObjects,
  setMediaObjectToPreview,
  titleIsEditable,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [imageRotation, setImageRotation] = useState(0);
  const [displayUrl, setDisplayUrl] = useState(mediaObject?.displayUrl);
  const [downloadUrl, setDownloadUrl] = useState(mediaObject?.downloadUrl);

  useEffect(() => {
    if (!mediaObject) {
      return;
    }
    if (!mediaObject.displayUrl || !mediaObject.downloadUrl) {
      setDisplayUrl(null);
      setDownloadUrl(null);
      dispatch(fetchResource(mediaObject.id, "media_objects")).then((responseMediaObject) => {
        setDisplayUrl(responseMediaObject.displayUrl);
        setDownloadUrl(responseMediaObject.downloadUrl);
      });
    } else {
      setDisplayUrl(mediaObject.displayUrl);
      setDownloadUrl(mediaObject.downloadUrl);
    }
  }, [mediaObject]);

  if (!mediaObject || !displayUrl || !downloadUrl) {
    return null;
  }

  const rotateImage = (direction) => {
    if (direction === "clockwise") {
      if (imageRotation > 270) {
        setImageRotation(90);
        return;
      }
      setImageRotation(imageRotation + 90);
    }
    if (direction === "counterclockwise") {
      if (imageRotation < 90) {
        setImageRotation(270);
        return;
      }
      setImageRotation(imageRotation - 90);
    }
  };

  const ShowImage = ({ mediaObject }) => {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      setIsLoading(true);
    }, [mediaObject]);

    return mediaObject.mimeType === "application/pdf" ? (
      <div className={classes.embedContainer}>
        {isLoading && (
          <div className={classes.loadingWrapper}>
            <CircularProgress />
          </div>
        )}
        <embed
          onLoad={() => setIsLoading(false)}
          src={displayUrl}
          type={mediaObject.mimeType}
          width="100%"
          height="95%"
          className={classes["rotate" + imageRotation]}
        />
      </div>
    ) : (
      <Grid container justifyContent={"center"}>
        <Grid item>
          <TransformWrapper
            wheel={{
              disabled: true,
            }}
          >
            {({ zoomIn, zoomOut, resetTransform }) => (
              <>
                {isLoading && (
                  <div className={classes.loadingWrapper}>
                    <CircularProgress />
                  </div>
                )}
                <Grid container justifyContent={"center"} alignItems={"center"}>
                  <Grid item>
                    <IconButton onClick={zoomIn} size="large">
                      <ZoomIn />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={zoomOut} size="large">
                      <ZoomOut />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={resetTransform} size="large">
                      <ZoomOutMap />
                    </IconButton>
                  </Grid>
                </Grid>
                <TransformComponent>
                  <img
                    onLoad={() => setIsLoading(false)}
                    src={displayUrl}
                    alt={mediaObject.originalName}
                    className={classes["rotate" + imageRotation]}
                    style={{ maxHeight: 768, maxWidth: 1024 }}
                  />
                </TransformComponent>
              </>
            )}
          </TransformWrapper>
        </Grid>
      </Grid>
    );
  };

  const handleTitleSave = async (newDescription) => {
    const updatedMediaObject = await ApiClient.put(mediaObject["@id"], {
      body: JSON.stringify({ description: newDescription }),
    });
    await refreshMediaObjects();
    setMediaObjectToPreview(updatedMediaObject);
  };

  return (
    <LegalbirdIoModal
      handleClose={closeDialog}
      open={!!mediaObject}
      title={
        titleIsEditable ? (
          <EditableTitle title={mediaObject.description} handleSave={handleTitleSave} />
        ) : (
          _.truncate(mediaObject.description, { length: 50 })
        )
      }
      hasActions={false}
      maxWidth={"md"}
    >
      <div className={classes.imageDialog}>
        <Grid container>
          <Grid item xs={12}>
            <IconButton
              onClick={() => switchImage("previous")}
              disabled={!hasPreviousImage}
              size="large">
              <ArrowBackIos />
            </IconButton>
            <IconButton onClick={() => switchImage("next")} disabled={!hasNextImage} size="large">
              <ArrowForwardIos />
            </IconButton>
          </Grid>
        </Grid>
        <div className={classes.imageContainer}>
          <ShowImage mediaObject={mediaObject} />
        </div>
        <div className={classes.buttonContainer}>
          <IconButton onClick={() => rotateImage("counterclockwise")} size="large">
            <RotateLeft />
          </IconButton>
          <IconButton onClick={() => rotateImage("clockwise")} size="large">
            <RotateRight />
          </IconButton>
        </div>
      </div>
    </LegalbirdIoModal>
  );
};

DisplayMediaObject.propTypes = {
  mediaObject: PropTypes.object,
  closeDialog: PropTypes.func.isRequired,
  switchImage: PropTypes.func,
  hasPreviousImage: PropTypes.bool,
  hasNextImage: PropTypes.bool,
  refreshMediaObjects: PropTypes.func,
  titleIsEditable: PropTypes.bool,
};

DisplayMediaObject.defaultProps = {
  switchImage: () => {},
  hasPreviousImage: false,
  hasNextImage: false,
  refreshMediaObjects: () => {},
  titleIsEditable: false,
  setMediaObjectToPreview: () => {},
};

export default DisplayMediaObject;
