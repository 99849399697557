import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import * as PropTypes from "prop-types";
import { VAT_MULTIPLIER } from "../../../services/globalVars";
import ContentBox from "../../ContentBox/ContentBox";

const useStyles = makeStyles(() => ({
  paper: {
    margin: "2rem 0 2rem 0",
    padding: "2rem",
    fontSize: "1rem",
  },
  headline: {
    marginBottom: "1rem",
    textAlign: "center",
    fontSize: "1.25rem",
  },
  noFineText: {
    textAlign: "center",
    color: "#a9a9a9",
  },
}));

const CostBoxTraffic = (props) => {
  const classes = useStyles(props);
  const { traffic } = props;

  const processReference = traffic.customer.prelead.partner === "advocard" ? 0 : traffic.costs.fine >= 71.5 ? 5103 : 5101;
  const processCosts = traffic.customer.prelead.partner === "advocard" ? 200 : traffic.costs.fine >= 71.5 ? 176 : 65;
  const processCostsAndExpenses = processCosts + 110 + 20 + 12;
  const courtProcessReference = traffic.costs.fine >= 71.5 ? 5109 : 5107;
  const courtProcessCosts = traffic.costs.fine >= 71.5 ? 176 : 65;
  const hearingReference = traffic.costs.fine >= 71.5 ? 5110 : 5108;
  const hearingCosts = traffic.costs.fine >= 71.5 ? 280.5 : 143;
  const courtCostsAndExpenses = courtProcessCosts + hearingCosts + 20;

  return (
    <ContentBox headline="Berechnete Kosten">
      {traffic.costs.fine ? (
        <>
          <p>
            <strong>
              Bußgeldhöhe:{" "}
              {traffic.costs.fine.toLocaleString("de-DE", {
                style: "currency",
                currency: "EUR",
              })}
            </strong>
            <br />
            <br />
          </p>
          <p>
            <strong>Kosten außergerichtliches Verfahren</strong>
            <br />
            Grundgebühr gem. Nr. 5100 VV RVG: 110,00 €<br />
            Verfahrensgebühr gem. Nr. {processReference} VV RVG:{" "}
            {processCosts.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
            <br />
            Entgelt für Post- und Telekommunikationsdienstleistungen Nr. 7002 VV RVG: 20,00 €<br />
            Auslagenpauschale für Aktenübersendung Behörde: 12,00 €<br />
            Zwischensumme Netto:{" "}
            {processCostsAndExpenses.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
            <br />
            <strong>
              Summe inkl. MwSt.:{" "}
              {(processCostsAndExpenses * VAT_MULTIPLIER).toLocaleString("de-DE", {
                style: "currency",
                currency: "EUR",
              })}
            </strong>
            <br />
            <br />
          </p>
          <p>
            <strong>Kosten gerichtliches Verfahren (RVG)</strong>
            <br />
            Verfahrensgebühr gem. Nr {courtProcessReference} VV RVG:{" "}
            {courtProcessCosts.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
            <br />
            Terminsgebühr gem Nr. {hearingReference} VV RVG:{" "}
            {hearingCosts.toLocaleString("de-DE", {
              style: "currency",
              currency: "EUR",
            })}
            <br />
            Entgelt für Post- und Telekommunikationsdienstleistungen Nr. 7002 VV RVG: 20,00 €<br />
            Zwischensumme Netto:{" "}
            {courtCostsAndExpenses.toLocaleString("de-DE", {
              style: "currency",
              currency: "EUR",
            })}
            <br />
            <strong>
              Summe inkl. MwSt:{" "}
              {(courtCostsAndExpenses * VAT_MULTIPLIER).toLocaleString("de-DE", {
                style: "currency",
                currency: "EUR",
              })}
            </strong>
          </p>
        </>
      ) : (
        <div className={classes.noFineText}>
          Bisher ist die Bußgeldhöhe unbekannt und daher keine Berechnung der Kosten nach RVG möglich.
        </div>
      )}
    </ContentBox>
  );
};

CostBoxTraffic.propTypes = {
  traffic: PropTypes.object.isRequired,
};

export default CostBoxTraffic;
