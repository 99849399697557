import ApiClient from "../../services/ApiClient";
import { GENERIC_RECEIVED } from "./actionTypes";
import _ from "lodash";

export const fetchCollection =
  (uri, name = "", options = {}) =>
  async (dispatch) => {
    const value = await ApiClient.get(uri, options);
    dispatch({ type: GENERIC_RECEIVED, name: name || _.camelCase(uri), value: value });
    return value;
  };

export const fetchResource =
  (id, uri, name = "", options = {}) =>
  async (dispatch) => {
    const value = await ApiClient.get(uri + "/" + id, options);
    dispatch({ type: GENERIC_RECEIVED, name: name || _.camelCase(uri) + id, value: value });
    return value;
  };

export const updateResource =
  (id, uri, data, name = "", options = {}) =>
  async (dispatch) => {
    options.body = JSON.stringify(data);
    const value = await ApiClient.put(uri + "/" + id, options);
    if (!!name) {
      dispatch({ type: GENERIC_RECEIVED, name: name, value: value });
    }
    return value;
  };

export const createResource =
  (uri, data, name = "", options = {}) =>
  async (dispatch) => {
    options.body = JSON.stringify(data);
    const value = await ApiClient.post(uri, options);
    if (!!name) {
      dispatch({ type: GENERIC_RECEIVED, name: name, value: value });
    }
    return value;
  };

export const deleteResource =
  (id, uri, data, name = "") =>
  async (dispatch) => {
    const value = await ApiClient.delete(uri + "/" + id);
    if (!!name) {
      dispatch({ type: GENERIC_RECEIVED, name: name, value: value });
    }
    return value;
  };
