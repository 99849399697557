import * as stagesCase from "../../Stages/StagesCase";
import { formValue } from "../../formServiceFunctions";

const providerProductSpecificDefinition = (product) => [
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.providerType`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    options: () => [
      { value: "mobile" },
      { value: "dsl" },
      { value: "electricity" },
      { value: "gas" },
      { value: "other" },
    ],
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.reasonForDispute`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    options: () => [
      { value: "priceIncrease" },
      { value: "extraOptionGas" },
      { value: "cancellationByProvider" },
      { value: "serviceNotAsAgreed" },
      { value: "cancellationRefused" },
      { value: "invoiceIncorrect" },
      { value: "other" },
      { value: "custom" },
    ],
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.contractSignedBy`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    isHidden: ({ values, product }) => formValue(values, `${product.productClassName}.reasonForDispute`) !== 'extraOptionGas',
    options: () => [
      { value: "client" },
      { value: "landlord" },
    ],
  },
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.extraOptionGasNotification`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    isHidden: ({ values, product }) => formValue(values, `${product.productClassName}.reasonForDispute`) !== 'extraOptionGas',
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.contractHasPriceFixing`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    isHidden: ({ values, product }) => formValue(values, `${product.productClassName}.reasonForDispute`) !== 'extraOptionGas' || formValue(values, `${product.productClassName}.contractSignedBy`) !== "client",
    options: () => [
      { value: "yes" },
      { value: "no" },
      { value: "unknown" },
    ],
  },
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.cancellationReceivedDate`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    isHidden: ({ values, product }) => formValue(values, `${product.productClassName}.reasonForDispute`) !== 'cancellationByProvider',
  },
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.cancellationDate`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    isHidden: ({ values, product }) => formValue(values, `${product.productClassName}.reasonForDispute`) !== 'cancellationRefused',
  },
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.endOfContractDate`,
    isDisabled: ({product}) => product.stage >= stagesCase.STAGE_CHECKED,
    isHidden: ({ values, product }) => formValue(values, `${product.productClassName}.reasonForDispute`) !== 'cancellationRefused',
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.opponentReactionToCancellation`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    isHidden: ({ values, product }) => formValue(values, `${product.productClassName}.reasonForDispute`) !== 'cancellationRefused',
    options: () => [
      { value: "noReaction" },
      { value: "cancellationRefused" },
      { value: "differentDate" },
    ],
  },
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.endOfContractDateOpponent`,
    isDisabled: ({product}) => product.stage >= stagesCase.STAGE_CHECKED,
    isHidden: ({ values, product }) => (formValue(values, `${product.productClassName}.reasonForDispute`) !== 'cancellationRefused' ||
       formValue(values, `${product.productClassName}.opponentReactionToCancellation`) !== 'differentDate'),
  },
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.priceIncreaseAnnouncementDate`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    isHidden: ({ values, product }) => formValue(values, `${product.productClassName}.reasonForDispute`) !== 'priceIncrease',
  },
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.specialTerminationDate`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    isHidden: ({ values, product }) => formValue(values, `${product.productClassName}.reasonForDispute`) !== 'priceIncrease',
  },
  {
    type: "ValidatorTextField",
    path: `${product.productClassName}.contractNumber`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
  },
];

export default providerProductSpecificDefinition;
