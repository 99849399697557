import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  note: {
    marginTop: "1rem",
    marginBottom: "2rem",
  },
}));

export default useStyles;
