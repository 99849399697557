const basicIdentifierTranslations = {
  INT: { label: "Nur zur internen Bearbeitung" },
  SN: { label: "Sendenachweis Einschreiben" },
  SAG: { label: "Schreiben an Gegenseite" },
  KON: { label: "Dokument zur Kenntnisnahme und Kontrolle" },
  UML: { label: "Umladung Gerichtstermin" },
  ABL: { label: "Abladung Gerichtstermin" },
  EB: { label: "Empfangsbekenntnis" },
  REP: { label: "Replik" },
  PPK: { label: "Prüfprotokoll beA" },
};

const contractLawIdentifierTranslations = {
  RG: { label: "Reaktion der Gegenseite" },
  GKV: { label: "Gerichtskostenvorschussrechnung" },
  VGLV: { label: "Vergleichsvorschlag Gegenseite" },
  KE: { label: "Klageerwiderung" },
  LGT: { label: "Ladung Gerichtstermin" },
  VGL: { label: "Vergleichsdokument vom Gericht" },
  URT: { label: "Urteil vom Gericht" },
  KLGS: { label: "Klage von Gegenseite" },
  VU: { label: "Versäumnisurteil" },
  KFBB: { label: "Kostenfestsetzungsbeschluss Beklagter" },
  KFBK: { label: "Kostenfestsetzungsbeschluss Kläger" },
  SVV: { label: "Schriftsätze im schriftlichen Vorverfahren" },
  ...basicIdentifierTranslations,
};

export const identifierTranslations = {
  divorce: {
    FBVA: { label: "Fragebogen Versorgungsausgleich vom Gericht" },
    FBVAEP: { label: "Angaben des Ehepartners zur Kontrolle" },
    FU: { label: "Fehlende Unterlagen des Kunden" },
    FUVA: { label: "Fehlende Unterlagen Versorgungsausgleich" },
    LGT: { label: "Ladung Scheidungstermin" },
    RGB: { label: "Rechnung Gerichtsgebühr" },
    SB: { label: "Scheidungsbeschluss" },
    SBRKV: { label: "Scheidungsbeschluss mit Rechtskraftvermerk" },
    VA: { label: "Infos zum Versorgungsausgleich" },
    VKH: { label: "Antrag Verfahrenskostenhilfe" },
    VKHBW: { label: "VKH bewilligt" },
    VKHNB: { label: "VKH nicht bewilligt" },
    VKHRZ: { label: "VKH Ratenzahlung" },
    SA: { label: "Scheidungsantrag" },
    ...basicIdentifierTranslations,
  },
  settlement: {
    GTOVGL: { label: "Protokoll Gütetermin ohne Vergleich" },
    KE: { label: "Klageerwiderung des Antraggegners" },
    LGT: { label: "Ladung Gütetermin" },
    LKT: { label: "Ladung zum Kammertermin" },
    VGL: { label: "Vergleichsdokument vom Gericht" },
    VGLV: { label: "Vergleichsvorschlag" },
    URT: { label: "Urteil vom Gericht" },
    ...basicIdentifierTranslations,
  },
  traffic: {
    BGB: { label: "Bußgeldbescheid" },
    ABGB: { label: "Abgeänderter Bußgeldbescheid" },
    EA: { label: "Ermittlungsakte" },
    REA: { label: "Rechnung Ermittlungsakte" },
    EM: { label: "Einstellungsmitteilung" },
    EMG: { label: "Einstellungsmitteilung durch Beschluss" },
    AN: { label: "Abgabenachricht Gericht" },
    LGT: { label: "Ladung Hauptverhandlung" },
    URT: { label: "Urteil" },
    EDB: { label: "Entscheidung durch Beschluss" },
    DZRSV: { label: "Deckungszusage RSV" },
    KARSV: { label: "Kostenablehnung" },
    RRSV: { label: "Rückfragen RSV" },
    RFB: { label: "Rückfragen Behörde" },
    RFG: { label: "Rückfragen Gericht" },
    ...basicIdentifierTranslations,
  },
  alimony: {
    ...basicIdentifierTranslations
  },
  death: {
    ...basicIdentifierTranslations
  },
  familyLawCase: {
    ...basicIdentifierTranslations
  },
  eventContract: contractLawIdentifierTranslations,
  membershipContract: contractLawIdentifierTranslations,
  otherContract: contractLawIdentifierTranslations,
  providerContract: contractLawIdentifierTranslations,
  purchaseContract: contractLawIdentifierTranslations,
  serviceContract: contractLawIdentifierTranslations,
  subscriptionContract: contractLawIdentifierTranslations,
  travelContract: contractLawIdentifierTranslations,
  vehicleContract: contractLawIdentifierTranslations,
};
