import ApiClient from "../../services/ApiClient";
import { persistToken } from "../../services/localStorage";
import { BACKOFFICEUSER_FETCH, BACKOFFICEUSER_LOGIN, BACKOFFICEUSER_LOGOUT } from "./actionTypes";

export const login = (credentials) => {
  return async (dispatch) => {
    const token = await ApiClient.getToken(credentials);
    persistToken(token);

    const backofficeUser = await ApiClient.get("backoffice_users/me");
    dispatch({ type: BACKOFFICEUSER_LOGIN, backofficeUser: backofficeUser["hydra:member"][0] });
  };
};

export const logout = () => {
  return async (dispatch) => {
    dispatch({ type: BACKOFFICEUSER_LOGOUT });
  };
};

export const fetch = () => {
  return async (dispatch) => {
    const backofficeUser = await ApiClient.get("backoffice_users/me");
    dispatch({ type: BACKOFFICEUSER_FETCH, backofficeUser: backofficeUser["hydra:member"][0] });
  };
}
