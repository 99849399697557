import ApiClient from "../ApiClient";
import _ from "lodash";
import { ArticleOutlined, InsertDriveFileOutlined, InsertPhotoOutlined, VideoFileOutlined } from "@mui/icons-material";
import React from "react";

export const previewCaseFile = async ({ product, documentType, documentIdentifier, params = {} }) => {
  const previewResponse = await ApiClient.post("files/generate/" + product.productClassName + "/" + product.id, {
    body: JSON.stringify(
      _.merge({}, params, { isPreview: true, documentType: documentType, documentIdentifier: documentIdentifier })
    ),
  });
  return previewResponse.base64Content;
};

export const mimeTypes = {
  image: ["image/jpeg", "image/png"], // removed "image/tiff" because only ie and safari support opening that format
  pdf: ["application/pdf"],
  video: ["video/x-msvideo", "video/mp4", "video/mpeg", "video/webm"],
};

export const getIconByMimeType = (mimeType) => {
  switch (true) {
    case _.includes(mimeTypes.pdf, mimeType):
      return <ArticleOutlined />;
    case _.includes(mimeTypes.image, mimeType):
      return <InsertPhotoOutlined />;
    case _.includes(mimeTypes.video, mimeType):
      return <VideoFileOutlined />;
    default:
      return <InsertDriveFileOutlined />;
  }
};

export const splitFileNameAndExtension = fileName => {
  let splitFileName = _.split(fileName, ".");
  const fileExtension = splitFileName.pop();
  return {
    extension: fileExtension,
    fileName: _.join(splitFileName, ".")
  };
}
