import { useEffect, useState } from "react";
import ApiClient from "../services/ApiClient";

function useEmailFetching(dataSource) {
  const [isLoading, setIsLoading] = useState(true);
  const [emails, setEmails] = useState();
  const [error, setError] = useState("");

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const fetchDataResponse = await ApiClient.get(`email_messages?${dataSource}`);
        if (fetchDataResponse) {
          setEmails(fetchDataResponse["hydra:member"]);
        }
      } catch (error) {
        setError(error.message);
      }
      setIsLoading(false);
    }
    fetchData();
  }, [dataSource]);

  return {
    error,
    isLoading,
    emails,
  };
}
export default useEmailFetching;
