import {
  Button,
  Grid,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import ClearableSearchField from "../Search/ClearableSearchField";
import LabeledSelect from "../Select/LabeledSelect";
import _ from "lodash";
import UserRow from "./UserRow";
import { useBackofficeUser } from "../../provider/BackofficeUserProvider";
import ContentBox from "../ContentBox/ContentBox";
import NewBackofficeUserModal from "./NewBackofficeUserModal";
import { Roles } from "../../types/BackofficeUser";
import { translate } from "../../services/Translations/translatorService";

const ENTRIES_PER_PAGE = 20;

export default function UserManagement() {
  const [nameFilter, setNameFilter] = useState("");
  const [roleFilter, setRoleFilter] = useState("all");
  const [page, setPage] = useState(0);
  const [statusFilter, setStatusFilter] = useState("all");
  const [newUserModalOpen, setNewUserModalOpen] = useState(false);
  const { backofficeUsers, fetchBackofficeUsers } = useBackofficeUser();

  const sortedBackofficeUsers = useMemo(
    () => _.sortBy(backofficeUsers, (user) => user.person.familyName),
    [backofficeUsers]
  );

  const hasNextPage = sortedBackofficeUsers.length > (page + 1) * ENTRIES_PER_PAGE;

  const filterBackofficeUsers = () => {
    let filteredBackofficeUsers = sortedBackofficeUsers;
    if (nameFilter) {
      filteredBackofficeUsers = _.filter(filteredBackofficeUsers, (backofficeUser) =>
        _.includes(_.toLower(backofficeUser.person.fullname), _.toLower(nameFilter))
      );
    }
    if (roleFilter !== "all") {
      filteredBackofficeUsers = _.filter(filteredBackofficeUsers, (backofficeUser) =>
        _.includes(backofficeUser.roles, roleFilter)
      );
    }
    if (statusFilter !== "all") {
      filteredBackofficeUsers = _.filter(
        filteredBackofficeUsers,
        (backofficeUser) => backofficeUser.status === statusFilter
      );
    }

    return filteredBackofficeUsers;
  };

  const filteredBackofficeUsers = useMemo(
    () => filterBackofficeUsers(),
    [nameFilter, statusFilter, roleFilter, sortedBackofficeUsers]
  );

  const backofficeUsersToDisplay = filteredBackofficeUsers.slice(
    page * ENTRIES_PER_PAGE,
    page * ENTRIES_PER_PAGE + ENTRIES_PER_PAGE
  );

  return (
    <>
      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={12} md={3} lg={4}>
          <Button variant={"contained"} fullWidth={false} onClick={() => setNewUserModalOpen(true)}>
            Neuen Nutzer anlegen
          </Button>
          <NewBackofficeUserModal
            isOpen={newUserModalOpen}
            setIsOpen={setNewUserModalOpen}
            fetchBackofficeUsers={fetchBackofficeUsers}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={4}>
          <ClearableSearchField
            filter={nameFilter}
            placeholder={"Name des Benutzers"}
            setFilter={setNameFilter}
            handleClearFilter={() => setNameFilter("")}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={2}>
          <LabeledSelect label={"Rolle"} value={roleFilter} onChange={(event) => setRoleFilter(event.target.value)}>
            <MenuItem value={"all"}>Alle Rollen</MenuItem>
            <MenuItem value={Roles.admin}>{translate("backofficeUser.roles.values." + Roles.admin)}</MenuItem>
            <MenuItem value={Roles.accountmanager}>{translate("backofficeUser.roles.values." + Roles.accountmanager)}</MenuItem>
            <MenuItem value={Roles.traineeLawyer}>{translate("backofficeUser.roles.values." + Roles.traineeLawyer)}</MenuItem>
            <MenuItem value={Roles.lawyer}>{translate("backofficeUser.roles.values." + Roles.lawyer)}</MenuItem>
            <MenuItem value={Roles.accounting}>{translate("backofficeUser.roles.values." + Roles.accounting)}</MenuItem>
            <MenuItem value={Roles.external}>{translate("backofficeUser.roles.values." + Roles.external)}</MenuItem>
            <MenuItem value={Roles.developer}>{translate("backofficeUser.roles.values." + Roles.developer)}</MenuItem>
            <MenuItem value={Roles.banking}>{translate("backofficeUser.roles.values." + Roles.banking)}</MenuItem>
            <MenuItem value={Roles.callcenter}>{translate("backofficeUser.roles.values." + Roles.callcenter)}</MenuItem>
          </LabeledSelect>
        </Grid>
        <Grid item xs={12} md={3} lg={2}>
          <LabeledSelect
            label={"Status"}
            value={statusFilter}
            onChange={(event) => setStatusFilter(event.target.value)}
          >
            <MenuItem value={"all"}>Alle Status</MenuItem>
            <MenuItem value={"invited"}>Eingeladen</MenuItem>
            <MenuItem value={"active"}>Aktiv</MenuItem>
            <MenuItem value={"inactive"}>Deaktiviert</MenuItem>
          </LabeledSelect>
        </Grid>
      </Grid>
      <ContentBox>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nutzer</TableCell>
                <TableCell>E-Mail-Adresse</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Rolle</TableCell>
                <TableCell>Freigeschaltet für</TableCell>
                <TableCell> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(backofficeUsersToDisplay, (user) => (
                <UserRow key={user.id} user={user} fetchBackofficeUsers={fetchBackofficeUsers} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack direction={"row"} justifyContent={"space-between"} sx={{ marginTop: "1rem" }}>
          <Button onClick={() => setPage(page - 1)} disabled={page < 1} fullWidth={false}>
            Vorherige Seite
          </Button>
          <Button onClick={() => setPage(page + 1)} disabled={!hasNextPage} fullWidth={false}>
            Nächste Seite
          </Button>
        </Stack>
      </ContentBox>
    </>
  );
}
