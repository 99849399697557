import React from "react";
import _ from "lodash";
import {
  AccountBalanceOutlined,
  Alarm,
  AssignmentIndOutlined,
  AssignmentTurnedInOutlined,
  AssignmentTurnedInOutlined as CreateDocument,
  BallotOutlined,
  CallOutlined as Call,
  ContactPhoneOutlined as LawyerCall,
  DateRangeOutlined as CourtDate,
  DescriptionOutlined as Document,
  EventAvailable,
  MailOutline as Mail,
  ReportProblemOutlined,
  RotateLeft, SpeakerNotesOutlined,
  WorkOutlineOutlined as Lawyer
} from "@mui/icons-material";
import { ForwardToInbox } from "@mui/icons-material";

const contractActivityTypes = [
  {
    name: "court_date",
    label: "Gerichtstermin",
    icon: <CourtDate color={"secondary"} />,
    prefillDescription: true,
  },
];

const activityTypes = {
  general: [
    {
      name: "no_type",
      label: "Kein Typ",
      icon: <BallotOutlined color={"secondary"} />,
      disabled: true,
      prefillDescription: true,
    },
    {
      name: "client_call",
      label: "Beratungstermin (manuell vereinbart)",
      icon: <LawyerCall color={"secondary"} />,
      prefillDescription: true,
    },
    {
      name: "lawyer_counseling_call",
      label: "Beratungstermin",
      icon: <LawyerCall color={"secondary"} />,
      disabled: true,
      prefillDescription: true,
    },
    {
      name: "calendly_event",
      label: "Beratungstermin",
      icon: <LawyerCall color={"secondary"} />,
      disabled: true,
      prefillDescription: true,
    },
    {
      name: "follow_up",
      label: "Nachfass",
      icon: <Call color={"secondary"} />,
      prefillDescription: false,
    },
    {
      name: "inbound_call",
      label: "Inbound Call",
      icon: <Call color={"secondary"} />,
      prefillDescription: true,
    },
    {
      name: "accountmanager_task",
      label: "KB Tätigkeit",
      icon: <AssignmentIndOutlined color={"secondary"} />,
      prefillDescription: false,
    },
    {
      name: "mandate_activity",
      label: "Mandatstätigkeit",
      icon: <Lawyer color={"secondary"} />,
      prefillDescription: false,
    },
    {
      name: "check_document",
      label: "Dokument prüfen",
      icon: <Document color={"secondary"} />,
      anchor: "#documents",
      prefillDescription: true,
    },
    {
      name: "check_payment",
      label: "Zahlungseingang prüfen",
      icon: <Alarm color={"secondary"} />,
      prefillDescription: true,
    },
    {
      name: "accounting",
      label: "Buchhaltung",
      icon: <AccountBalanceOutlined color={"secondary"} />,
      prefillDescription: false,
    },
    {
      name: "check_status",
      label: "Statuscheck",
      icon: <RotateLeft color={"secondary"} />,
      prefillDescription: false,
    },
    {
      name: "deadline",
      label: "Frist",
      icon: <Alarm color={"secondary"} />,
      prefillDescription: true,
    },
    {
      name: "internal_deadline",
      label: "Interne Frist",
      icon: <EventAvailable color={"secondary"} />,
      prefillDescription: true,
    },
    {
      name: "peremptory_term",
      label: "Notfrist",
      icon: <ReportProblemOutlined color={"secondary"} />,
      prefillDescription: true,
    },
    {
      name: "mail",
      label: "Post",
      icon: <Mail color={"secondary"} />,
      prefillDescription: false,
    },
    {
      name: "email",
      label: "E-Mail",
      icon: <Mail color={"secondary"} />,
      anchor: "#mails",
      prefillDescription: false,
    },
    {
      name: "check_case",
      label: "Fallprüfung",
      icon: <AssignmentTurnedInOutlined color={"secondary"} />,
      prefillDescription: true,
    },
    {
      name: "send_via_bea",
      label: "Versand per beA",
      icon: <ForwardToInbox />,
      prefillDescription: true,
    },
    {
      name: "representative",
      label: "Terminsvertreter",
      icon: <Lawyer color={"secondary"} />,
      prefillDescription: true,
    },
    {
      name: "call_notes",
      label: "Gesprächsnotiz",
      icon: <SpeakerNotesOutlined color={"secondary"} />,
      prefillDescription: true,
      disabled: true,
    },
  ],
  divorce: [
    {
      name: "status_1_call",
      label: "Status 1 Call",
      icon: <Call color={"secondary"} />,
      prefillDescription: true,
    },
    {
      name: "initial_call",
      label: "Ersttelefonat",
      icon: <Call color={"secondary"} />,
      prefillDescription: true,
    },
    {
      name: "create_divorce_application",
      label: "Scheidungsantrag erstellen",
      icon: <CreateDocument color={"secondary"} />,
      prefillDescription: true,
    },
    {
      name: "send_document_to_customer",
      label: "Dokument an Kunden schicken",
      icon: <Document color={"secondary"} />,
      prefillDescription: true,
    },
    {
      name: "submit_divorce",
      label: "Scheidung einreichen",
      icon: <CreateDocument color={"secondary"} />,
      prefillDescription: true,
    },
    {
      name: "court_date",
      label: "Gerichtstermin",
      icon: <CourtDate color={"secondary"} />,
      prefillDescription: true,
    },
  ],
  settlement: [
    {
      name: "status_1_call",
      label: "Status 1 Call",
      icon: <Call color={"secondary"} />,
      prefillDescription: true,
    },
    {
      name: "initial_call",
      label: "Ersttelefonat",
      icon: <Call color={"secondary"} />,
      prefillDescription: true,
    },
    {
      name: "create_dismissal_protection_suit",
      label: "Klage erstellen",
      icon: <CreateDocument color={"secondary"} />,
      prefillDescription: true,
    },
    {
      name: "court_date",
      label: "Gerichtstermin",
      icon: <CourtDate color={"secondary"} />,
      prefillDescription: true,
    },
    {
      name: "send_document_to_customer",
      label: "Dokument an Kunden schicken",
      icon: <Document color={"secondary"} />,
      prefillDescription: true,
    },
    {
      name: "submit_dismissal_protection_suit",
      label: "Klage einreichen",
      icon: <CreateDocument color={"secondary"} />,
      prefillDescription: true,
    },
  ],
  traffic: [
    {
      name: "initial_call",
      label: "Ersttelefonat",
      icon: <Call color={"secondary"} />,
      prefillDescription: true,
    },
    {
      name: "send_document_to_customer",
      label: "Dokument an Kunden schicken",
      icon: <Document color={"secondary"} />,
      prefillDescription: true,
    },
    {
      name: "access_to_records",
      label: "Einspruch/Akteneinsicht",
      icon: <CreateDocument color={"secondary"} />,
      prefillDescription: true,
    },
    {
      name: "submit_opinion",
      label: "Stellungnahme schicken",
      icon: <CreateDocument color={"secondary"} />,
      prefillDescription: true,
    },
    {
      name: "court_date",
      label: "Gerichtstermin",
      icon: <CourtDate color={"secondary"} />,
      prefillDescription: true,
    },
  ],
  eventContract: contractActivityTypes,
  membershipContract: contractActivityTypes,
  otherContract: contractActivityTypes,
  providerContract: contractActivityTypes,
  purchaseContract: contractActivityTypes,
  serviceContract: contractActivityTypes,
  subscriptionContract: contractActivityTypes,
  travelContract: contractActivityTypes,
  vehicleContract: contractActivityTypes,
};

export const deadlineActivityTypes = ["deadline", "peremptory_term", "internal_deadline"];

export function getActivitiesSet(productClassName) {
  const generalActivityTypesCopy = _.cloneDeep(activityTypes.general);
  const productActivityTypesCopy = _.cloneDeep(_.get(activityTypes, productClassName, []));
  const combinedActivityTypes = [...generalActivityTypesCopy, ...productActivityTypesCopy];
  return _.uniqBy(combinedActivityTypes, (activityType) => activityType.name);
}

export function getFullActivitiesSet() {
  const allActivityTypes = [
    ..._.cloneDeep(activityTypes.divorce),
    ..._.cloneDeep(activityTypes.settlement),
    ..._.cloneDeep(activityTypes.traffic),
    ..._.cloneDeep(activityTypes.general),
  ];
  return _.sortBy(
    _.uniqBy(allActivityTypes, (activityType) => activityType.name),
    "label"
  );
}

export function getDeadlinesActivitiesSet() {
  const allActivityTypes = [
    ..._.cloneDeep(activityTypes.divorce),
    ..._.cloneDeep(activityTypes.settlement),
    ..._.cloneDeep(activityTypes.traffic),
    ..._.cloneDeep(activityTypes.general),
  ];
  const allDeadlineActivityTypes = _.filter(allActivityTypes, (activityType) =>
    _.includes(deadlineActivityTypes, activityType.name)
  );
  return _.sortBy(
    _.uniqBy(allDeadlineActivityTypes, (activityType) => activityType.name),
    "label"
  );
}

export function getActivityIcon(productClassName, activityName) {
  return _.get(
    _.find(getActivitiesSet(productClassName), (activity) => activity.name === activityName),
    "icon",
    <>{activityName}</>
  );
}

export function getActivityAnchor(productClassName, activityName) {
  return _.get(
    _.find(getActivitiesSet(productClassName), (activity) => activity.name === activityName),
    "anchor"
  );
}

export function getActivityLabel(productClassName, activityName) {
  return _.get(
    _.find(getActivitiesSet(productClassName), (activity) => activity.name === activityName),
    "label",
    "N/A"
  );
}

export function getActivityData(productClassName, activityName) {
  return _.find(getActivitiesSet(productClassName), (activity) => activity.name === activityName);
}

export function transformActivitySetToAutoCompleteOptions(activityTypes) {
  return _.map(activityTypes, (activityType) => {
    return { label: activityType.label, name: activityType.name, disabled: activityType.disabled };
  });
}
