import React, { useState } from "react";
import { IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ButtonLoading from "../Button/ButtonLoading";
import Snackbar from "../Snackbar/Snackbar";
import { useSnackbar } from "notistack";
import ApiClient from "../../services/ApiClient";
import _ from "lodash";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import { useSelector } from "react-redux";
import { getBackofficeUser } from "../../store/backofficeUser/reducer";

export default function EmailChangeModal({ newEmail, setIsEmailModalOpen, isEmailModalOpen }) {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useSelector((state) => getBackofficeUser(state));

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    ApiClient.getToken({ email: currentUser.email, password: password }).then(
      () => {
        ApiClient.post("change_user_email", {
          body: JSON.stringify({ newEmail: _.toLower(newEmail) }),
        }).then(() => {
          createSnackbar("Es wurde Ihnen eine Bestätigungsmail zugesendet");
          setIsLoading(false);
          setIsEmailModalOpen(false);
        });
      },
      () => {
        createSnackbar("Das eingegebene Passwort ist falsch");
        setIsLoading(false);
      }
    );
  };

  const createSnackbar = (snackbarMessage) => {
    enqueueSnackbar("", {
      content: () => (
        <div>
          <Snackbar message={snackbarMessage} isNonInteractive />
        </div>
      ),
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
  };

  return (
    <LegalbirdIoModal
      handleClose={() => setIsEmailModalOpen(false)}
      open={isEmailModalOpen}
      title={"E-Mail-Adresse ändern"}
      hideCancelButton
      submitButton={
        <ButtonLoading
          color="primary"
          variant="contained"
          onClick={(e) => handleSubmit(e)}
          fullWidth
          isLoading={isLoading}
        >
          E-Mail-Adresse ändern
        </ButtonLoading>
      }
    >
      <form>
        <Stack spacing={2}>
          <Typography>
            Zu Ihrer Sicherheit: Bitte bestätigen Sie die Änderung der E-Mail-Adresse mit Ihrem Passwort.
          </Typography>
          <TextField
            fullWidth={true}
            type={showPassword ? "text" : "password"}
            label="Passwort"
            name="password"
            value={password}
            onChange={({ target }) => setPassword(target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Typography>
            Sie müssen die neue E-Mail-Adresse noch bestätigen, bevor die Änderung endgültig übernommen wird. Hierzu
            erhalten Sie eine E-Mail von uns.
          </Typography>
        </Stack>
      </form>
    </LegalbirdIoModal>
  );
}
