import React from "react";
import * as PropTypes from "prop-types";
import caseAlertsStyle from "./caseAlertsStyle";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(caseAlertsStyle);
const CaseAlerts = ({ product }) => {
  const classes = useStyles();
  return (
    <>
      {product.preventAllSystemEmails && <div className={classes.caseAlert}>Achtung: E-Mails sind pausiert!</div>}
      {product.backofficeCase.preventAllSystemBeaOrFax && (
        <div className={classes.caseAlert}>Achtung: Automatischer beA-/Fax-Versand ist pausiert!</div>
      )}
      {product.preventAccounting && (
        <div className={classes.caseAlert}>Achtung: Automatische Kontoeinträge/Rechnungen sind pausiert!</div>
      )}
      {product.backofficeCase.preventAllSystemActivities && (
        <div className={classes.caseAlert}>Achtung: Automatische Aktivitäten sind pausiert!</div>
      )}
    </>
  );
};
CaseAlerts.propTypes = {
  product: PropTypes.object.isRequired,
};
export default CaseAlerts;
