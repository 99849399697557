import React, { useMemo, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import casePaidTaskStyle from "./casePaidTaskStyle";
import { Box, Button, Checkbox, FormControlLabel, Stack } from "@mui/material";
import { getInitialValuesForPaidTask, getSubmissionCardEnabled, getTemplates } from "../../services/paidTaskService";
import _ from "lodash";
import CasePaidTaskPdfGenerator from "./CasePaidTaskPdfGenerator";
import useForm from "../../hooks/useForm";
import ValidatorForm from "../Validator/ValidatorForm";
import FormElementAnnotated from "../Case/CaseForm/FormElementAnnotated";
import CasePaidTaskFileUploadArea from "./CasePaidTaskFileUploadArea";
import { ContentContainer } from "../Container/PageContentContainer";
import ButtonLoading from "../Button/ButtonLoading";
import { useDispatch, useSelector } from "react-redux";
import { updateResource } from "../../store/generic/actions";
import { editorStateToHTML } from "../../services/Editor/editorFunctions";
import moment from "moment";
import { getBackofficeUser } from "../../store/backofficeUser/reducer";
import { buildAddress } from "../Case/CasePdfGenerator/casePdfGeneratorService";
import PropTypes from "prop-types";
import { clearSessionStorageState } from "../../services/browserStorageService";
import { PAID_TASK_STATUS_ASSIGNED, PAID_TASK_STATUS_IN_REVIEW } from "../PaidTask/paidTaskStatus";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import { useSnackbar } from "notistack";
import Snackbar from "../Snackbar/Snackbar";
import ContentBox from "../ContentBox/ContentBox";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import { formValue } from "../../services/formServiceFunctions";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles(casePaidTaskStyle);

const CasePaidTaskSubmission = ({ paidTask, product }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [errorModalData, setErrorModalData] = useState("");
  const [choiceConfirmed, setChoiceConfirmed] = useState(false);
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => getBackofficeUser(state));
  const { enqueueSnackbar } = useSnackbar();

  const paidTaskTypeConfig = useMemo(
    () => _.find(getTemplates(product), (template) => template.type === paidTask.type),
    [paidTask.type]
  );

  const checkNonValidatorConditions = () => {
    if (paidTaskTypeConfig.hasEditor && editorStateToHTML(useFormProps.values.content).length < 200) {
      setErrorModalData("editorContentLength");
      return false;
    }

    if (
      paidTaskTypeConfig.mainDocumentConfig &&
      !_.find(paidTask.mediaObjects, (mediaObject) => mediaObject.identifier === "paidTaskMainDocument")
    ) {
      setErrorModalData("requiredFileMissing");
      return false;
    }
    return true;
  };

  //triggers status change when externals execute it
  const onSubmit = async () => {
    if (!checkNonValidatorConditions()) {
      return;
    }
    setIsLoading(true);
    const formData = _.merge({}, useFormProps.values);
    if (formData?.content?.getCurrentContent) {
      //not convertToRaw because, even though it's the right format for saving, we need the html in the backend subscriber
      formData.content = editorStateToHTML(formData.content);
      formData.address = buildAddress(useFormProps.values, product);
      clearSessionStorageState("customPdf");
    }

    let payload = {
      formData: formData,
    };

    if (currentUser.isExternal) {
      payload.markedAsDoneDate = moment().format("DD.MM.YYYY");
      payload.changesRequested = false;
    }

    await dispatch(updateResource(paidTask.id, "paid_tasks", payload, "paidTask" + paidTask.id));

    if (currentUser.isExternal) {
      enqueueSnackbar("", {
        content: () => (
          <div>
            <Snackbar
              message={"Sie haben diese Aufgabe erledigt"}
              buttonType={"link"}
              buttonLink={"/services/extern/aufgaben"}
              buttonTarget={"_self"}
              buttonText={"Meine Aufgaben"}
            />
          </div>
        ),
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        persist: true,
      });
    }
    setIsLoading(false);
  };

  const initialValues = useMemo(
    () => getInitialValuesForPaidTask(paidTaskTypeConfig, product, paidTask),
    [paidTaskTypeConfig, product, paidTask]
  );

  const useFormProps = useForm({
    initialValues,
    onSubmit,
  });

  if (!paidTaskTypeConfig) {
    return <Stack justifyContent={"center"}>Für diesen Ausschreibentyp ist keine Konfiguration hinterlegt</Stack>;
  }

  const formFieldsEnabled =
    currentUser.isExternal || (paidTaskTypeConfig.hasEditor && paidTask.status === PAID_TASK_STATUS_IN_REVIEW);

  const isDisabled = !getSubmissionCardEnabled(paidTask.status, currentUser.isExternal);

  return (
    <Box
      sx={
        isDisabled ? {
          opacity: 0.5,
          pointerEvents: "none",
        } : {}
      }
    >
      <ContentBox headline="Bearbeitung" headlineVariant="h2">
        <div className={classes.verticalSpace} />
        <ValidatorForm onSubmit={useFormProps.handleSubmit} submitOnEnter={false}>
          {paidTaskTypeConfig.hasEditor && (
            <CasePaidTaskPdfGenerator
              paidTask={paidTask}
              product={product}
              {...useFormProps}
              initialValues={initialValues}
            />
          )}
          <ContentContainer size={"tiny"} className={classes.formElementsContainer}>
            <CasePaidTaskFileUploadArea
              paidTaskTypeConfig={paidTaskTypeConfig}
              product={product}
              paidTask={paidTask}
              readOnly={paidTask.status !== PAID_TASK_STATUS_ASSIGNED}
            />
            <div className={classes.verticalSpace} />
            {_.map(paidTaskTypeConfig.fields, (formElementProps) => (
              <div className={classes.formElement} key={formElementProps.path}>
                <FormElementAnnotated
                  headline={formElementProps.headline}
                  description={formElementProps.description}
                  product={product}
                  {...formElementProps}
                  path={"temporaryField." + formElementProps.path}
                  translationPath={product.productClassName + "." + formElementProps.path}
                  {...useFormProps}
                  isDisabled={() => !formFieldsEnabled}
                />
              </div>
            ))}
            <Stack direction={"column"} alignItems={"center"} spacing={2} className={classes.buttonArea}>
              {currentUser.isExternal && (
                <FormControlLabel
                  labelPlacement="start"
                  control={
                    <Checkbox checked={choiceConfirmed} onChange={({ target }) => setChoiceConfirmed(target.checked)} />
                  }
                  label={"Ich möchten diese Aufgabe als erledigt markieren"}
                />
              )}
              {currentUser.isExternal && (
                <ButtonLoading
                  variant={"contained"}
                  isLoading={isLoading}
                  className={classes.submitButton}
                  disabled={!choiceConfirmed}
                  type={"submit"}
                  fullWidth={false}
                >
                  Aufgabe erledigt
                </ButtonLoading>
              )}
              {!currentUser.isExternal &&
                paidTask.status === PAID_TASK_STATUS_IN_REVIEW &&
                paidTaskTypeConfig.hasEditor && (
                  <>
                    <Typography variant={"h3"}>Auswahl der Unterschrift</Typography>
                    <RadioGroup
                      row
                      name="sigantureType"
                      value={formValue(useFormProps.values, "sigantureType")}
                      onChange={useFormProps.handleChange}
                    >
                      <FormControlLabel
                        value={"internal"}
                        control={<Radio />}
                        label={paidTask.backofficeCase?.lawyer?.person?.fullname || "Interner Anwalt"}
                      />

                      <FormControlLabel
                        value={"external"}
                        control={<Radio />}
                        label={paidTask.assignee?.person?.fullname || "Externer Anwalt"}
                      />
                    </RadioGroup>
                    <ButtonLoading
                      variant={"contained"}
                      isLoading={isLoading}
                      className={classes.submitButton}
                      type={"submit"}
                      fullWidth={false}
                    >
                      Änderungen speichern
                    </ButtonLoading>
                  </>
                )}
            </Stack>
          </ContentContainer>
        </ValidatorForm>
        <LegalbirdIoModal
          handleClose={() => setErrorModalData("")}
          open={!!errorModalData}
          title={"Erledigung nicht möglich"}
          hideCancelButton
          submitButton={
            <Button onClick={() => setErrorModalData("")} variant={"contained"} color={"primary"}>
              Ok
            </Button>
          }
        >
          {errorModalData === "editorContentLength" &&
            "Der Text im Editor für das Schreiben ist leider mit weniger als 200 Zeichen zu kurz. Ist das Schreiben tatsächlich so kurz, oder haben Sie vergessen, den Text einzufügen?"}
          {errorModalData === "requiredFileMissing" &&
            "Leider haben Sie nicht alle geforderten Dokumente hochgeladen. Bitte prüfen Sie die hochgeladenen Dokumente."}
        </LegalbirdIoModal>
      </ContentBox>
    </Box>
  );
};

CasePaidTaskSubmission.propTypes = {
  paidTask: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
};

export default CasePaidTaskSubmission;
