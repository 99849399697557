import React, { useState } from "react";
import * as PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import { updateResource } from "../../store/generic/actions";
import { useDispatch } from "react-redux";

export default function ChangeMediaObjectVisibilityModal({
  mediaObject,
  updateMediaObjects,
  changeVisibilityOpen,
  setChangeVisibilityOpen,
  closeMenu,
}) {
  const dispatch = useDispatch();
  const [showInGallery, setShowInGallery] = useState(mediaObject.showInGallery);
  const [showInExternalView, setShowInExternalView] = useState(mediaObject.showInExternalView);

  const handleSave = () => {
    dispatch(
      updateResource(mediaObject.id, "media_objects", {
        showInGallery: showInGallery,
        showInExternalView: showInExternalView,
      })
    ).then(() => updateMediaObjects());
    setChangeVisibilityOpen(false);
    closeMenu();
  };

  return (
    <LegalbirdIoModal
      handleClose={() => {
        setChangeVisibilityOpen(false);
        closeMenu();
      }}
      open={changeVisibilityOpen}
      title={"Sichtbarkeit einstellen"}
      submitButton={
        <Button
          variant={"contained"}
          onClick={handleSave}
          disabled={showInExternalView === mediaObject.showInExternalView && showInGallery === mediaObject.showInGallery}
        >
          Speichern
        </Button>
      }
    >
      <Stack>
        Für wen soll das Dokument sichtbar sein?
        <FormControlLabel
          control={
            <Checkbox checked={showInExternalView} onChange={(event) => setShowInExternalView(event.target.checked)} />
          }
          label={"Für Extern sichtbar"}
        />
        <FormControlLabel
          control={<Checkbox checked={showInGallery} onChange={(event) => setShowInGallery(event.target.checked)} />}
          label={"Für Kunde sichtbar"}
        />
      </Stack>
    </LegalbirdIoModal>
  );
}

ChangeMediaObjectVisibilityModal.propTypes = {
  mediaObject: PropTypes.object.isRequired,
  updateMediaObjects: PropTypes.func.isRequired,
  changeVisibilityOpen: PropTypes.bool.isRequired,
  setChangeVisibilityOpen: PropTypes.func.isRequired,
  closeMenu: PropTypes.func,
};

ChangeMediaObjectVisibilityModal.defaultProps = {
  closeMenu: () => {},
};
