import React from "react";
import _ from "lodash";
import { getRoleToDisplay, sortByRole } from "../../../../services/backofficeUserService";
import LegalbirdAutoComplete from "../../../AutoComplete/LegalbirdAutoComplete";

const getOptions = (agents) => {
  const users = _.map(sortByRole(agents), (agent) => {
    return {
      label: agent.person.fullname,
      id: agent["@id"],
      groupBy: getRoleToDisplay(agent),
    };
  });

  return [{ label: "Alle Benutzer", id: "all" }, ...users];
};

const UserFilter = ({ userFilter, setUserFilter, agents }) => {
  if (_.isEmpty(agents)) {
    return null;
  }

  const options = getOptions(agents);

  const value = _.find(options, (option) => option.id === userFilter);

  return (
    <LegalbirdAutoComplete
      label="Nutzer"
      value={value}
      options={options}
      groupBy={(option) => option.groupBy}
      setValue={(event, value) => {
        setUserFilter(value.id);
      }}
    />
  );
};

export default UserFilter;
