import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import LegalBirdPageLoading from "../../ContentLoader/LegalBirdPageLoading";
import CaseRelatedActions from "../../Case/CaseRelatedActions/CaseRelatedActions";
import PageContentContainer from "../../Container/PageContentContainer";
import { ProductHeadline } from "../../PageHeadline/PageHeadline";
import { useDispatch, useSelector } from "react-redux";
import { getGeneric } from "../../../store/generic/reducer";
import { fetchCollection } from "../../../store/generic/actions";
import CaseProvider, { useCase } from "../../../provider/CaseProvider";
import CaseReferenceFile from "../../Case/CaseReferenceFile/CaseReferenceFile";
import _ from "lodash";
import { Box } from "@mui/material";

const CaseReferenceFilePage = () => {
  const { productId, productUrlPart } = useParams();
  return (
    <CaseProvider caseUrlPart={productUrlPart} caseId={productId}>
      <CaseReferenceFilePageInner />
    </CaseProvider>
  );
};

const CaseReferenceFilePageInner = () => {
  const { courtAppointmentId } = useParams();
  const { product, isLoading, refreshCase } = useCase();
  const dispatch = useDispatch();
  const referenceFiles = useSelector((state) =>
    getGeneric(state, _.camelCase("reference_files?courtAppointmentId=" + courtAppointmentId))
  );

  useEffect(() => {
    fetchReferenceFiles();
  }, [dispatch, product]);

  const fetchReferenceFiles = async () => {
    await dispatch(fetchCollection("reference_files?courtAppointmentId=" + courtAppointmentId));
  };

  if (isLoading || !referenceFiles) {
    return <LegalBirdPageLoading />;
  }

  return (
    <>
      <CaseRelatedActions product={product} refreshPage={refreshCase} />
      <PageContentContainer size={"small"}>
        <ProductHeadline title="Terminsvertreter-Handakte zu" product={product} />
        {referenceFiles["hydra:member"].length < 1 ? (
          <Box sx={{ textAlign: "center" }}>Es gibt keine Handakte für diesen Termin</Box>
        ) : (
          <CaseReferenceFile
            product={product}
            referenceFile={_.head(referenceFiles["hydra:member"])}
            refreshReferenceFile={fetchReferenceFiles}
          />
        )}
      </PageContentContainer>
    </>
  );
};

export default CaseReferenceFilePage;
