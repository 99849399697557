import React, { useEffect, useState } from "react";
import { Chip, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import { useSelector } from "react-redux";
import { getBackofficeUser } from "../../store/backofficeUser/reducer";
import _ from "lodash";
import { translate } from "../../services/Translations/translatorService";
import ApiClient from "../../services/ApiClient";
import useForm from "../../hooks/useForm";
import ValidatorForm from "../Validator/ValidatorForm";
import ValidatorTextField from "../Validator/ValidatorTextField";
import { numberFieldDefault, requiredFieldDefault } from "../../services/validationRules";
import { formValue, fromFormBlockedDays, toFormBlockedDays } from "../../services/formServiceFunctions";
import SubmitButton from "../Button/SubmitButton";
import LegalbirdAutoComplete from "../AutoComplete/LegalbirdAutoComplete";
import { sortByRole } from "../../services/backofficeUserService";
import HolidaySelection from "./HolidaySelection";
import PageContentContainer from "../Container/PageContentContainer";
import ContentBox from "../ContentBox/ContentBox";
import { useBackofficeUser } from "../../provider/BackofficeUserProvider";
import UserPresenceConfiguratorFormElements from "./UserPresenceConfiguratorFormElements";

const HumanResourceManagement = () => {
  const currentUser = useSelector((state) => getBackofficeUser(state));
  const { internals } = useBackofficeUser();
  const [userId, setUserId] = useState(currentUser.id);
  const [humanResource, setHumanResource] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    availableMinutesPerDay: "0",
    trainedProducts: [],
    blockedDays: [],
  };

  const onSubmit = async ({ values }) => {
    setIsLoading(true);
    if (humanResource) {
      const convertedBlockedDays = fromFormBlockedDays(values.blockedDays);
      await ApiClient.put(humanResource["@id"], {
        body: JSON.stringify({
          trainedProducts: values.trainedProducts,
          blockedDays: convertedBlockedDays,
          availableMinutesPerDay: _.toNumber(values.availableMinutesPerDay),
          workingHours: values.workingHours,
        }),
      });
      await fetchHumanResource(userId);
    }
    setIsLoading(false);
  };

  const { values, handleChange, handleSubmit, updateAllValues, registerValidators, touchedValues, errors, handleBlur } =
    useForm({
      initialValues,
      onSubmit,
    });

  const fetchHumanResource = async (userId) => {
    setIsLoading(true);
    const fetchedHumanResource = (await ApiClient.get("/human_resources?backofficeUser=" + userId))["hydra:member"][0];
    setHumanResource(fetchedHumanResource);
    if (fetchedHumanResource) {
      const convertedBlockedDays = toFormBlockedDays(fetchedHumanResource.blockedDays);
      updateAllValues({
        trainedProducts: fetchedHumanResource.trainedProducts,
        blockedDays: convertedBlockedDays,
        availableMinutesPerDay: "" + fetchedHumanResource.availableMinutesPerDay,
        workingHours: fetchedHumanResource.workingHours,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchHumanResource(userId).then();
  }, []);

  const handleUserChange = async (event, value) => {
    setUserId(value.id);
    await fetchHumanResource(value.id);
  };

  if (internals.length < 1) {
    return null;
  }

  const options = getOptions(internals);
  const value = _.find(options, (option) => option.id === userId);

  return (
    <PageContentContainer size="tiny">
      <ContentBox headline={"HR Planung"}>
        <ValidatorForm onSubmit={handleSubmit}>
          <Grid container justifyContent={"center"} spacing={2}>
            <Grid item xs={12}>
              <LegalbirdAutoComplete
                value={value}
                options={options}
                setValue={handleUserChange}
                label={"Mitarbeiter"}
              />
            </Grid>
            {_.includes(currentUser.roles, "ROLE_ADMIN") && humanResource && (
              <>
                <Grid item xs={12}>
                  <ValidatorTextField
                    name={"availableMinutesPerDay"}
                    validators={[...numberFieldDefault, ...requiredFieldDefault]}
                    registerValidators={registerValidators}
                    label={"Verfügbare Minuten pro Tag"}
                    value={formValue(values, "availableMinutesPerDay")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors["availableMinutesPerDay"]}
                    helperText={errors["availableMinutesPerDay"]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="trained-products-select">Trainierte Produkte</InputLabel>
                    <Select
                      label="Trainierte Produkte"
                      labelId="trained-products-select"
                      multiple
                      name={"trainedProducts"}
                      value={formValue(values, "trainedProducts")}
                      input={<OutlinedInput label="Trainierte Produkte" />}
                      onChange={handleChange}
                      renderValue={(selected) => (
                        <Grid container spacing={1}>
                          {selected.map((value) => (
                            <Grid item key={value}>
                              <Chip key={value} label={_.capitalize(translate("general.productCategory." + value))} />
                            </Grid>
                          ))}
                        </Grid>
                      )}
                    >
                      <MenuItem value={"familyLaw"}>
                        {_.capitalize(translate("general.productCategory.familyLaw"))}
                      </MenuItem>
                      <MenuItem value={"labourLaw"}>
                        {_.capitalize(translate("general.productCategory.labourLaw"))}
                      </MenuItem>
                      <MenuItem value={"contractLaw"}>
                        {_.capitalize(translate("general.productCategory.contractLaw"))}
                      </MenuItem>
                      <MenuItem value={"trafficLaw"}>
                        {_.capitalize(translate("general.productCategory.trafficLaw"))}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
            {humanResource && (
              <>
                <UserPresenceConfiguratorFormElements
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <Grid item xs={12} sx={{ paddingTop: "1rem" }}>
                  {/*second disabled part (_.find) so that the initial user cant save his humanresource entity if hes not internal*/}
                  <SubmitButton
                    error={_.keys(errors).length > 0}
                    isLoading={isLoading}
                    disabled={
                      _.keys(touchedValues).length === 0 ||
                      !_.find(internals, (internalUser) => internalUser.id === userId)
                    }
                    variant={"contained"}
                    type={"submit"}
                  >
                    Speichern
                  </SubmitButton>
                </Grid>
              </>
            )}
            {!humanResource && !!_.find(internals, (internalUser) => internalUser.id === userId) && (
              <Grid item xs={12}>
                Für diesen Benutzer können keine Ressourcen festgelegt werden.
              </Grid>
            )}
          </Grid>
        </ValidatorForm>
      </ContentBox>
      {_.includes(currentUser.roles, "ROLE_ADMIN") && <HolidaySelection />}
    </PageContentContainer>
  );
};

const getOptions = (users) => {
  return _.map(sortByRole(users), (user) => {
    return {
      label: user.person.fullname,
      id: user.id,
    };
  });
};

export default HumanResourceManagement;
