import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGeneric } from "../../store/generic/reducer";
import { createResource, deleteResource, fetchCollection, updateResource } from "../../store/generic/actions";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import _ from "lodash";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import makeStyles from "@mui/styles/makeStyles";
import paymentRunStyle from "./paymentRunStyle";
import ButtonLoading from "../Button/ButtonLoading";
import { useSnackbar } from "notistack";
import ApiClient from "../../services/ApiClient";
import Snackbar from "../Snackbar/Snackbar";
import PageContentContainer from "../Container/PageContentContainer";
import PageHeadline from "../PageHeadline/PageHeadline";
import { DropzoneArea } from "mui-file-dropzone";
import ContentBox from "../ContentBox/ContentBox";
import { Box } from "@mui/material";

const useStyles = makeStyles(paymentRunStyle);

const PaymentRunList = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const paymentRunList = useSelector((state) => getGeneric(state, "paymentRunList"));
  const { enqueueSnackbar } = useSnackbar();
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAdvoAssist, setIsLoadingAdvoAssist] = useState(false);
  const [isLoadingPaidTasks, setIsLoadingPaidTasks] = useState(false);

  useEffect(() => {
    if (!paymentRunList) {
      dispatch(fetchCollection("payment_runs", "paymentRunList"));
    }
  }, [paymentRunList, dispatch]);

  const saveFile = async () => {
    if (!files[0]) {
      return;
    }

    setIsLoading(true);

    let formData = new FormData();
    formData.append("file", files[0]);
    let headers = new Headers();
    headers.set("Content-Type", "multipart/form-data");

    const res = await ApiClient.post("service/csv_payment_run", {
      headers: headers,
      body: formData,
    });

    if (_.isError(res)) {
      enqueueSnackbar(res.errors.csv, {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    } else {
      enqueueSnackbar("", {
        content: () => (
          <div>
            <Snackbar
              message={"Zahllauf wurde erfolgreich erstellt"}
              buttonText={"zum Zahllauf"}
              buttonLink={"https://drive.google.com/drive/folders/1TtVXM83YB4wAf6Kz_86iGvFP8UzJkTT8"}
            />
          </div>
        ),
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }

    dispatch(fetchCollection("payment_runs", "paymentRunList")).then(() => setIsLoading(false));
  };

  const fileChange = (files) => {
    setFiles(files);
  };

  const addPaymentRun = async () => {
    setIsLoadingAdvoAssist(true);
    const res = await dispatch(createResource("payment_runs", { type: "AdvoAssist" }));
    if (_.isError(res)) {
      enqueueSnackbar("Fehler beim Erstellen des Zahllaufs", {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    } else {
      enqueueSnackbar("", {
        content: () => (
          <div>
            <Snackbar
              message={"Zahllauf wurde erfolgreich erstellt"}
              buttonText={"zum Zahllauf"}
              buttonLink={"https://drive.google.com/drive/folders/1TtVXM83YB4wAf6Kz_86iGvFP8UzJkTT8"}
            />
          </div>
        ),
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
    dispatch(fetchCollection("payment_runs", "paymentRunList")).then(() => setIsLoadingAdvoAssist(false));
  };

  const createPaidTasksInvoices = async () => {
    setIsLoadingPaidTasks(true);
    ApiClient.post("services/controlling/process_paid_task_controlling").then(
      (response) => {
        if (_.isError(response)) {
          enqueueSnackbar("", {
            content: () => (
              <div>
                <Snackbar message={response.errors.message} isNonInteractive={true} />
              </div>
            ),
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
          setIsLoadingPaidTasks(false);
        } else {
          enqueueSnackbar("", {
            content: () => (
              <div>
                <Snackbar message={"Verarbeitung wurde erfolgreich gestartet"} isNonInteractive={true} />
              </div>
            ),
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
          setIsLoadingPaidTasks(false);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const buttonActions = async (paymentRun) => {
    if (paymentRun.status === "success") {
      await dispatch(updateResource(paymentRun.id, "payment_runs", { paid: moment() }));
    }
    if (paymentRun.status === "initialized" || !paymentRun.status) {
      await dispatch(deleteResource(paymentRun.id, "payment_runs"));
    }

    dispatch(fetchCollection("payment_runs", "paymentRunList"));
  };

  return (
    <PageContentContainer size={"small"}>
      <PageHeadline main="Zahlläufe" />
      <ContentBox headline="Aktuelle Zahlläufe">
        {!!paymentRunList && !isLoading ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Typ</TableCell>
                <TableCell>Erstellt</TableCell>
                <TableCell>Status</TableCell>
                <TableCell># Zahlungen</TableCell>
                <TableCell>Ausgezahlt</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(_.slice(paymentRunList["hydra:member"], 0, 5), (paymentRun) => {
                return (
                  <TableRow key={paymentRun.id}>
                    <TableCell>{paymentRun.id}</TableCell>
                    <TableCell>{paymentRun.type}</TableCell>
                    <TableCell>{moment(paymentRun.created).format("DD.MM.YYYY HH:mm:ss")}</TableCell>
                    <TableCell>{paymentRun.status}</TableCell>
                    <TableCell>{paymentRun.numberOfPayments}</TableCell>
                    <TableCell>
                      {paymentRun.paid ? (
                        moment(paymentRun.paid).format("DD.MM.YYYY HH:mm:ss")
                      ) : (
                        <Button onClick={() => buttonActions(paymentRun)}>
                          {paymentRun.status === "success" ? "Als bezahlt markieren" : "Zahllauf löschen"}
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <Grid container justifyContent={"center"} alignContent={"center"} style={{ minHeight: 200 }}>
            <CircularProgress />
          </Grid>
        )}
      </ContentBox>

      <ContentBox headline="AdvoAssist">
        <Box sx={paymentRunStyle.buttonContainer}>
          <ButtonLoading onClick={addPaymentRun} variant={"contained"} isLoading={isLoadingAdvoAssist}>
            Neuen AdvoAssist Zahllauf starten
          </ButtonLoading>
        </Box>
      </ContentBox>

      <ContentBox headline="Abgenommene Aufgaben abrechnen">
        <Box sx={paymentRunStyle.buttonContainer}>
          <ButtonLoading onClick={createPaidTasksInvoices} variant={"contained"} isLoading={isLoadingPaidTasks}>
            Abgenommene Ausschreibungen abrechnen
          </ButtonLoading>
        </Box>
      </ContentBox>

      <ContentBox headline="CSV Zahllauf erstellen">
        <Box sx={paymentRunStyle.buttonContainer}>
          <Grid container>
            <Grid item xs={12}>
              <DropzoneArea
                onChange={fileChange}
                acceptedFiles={["text/csv"]}
                filesLimit={1}
                dropzoneText={"CSV Datei auswählen"}
                showFileNames
                showAlerts={false}
                maxFileSize={300000000}
                useChipsForPreview
              />
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: "1rem" }}>
              <ButtonLoading onClick={saveFile} variant={"contained"} isLoading={isLoading}>
                Zahllauf erstellen
              </ButtonLoading>
            </Grid>
          </Grid>
        </Box>
      </ContentBox>
    </PageContentContainer>
  );
};

export default PaymentRunList;
