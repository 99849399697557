import React, { useEffect, useMemo, useState } from "react";
import * as PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import scannedMediaObjectRowStyle from "./scannedMediaObjectRowStyle";
import { Checkbox, IconButton, MenuItem, TableCell, TableRow, TextField, Tooltip } from "@mui/material";
import moment from "moment";
import LegalbirdAutoComplete from "../AutoComplete/LegalbirdAutoComplete";
import _ from "lodash";
import { translate } from "../../services/Translations/translatorService";
import { ALGOLIA_INDEX } from "../../config/_entrypoint";
import ApiClient from "../../services/ApiClient";
import { Delete, FeedbackOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { getBackofficeUser } from "../../store/backofficeUser/reducer";
import { getCaseLink } from "../../services/Product/ProductService";
import { Link } from "react-router-dom";
import ScannedFile from "./ScannedFile";
import { useFileIdentifier } from "../../provider/FileIdentifierProvider";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import ButtonLoading from "../Button/ButtonLoading";
import SearchBar from "../Algolia/SearchBar";
import CaseHit from "../Case/CaseEntityPicker/Hit/CaseHit";
import LabeledSelect from "../Select/LabeledSelect";
import { userHasRole } from "../../services/backofficeUserService";
import { Roles } from "../../types/BackofficeUser";

const useStyles = makeStyles(scannedMediaObjectRowStyle);

export default function ScannedMediaObjectRow({ mediaObject, refreshList }) {
  const classes = useStyles();

  const { getIdentifiersByProductClass, isLoading: isFileIdentifiersLoading } = useFileIdentifier();
  const currentUser = useSelector((state) => getBackofficeUser(state));
  const defaultIdentifier = { label: "Ohne Kenner", value: "XXX" };
  const [identifier, setIdentifier] = useState(defaultIdentifier);
  const [identifierOptions, setIdentifierOptions] = useState([defaultIdentifier]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeField, setActiveField] = useState("");
  const [fileDescription, setFileDescription] = useState(mediaObject.description);
  const { productId, productType: productClassName, caseLabel } = mediaObject.scanMetaData;

  useEffect(() => {
    if (productId && !isFileIdentifiersLoading) {
      let identifierOptions = _.map(getIdentifiersByProductClass(productClassName), (identifier, key) => {
        return {
          label: key + " - " + translate(`identifier.${productClassName}.${key}.label`),
          value: key,
        };
      });
      identifierOptions = _.sortBy(identifierOptions, (option) => option.label);
      setIdentifierOptions([defaultIdentifier, ...identifierOptions]);
      //this field unfortunately has 2 sources of truth
      const identifierToBeSet = mediaObject.identifier || mediaObject.scanMetaData?.labelPredicted;
      if (!!identifierToBeSet) {
        const identifierAutoCompleteValue = {
          label: identifierToBeSet + " - " + translate(`identifier.${productClassName}.${identifierToBeSet}.label`),
          value: identifierToBeSet,
        };
        setIdentifier(identifierAutoCompleteValue);
      }
    }
  }, [productClassName, productId, isFileIdentifiersLoading]);

  const onCheckboxChange = async (event) => {
    setIsLoading(true);
    let scanMetaDataCopy = { ...mediaObject.scanMetaData };
    scanMetaDataCopy.checkedDate = event.target.checked ? moment().format() : null;
    scanMetaDataCopy.checkedBy = event.target.checked ? currentUser["@id"] : null;
    await ApiClient.put(mediaObject["@id"], {
      body: JSON.stringify({
        scanMetaData: scanMetaDataCopy,
      }),
    });
    refreshList();
    setIsLoading(false);
  };

  const onHitClick = async ({ product, productId, reference, label }, event) => {
    event.preventDefault();
    setIsLoading(true);
    let scanMetaDataCopy = { ...mediaObject.scanMetaData };
    scanMetaDataCopy.productType = product;
    scanMetaDataCopy.productId = productId;
    scanMetaDataCopy.reference = reference;
    scanMetaDataCopy.caseLabel = label;
    await ApiClient.put(mediaObject["@id"], {
      body: JSON.stringify({
        scanMetaData: scanMetaDataCopy,
      }),
    });
    refreshList();
    setIsLoading(false);
  };

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const resetActiveField = () => {
    setActiveField("");
  };

  const handleDeleteMediaobject = async () => {
    setIsLoading(true);
    await ApiClient.put(mediaObject["@id"], {
      body: JSON.stringify({
        deleted: true,
      }),
    });
    refreshList();
    setIsLoading(false);
    setDeleteModalOpen(false);
  };

  const handleAutoCompleteValueChange = async (e, value) => {
    setIsLoading(true);
    setIdentifier(value);
    await ApiClient.put(mediaObject["@id"], {
      body: JSON.stringify({
        identifier: value.value === defaultIdentifier.value ? null : value.value,
      }),
    });

    await refreshList();
    resetActiveField();
    setIsLoading(false);
  };

  const handleSaveDescription = async () => {
    setIsLoading(true);
    await ApiClient.put(mediaObject["@id"], {
      body: JSON.stringify({
        description: fileDescription,
      }),
    });
    refreshList();
    resetActiveField();
    setIsLoading(false);
  };

  const handleElectronicFileFolderPathChange = ({ target }) => {
    setIsLoading(true);
    ApiClient.put(mediaObject["@id"], {
      body: JSON.stringify({
        electronicFileFolderPath: target.value,
      }),
    })
      .then(() => {
        refreshList();
      })
      .finally(() => {
        resetActiveField();
        setIsLoading(false);
      });
  };

  const autoCompleteValue = _.find(
    identifierOptions,
    (identifierOption) => identifierOption.value === identifier.value
  );

  const isIntOrKon = _.includes(["KON", "INT"], identifier.value);

  const isCheckable = useMemo(() => {
    return (
      !!productId &&
      !isLoading &&
      !(isIntOrKon && _.isEmpty(mediaObject.description)) &&
      !(isIntOrKon && ["blank", null].includes(mediaObject.electronicFileFolderPath)) &&
      (!!mediaObject.identifier || !!mediaObject.scanMetaData?.labelPredicted)
    );
  }, [productId, isLoading, mediaObject, identifier]);

  if (isFileIdentifiersLoading) {
    return null;
  }

  return (
    <>
      <TableRow sx={isIntOrKon ? scannedMediaObjectRowStyle.borderLess : {}}>
        <TableCell>
          <div>{moment(mediaObject.created).format("DD.MM.YYYY")}</div>
          <div>{moment(mediaObject.created).format("HH:mm")} Uhr</div>
        </TableCell>
        <TableCell>
          <ScannedFile mediaObject={mediaObject} identifier={identifier.value} />
        </TableCell>
        <TableCell>
          {mediaObject.fieldname === "bea" && !userHasRole(currentUser, Roles.admin) ? (
            "beA"
          ) : (
            <>
              <IconButton onClick={handleOpenDeleteModal} disabled={isLoading}>
                <Delete />
              </IconButton>
              <LegalbirdIoModal
                handleClose={() => setDeleteModalOpen(false)}
                open={deleteModalOpen}
                title={"Dokument löschen"}
                submitButton={
                  <ButtonLoading onClick={handleDeleteMediaobject} isLoading={isLoading} variant={"contained"}>
                    Dokument löschen
                  </ButtonLoading>
                }
              >
                Durch den Klick auf "Dokument löschen" wird das Dokument dauerhaft gelöscht. Grund hierfür können
                beispielsweise falsch eingescannte oder nicht lesbare Dokumente sein.
              </LegalbirdIoModal>
            </>
          )}
        </TableCell>
        <TableCell>
          {!!productId ? (
            <Link className={classes.link} to={getCaseLink({ productClassName, id: productId })} target={"_blank"}>
              {!!caseLabel ? caseLabel : "Zum Fall"}
            </Link>
          ) : (
            "Unzugeordnet"
          )}
        </TableCell>
        <TableCell>
          {mediaObject.scanMetaData?.isFamilyNameInText === false && (
            <Tooltip title="Automatische Fallzuordnung bitte genau prüfen, da Name des Mandanten im Dokument nicht erkannt wurde">
              <FeedbackOutlined sx={{ color: "#db1919" }} />
            </Tooltip>
          )}
        </TableCell>
        <TableCell>
          <SearchBar
            searchConfig={{
              indexName: ALGOLIA_INDEX,
            }}
            resultListConfig={{
              itemSize: 330,
              width: "100%",
            }}
            HitComponent={CaseHit}
            onHitClick={onHitClick}
            disabled={isLoading || !["search", ""].includes(activeField)}
          />
        </TableCell>
        <TableCell>
          <LegalbirdAutoComplete
            disabled={!productId || isLoading || !["identifier", ""].includes(activeField)}
            getOptionDisabled={(option) => option.value === defaultIdentifier.value}
            setValue={handleAutoCompleteValueChange}
            value={autoCompleteValue}
            options={identifierOptions}
            label={"Kenner"}
            onFocus={() => setActiveField("identifier")}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            disabled={!isCheckable}
            onChange={onCheckboxChange}
            checked={!!mediaObject.scanMetaData?.checkedDate}
          />
        </TableCell>
      </TableRow>
      {isIntOrKon && (
        <TableRow sx={scannedMediaObjectRowStyle.additionalRow}>
          <TableCell />
          <TableCell colSpan={5}>
            <TextField
              margin="none"
              label={"Beschreibung"}
              value={fileDescription}
              onChange={({ target }) => setFileDescription(target.value)}
              onBlur={handleSaveDescription}
              disabled={isLoading || !["description", ""].includes(activeField)}
              onFocus={() => setActiveField("description")}
            />
          </TableCell>
          <TableCell>
            <LabeledSelect
              label={"Ablage in Ordner"}
              value={mediaObject.electronicFileFolderPath || "blank"}
              onChange={handleElectronicFileFolderPathChange}
              disabled={isLoading || !["folder", ""].includes(activeField)}
              onFocus={() => setActiveField("folder")}
              onBlur={resetActiveField}
            >
              <MenuItem value={"blank"} disabled>
                {" "}
              </MenuItem>
              <MenuItem value={"Mandant"}>Mandant</MenuItem>
              <MenuItem value={"Gerichtliches Verfahren"}>Gerichtliches Verfahren</MenuItem>
              <MenuItem value={"Außergerichtliches Verfahren"}>Außergerichtliches Verfahren</MenuItem>
              <MenuItem value={"RSV"}>RSV</MenuItem>
              <MenuItem value={"ToDo"}>{"ToDo"}</MenuItem>
              <MenuItem value={"Rechnungen"}>Rechnungen</MenuItem>
              <MenuItem value={"Sonstiges"}>Sonstiges</MenuItem>
            </LabeledSelect>
          </TableCell>
          <TableCell />
        </TableRow>
      )}
    </>
  );
}

ScannedMediaObjectRow.propTypes = {
  mediaObject: PropTypes.object.isRequired,
  refreshList: PropTypes.func.isRequired,
};

ScannedMediaObjectRow.defaultProps = {};
