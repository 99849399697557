import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import useStyles from "./emailsStyle";
import _ from "lodash";
import Email from "./Email";
import useEmailFetching from "../../hooks/useEmailFetching";
import ButtonLoading from "../Button/ButtonLoading";
import ContentBox from "../ContentBox/ContentBox";

const Emails = ({ product, isExternalUser, refreshCounter }) => {
  let filters = {
    refreshCounter: refreshCounter,
    backofficeCase: product.backofficeCase["@id"],
  };
  if (isExternalUser) {
    filters.hiddenExternally = false;
  }
  const dataSource = new URLSearchParams(filters);
  const { emails, isLoading } = useEmailFetching(dataSource.toString());
  const [emailCollection, setEmailCollection] = useState(emails);
  const [emailDisplayCount, setEmailDisplayCount] = useState(3);
  const classes = useStyles();

  useEffect(() => setEmailCollection(emails), [emails]);

  const updateEmailListEntry = (emailMessageResult) => {
    if (emailMessageResult.id) {
      const updatedEmailMessages = _.map(emailCollection, (email) =>
        emailMessageResult.id === email.id ? emailMessageResult : email
      );
      setEmailCollection(updatedEmailMessages);
    }
  };

  if (isLoading) {
    return null;
  }

  const displayedEmails = _.slice(emailCollection, 0, emailDisplayCount);
  return (
    <>
      <div className={classes.scrollToAnchor} id={"mails"} />
      <ContentBox headline="E-Mails">
        {displayedEmails.length > 0 ? (
          _.map(displayedEmails, (emailMessage, index) => (
            <Email key={index} emailMessage={emailMessage} updateEmailListEntry={updateEmailListEntry} product={product} />
          ))
        ) : (
          <div className={classes.noMails}>Es sind noch keine Mails vorhanden</div>
        )}
        {emails.length > emailDisplayCount && (
          <ButtonLoading onClick={() => setEmailDisplayCount(emailDisplayCount + 10)} className={classes.button}>
            Mehr anzeigen
          </ButtonLoading>
        )}
        {displayedEmails.length > 3 && (
          <ButtonLoading onClick={() => setEmailDisplayCount(3)} className={classes.button}>
            Weniger anzeigen
          </ButtonLoading>
        )}
      </ContentBox>
    </>
  );
};

Emails.propTypes = {
  isExternalUser: PropTypes.bool,
  product: PropTypes.object.isRequired,
};

Emails.defaultProps = {
  isExternalUser: false,
};

export default Emails;
