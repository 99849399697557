import React from "react";
import {
  STAGE_DATA_COMPLETED,
  STAGE_DATA_COMPLETED_LAWFIRM,
  STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT,
  STAGE_WAITING_FOR_FEEDBACK,
} from "../../Stages/StagesCase";
import PreviewButton from "../../../components/Preview/PreviewButton";

export const contractCaseDetailsSpecialActions = (product) => {
  let specialActions = [];
  if (
    (product.stage === STAGE_DATA_COMPLETED || product.stage === STAGE_WAITING_FOR_FEEDBACK) &&
    product.caseCategoryAssessment === "reject" &&
    product.assessmentRejectionText
  ) {
    specialActions.push(
      <PreviewButton
        key={"previewCaseRejectedEmail"}
        product={product}
        documentType={"email"}
        documentIdentifier={"contractLaw/case-rejected"}
        buttonText={"Vorschau Für E-Mail"}
        modalHeadline={"Vorschau E-Mail"}
      />
    );
  }

  if (product.stage === STAGE_DATA_COMPLETED_LAWFIRM) {
    specialActions.push(
      <PreviewButton
        key={"preview-claimLetter"}
        product={product}
        documentType={"pdf"}
        documentIdentifier={"claimLetter"}
        buttonText={"Vorschau für Schreiben"}
        modalHeadline={"Vorschau außergerichtliches Schreiben"}
      />
    );
  }

  if (product.stage === STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT) {
    specialActions.push(
      <PreviewButton
        key={"preview-feedbackEmail"}
        product={product}
        documentType={"email"}
        documentIdentifier={"contractLaw/feedback"}
        buttonText={"Vorschau für Feedback"}
        modalHeadline={"Vorschau Feedback Email"}
      />
    );
  }

  if (specialActions.length === 0) {
    return null;
  }

  return specialActions;
};
