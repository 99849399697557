import React, { useContext, useEffect, useState } from "react";
import { BackofficeCase } from "../types/BackofficeCase";
import ApiClient from "../services/ApiClient";

interface AccessibleCasesContextInterface {
  isLoading: boolean;
  accessibleCases: BackofficeCase[] | null;
}

const AccessibleCasesContext = React.createContext<AccessibleCasesContextInterface | null>(null);

export default function AccessibleCasesProvider({ children }: { children?: React.ReactNode }) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [accessibleCases, setAccessibleCases] = useState<BackofficeCase[] | null>(null);

  useEffect(() => {
    ApiClient.get("/services/external/accessibleCases").then((apiResult) => {
      setAccessibleCases(apiResult);
      setIsLoading(false);
    });
  }, []);

  const data: AccessibleCasesContextInterface = {
    isLoading,
    accessibleCases
  }

  return <AccessibleCasesContext.Provider value={data}>{children}</AccessibleCasesContext.Provider>
}

export const useAccessibleCases = () => {
  const accessibleCasesContext = useContext(AccessibleCasesContext);
  if (!accessibleCasesContext) {
    throw new Error("useAccessibleCases can only be used inside AccessibleCasesProvider");
  }
  return accessibleCasesContext;
};