import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import useStyles from "./uploadFileStyles";
import { Checkbox, MenuItem } from "@mui/material";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import ApiClient from "../../../services/ApiClient";
import ButtonLoading from "../../Button/ButtonLoading";
import { fetchMediaObjects } from "../../../hooks/useMediaObjects";
import { getIdentifiers } from "../../../services/identifierService";
import moment from "moment";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import { useDispatch } from "react-redux";
import useForm from "../../../hooks/useForm";
import { formValue } from "../../../services/formServiceFunctions";
import { requiredFieldDefault } from "../../../services/validationRules";
import ValidatorTextField from "../../Validator/ValidatorTextField";
import ValidatorSelect from "../../Validator/ValidatorSelect";
import LegalbirdAutoComplete from "../../AutoComplete/LegalbirdAutoComplete";
import _ from "lodash";
import { translate } from "../../../services/Translations/translatorService";
import { useSnackbar } from "notistack";
import Snackbar from "../../Snackbar/Snackbar";

const MediaObjectFormModal = ({
  open,
  closeDialog,
  product,
  fileData,
  submitDisabled,
  children = null,
  requestUri = "media_objects",
  contentType = "multipart/form-data",
  errorMessage = "Datei konnte nicht hinzugefügt werden",
}) => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [identifierOptions, setIdentifierOptions] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!open || !_.isEmpty(identifierOptions)) {
      return;
    }
    getIdentifiers(product.productClassName).then((result) => {
      let identifierOptions = _.map(result, (identifier, key) => {
        return {
          label: key + " - " + translate(`identifier.${product.productClassName}.${key}.label`),
          value: key,
        };
      });

      identifierOptions = _.sortBy(identifierOptions, (option) => option.label);
      setIdentifierOptions([{ label: "Ohne Kenner", value: "no_value" }, ...identifierOptions]);
    });
  }, [open, identifierOptions]);

  const specialHandlingIdentifiers = ["KON", "INT", "no_value"];

  const getRequestData = () => {
    let payload = {};

    _.forEach(fileData, (value, key) => {
      payload[key] = value;
    });

    payload["product"] = product.productClassName;
    payload["productId"] = product.id;

    if (formValue(values, "identifier.value") !== "no_value") {
      payload["identifier"] = formValue(values, "identifier.value");
    }

    if (showVisibilityCheckboxes) {
      payload["showInGallery"] = formValue(values, "showInGallery") ? "true" : "false";
      payload["showInExternalView"] = formValue(values, "showInExternalView") ? "true" : "false";
    }

    if (showDescriptionTextField) {
      payload["description"] = formValue(values, "description");
    }

    if (showElectronicFileFolderSelect) {
      payload["electronicFileFolderPath"] = formValue(values, "electronicFileFolderPath");
    }

    if (contentType === "multipart/form-data") {
      let formData = new FormData();
      _.forEach(payload, (value, key) => {
        formData.append(key, value);
      });
      return formData;
    }
    return JSON.stringify(payload);
  };

  const onSubmit = async () => {
    setIsLoading(true);
    let headers = new Headers();
    headers.set("Content-Type", contentType);
    const result = await ApiClient.post(requestUri, {
      headers: headers,
      body: getRequestData(),
    });

    if (_.isError(result)) {
      enqueueSnackbar("", {
        content: () => (
          <div>
            <Snackbar message={errorMessage} isNonInteractive />
          </div>
        ),
        persist: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }

    fetchMediaObjects(product, dispatch);
    setIsLoading(false);
    resetDialog();
  };

  const initialValues = {
    description: "Dokument vom " + moment().format("DD.MM.YYYY"),
    showInGallery: true,
    showInExternalView: true,
    identifier: { label: "Ohne Kenner", value: "no_value" },
    electronicFileFolderPath: "blank",
  };

  const { values, errors, handleChange, handleSubmit, registerValidators, handleBlur, clearForm } = useForm({
    initialValues,
    onSubmit,
    clearFormAfterSubmit: true,
  });

  if (identifierOptions.length < 1) {
    return null;
  }

  const resetDialog = () => {
    clearForm();
    closeDialog();
  };

  const handleAutoCompleteValueChange = (e, value) => {
    handleChange({
      target: {
        name: "identifier",
        value: value,
      },
    });
  };

  const autoCompleteValue = _.find(
    identifierOptions,
    (identifier) => identifier.value === formValue(values, "identifier.value", "no_value")
  );

  const showDescriptionTextField = _.includes(specialHandlingIdentifiers, autoCompleteValue.value);
  const showVisibilityCheckboxes = formValue(values, "identifier.value") === "no_value";
  const showElectronicFileFolderSelect = _.includes(specialHandlingIdentifiers, autoCompleteValue.value);

  return (
    <LegalbirdIoModal
      handleClose={resetDialog}
      open={open}
      disableBackdropClick
      title={"Datei Hochladen"}
      submitButton={
        <ButtonLoading
          variant={"contained"}
          onClick={handleSubmit}
          disabled={
            submitDisabled ||
            (showElectronicFileFolderSelect && formValue(values, "electronicFileFolderPath") === "blank")
          }
          isLoading={isLoading}
        >
          Datei hinzufügen
        </ButtonLoading>
      }
    >
      {children}
      <Grid container spacing={2}>
        {showVisibilityCheckboxes && (
          <>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.checkbox}
                    checked={formValue(values, "showInGallery")}
                    name={"showInGallery"}
                    onChange={handleChange}
                  />
                }
                label={"Für Kunden sichtbar"}
              />
            </Grid>
            <Grid item xs={8}>
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.checkbox}
                    checked={formValue(values, "showInExternalView")}
                    name={"showInExternalView"}
                    onChange={handleChange}
                  />
                }
                label={"Für Extern sichtbar"}
              />
            </Grid>
          </>
        )}
        <Grid item xs={5}>
          <LegalbirdAutoComplete
            setValue={handleAutoCompleteValueChange}
            value={autoCompleteValue}
            options={identifierOptions}
            label={"Kenner"}
          />
        </Grid>
        {showDescriptionTextField && (
          <Grid item xs={7}>
            <ValidatorTextField
              label={"Beschreibungstext"}
              name={"description"}
              value={formValue(values, "description")}
              onChange={handleChange}
              onBlur={handleBlur}
              registerValidators={registerValidators}
              validators={requiredFieldDefault}
              error={!!errors["description"]}
              helperText={errors["description"]}
            />
          </Grid>
        )}
        {showElectronicFileFolderSelect && (
          <Grid item xs={5}>
            <ValidatorSelect
              label={"Ablage in Ordner"}
              name={"electronicFileFolderPath"}
              value={formValue(values, "electronicFileFolderPath")}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <MenuItem value={"blank"} disabled>
                {" "}
              </MenuItem>
              <MenuItem value={"Mandant"}>Mandant</MenuItem>
              <MenuItem value={"Gerichtliches Verfahren"}>Gerichtliches Verfahren</MenuItem>
              <MenuItem value={"Außergerichtliches Verfahren"}>Außergerichtliches Verfahren</MenuItem>
              <MenuItem value={"RSV"}>RSV</MenuItem>
              <MenuItem value={"ToDo"}>{"ToDo"}</MenuItem>
              <MenuItem value={"Rechnungen"}>Rechnungen</MenuItem>
              <MenuItem value={"Sonstiges"}>Sonstiges</MenuItem>
            </ValidatorSelect>
          </Grid>
        )}
      </Grid>
    </LegalbirdIoModal>
  );
};

MediaObjectFormModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  submitDisabled: PropTypes.bool,
  fileData: PropTypes.object.isRequired,
};

export default MediaObjectFormModal;
